import { Product } from "../models";

export const classroomUrlMap = (products = []) => {
  if (products.includes(Product.TS)) {
    return "ts";
  } else if (products.includes(Product.CE) && products.includes(Product.TRK)) {
    return "ce+trek";
  } else if (products.includes(Product.CE)) {
    return "ce";
  }

  return "ts";
};
