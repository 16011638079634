import clsx from "clsx";
import React from "react";
import { useLocation } from "react-router-dom";

import {
  DataTable,
  Column,
  ColumnOptions,
  ColumnType,
  BasicCell,
  SortDirection,
} from "@transfr-inc/dashboard-components/data-table";

import { Rating } from "@transfr-inc/dashboard-components";
import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";

import {
  DataTableColumnDefinition,
  getUserDetailPathConfig,
} from "../../../users/list/data-table-column-definition";

import { CheckedCell } from "../../../../components/table-cells";
import { ColumnHeaderInformationTooltip } from "../../../../components/custom/content/column-header-information-tooltip";

import { getTotalCareerGoals } from "../../../../models";

import "./classroom.trek-careers-explored.table.scss";

export function ClassroomTrekCareersExploredTable({
  data,
  responsiveStyles,
  onGoalsClick,
}) {
  const { classroomId } = useLocation().state;
  const breadcrumbLabel = "Classroom";
  const columns = [
    DataTableColumnDefinition.AVATAR,
    DataTableColumnDefinition.LAST_NAME(
      getUserDetailPathConfig({ breadcrumbLabel, classroomId })
    ),
    DataTableColumnDefinition.FIRST_NAME(
      getUserDetailPathConfig({ breadcrumbLabel, classroomId })
    ),
    DataTableColumnDefinition.USERNAME,
    new Column(
      "viewedAt",
      "date viewed",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => {
          const formattedDate = value ? formatDateDigits(value) : undefined;
          return <BasicCell value={formattedDate}></BasicCell>;
        },
      })
    ),
    new Column(
      "goals",
      "Goals",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value, row) => {
          const totalGoals = getTotalCareerGoals(value);
          const cellValue =
            totalGoals > 0 ? (
              <button onClick={() => onGoalsClick(row)}>{totalGoals}</button>
            ) : undefined;
          return <BasicCell value={cellValue} />;
        },
      })
    ),
    new Column(
      "isFavorited",
      (
        <ColumnHeaderInformationTooltip
          headerTitle={"Favorited"}
          tooltipText={
            "Favorited from within the Trek app. This does not include simulations favorited in the headset."
          }
        ></ColumnHeaderInformationTooltip>
      ),
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => {
          return value ? <Rating value={1} maxValue={1} /> : <BasicCell />;
        },
      })
    ),
    new Column(
      "isExploredVR",
      "Explored VR",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (isExploredVR) => (
          <CheckedCell value={isExploredVR} nullable />
        ),
      })
    ),
    new Column(
      "isSelected",
      "Career Selected",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (isSelected) => <CheckedCell value={isSelected} />,
      })
    ),
  ];

  return (
    <DataTable
      className={clsx(
        "classroom-trek-careers-explored-table",
        responsiveStyles
      )}
      columns={columns}
      data={data}
      keyFields={["userId"]}
      defaultSortPropName={"latestTimeStamp"}
      defaultSortDirection={SortDirection.DESCENDING}
      responsiveStyles={responsiveStyles}
      rowHeightConfig={{ large: 54, medium: 160, small: 280 }}
    ></DataTable>
  );
}
