import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";

import { PageLayout } from "@transfr-inc/dashboard-components/layouts";

import {
  Tab,
  TabHeaders,
  Tabs,
  TextTooltip,
} from "@transfr-inc/dashboard-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProductTabHeader } from "../../../components/custom/tabs/product-tab-header";
import container from "../../../container";
import { copyToClipboard } from "../../../lib/browser-utils";
import { useApiRequest } from "../../../lib/http-client";
import { Product } from "../../../models";
import { MyWorkTab } from "../components/my-work.tab";
import "./index.scss";

export default () => {
  const { currentUser } = useStoreState((store) => store.account);
  const { ceStudentExperienceEnabled } = useStoreState((state) => state.app);

  const { userId, organizationCode } = currentUser;
  const [isLoading, setIsLoading] = useState(true);
  const [trainee, setTrainee] = useState();
  const [classrooms, setClassrooms] = useState();
  const { userService, organizationService } = container;

  const { loading: loadingTrainee } = useApiRequest(() =>
    userService.getUserDetails(userId).then(setTrainee)
  );

  const { response: organization, sendRequest: getOrgDetails } = useApiRequest(
    () => organizationService.getOrganizationDetails(organizationCode),
    false
  );

  const { loading: loadingClassrooms } = useApiRequest(() =>
    userService
      .getUserClassrooms(userId)
      .then((results) =>
        results.filter(
          (r) =>
            (ceStudentExperienceEnabled && r.products.includes(Product.CE)) ||
            r.products.includes(Product.TS)
        )
      )
      .then(setClassrooms)
  );

  useEffect(() => {
    !loadingTrainee && getOrgDetails();
  }, [loadingTrainee]);

  useEffect(() => {
    setIsLoading(loadingClassrooms || loadingTrainee || !organization);
  }, [loadingClassrooms, loadingTrainee, organization]);

  const getSelectedTab = () => {
    return classrooms[0]?.classroomId;
  };

  return (
    !isLoading && (
      <PageLayout className={"trainee-detail-page"}>
        <div className="trainee-detail-content">
          <div className="trainee-classrooms-header">
            <h2>My Work</h2>
          </div>
          {classrooms?.length > 0 ? (
            <Tabs
              className="trainee-detail-tabs"
              selectedTabName={isLoading ? "" : getSelectedTab()}
            >
              <TabHeaders className="classroom-tabs">
                {classrooms?.map((c) => (
                  <ProductTabHeader
                    key={c.classroomId}
                    name={c.classroomId}
                    productId={c.products[0]}
                    text={c.name}
                  />
                ))}
              </TabHeaders>
              {classrooms?.map((classroom) => (
                <Tab
                  key={classroom.classroomId}
                  name={classroom.classroomId}
                  className={"classroom-tab"}
                >
                  <h2
                    className="classroom-code"
                    onClick={() => copyToClipboard(classroom.code)}
                  >
                    Classroom Code
                    <div className="code">
                      {classroom.code}
                      <TextTooltip
                        className="info"
                        text="Copied to clipboard"
                        trigger="click"
                      >
                        <FontAwesomeIcon icon="fa-regular fa-copy" />
                      </TextTooltip>
                    </div>
                  </h2>
                  <MyWorkTab
                    trainee={trainee}
                    classroom={classroom}
                    organization={organization}
                  />
                </Tab>
              ))}
            </Tabs>
          ) : (
            <div className="no-classrooms-container">
              This trainee is not enrolled in a classroom.
            </div>
          )}
        </div>
      </PageLayout>
    )
  );
};
