import { action } from "easy-peasy";

// Top level application state management
export default ({ toggles, appSettingsCache }) => {
  const appSettings = appSettingsCache.get();

  return {
    ...toggles,
    lastEntryPoint: appSettings?.lastEntryPoint,
    setLastEntryPoint: action((state, value) => {
      if (state.lastEntryPoint !== value) {
        state.lastEntryPoint = value;
        appSettingsCache.set({ lastEntryPoint: value });
      }
    }),
    dashboardLoaded: false,
    setDashboardLoaded: action((state) => {
      state.dashboardLoaded = true;
    }),
    redirect: undefined,
    setRedirect: action((state, value) => {
      state.redirect = value;
    }),
  };
};
