import { useMemo, useState } from "react";
import { ExportStatus, doExportData } from "./export-utils";

import container from "../container";
import { Product, Role } from "../models";
import { exportOrganizationCeStats } from "../services";

import { checkPromisesFulfilled, getPromisesValues } from "./browser-utils";

const exploredVrHeader = "Careers Explored";

const exportOptions = { createFile: false };

const mergeTrekExportData = ({
  trekSummary,
  ceStats,
  namesMap,
  headerTitle,
}) => {
  const exploredVrMap = new Map(
    ceStats.data
      ?.filter((values) => values[exploredVrHeader] !== "")
      .map((values) => [values[headerTitle], values[exploredVrHeader]])
  );

  return Object.entries(trekSummary).map(([key, stats]) => {
    const nameValue = namesMap.get(key);
    const exploredVrValue = exploredVrMap.get(nameValue);

    return {
      [headerTitle]: nameValue,
      "Unique Explorers": stats.totalActiveUsers,
      "Explored VR": exploredVrValue ?? 0,
      "Careers Explored": stats.totalCareersExplored,
      "Careers Selected": stats.totalPlansSubmitted,
    };
  });
};

export const useTrekExport = ({ organizations, classrooms }) => {
  const { trekService, organizationService, classroomService } = container;
  const [exportStatus, setExportStatus] = useState();

  const [isLoading, hasError] = useMemo(
    () => [
      exportStatus === ExportStatus.creating,
      exportStatus === ExportStatus.error,
    ],
    [exportStatus]
  );

  const [organizationsNamesMap, classroomsNamesMap] = useMemo(
    () => [
      new Map(organizations.map((c) => [c.code, c.name])),
      new Map(classrooms.map((c) => [c.classroomId, c.name])),
    ],
    [organizations, classrooms]
  );

  const organizationHeaderTitle = "Organization";
  const classroomHeaderTitle = "Classroom";

  const exportTrekData = async ({
    orgCode,
    classroomId,
    filters,
    prefixFileName,
    role,
    userId,
    isMultiOrg,
  }) => {
    const fileName = `${prefixFileName} - Trek`;
    let csvData;
    setExportStatus(ExportStatus.creating);

    try {
      if (classroomId) {
        csvData = await getClassroomCSVData({ classroomId, orgCode, filters });
      } else if (orgCode && isMultiOrg) {
        csvData = await getMultiOrgCSVData({ orgCode, filters });
      } else {
        csvData = await getSingleOrgCSVData({ orgCode, role, userId, filters });
      }

      await doExportData(fileName, csvData);
      setExportStatus(ExportStatus.complete);
    } catch (error) {
      console.error(error);
      setExportStatus(ExportStatus.error);
    }
  };

  const getMultiOrgCSVData = async ({ orgCode, filters }) => {
    const filtersWithProduct = { productId: Product.TRK, ...filters };
    const exportRequest = organizationService.exportMultiOrganizationCeStats(
      orgCode,
      filtersWithProduct,
      undefined,
      exportOptions
    );

    const [trekSummary, ceStats] = await Promise.allSettled([
      trekService.getInsightsGrouped({
        orgCode,
        filters,
      }),
      exportRequest,
    ])
      .then(checkPromisesFulfilled)
      .then(getPromisesValues);

    return mergeTrekExportData({
      trekSummary,
      ceStats,
      namesMap: organizationsNamesMap,
      headerTitle: organizationHeaderTitle,
    });
  };

  const getSingleOrgCSVData = async ({ orgCode, role, userId, filters }) => {
    const filtersWithProduct = { productId: Product.TRK, ...filters };
    const orgCodeOrUserId = role === Role.INSTRUCTOR.id ? userId : orgCode;
    const exportRequest = exportOrganizationCeStats(
      role,
      orgCodeOrUserId,
      filtersWithProduct,
      undefined,
      false,
      exportOptions
    );

    const [trekSummary, ceStats] = await Promise.allSettled([
      trekService.getInsightsGrouped({
        orgCode,
        filters,
      }),
      exportRequest,
    ])
      .then(checkPromisesFulfilled)
      .then(getPromisesValues);

    return mergeTrekExportData({
      trekSummary,
      ceStats,
      namesMap: classroomsNamesMap,
      headerTitle: classroomHeaderTitle,
    });
  };

  const getClassroomCSVData = async ({ classroomId, orgCode, filters }) => {
    const filtersWithProduct = { productId: Product.TRK, ...filters };
    const exportRequest = classroomService.exportClassroomCeStats(
      classroomId,
      filtersWithProduct,
      undefined,
      exportOptions
    );

    const [trekSummary, ceStats] = await Promise.allSettled([
      trekService.getInsights({
        classroomId,
        orgCode,
        filters,
      }),
      exportRequest,
    ])
      .then(checkPromisesFulfilled)
      .then(getPromisesValues);

    return mergeTrekExportData({
      trekSummary: { [classroomId]: trekSummary },
      ceStats,
      namesMap: classroomsNamesMap,
      headerTitle: classroomHeaderTitle,
    });
  };

  return {
    hasError,
    isLoading,
    exportTrekData,
  };
};
