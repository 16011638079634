import React, { useEffect } from "react";
import clsx from "clsx";
import { Role } from "../../models/role";

import {
  useScreenBreakpoint,
  useButtonBarShift,
} from "@transfr-inc/dashboard-components/hooks";

import { NavbarItemProfile } from "./navbar-item.profile";
import avatarImage from "../../../public/images/avatar.png";

import transfrLogo from "../../../public/images/logo.svg";
import "./navbar.scss";
import { NavbarRoutes } from "./navbar.routes";

import {
  NavBar,
  NavBarMobile,
  NavBarSection,
  useDrawer,
} from "@transfr-inc/dashboard-components";
import { useStoreState } from "easy-peasy";

export function NavigationBar({ onSkipNavigation, isStudentExperience }) {
  const { role } = useStoreState((store) => store.account);
  const { isMobile, isTablet, isDesktop } = useScreenBreakpoint();
  const leftDrawer = useDrawer();
  const navItemsMode = isMobile ? "more" : "full";
  const { toggleShift, revertShift } = useButtonBarShift();

  useEffect(() => {
    toggleShift();
    return () => revertShift();
  }, [leftDrawer.isExpanded]);

  const drawerContent = (
    <>
      <div className="skip-nav">
        <a href="#" onClick={(e) => onSkipNavigation(e)}>
          Skip Navigation
        </a>
      </div>
      <div>
        <img
          className={clsx(
            "logo",
            !leftDrawer.isExpanded && !isMobile && "wrapped"
          )}
          src={transfrLogo}
          alt="transfr logo"
        ></img>
      </div>
      <div
        className={clsx(
          "items-container",
          !leftDrawer.isExpanded && !isMobile && "collapsed"
        )}
      >
        <NavbarRoutes
          isExpanded={isMobile ? true : leftDrawer.isExpanded}
          mode={navItemsMode}
        ></NavbarRoutes>
      </div>
      <NavbarItemProfile
        isExpanded={isMobile ? true : leftDrawer.isExpanded}
      ></NavbarItemProfile>
    </>
  );

  return !isMobile ? (
    <NavBar
      className={clsx("unified-dashboard-navbar")}
      onSkipNavigation={onSkipNavigation}
      isStudentExperience={isStudentExperience}
    >
      <NavBarSection>
        <NavbarRoutes
          isExpanded={isMobile ? true : leftDrawer.isExpanded}
          mode={navItemsMode}
        ></NavbarRoutes>
      </NavBarSection>
      <NavBarSection className="bottom-section">
        <NavbarItemProfile
          isExpanded={isMobile ? true : leftDrawer.isExpanded}
        ></NavbarItemProfile>
      </NavBarSection>
    </NavBar>
  ) : (
    <NavBarMobile
      drawerContent={drawerContent}
      moreImage={avatarImage}
      useMore={role != Role.STUDENT.id}
    >
      <NavbarRoutes isExpanded={false} mode="mobile"></NavbarRoutes>
      {role == Role.STUDENT.id && (
        <NavbarItemProfile className={"hide-more"}></NavbarItemProfile>
      )}
    </NavBarMobile>
  );
}
