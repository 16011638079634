import { action, thunk } from "easy-peasy";

export default ({ integrationService }) => ({
  icons: {},

  setIcon: action((state, { providerId, icon }) => {
    state.icons[providerId] = icon;
  }),

  loadIcon: thunk(async (actions, providerId, { getState }) => {
    const { icons } = getState();
    if (!icons[providerId]) {
      const icon = await integrationService.getProviderIconRect(providerId);
      actions.setIcon({ providerId, icon });
    }
  }),
});
