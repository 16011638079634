import React from "react";
import { useStoreState } from "easy-peasy";

import {
  Link,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";

// TODO: Move function to library
export function formatDateDigits(date = new Date(), twoDigits = false) {
  date = date instanceof Date ? date : new Date(date);
  const digitConfig = twoDigits ? "2-digit" : "numeric";
  const dateArray = date
    .toLocaleDateString("en-US", {
      month: digitConfig,
      year: "2-digit",
      day: digitConfig,
      hour: "2-digit",
      minute: "2-digit",
      timeZoneName: "short",
      timeZone: "America/New_York",
    })
    .split("/");

  return [dateArray[0], dateArray[1], dateArray[2]]
    .join("/")
    .replace(",", " at ");
}

const TimeRangeString = `This site will be down for planned maintenance from {startDate} to {endDate}.`;
const OnlyStartDateString = `This site will be down for planned maintenance starting {startDate} for {maintenanceDuration} hours.`;

const getMessage = (startDate, endDate, maintenanceDuration) => {
  return startDate && endDate
    ? TimeRangeString.replace(
        "{startDate}",
        formatDateDigits(startDate)
      ).replace("{endDate}", formatDateDigits(endDate))
    : OnlyStartDateString.replace(
        "{startDate}",
        formatDateDigits(startDate)
      ).replace("{maintenanceDuration}", maintenanceDuration);
};

export const MaintenanceWarningMessage = ({ onClose }) => {
  const {
    maintenanceStartDate,
    maintenanceEndDate,
    maintenanceWindowInHours,
    maintenanceWarningDisplayStartDate,
  } = useStoreState((store) => store.app);

  const startDate = new Date(maintenanceStartDate);
  const endDate = maintenanceEndDate && new Date(maintenanceEndDate);
  const displayStartDate =
    maintenanceWarningDisplayStartDate &&
    new Date(maintenanceWarningDisplayStartDate);
  const maintenanceDuration = maintenanceWindowInHours;

  const message = getMessage(startDate, endDate, maintenanceDuration);

  const calculatedEndDate = startDate.setHours(
    startDate.getHours() + maintenanceWindowInHours
  );
  // When no end date is provided the warning will show until the flag is set to false
  // When no display start date is provided the warning will start showing immediately
  const showWarning =
    (endDate
      ? endDate?.getTime() > Date.now()
      : calculatedEndDate > Date.now()) &&
    (displayStartDate ? displayStartDate?.getTime() < Date.now() : true);

  return (
    showWarning && (
      <Notification
        className="maintenance-warning"
        type={NotificationType.warning}
        icon={"fa-triangle-exclamation"}
        onClose={onClose}
        closable
      >
        {message}
        <br />
        If you have any questions, please contact our support team at{" "}
        <Link
          to="mailto:technicalsupport@transfrvr.com"
          isExternal
          newTab={false}
        >
          technicalsupport@transfrvr.com
        </Link>
      </Notification>
    )
  );
};
