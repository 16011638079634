import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Loader,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import "./trainee-modals.scss";

const getTraineeName = (trainee) =>
  `${trainee.firstName} ${trainee.lastName}`.trim();

export function RemoveTraineeModal({
  open,
  onClose,
  onRemoveTrainee,
  trainee,
  classroom,
}) {
  const [loading, setLoading] = useState();

  const onCloseHandler = () => {
    onClose && onClose();
  };

  const onRemoveTraineeHandler = () => {
    setLoading(true);
    onRemoveTrainee && onRemoveTrainee(trainee, classroom).finally(setLoading);
  };

  return (
    <Modal
      modalClassName="remove-trainee-modal"
      open={open}
      onClose={onCloseHandler}
    >
      {loading && <Loader overlay></Loader>}
      <ModalHeader
        className="red-icon"
        icon={["fa-light", "user-xmark"]}
        title="Remove Trainee"
      ></ModalHeader>
      <ModalBody>
        Are you sure you would like to remove{" "}
        <strong>{getTraineeName(trainee)}</strong> from{" "}
        <strong>{classroom.name}</strong> ?
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCloseHandler}>No, Cancel</Button>
        <Button destructive onClick={onRemoveTraineeHandler}>
          Yes, Remove Trainee
        </Button>
      </ModalFooter>
    </Modal>
  );
}
