import { RequestProgressEvent } from "./lib/http-client";
import { default as LocalStorageCache } from "./lib/local-storage";
import {
  AccountService,
  ClassroomService,
  InsightsService,
  IntegrationService,
  MenuBuilderService,
  OrganizationService,
  SimulationsService,
  TrekService,
  UserService,
} from "./services";

/* Poor mans DI */
const caches = {
  currentUserCache: new LocalStorageCache("app/dashboard/currentUser", 86400),
  tokenCache: new LocalStorageCache("app/dashboard/token", 86400),
  appSettingsCache: new LocalStorageCache("app/dashboard/settings", 86400),
  dateSettingsCache: new LocalStorageCache("app/dashboard/dateSettings", 86400),
  proProfsCache: new LocalStorageCache("app/dashboard/proProfs", 86400),
};

/* Poor mans DI */
const toggles = {
  ceEnabled: process.env.IS_CE_ENABLED.toUpperCase() === "TRUE",
  menuBuilderEnabled:
    process.env.IS_MENU_BUILDER_ENABLED.toUpperCase() === "TRUE",
  simPassEnabled: process.env.IS_SIM_PASS_ENABLED.toUpperCase() === "TRUE",
  maintenanceWarningEnabled:
    process.env.MAINTENANCE_WARNING_ENABLED.toUpperCase() === "TRUE",
  maintenanceStartDate: process.env.MAINTENANCE_START_DATE,
  maintenanceEndDate: process.env.MAINTENANCE_END_DATE,
  maintenanceWindowInHours: process.env.MAINTENANCE_WINDOW_IN_HOURS,
  maintenanceWarningDisplayStartDate:
    process.env.MAINTENANCE_WARNING_DISPLAY_START_DATE,
  proProfsEnabled: process.env.PROPROFS_ENABLED.toUpperCase() === "TRUE",
  trekEnabled: process.env.TREK_BETA_ENABLED.toUpperCase() === "TRUE",
  integrationsEnabled:
    process.env.INTEGRATIONS_ENABLED.toUpperCase() === "TRUE",
  snowplowEnabled: process.env.SNOWPLOW_ENABLED.toUpperCase() === "TRUE",
  selfSignupEnabled: process.env.SELF_SIGNUP_ENABLED.toUpperCase() === "TRUE",
  snowplowCollectorUrl: process.env.SNOWPLOW_COLLECTOR_URL,
  ceStudentExperienceEnabled:
    process.env.CE_STUDENT_EXPERIENCE_ENABLED === "TRUE",
  rosteringEnabled: process.env.ROSTERING_ENABLED === "TRUE",
  missionControlEnabled:
    process.env.IS_MISSION_CONTROL_ENABLED.toUpperCase() === "TRUE",
};

/* Poor mans DI */
const serviceConfig = { timeout: 300000 };
const requestProgressConfig = {
  progressTimeout: 60000,
  progressTimeoutHandler: (url, status) => {
    const requestTimeoutEvent = new CustomEvent(RequestProgressEvent, {
      detail: { url, status },
    });
    document.dispatchEvent(requestTimeoutEvent);
  },
};
const services = {
  accountService: new AccountService(
    process.env.DASHBOARD_API_URL,
    { publicPaths: ["/auth/login", "/auth/reset"], ...serviceConfig },
    caches,
    requestProgressConfig
  ),
  organizationService: new OrganizationService(
    process.env.DASHBOARD_API_URL,
    serviceConfig,
    caches,
    requestProgressConfig
  ),
  classroomService: new ClassroomService(
    process.env.DASHBOARD_API_URL,
    serviceConfig,
    caches,
    requestProgressConfig
  ),
  userService: new UserService(
    process.env.DASHBOARD_API_URL,
    serviceConfig,
    caches,
    requestProgressConfig
  ),
  insightsService: new InsightsService(
    process.env.INSIGHTS_API_URL,
    serviceConfig,
    caches,
    requestProgressConfig
  ),
  simulationsService: new SimulationsService(
    process.env.DASHBOARD_API_URL,
    serviceConfig,
    caches,
    requestProgressConfig
  ),
  menuBuilderService: new MenuBuilderService(
    process.env.DASHBOARD_API_URL,
    serviceConfig,
    caches,
    requestProgressConfig
  ),
  integrationService: new IntegrationService(
    process.env.DASHBOARD_API_URL,
    serviceConfig,
    caches,
    requestProgressConfig
  ),
  trekService: new TrekService(
    process.env.CESE_API_URL,
    serviceConfig,
    caches,
    requestProgressConfig
  ),
};

/**
 * A container representing service and cache facilities
 * for our stores to consume
 * @typedef Container
 * @type {object}
 *
 * @property {LocalStorageCache} currentUserCache - A user cache for the logged in user
 * @property {LocalStorageCache} tokenCache - A JWT token cache used for our HttpClient
 * @property {LocalStorageCache} dateSettingsCache - A cache for the date settings
 * @property {AccountService} accountService - Account service
 * @property {OrganizationService} organizationService - Organization service
 * @property {UserService} userService - User service
 * @property {ClassroomService} classroomService - User service
 * @property {MenuBuilderService} menuBuilderService - Menu builder service
 * @property {TrekService} trekService - Trek service
 *
 */
export default {
  ...caches,
  ...services,
  toggles,
};
