import clsx from "clsx";
import React from "react";

import { Product } from "../../../models/product";
import { Product as ProductImage } from "../../products/product";

export const ProductDropdownOption = ({
  product,
  productImagesEnabled,
  cePlusTrkEnabled,
  className,
}) => {
  const optionText =
    product.id == Product.TRK && cePlusTrkEnabled
      ? "CE + Trek"
      : product.displayName;

  return (
    <div className={clsx("product-dropdown-option", className)}>
      {productImagesEnabled && (
        <ProductImage productId={product?.id}></ProductImage>
      )}
      <span title={optionText}>{optionText}</span>
    </div>
  );
};
