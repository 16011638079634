import React, { useEffect, useState } from "react";
import { useScreenBreakpoint } from "@transfr-inc/dashboard-components/hooks";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { useStoreActions, useStoreState } from "easy-peasy";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useQueryString } from "../../lib/hooks";
import { NonStudentSignupForm } from "./components/non-student-create-account.form";
import { StudentCreateAccountForm } from "./components/student-create-account.form";

import {
  SideImageLayout,
  LegalFooter,
} from "@transfr-inc/dashboard-components/layouts";
import { Terms, PrivacyPolicy } from "../../models/links";

import forgotPasswordImage from "../../../public/images/forgot-password.svg";
import createAccountImage from "../../../public/images/create.svg";

import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";

import "./create-account.scss";

export default function CreateAccount() {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, dirtyFields, isValid },
  } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: { termsAndConditionsVersion: 0 },
  });

  const actions = useStoreActions((store) => store.resetPassword);
  const state = useStoreState((store) => store.resetPassword);
  const { logout } = useStoreActions((state) => state.account);
  const [termsChecked, setTermsChecked] = useState(false);
  const [sideImage, setSideImage] = useState();
  const [layoutTitle, setLayoutTitle] = useState();
  const [notAcceptedNotification, setNotAcceptedNotification] = useState();
  const { isMobile } = useScreenBreakpoint();

  const handleChecked = (value) => {
    if (typeof value === "boolean") {
      setTermsChecked(value);
    } else {
      setTermsChecked(!termsChecked);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (termsChecked) {
      setValue("termsAndConditionsVersion", Terms.version);
      handleSubmit(actions.onSubmit)();
    } else {
      setNotAcceptedNotification({
        type: NotificationType.error,
        message: "Required fields are missing.",
      });
    }
  };

  const history = useHistory();
  const queryParams = useQueryString();

  // onload
  useEffect(() => {
    actions.onPageLoad(queryParams);
  }, []);

  useEffect(() => {
    // Any error when validating the token will be handled as an expired token
    if (!state.loadState.blocking && state.loadState.error) {
      setLayoutTitle(["Invitation", "Expired"]);
    }
  }, [state.loadState]);

  // onsave
  useEffect(() => {
    if (state.saveState.success) {
      goLoginPage();
    }
    if (state.saveState.error) {
      setNotAcceptedNotification({
        type: NotificationType.error,
        message: "Something went wrong.",
      });
    }
  }, [state.saveState.success, state.saveState.error]);

  useEffect(() => {
    if (state.model.self_signup) {
      setLayoutTitle(["Create", "Password"]);
      setSideImage(createAccountImage);
    } else {
      setLayoutTitle(["Create", "Account"]);
      setSideImage(forgotPasswordImage);
    }
  }, [state.model.self_signup]);

  const goLoginPage = () => {
    logout({ logOutRequested: true });
    history.push({
      pathname: "/login",
      state: {
        passwordResetSuccess: true,
      },
    });
  };

  return (
    <SideImageLayout
      className={"create-account"}
      title={layoutTitle}
      image={sideImage}
      showMobileImage={isMobile}
      footer={
        <LegalFooter terms={Terms.url} privacyPolicy={PrivacyPolicy.url} />
      }
    >
      <div className="notification-container">
        {notAcceptedNotification && (
          <Notification
            type={notAcceptedNotification.type}
            icon={notAcceptedNotification.icon}
            onClose={() => {
              setNotAcceptedNotification();
            }}
            closable
          >
            {notAcceptedNotification.message}
          </Notification>
        )}
      </div>
      {state.loadState.active && (
        <div>
          {!state.loadState.blocking && state.loadState.error && (
            <div className="account-form">
              <div className="instructions">
                Your reset password link has expired. Please visit the sign in
                page and press “Forgot Password” to try again.
              </div>
              <div>
                <Button
                  className="primary block"
                  label="Return to Sign In"
                  onClick={goLoginPage}
                />
              </div>
            </div>
          )}

          {state.loadState.success && (
            <form
              className="account-form"
              onSubmit={onSubmit}
              noValidate={true}
            >
              {state.model.self_signup ? (
                <StudentCreateAccountForm
                  errors={errors}
                  dirtyFields={dirtyFields}
                  register={register}
                  getValues={getValues}
                  email={state.model.email}
                  blocking={state.saveState.blocking}
                  isValid={isValid}
                  handleChecked={handleChecked}
                  notAcceptedNotification={notAcceptedNotification}
                  termsChecked={termsChecked}
                  onSetLayoutTitle={setLayoutTitle}
                ></StudentCreateAccountForm>
              ) : (
                <NonStudentSignupForm
                  errors={errors}
                  dirtyFields={dirtyFields}
                  register={register}
                  getValues={getValues}
                  email={state.model.email}
                  blocking={state.saveState.blocking}
                  isValid={isValid}
                  handleChecked={handleChecked}
                  notAcceptedNotification={notAcceptedNotification}
                  termsChecked={termsChecked}
                ></NonStudentSignupForm>
              )}
            </form>
          )}
        </div>
      )}
    </SideImageLayout>
  );
}
