import React, { useEffect, useRef, useState } from "react";

import { ClassroomInsights } from "../components/classroom.insights";
import { ClassroomSimulations } from "../components/classroom.simulations";

import { Loader } from "@transfr-inc/dashboard-components";
import { useFilter } from "@transfr-inc/dashboard-components/forms";

import { useApiRequest } from "../../../../lib/http-client";
import { MasteryStatus } from "../../../../models/mastery";
import container from "../../../../container";

import {
  filterModules,
  mergeMasteryInfo,
} from "../components/classroom.modules.utils";

export const StatusFilter = {
  Started: "STARTED",
  Mastered: MasteryStatus.Mastered,
  NeedsWork: MasteryStatus.NeedsWork,
  Stuck: MasteryStatus.Stuck,
  All: "ALL",
};

export const AllModulesOption = "All Modules";

export function ClassroomProgressTsTab({ classroom, trainees }) {
  const [modules, setModules] = useState();
  const [filteredSims, setFilteredSims] = useState();
  const [masteryStats, setMasteryStats] = useState({});
  const [openAccordion, setOpenAccordion] = useState();
  const [filterSelected, setFilterSelected] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [isAllModulesExpanded, setIsAllModulesExpanded] = useState(false);
  const filtersRef = useRef({
    status: undefined,
    objectId: undefined,
    moduleId: undefined,
  });

  const { insightsService } = container;

  const { loading: loadingModules, response: modulesMap } = useApiRequest(() =>
    insightsService
      .getModulesForOrg(classroom.clientCode)
      .then((response) => new Map(response.map((m) => [m.moduleId, m])))
  );

  const { loading: loadingMasteryInfo, response: masteryInfo } = useApiRequest(
    () => insightsService.getClassroomMasteryInfo(classroom.classroomId)
  );

  const { loading: loadingMasteryStats, sendRequest: getClassroomStats } =
    useApiRequest(() => getClassroomsStatsRequest(), false);

  const moduleFilter = useFilter({
    data: modules,
    queryFn: filterModules,
  });

  useEffect(() => {
    if (modulesMap && masteryInfo) {
      const modulesArray = [...modulesMap.values()];
      const mergedData = mergeMasteryInfo(trainees, modulesArray, masteryInfo);
      initModules(mergedData);
      trainees & getClassroomStats();
    }
  }, [modulesMap, masteryInfo, trainees]);

  const getClassroomsStatsRequest = () => {
    const { objectId, moduleId } = filtersRef.current;

    setMasteryStats();
    return insightsService
      .getClassroomMasteryStats(classroom.classroomId, moduleId, objectId)
      .then((response) => {
        const totalSims = getAllSimsCount();
        setMasteryStats({ ...response, totalSims });
        onFilterModules(filtersRef.current);
        setOpenAccordion(objectId || moduleId);
      });
  };

  const getModulesStatusAll = () =>
    moduleFilter.queryData({
      ...filtersRef.current,
      status: StatusFilter.All,
    });

  const getAllSimsCount = () => {
    const totalModules = getModulesStatusAll();
    const reduceFn = (acc, m) => acc + m.simulations.length;
    // eslint-disable-next-line unicorn/no-array-reduce
    const totalSims = totalModules?.reduce((acc, m) => reduceFn(acc, m), 0);
    return totalSims ? totalSims * trainees?.length : 0;
  };

  const initModules = (data) => {
    setModules(data);
    moduleFilter.updateData(data);
    onStatusSelected(StatusFilter.Started);
  };

  const onFilterModules = ({ status, objectId, moduleId }) => {
    const results = moduleFilter.queryData({ status, objectId, moduleId });
    setFilteredSims(results);
  };

  const onModuleSelected = (optionSelected) => {
    const module = optionSelected.length === 1 ? optionSelected[0] : {};
    const { displayName, moduleId } = module;
    filtersRef.current.moduleId = moduleId;
    setFilterSelected(displayName);
    getClassroomStats();
  };

  const onSimNameSelected = (optionSelected) => {
    const { learningObjectiveId: objectId, displayName } = optionSelected || {};
    filtersRef.current.objectId = objectId;
    setSearchTerm(displayName);
    getClassroomStats();
  };

  const onStatusSelected = (status) => {
    filtersRef.current.status = status;
    onFilterModules(filtersRef.current);
  };

  const onClearFilters = () => {
    const { current } = filtersRef;
    current.moduleId = undefined;
    current.objectId = undefined;
    setFilterSelected(AllModulesOption);
    setSearchTerm();
    getClassroomStats();
  };

  const onExpandCollapseAllModules = () => {
    setIsAllModulesExpanded(!isAllModulesExpanded);
  };

  return (
    <>
      {(loadingMasteryInfo || loadingMasteryStats || loadingModules) && (
        <Loader overlay fixed></Loader>
      )}
      <ClassroomInsights
        classroom={classroom}
        masteryStats={masteryStats}
        onStatusSelected={onStatusSelected}
        disabled={loadingMasteryInfo || loadingModules}
        data={modules}
        onModuleSelected={onModuleSelected}
        onSimNameSelected={onSimNameSelected}
        filterSelected={filterSelected}
        searchTerm={searchTerm}
        onExpandCollapseAllModules={onExpandCollapseAllModules}
        isAllModulesExpanded={isAllModulesExpanded}
        currentModuleId={filtersRef.current.moduleId}
      ></ClassroomInsights>
      <ClassroomSimulations
        data={filteredSims}
        classroom={classroom}
        trainees={trainees}
        openAccordion={openAccordion}
        onClearFilters={onClearFilters}
        onExpandCollapseAllModules={onExpandCollapseAllModules}
        isAllExpanded={isAllModulesExpanded}
      ></ClassroomSimulations>
    </>
  );
}
