import React from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./shortcuts.header.scss";

export default ({ image, icon, className }) => {
  return (
    <div className={clsx("shortcuts-header", className)}>
      <div className="image" style={{ backgroundImage: `url(${image})` }}></div>
      <div className="badge">
        <FontAwesomeIcon icon={icon} inverse />
      </div>
    </div>
  );
};
