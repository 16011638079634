import React from "react";

import { Route, Switch, useRouteMatch } from "react-router-dom";
import { default as UserList } from "./list";
import { default as TraineeDetail } from "./trainee-detail";
import { default as ContainerRoute } from "../../components/common/routing/container-route";

export default function Users({ responsiveStyles = {} }) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ContainerRoute
        exact={true}
        path={path}
        component={UserList}
        responsiveStyles={responsiveStyles}
      ></ContainerRoute>
      <Route
        exact={true}
        path={`${path}/:userId`}
        component={TraineeDetail}
      ></Route>
    </Switch>
  );
}
