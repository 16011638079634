import React, { useMemo, useState, useEffect } from "react";

import { NotificationType } from "@transfr-inc/dashboard-components";
import { useStoreState, useStoreActions } from "easy-peasy";

import { Button } from "@transfr-inc/dashboard-components/forms";

import {
  SyncIntegrationErrorsModal,
  SyncIntegrationModal,
} from "../../../../components/modals";
import { RemoveIntegrationModal } from "../../../../components/modals";
import IntegrationDate from "./integration-date";
import container from "../../../../container";
import { Link } from "@transfr-inc/dashboard-components";

import "./integration-block.scss";
import IntegrationDateWithBadge from "./integration-date-with-badge";

const IntegrationBlock = ({
  integration,
  launchNotification,
  refresh,
  loading,
}) => {
  const { currentUser } = useStoreState((store) => store.account);
  const { id: userId } = currentUser;
  const { integrationService } = container;
  const { id, providerId, createdDate, lastRunSyncJobId } = integration;

  const icon = useStoreState((state) => state.providerIcon.icons[providerId]);
  const loadIcon = useStoreActions((actions) => actions.providerIcon.loadIcon);

  useEffect(() => {
    loadIcon(providerId);
  }, [providerId, loadIcon]);

  const lastRunDate = integration?.lastRunDate;
  const lastRunStatus = integration?.lastRunStatus;
  const errors = integration?.errors;

  const [showSyncModal, setShowSyncModal] = useState();
  const [showRemoveIntegrationModal, setShowRemoveIntegrationModal] =
    useState();
  const [showSyncErrorsModal, setShowSyncErrorsModal] = useState();

  const handleCloseModal = () => {
    setShowSyncModal();
    setShowRemoveIntegrationModal();
    setShowSyncErrorsModal();
  };

  const syncIntegration = async (data) => {
    try {
      await integrationService.syncIntegration(data.id, userId);
      refresh(lastRunDate, id);
    } catch {
      launchNotification({
        type: NotificationType.error,
        message: `An error occurred and your ${
          data.providerName || ""
        } integration did not sync properly. Please try again. ${(
          <a onClick={() => setShowSyncErrorsModal(true)}>View Errors</a>
        )}`,
        icon: ["fa-solid", "triangle-exclamation"],
      });
    }
    handleCloseModal();
  };

  const onRemoveIntegration = async (data) => {
    try {
      await integrationService.removeIntegration(data.id);
      launchNotification({
        type: NotificationType.success,
        message: `Your ${
          data.providerName || ""
        } integration has been removed.`,
        icon: ["fa-solid", "circle-check"],
      });

      refresh();
    } catch {
      launchNotification({
        type: NotificationType.error,
        message: `An error ocurred during integration removal. Please try again.`,
        icon: ["fa-solid", "triangle-exclamation"],
      });
    }
    handleCloseModal();
  };

  const syncInfo = useMemo(() => {
    if (lastRunStatus === "Success" || lastRunStatus === "Failed") {
      return (
        <div className="block-sync-date">
          <div className="sync-title">Last Sync</div>
          <div className="sync-date">
            <IntegrationDateWithBadge connection={integration} />
          </div>
        </div>
      );
    }
    return;
  }, [lastRunDate, lastRunStatus, errors, lastRunSyncJobId, id]);

  return (
    <div className="integration-block-container">
      <div className="left-container">
        <div className="block-name">
          <img className="provider-image" src={icon} alt={integration.name} />
        </div>
        <Link underline to={`/app/integrations/${id}/sync-history`}>
          View Event Log
        </Link>
        <div className="block-sync-date">
          <div className="sync-title">Integrated</div>
          <div className="sync-date">
            <IntegrationDate date={createdDate} />
          </div>
        </div>
        {syncInfo}
      </div>

      <div className="right-container">
        <Button
          className="sync-button"
          icon={["fa-regular", "rotate"]}
          onClick={() => setShowSyncModal(true)}
          disabled={loading}
        >
          Sync
        </Button>
        <Button
          icon={["fa-regular", "times"]}
          borderless
          onClick={() => setShowRemoveIntegrationModal(true)}
        />
      </div>
      <SyncIntegrationModal
        integration={integration}
        open={showSyncModal}
        onCancel={handleCloseModal}
        onSyncIntegration={syncIntegration}
      />
      <RemoveIntegrationModal
        integration={integration}
        open={showRemoveIntegrationModal}
        onCancel={handleCloseModal}
        onRemoveIntegration={onRemoveIntegration}
      />
      <SyncIntegrationErrorsModal
        open={showSyncErrorsModal}
        onClose={handleCloseModal}
        errors={errors}
      />
    </div>
  );
};

export default IntegrationBlock;
