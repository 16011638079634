import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import { default as ClassroomList } from "./list";
import { default as ClassroomDetails } from "./details";
import { default as ContainerRoute } from "../../components/common/routing/container-route";

export default function Classrooms({ responsiveStyles = {} }) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ContainerRoute
        exact={true}
        path={path}
        component={ClassroomList}
        responsiveStyles={responsiveStyles}
      ></ContainerRoute>
      <ContainerRoute
        exact={true}
        path={`${path}/:product/:code`}
        component={ClassroomDetails}
        responsiveStyles={responsiveStyles}
      ></ContainerRoute>
    </Switch>
  );
}
