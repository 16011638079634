import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { RequiredItem } from "@transfr-inc/dashboard-components";

import { UserInfo } from "../../../components/custom/forms";
import { OrganizationDropdownList } from "../../../components/custom/dropdowns/organization-dropdown-list";
import { ClassroomDropdownList } from "../../../components/custom/dropdowns/classroom-dropdown-list";

import "./new-user-form.scss";

export const NewUserForm = ({
  role,
  setFormInvalid,
  emailOptional,
  orgSelectionEnabled,
  classroomSelectionEnabled,
  selectedOrg,
  selectedClassroom,
  onOrgSelected,
  onClassroomSelected,
  responseNotification,
  onNotificationChange,
}) => {
  const {
    formState: { isValid },
  } = useFormContext();
  useEffect(() => {
    setFormInvalid(!isValid || (!selectedOrg && orgSelectionEnabled));
  }, [isValid, selectedClassroom]);

  return (
    <>
      <div className="organization-section">
        {orgSelectionEnabled && role && (
          <>
            <div className="section-title">
              <RequiredItem text="Organization" />
            </div>
            <OrganizationDropdownList
              className="dropdown"
              onOrgSelected={onOrgSelected}
              selectedOrg={selectedOrg}
              required
            />
          </>
        )}
      </div>
      <div className="classroom-section">
        {classroomSelectionEnabled && (selectedOrg || !orgSelectionEnabled) && (
          <ClassroomDropdownList
            className="dropdown"
            onClassroomSelected={onClassroomSelected}
            selectedClassroom={selectedClassroom}
            orgCode={selectedOrg?.code}
          />
        )}
      </div>
      <div className="user-section">
        {((orgSelectionEnabled && selectedOrg) || !orgSelectionEnabled) &&
          role && (
            <div className="user-details">
              <div className="section-title">User Details</div>
              <UserInfo
                className="user-info"
                role={role}
                emailOptional={emailOptional}
                responseNotification={responseNotification}
                onNotificationChange={onNotificationChange}
                required
              />
            </div>
          )}
      </div>
    </>
  );
};
