import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import "./confirm-demo-modal.scss";

export function ConfirmDemoModal({ open, onBackToModal, onConfirmClose }) {
  const onBackToAddTraineeModalHandler = () => {
    onBackToModal && onBackToModal();
  };

  return (
    <Modal
      modalClassName="are-you-sure-modal"
      open={open}
      onClose={onBackToAddTraineeModalHandler}
      preventClose
    >
      <ModalHeader
        className="warning-icon"
        icon={["fa-regular", "triangle-exclamation"]}
        title={"Demo Data Unavailable"}
      ></ModalHeader>
      <ModalBody>
        You have set this classroom as demo only. Progress data for demo
        classrooms will not be stored by Transfr in your organization&apos;s
        dashboard. Are you sure you would like to proceed?
      </ModalBody>
      <ModalFooter>
        <Button onClick={onBackToAddTraineeModalHandler}>No, Cancel</Button>
        <Button primary onClick={onConfirmClose}>
          Yes, Create Classroom
        </Button>
      </ModalFooter>
    </Modal>
  );
}
