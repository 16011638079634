import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useContainerQuery } from "@transfr-inc/dashboard-components/container-query";

import { SearchBox, useSearch } from "@transfr-inc/dashboard-components/forms";

import {
  SlashCircleIcon,
  Loader,
  Badge,
} from "@transfr-inc/dashboard-components";
import { ShortcutAction } from "../../../../components/shortcuts";

import { ClassroomCeSimulationAccordion } from "./classroom.ce-simulation.accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./classroom.ce-simulations.scss";

const query = {
  "small-panel": {
    minWidth: 320,
    maxWidth: 850,
  },
  "medium-panel": {
    minWidth: 700,
    maxWidth: 900,
  },
  "large-panel": {
    minWidth: 900,
  },
};

export function ClassroomCeSimulations({ careers, unexplored }) {
  const [simulations, setSimulations] = useState(careers);
  const [unexploredSimulations, setUnexploredSimulations] =
    useState(unexplored);
  const [params, containerRef] = useContainerQuery(query);

  const simsCareersSearch = useSearch({
    careers: simulations,
    searchKeys: ["name", "occupationName"],
  });

  const unexploredCareersSearch = useSearch({
    careers: unexploredSimulations,
    searchKeys: ["name", "occupationName"],
  });

  const onSimsCareerTermChange = (term) => {
    const simResults = simsCareersSearch.queryData(term);
    setSimulations(simResults);
    const unexploredResults = unexploredCareersSearch.queryData(term);
    setUnexploredSimulations(unexploredResults);
  };

  const onSimCareerSelected = (option) => {
    onSimsCareerTermChange(option.name);
  };

  useEffect(() => {
    if (careers) {
      setSimulations(careers);
      simsCareersSearch.updateData(careers);
    }
    if (unexplored) {
      setUnexploredSimulations(unexplored);
      unexploredCareersSearch.updateData(unexplored);
    }
  }, [careers, unexplored]);

  return (
    <div className="classroom-sims-section">
      <div className="sims-header">
        <h2>
          Careers{" "}
          <Badge
            value={
              simulations ? (
                simulations.length
              ) : (
                <FontAwesomeIcon icon={"fa-thin fa-hyphen"} />
              )
            }
          />
        </h2>
        <div className="filters-section">
          <SearchBox
            className="sims-search"
            placeholder="Search Careers..."
            disabled={!simulations}
            query={simsCareersSearch.queryData}
            onSearchTermChange={onSimsCareerTermChange}
            onResultSelected={onSimCareerSelected}
            customOptionLabel={(r) => `${r?.name} (${r?.occupationName})`}
          ></SearchBox>
        </div>
      </div>
      {simulations?.length === 0 && (
        <ShortcutAction className="no-data-message" disabled>
          <SlashCircleIcon></SlashCircleIcon>
          {careers?.length < 1
            ? "There are no explored careers for this classroom."
            : "No data to display. Please try adjusting your search or filters."}
        </ShortcutAction>
      )}
      <div className="sim-accordions-container" ref={containerRef}>
        {simulations ? (
          simulations.map((d, i) => (
            <ClassroomCeSimulationAccordion
              key={`${d.id}-${d.version}`}
              sim={d}
              index={i + 1}
              responsiveStyles={params}
              isExpanded={i === 0}
            />
          ))
        ) : (
          <Loader overlay />
        )}
      </div>
      <div className={clsx("sims-header", "unexplored")}>
        <h2>
          Careers Unexplored{" "}
          <Badge
            value={
              unexploredSimulations ? (
                unexploredSimulations.length
              ) : (
                <FontAwesomeIcon icon={"fa-thin fa-hyphen"} />
              )
            }
          />
        </h2>
      </div>
      {unexploredSimulations?.length === 0 && (
        <ShortcutAction className="no-data-message" disabled>
          <SlashCircleIcon></SlashCircleIcon>
          {unexplored?.length < 1
            ? "There are no unexplored careers for this classroom."
            : "No data to display. Please try adjusting your search or filters."}
        </ShortcutAction>
      )}
      <div
        className={clsx("sim-accordions-container", "unexplored")}
        ref={containerRef}
      >
        {unexploredSimulations ? (
          unexploredSimulations.map((d, i) => (
            <ClassroomCeSimulationAccordion
              key={`${d.id}-${d.version}`}
              sim={d}
              index={i + 1}
              responsiveStyles={params}
              isExpanded={false}
              explored={false}
            />
          ))
        ) : (
          <Loader overlay />
        )}
      </div>
    </div>
  );
}
