import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQueryString } from "../../lib/hooks";

import { useStoreActions, useStoreState } from "easy-peasy";

export default function ProProfsLogout() {
  const history = useHistory();
  const queryParams = useQueryString();
  const { action } = queryParams;

  const accountActions = useStoreActions((state) => state.account);
  const accountStore = useStoreState((store) => store.account);

  const handleProProfsLogout = async () => {
    if (action === "logout" || action === "custom_logout") {
      if (accountStore.proProfsSettings?.appLogoutRequested) {
        accountActions.setRedirectUrl();
        history.replace("/login");
      } else {
        accountActions.setRedirectUrl();
        await accountActions.logout();
      }
    }
  };

  useEffect(() => {
    handleProProfsLogout();
  }, []);

  return <div></div>;
}
