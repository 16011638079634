import ceBannerImage from "../../public/images/female-hero.png";
import tsBannerImage from "../../public/images/hero.png";
import trekBannerImage from "../../public/images/trek-hero.png";

export const Product = {
  TS: 1,
  CE: 2,
  TRK: 3,
  MULTI: "MULTI",
};

export const SimPassProducts = [Product.TS, Product.CE];

const products = new Map();
products.set(Product.TS, {
  id: Product.TS,
  name: "TS",
  displayName: "Training Simulations",
  description:
    "Designed to provide trainees with a personalized path to master the hands-on skills required for on-the-job success.",
  banner: tsBannerImage,
  icon: "fa-solid fa-bullseye-arrow",
});

products.set(Product.CE, {
  id: Product.CE,
  name: "CE",
  displayName: "Career Exploration",
  description:
    "Trainees can experience a wide variety of well-paying jobs in a safe, supportive setting, guided by our expert digital coach.",
  banner: ceBannerImage,
  icon: "fa-solid fa-bullseye-arrow",
});

products.set(Product.TRK, {
  id: Product.TRK,
  name: "TRK",
  displayName: "Trek",
  description:
    "Helping students find a meaningful career pathway through immersive exploration.",
  banner: trekBannerImage,
  icon: "fa-regular fa-person-hiking",
});

export function getProductName(productId) {
  return products.get(productId).name;
}

export function getProductDisplayName(productId) {
  return products.get(productId).displayName;
}

export function getProductDescription(productId) {
  return products.get(productId).description;
}

export function getProductBanner(productID) {
  return products.get(productID).banner;
}

export function getProductIcon(productID) {
  return products.get(productID).icon;
}

export function getProduct(productId) {
  return products.get(productId);
}

export function getProducts() {
  return [...products.values()];
}
