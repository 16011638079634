import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import clsx from "clsx";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  NotificationType,
  NotificationStack,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import container from "../../container";

import "./delete-modal.scss";

export const BulkRemoveUserModal = ({
  onCancel,
  show,
  className,
  deleteList,
  onRowRemoved,
  classroomName,
  classroomId,
  userLabel,
}) => {
  const { singular, plural } = userLabel;
  const [notifications, setNotifications] = useState();
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const onCloseNotification = (index) => {
    setNotifications(notifications.filter((n, i) => i !== index));
  };

  const showNotification = (type, message, icon) => {
    const notification = { type, message, icon };
    setNotifications((n) => {
      const notificationsUpdated = n ?? [];
      return [...notificationsUpdated, notification];
    });
  };

  const { classroomService, userService } = container;

  const { handleSubmit, formState, reset } = useForm({
    defaultValues: { ...deleteList },
  });

  const getContent = (deleteList) => {
    return deleteList.length == 1 ? (
      <div className="delete-content">
        Are you sure you would like to remove
        <b>
          {" "}
          {deleteList[0].firstName} {deleteList[0].lastName}{" "}
        </b>
        from <b>{classroomName}</b>? A trainee must be enrolled in at least one
        classroom.
      </div>
    ) : (
      <div className="delete-content">
        Are you sure you would like to remove
        <b> {deleteList.length} </b>
        trainees from <b>{classroomName}</b>? A trainee must be enrolled in at
        least one classroom.
      </div>
    );
  };

  async function getStudentsToRemove(values) {
    const students = await Promise.all(
      values.map(async (student) => {
        return {
          ...student,
          classrooms: await userService.getUserClassrooms(student["userId"]),
        };
      })
    );

    const [allowRemove, blockRemove] = students.reduce(
      ([p, f], e) =>
        e.classrooms.length > 1 ? [[...p, e], f] : [p, [...f, e]],
      [[], []]
    );

    return [allowRemove, blockRemove];
  }

  async function submitForm(values, e) {
    try {
      const [allowRemove, blockRemove] = await getStudentsToRemove(values);

      if (blockRemove.length > 0) {
        showNotification(
          NotificationType.error,
          blockRemove.length > 1 ? (
            <div>
              <b>{blockRemove.length} </b> trainees only enrolled in{" "}
              <b>{classroomName}</b> could not be removed.
            </div>
          ) : (
            <div>
              <b>
                {blockRemove[0].firstName} {blockRemove[0].lastName}
              </b>{" "}
              could not be removed while only enrolled in <b>{classroomName}</b>
              .
            </div>
          )
        );
      }

      if (allowRemove.length > 0) {
        const traineeIds = allowRemove.map((x) => x["userId"]);
        const results = await classroomService.bulkRemoveTrainee(classroomId, {
          traineeIds: traineeIds,
        });
        onRowRemoved();
        showNotification(
          NotificationType.success,
          allowRemove.length > 1 ? (
            <div>
              <b>{allowRemove.length} </b> trainees have been removed.
            </div>
          ) : (
            <div>
              <b>
                {allowRemove[0].firstName} {allowRemove[0].lastName}
              </b>{" "}
              has been removed.
            </div>
          )
        );
      }
    } catch (error) {
      console.log(error);
      console.trace();
      showNotification(NotificationType.error, "Something went wrong");
    } finally {
      onCancel();
      setIsAlertVisible(true);
      setTimeout(() => {
        setIsAlertVisible(false);
        setNotifications();
      }, 10000);
    }
  }

  const onClick = (values, e) => {
    handleSubmit(submitForm)(e);
  };

  useEffect(() => {
    reset(deleteList);
  }, [deleteList]);

  return (
    <div className={clsx("delete-modal-container", className)}>
      <Modal modalClassName={"delete-modal"} open={show} onClose={onCancel}>
        <ModalHeader
          className="red-icon"
          icon={["fa-light", "user-xmark"]}
          title={`Remove ${deleteList.length > 1 ? plural : singular}`}
        ></ModalHeader>
        <ModalBody className="delete-body">{getContent(deleteList)}</ModalBody>
        <ModalFooter>
          <Button onClick={onCancel}>Cancel</Button>
          <Button destructive onClick={onClick}>
            {`Yes, Remove ${deleteList.length > 1 ? plural : singular}`}
          </Button>
        </ModalFooter>
      </Modal>
      <div className="success-notification">
        {isAlertVisible && notifications?.length > 0 && (
          <NotificationStack
            notifications={notifications}
            onClose={onCloseNotification}
            closable
          ></NotificationStack>
        )}
      </div>
    </div>
  );
};
