import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";

import { Breadcrumb, Loader } from "@transfr-inc/dashboard-components";
import {
  PageHeader,
  PageLayout,
} from "@transfr-inc/dashboard-components/layouts";
import { Button } from "@transfr-inc/dashboard-components/forms";
import container from "../../../container";
import { useApiRequest } from "../../../lib/http-client";

import SyncHistoryItem from "./sync-history-item";
import IntegrationDate from "../list/components/integration-date";
import "./sync-history.scss";
import IntegrationDateWithBadge from "../list/components/integration-date-with-badge";

const SYNC_ITEMS_TO_LOAD = 3;

const SyncHistory = ({ responsiveStyles }) => {
  const { connectionId } = useParams();
  const connection = useStoreState(
    (state) => state.connection.connections[connectionId]
  );
  const loadConnections = useStoreActions(
    (actions) => actions.connection.loadConnections
  );
  const icon = useStoreState(
    (state) => state.providerIcon.icons[connection?.providerId]
  );
  const loadIcon = useStoreActions((actions) => actions.providerIcon.loadIcon);
  const [headerLoading, setHeaderLoading] = useState(true);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [syncHistoryList, setSyncHistoryList] = useState([]);
  const { integrationService } = container;

  useEffect(() => {
    const loadData = async () => {
      await loadConnections(connectionId);
      await loadIcon(connection.providerId);
      setHeaderLoading(false);
    };

    loadData();
  }, [connection, icon]);

  const {
    loading: syncHistoryLoading,
    response: syncHistory = [],
    sendRequest: requestSyncHistory,
  } = useApiRequest(() =>
    integrationService
      .getSyncHistory(connectionId, syncHistoryList.length, SYNC_ITEMS_TO_LOAD)
      .then(async (response) => {
        if (response.length < SYNC_ITEMS_TO_LOAD) {
          setCanLoadMore(false);
        }
        setSyncHistoryList([...syncHistoryList, ...response]);
        return syncHistoryList;
      })
  );

  if (headerLoading) {
    return <Loader overlay></Loader>;
  }

  if (syncHistory.length === 0) {
    syncHistory.push({
      status: "Pending",
      executionDateTime: new Date().toISOString(),
      message: "Processing...",
      results: [],
    });
  }

  const syncItems = syncHistoryList.map((syncRecord, i) => (
    <SyncHistoryItem
      key={`${i}-${syncRecord.executionDateTime}`}
      syncRecord={syncRecord}
    />
  ));

  return (
    <PageLayout
      className="sync-history"
      responsiveStyles={responsiveStyles}
      header={
        <PageHeader
          breadcrumb={
            <Breadcrumb label="All Integrations" to="/integrations" />
          }
          title={
            <img
              className="provider-image"
              src={icon}
              alt={connection.providerName}
            />
          }
          subTitle={
            <div className="connection-info-row">
              <div>
                Integrated: <IntegrationDate date={connection.createdDate} />
              </div>
              <div className="spacer" />
              <div>
                Last Sync: <IntegrationDateWithBadge connection={connection} />
              </div>
            </div>
          }
        ></PageHeader>
      }
    >
      <div className="sync-history__body">
        {syncHistoryLoading && <Loader overlay></Loader>}
        {syncItems}
        {!syncHistoryLoading && canLoadMore && (
          <Button onClick={requestSyncHistory} className="load-more-button">
            Load More
          </Button>
        )}
      </div>
    </PageLayout>
  );
};

export default SyncHistory;
