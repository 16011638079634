import React from "react";

import {
  CareerRatingReference,
  RatingReferenceType,
} from "./career-rating-reference";

export const CareerSummary = ({ career }) => {
  return (
    <div className="summary-container">
      <div>
        <div>
          Career Cluster: <strong>{career?.clusterName}</strong>
        </div>
        <div>
          Sim: <strong>{career?.name}</strong>
        </div>
        <div>
          Active Explorers: <strong>{career?.totalActiveExplorers}</strong>
        </div>
      </div>
      <div className="reference-container">
        <CareerRatingReference
          value={career?.totalHighlyRated}
          type={RatingReferenceType.High}
        />
        <span>Rated this career with 4-5 stars</span>

        <CareerRatingReference
          value={career?.totalLowlyRated}
          type={RatingReferenceType.Low}
        />
        <span>Rated this career below 4 stars</span>

        <CareerRatingReference
          value={career?.totalUnrated}
          type={RatingReferenceType.Unrated}
        />
        <span>Didn&apos;t provide a rating</span>
      </div>
    </div>
  );
};
