import React, { useMemo } from "react";

import {
  FullScreenModal,
  FullScreenModalBody,
  FullScreenModalFooter,
  FullScreenModalHeader,
  Notification,
} from "@transfr-inc/dashboard-components";
import { Button, Input } from "@transfr-inc/dashboard-components/forms";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useApiRequest } from "../../../lib/http-client";
import container from "../../../container";
import { TextTooltip } from "@transfr-inc/dashboard-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./add-integration.modal.scss";

export default ({
  open,
  integration,
  onCancel,
  onAddIntegration,
  addIntegrationFailed,
}) => {
  const { inputs = [], id } = integration;
  const { integrationService } = container;

  const { response: icon = {} } = useApiRequest(() =>
    integrationService.getProviderIconSquare(id)
  );

  const methods = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      formInputs: inputs,
    },
    shouldUnregister: true,
  });

  const { control, handleSubmit, formState } = methods;
  const { isValid } = formState;

  const { fields } = useFieldArray({
    control,
    name: "formInputs",
  });

  const integrationInputList = useMemo(() => {
    if (fields.length > 0) {
      return fields
        .sort((a, b) => a.order - b.order || a.key.localeCompare(b.key))
        .map((field, index) => {
          switch (field.type) {
            case "string":
              return (
                <Controller
                  control={control}
                  name={`formInputs[${index}].${field.key}`}
                  key={field.id}
                  rules={{
                    required: {
                      value: true,
                      message: "Required fields are missing.",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <div className="integrations-input-row">
                        <Input
                          className="integration-input"
                          label={removeUnderline(field.key)}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                        />
                        {field.helpText && (
                          <TextTooltip text={field.helpText}>
                            <FontAwesomeIcon icon="fa-solid fa-circle-info" />
                          </TextTooltip>
                        )}
                      </div>
                    );
                  }}
                />
              );

            default:
              return;
          }
        });
    }
  }, [fields]);

  const handleSync = () => {
    handleSubmit((data) => {
      const mapped = data.formInputs.map((input) => {
        const key = Object.keys(input)[0];
        const inputValue = input[key];
        const correspondingInput = fields.find((i) => i.key === key);
        return {
          key: key,
          value: inputValue,
          encrypted: correspondingInput.encrypted,
        };
      });
      onAddIntegration(mapped, id);
    })();
  };

  return (
    <FullScreenModal
      open={open}
      className="sync-integration-modal"
      onClose={onCancel}
    >
      <FullScreenModalHeader
        icon={["fa-regular", "swap"]}
        title="Add Integration"
      ></FullScreenModalHeader>
      <FullScreenModalBody>
        <div className="modal-container">
          <div className="image-container">
            <img className="provider-img" src={icon} />
          </div>
          {addIntegrationFailed && (
            <Notification type="error">
              Connection Failed. Please review inputs and try again.
            </Notification>
          )}
          {integrationInputList}
        </div>
      </FullScreenModalBody>
      <FullScreenModalFooter>
        <Button onClick={onCancel}>Cancel</Button>
        <Button primary onClick={handleSync} disabled={!isValid}>
          Integrate
        </Button>
      </FullScreenModalFooter>
    </FullScreenModal>
  );
};

const removeUnderline = (str) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
