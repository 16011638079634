import React from "react";

import {
  CareerRatingReference,
  RatingReferenceType,
} from "./career-rating-reference";

export const LeaderboardSummary = ({ activeExplorers }) => {
  return (
    <div className="summary-container">
      {activeExplorers >= 0 && (
        <div>
          Active Explorers: <strong>{activeExplorers}</strong>
        </div>
      )}
      <div className="legend-container">
        <div className="legend-item">
          <CareerRatingReference isReference type={RatingReferenceType.High} />
          <span>4-5 stars</span>
        </div>
        <div className="legend-item">
          <CareerRatingReference isReference type={RatingReferenceType.Low} />
          <span>Below 4 stars</span>
        </div>
        <div className="legend-item">
          <CareerRatingReference
            isReference
            type={RatingReferenceType.Unrated}
          />
          <span>Rating not provided</span>
        </div>
      </div>
    </div>
  );
};
