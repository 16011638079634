const maxAttempts = 2;

export function initPendo(
  user,
  roleId,
  impersonated,
  attempt = 0,
  menuBuilderEnabled
) {
  const idSuffix = impersonated ? "_imp" : "";
  const pendoConfig = {
    visitor: {
      id: `${user.userId}${idSuffix}`.trim(),
      email: user.email,
      full_name: `${user.firstName} ${user.lastName}`.trim(),
      role: roleId,
    },
    account: {
      id: user.organizationCode,
      name: user.organizationName,
      menuBuilderEnabled: menuBuilderEnabled,
    },
  };

  try {
    // If user logs out and logs in with another account
    // we need to refresh the session values with "indentify"
    // instead of using "initialize"
    if (pendo.isReady()) {
      pendo.identify(pendoConfig);
    } else {
      pendo.initialize(pendoConfig);
    }
  } catch (error) {
    console.error(
      "An error occurred while configuring pendo, will retry again.",
      error
    );
    // When reloading app because an update, pendo is not loading at first attempt
    // This is attempt to init pendo correctly
    if (attempt < maxAttempts) {
      const currentAttempt = attempt + 1;
      setTimeout(() => {
        initPendo(user, roleId, impersonated, currentAttempt);
      }, 1000);
    }
  }
}
