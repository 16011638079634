import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./trainee-avatar.scss";

export const traineeAvatars = [
  "fa-face-laugh-beam",
  "fa-alien-8bit",
  "fa-robot",
  "fa-otter",
];

export function getRandomTraineeAvatar() {
  const randomIndex = Math.floor(Math.random() * traineeAvatars.length);
  return traineeAvatars[randomIndex];
}

export function TraineeAvatar({
  width = 89,
  height = 80,
  color = "#FF8672",
  accentColor = "#79242F",
  icon = "fa-face-laugh-beam",
}) {
  return (
    <div className="trainee-avatar">
      <svg
        width={width}
        height={height}
        viewBox="0 0 134 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M96 86L96 83L134 83V86L96 86Z" fill="#EEF4F8" />
        <circle cx="62.9978" cy="63.5984" r="55.2" fill={color} />
        <circle cx="63" cy="60" r="59" stroke={color} strokeWidth="2" />
        <circle cx="120" cy="16" r="5" fill={accentColor} />
        <path
          d="M19.6842 88.101C19.6842 96.1487 26.0845 102.679 34 102.733V88L19.6845 88C19.6843 88.0336 19.6842 88.0673 19.6842 88.101Z"
          fill={accentColor}
        />
        <path
          d="M0 88.101C1.94807e-06 96.1487 6.40025 102.679 14.3158 102.733L14.3158 88L0.00033601 88C0.000112117 88.0336 -5.80205e-09 88.0673 0 88.101Z"
          fill={accentColor}
        />
      </svg>
      <div className="icon-container">
        <FontAwesomeIcon icon={["fa-light", icon]} color={accentColor} />
      </div>
    </div>
  );
}
