import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  Loader,
  RequiredItem,
} from "@transfr-inc/dashboard-components";

import {
  CheckboxStates,
  TriCheckBox,
  Button,
} from "@transfr-inc/dashboard-components/forms";

import { useOrderer, OrderMove } from "@transfr-inc/dashboard-components/hooks";

import { ListItemEdition, Movement } from "../../components";

import "./edit-categories.modal.scss";

export const EditCategoriesModal = ({
  open,
  onClose,
  onApplyChanges,
  async,
  categories,
  className,
  checkVersionNumber,
}) => {
  const { CHECKED, EMPTY, INDETERMINATE } = CheckboxStates;
  const [selectAllItems, setSelectAllItems] = useState(true);
  const [loading, setLoading] = useState();
  const [animationParent] = useAutoAnimate();

  const {
    items: categoryItems = [],
    updateItems: updateCategories,
    move,
    isFirstItem,
    isLastItem,
  } = useOrderer({
    data: categories,
    idProp: "categoryId",
    orderProp: "orderNumber",
  });

  const categoryCount = useMemo(
    () => categoryItems?.filter((c) => c.isVisible).length,
    [categoryItems]
  );

  const checkboxValue = useMemo(() => {
    const selected = categoryItems?.filter((c) => c.isVisible).length;
    if (selected === categoryItems?.length) {
      return CHECKED;
    } else {
      return selected === 0 ? EMPTY : INDETERMINATE;
    }
  }, [categoryItems]);

  const onSelectAllItems = () => {
    const selectAllItemsValue = !selectAllItems;
    updateAllItems(selectAllItemsValue);
  };

  const onApply = async () => {
    const versionNumberIsValid = await checkVersionNumber();
    if (!versionNumberIsValid) {
      setLoading();
      onClose();
      return;
    }
    if (async) {
      setLoading(true);
      await onApplyChanges?.(categoryItems);
      setLoading();
    } else {
      onApplyChanges?.(categoryItems);
    }
  };

  const onCategorySelected = (index) => {
    updateCategories(
      categoryItems.map((category, i) => {
        if (i === index) {
          category.isVisible = !category.isVisible;
        }
        return category;
      })
    );
  };

  const updateAllItems = (isAllSelected) => {
    updateCategories(
      categoryItems.map((category) => ({
        ...category,
        isVisible: isAllSelected,
      }))
    );
    setSelectAllItems(isAllSelected);
  };

  const onChangeOrder = (movement, index) => {
    const orderMove =
      movement === Movement.Up ? OrderMove.Backward : OrderMove.Forward;
    move(orderMove, index);
  };

  useEffect(() => {
    updateCategories(categories?.map((c, i) => ({ ...c, orderNumber: i })));
  }, [open]);

  return (
    <Modal
      modalClassName={clsx("edit-categories-modal", className)}
      open={open}
      onClose={onClose}
      preventClose={loading}
    >
      {loading && <Loader overlay />}
      <ModalHeader icon={["fa-regular", "pen-to-square"]}>
        <div className="modal-title">
          <h2>Categories</h2>
          <Badge value={categoryCount} />
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          <span>Check the categories that you would like included.</span>
          <br />
          <span> Unchecked categories can be recovered later.</span>
          <br />
          <RequiredItem
            className="required-container"
            text="At least 1 category must be selected"
          />
        </div>
        <div className="actions-container">
          <TriCheckBox
            labelPresent
            value={checkboxValue}
            onChange={onSelectAllItems}
          ></TriCheckBox>
        </div>
        <div className="category-list" ref={animationParent}>
          {categoryItems?.map((category, index) => (
            <ListItemEdition
              key={`${category.categoryId}`}
              isChecked={category?.isVisible}
              onChecked={() => onCategorySelected(index)}
              isDownEnabled={!isLastItem(category)}
              isUpEnabled={!isFirstItem(category)}
              onChangeOrder={(movement) => onChangeOrder(movement, index)}
              label={category?.categoryName}
              type={category?.categoryType}
            />
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Cancel</Button>
        <Button primary disabled={categoryCount === 0} onClick={onApply}>
          Apply
        </Button>
      </ModalFooter>
    </Modal>
  );
};
