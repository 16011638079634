import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Button } from "@transfr-inc/dashboard-components/forms";
import { formatTimestap } from "@transfr-inc/dashboard-components/utils";
import { useUnloadWarning } from "@transfr-inc/dashboard-components/hooks";
import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";

import { TraineeSummaryStats } from "./trainee.ce.databoxes";
import { RemoveTraineeModal } from "../modals/remove-trainee";

import { ClassroomRoute, RootPath } from "../../../../lib/routes";

import container from "../../../../container";

import "./trainee.insights.scss";
import { useApiRequest } from "../../../../lib/http-client";
import { classroomUrlMap } from "../../../../lib/classroom-url-map";

export function TraineeCeInsights({
  trainee,
  classroom,
  onRemoveTrainee,
  removeTraineeEnabled,
}) {
  const [masteryStats, setMasteryStats] = useState();
  const [openRemoveModal, setOpenRemoveModal] = useState();
  const { insightsService } = container;
  const { organizationCode, userId, firstName, lastName } = trainee;
  const { url } = useRouteMatch(`${RootPath}`);
  const history = useHistory();

  const { loading: downloadingFile, sendRequest: exportTraineeData } =
    useApiRequest(() => {
      const { classroomId } = classroom;

      const fileName = `${firstName}_${lastName}-${formatTimestap()}`;

      return insightsService.exportExplorerData(
        organizationCode,
        classroomId,
        userId,
        fileName
      );
    }, false);

  useUnloadWarning({ trigger: downloadingFile });

  useEffect(() => {
    if (classroom) {
      getUserMasteryStats();
    }
    return () => insightsService.cancelRequest();
  }, []);

  const getUserMasteryStats = () => {
    insightsService
      .getExplorerSummaryStats(trainee.userId)
      .then(setMasteryStats)
      .catch(() => setMasteryStats({}));
  };

  const goClassroomDetailsPage = () => {
    const location = {
      pathname: `${url}${ClassroomRoute.path}/${classroomUrlMap(
        classroom.products
      )}/${classroom.code}`,
      state: {
        classroomId: classroom.classroomId,
        breadcrumbLabel: `Trainee`,
      },
    };
    history.push(location);
  };

  const onRemoveTraineeHandler = (trainee, classroom) => {
    return onRemoveTrainee(trainee, classroom).then(() => setOpenRemoveModal());
  };

  return (
    <>
      <div className="insights-section">
        <div className="insights-header">
          <div className="title-section">
            <h2 className="title">Insights</h2>
          </div>
          <div className="actions-section">
            <Button
              size="small"
              icon={["fa-light", "file-export"]}
              disabled={downloadingFile}
              onClick={exportTraineeData}
            >
              Export Data (.csv)
            </Button>
            {removeTraineeEnabled && (
              <Button
                size="small"
                icon={["fa-light", "user-xmark"]}
                onClick={() => setOpenRemoveModal(true)}
              >
                Remove Trainee
              </Button>
            )}
            <Button
              size="small"
              primary
              icon={["fa-light", "screen-users"]}
              onClick={goClassroomDetailsPage}
            >
              View Classroom
            </Button>
          </div>
        </div>
        <TraineeSummaryStats
          className="ce"
          masteryStats={masteryStats}
        ></TraineeSummaryStats>
        <div className="notification-loader-container">
          {downloadingFile && (
            <Notification
              type={NotificationType.info}
              closable
              icon={["fa-solid", "spinner"]}
              iconConfig={{ spin: true }}
            >
              We’re preparing a download of{" "}
              <strong>{`${firstName} ${lastName}'s`}</strong> insights. Please
              wait...
            </Notification>
          )}
        </div>
      </div>
      {removeTraineeEnabled && (
        <RemoveTraineeModal
          open={openRemoveModal}
          onClose={() => setOpenRemoveModal(false)}
          onRemoveTrainee={onRemoveTraineeHandler}
          trainee={trainee}
          classroom={classroom}
        ></RemoveTraineeModal>
      )}
    </>
  );
}
