import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  RequiredItem,
  RequiredFieldsText,
  Loader,
  Notification,
} from "@transfr-inc/dashboard-components";

import {
  Button,
  Input,
  ValidationItem,
} from "@transfr-inc/dashboard-components/forms";

import { useApiRequest } from "../../../lib/http-client";
import container from "../../../container";

import "./change-password.modal.scss";

export default ({
  open,
  userIds,
  onCancel,
  onPasswordChanged,
  backdropClassName,
  modalClassName,
}) => {
  const { register, getValues, formState, reset } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      newPassword: "",
      newPasswordConfirmation: "",
    },
  });
  const { errors, dirtyFields, isValid } = formState;
  const [showError, setError] = useState();
  const { userService } = container;

  const updatePasswordRequest = () => {
    const { newPassword } = getValues();

    return userService
      .updatePassword(userIds, newPassword)
      .then(onPasswordChanged)
      .catch((error) => {
        console.error("[Update Password] - Error:", error);
        setError(true);
      });
  };

  const { loading, sendRequest: updatePassword } = useApiRequest(
    updatePasswordRequest,
    false
  );

  useEffect(() => {
    if (open) {
      reset();
      setError();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onCancel}
      backdropClassName={backdropClassName}
      modalClassName={clsx("change-password-modal", modalClassName)}
      preventClose={loading}
    >
      {loading && <Loader overlay />}
      <ModalHeader
        title={
          <div className="modal-title-badge">
            {"Change Password"}
            {userIds?.length > 1 && (
              <div className="badge">{userIds?.length}</div>
            )}
          </div>
        }
        icon={["fa-regular", "key"]}
      ></ModalHeader>
      <ModalBody>
        {showError && (
          <Notification type={"error"}>
            Something went wrong. Please try again.
          </Notification>
        )}
        <form autoComplete="off">
          <RequiredFieldsText></RequiredFieldsText>
          <Input
            label={<RequiredItem text={"New Password"} />}
            type="password"
            required
            {...register("newPassword", {
              required: true,
              maxLength: 50,
              validate: {
                count: (v) =>
                  v.length >= 4 || "Password must be at least 4 characters",
                hasLetter: (v) =>
                  /[A-Za-z]/.test(v) || "Password must include a letter",
                whiteSpace: (v) =>
                  /^\S*$/.test(v) || "White spaces are not allowed",
                hasOnlyAllowedSpecials: (v) =>
                  !/[^\w!,.?@-]+/.test(v) ||
                  "Password cannot include special characters other than @ ! ? , . - _",
              },
            })}
          ></Input>
          <Input
            label={<RequiredItem text={"Confirm New Password"} />}
            type="password"
            required
            {...register("newPasswordConfirmation", {
              required: true,
              validate: {
                match: (v) => {
                  const { newPassword } = getValues();
                  return newPassword === v;
                },
              },
            })}
          ></Input>
        </form>
        <div className="requirements">
          <div className="title">Password Requirements</div>
          <ul>
            <li>
              <ValidationItem
                label={"Minimum of 4 characters"}
                valid={!errors.newPassword?.types.count}
                enabled={dirtyFields.newPassword}
              />
            </li>
            <li>
              <ValidationItem
                label={"At least one letter"}
                valid={!errors.newPassword?.types.hasLetter}
                enabled={dirtyFields.newPassword}
              />
            </li>
            <li>
              <ValidationItem
                label={"No whitespace allowed"}
                valid={!errors.newPassword?.types.whiteSpace}
                enabled={dirtyFields.newPassword}
              />
            </li>
            <li>
              <ValidationItem
                label={"No special characters other than @ ! ? , . - _"}
                valid={!errors.newPassword?.types.hasOnlyAllowedSpecials}
                enabled={dirtyFields.newPassword}
              />
            </li>
            <li>
              <ValidationItem
                label={"Confirm new password"}
                valid={!errors.newPasswordConfirmation?.types.match}
                enabled={dirtyFields.newPasswordConfirmation}
              />
            </li>
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel}>Cancel</Button>
        <Button primary onClick={updatePassword} disabled={!isValid}>
          {userIds?.length > 1
            ? `Change Password (${userIds?.length})`
            : "Change Password"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
