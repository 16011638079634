import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader, TextTooltip } from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import container from "../../../../container";
import { copyToClipboard } from "../../../../lib/browser-utils";
import { useApiRequest } from "../../../../lib/http-client";

import "./classroom.qr.tab.scss";

const infoText = "Copied to clipboard";

export const ClassroomQRTab = ({ classroom }) => {
  const { classroomId } = classroom ?? {};
  const { classroomService } = container;

  const { loading, response: qrData } = useApiRequest(() =>
    classroomService.getQuickSignupQRCode(classroomId)
  );

  const qrImgString = `data:image/png;base64,${qrData?.code}`;

  return (
    <div className="classroom-qr-main-container">
      <div className="qr-signup-text">
        Quickly signup students for this classroom.{" "}
        <TextTooltip
          className="tooltip"
          text={
            "Link and QR code expire in 48 hours, please refresh to generate a new code."
          }
        >
          <FontAwesomeIcon icon="fa-regular fa-circle-info" />
        </TextTooltip>
      </div>
      {loading && <Loader overlay></Loader>}
      <img className="qr-img" src={qrImgString} />
      <TextTooltip className="info" text={infoText} trigger="click">
        <Button
          icon={["fa-light", "fa-link"]}
          onClick={() => copyToClipboard(qrData.link)}
          size="small"
        >
          Copy Link
        </Button>
      </TextTooltip>
    </div>
  );
};
