import { React } from "react";
import clsx from "clsx";

import { DataBox, DataBoxGroup } from "@transfr-inc/dashboard-components";

import "./my-work.ce.databoxes.scss";
import { getCareerStatus, StatusFilter } from "../../models/career-status";

export function MyWorkDataboxes({
  masteryStats,
  onStatusSelected,
  className,
  dataGroupedByOccupation,
  responsiveStyles,
}) {
  const { totalExplored = 0, totalHighlyRated = 0 } = masteryStats || {};
  const allCount = dataGroupedByOccupation
    ? Object.keys(dataGroupedByOccupation).length
    : 0;
  const totalNotExplored = allCount - totalExplored;

  const dataBoxes = [
    <DataBox
      key={`trainee-ce-db-${getCareerStatus(StatusFilter.All).key}`}
      icon={getCareerStatus(StatusFilter.All).icon}
      label={getCareerStatus(StatusFilter.All).label}
      value={allCount ?? ""}
      description={getCareerStatus(StatusFilter.All).description}
      onClick={() => onStatusSelected(StatusFilter.All)}
    ></DataBox>,
    <DataBox
      key={`trainee-ce-db-${getCareerStatus(StatusFilter.CareersExplored).key}`}
      icon={getCareerStatus(StatusFilter.CareersExplored).icon}
      label={getCareerStatus(StatusFilter.CareersExplored).label}
      value={totalExplored ?? ""}
      description={getCareerStatus(StatusFilter.CareersExplored).description}
      onClick={() => onStatusSelected(StatusFilter.CareersExplored)}
    ></DataBox>,
    <DataBox
      key={`trainee-ce-db-${
        getCareerStatus(StatusFilter.CareersNotExplored).key
      }`}
      icon={getCareerStatus(StatusFilter.CareersNotExplored).icon}
      label={getCareerStatus(StatusFilter.CareersNotExplored).label}
      value={totalNotExplored ?? ""}
      description={getCareerStatus(StatusFilter.CareersNotExplored).description}
      onClick={() => onStatusSelected(StatusFilter.CareersNotExplored)}
    ></DataBox>,
    <DataBox
      key={`trainee-ce-db-${
        getCareerStatus(StatusFilter.CareersHighlyRated).key
      }`}
      icon={getCareerStatus(StatusFilter.CareersHighlyRated).icon}
      label={getCareerStatus(StatusFilter.CareersHighlyRated).label}
      value={totalHighlyRated ?? ""}
      description={getCareerStatus(StatusFilter.CareersHighlyRated).description}
      onClick={() => onStatusSelected(StatusFilter.CareersHighlyRated)}
    ></DataBox>,
  ];

  return (
    <DataBoxGroup
      className={clsx("sim-status-group", className, responsiveStyles)}
    >
      {[...dataBoxes]}
    </DataBoxGroup>
  );
}
