import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Loader,
  Notification,
  NotificationType,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@transfr-inc/dashboard-components";

import { Button, Input } from "@transfr-inc/dashboard-components/forms";

import "./edit-classroom.modal.scss";

export function EditClassroomModal({
  classroom,
  open,
  onEditClassroom,
  onEditClassroomAsync,
  onCancel,
}) {
  const [loading, setLoading] = useState();
  const [notification, setNotification] = useState();
  const { formState, getValues, control, reset, handleSubmit } = useForm({
    criteriaMode: "all",
    mode: "onBlur",
    defaultValues: { ...classroom },
  });

  const onCloseHandler = () => {
    onCancel && onCancel();
    setNotification();
    reset(classroom, { keepDefaultValues: true });
  };

  const onEditClassroomHandler = () => {
    const classroomName = getValues("name");
    onEditClassroom && onEditClassroom(classroomName);
    onEditClassroomAsync && asyncHandler(classroomName);
  };

  const asyncHandler = (value) => {
    setNotification();
    setLoading(true);
    onEditClassroomAsync(value)
      .catch(() =>
        setNotification({
          type: NotificationType.error,
          message: "Something went wrong. Please try again.",
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      modalClassName={"edit-classroom-modal"}
      open={open}
      onClose={onCloseHandler}
      preventClose={loading}
    >
      {loading && <Loader overlay></Loader>}
      <ModalHeader
        className="blue-icon"
        icon={["fa-regular", "pen-to-square"]}
        title="Edit Classroom Details"
      ></ModalHeader>
      <ModalBody>
        {notification && (
          <Notification {...notification}>{notification.message}</Notification>
        )}
        <form
          className="edit-trainee-form"
          noValidate={true}
          onSubmit={handleSubmit(onEditClassroomHandler)}
        >
          <Controller
            control={control}
            name="name"
            defaultValue={classroom?.name}
            rules={{
              required: true,
              maxLength: 50,
            }}
            render={({ field: { onBlur, onChange, value, ref } }) => {
              return (
                <Input
                  label={"Name"}
                  type="text"
                  name="name"
                  errors={formState.errors?.name}
                  ref={ref}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  required
                />
              );
            }}
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCloseHandler}>Cancel</Button>
        <Button
          primary
          onClick={onEditClassroomHandler}
          disabled={!formState.isValid}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
}
