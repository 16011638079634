import React from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import "./cancel.modal.scss";

export default ({
  title,
  open,
  body,
  cancelText = "Cancel",
  acceptText = "Continue",
  integration,
  onCancel,
  onAccept,
}) => {
  const onConfirm = () => {
    onAccept(integration);
  };

  return (
    <Modal open={open} backdropClassName="cancel-modal" onClose={onCancel}>
      <ModalHeader title={title}></ModalHeader>
      <ModalBody>
        <div>{body}</div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel}>{cancelText}</Button>
        <Button destructive onClick={onConfirm}>
          {acceptText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
