import * as React from "react";
const SvgGradientLoader = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  color: "#2791B5",
  ...props
}, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("linearGradient", {
  id: "spinner-firstHalf"
}, /* @__PURE__ */ React.createElement("stop", {
  offset: 0,
  stopOpacity: 0.5,
  stopColor: "currentColor"
}), /* @__PURE__ */ React.createElement("stop", {
  offset: 1,
  stopOpacity: 1,
  stopColor: "currentColor"
})), /* @__PURE__ */ React.createElement("linearGradient", {
  id: "spinner-secondHalf"
}, /* @__PURE__ */ React.createElement("stop", {
  offset: 0,
  stopOpacity: 1,
  stopColor: "currentColor"
}), /* @__PURE__ */ React.createElement("stop", {
  offset: 1,
  stopOpacity: 0.01,
  stopColor: "currentColor"
}))), /* @__PURE__ */ React.createElement("g", {
  strokeWidth: 2
}, /* @__PURE__ */ React.createElement("path", {
  d: "M 21 12 A 9 9 0 1 1 21 11.9",
  stroke: "url(#spinner-secondHalf)"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M 3 12 A 9 9 180 0 1 21 12",
  stroke: "url(#spinner-firstHalf)"
})));
export default SvgGradientLoader;
