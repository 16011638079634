import React from "react";
import clsx from "clsx";

import { DataBox, DataBoxGroup } from "@transfr-inc/dashboard-components";

import simsMasteredIcon from "../../../../../public/images/databox/sims-mastered.svg";
import SimsCompleted from "../../../../../public/images/databox/sims-completed.svg";
import TimeHeadsetIcon from "../../../../../public/images/databox/time-headset.svg";
import { formatTime } from "@transfr-inc/dashboard-components/utils";

import "./trainee.databoxes.scss";

export function TraineeSummaryStats({ masteryStats, className }) {
  const {
    totalTimespent = 0,
    totalExplored = 0,
    totalHighlyRated = 0,
  } = masteryStats || {};

  return (
    <DataBoxGroup className={clsx("sim-status-group", className, "ce")}>
      <DataBoxGroup>
        <DataBox
          icon={TimeHeadsetIcon}
          label="TIME IN HEADSET"
          value={formatTime(totalTimespent) ?? ""}
          description="Amount of time explorers have spent exploring careers."
        ></DataBox>
        <DataBox
          icon={SimsCompleted}
          label="CAREERS EXPLORED"
          value={totalExplored ?? ""}
          description="Number of careers explorers completed."
        ></DataBox>
        <DataBox
          icon={simsMasteredIcon}
          label="CAREERS 4-5 STARS"
          value={totalHighlyRated ?? ""}
          description="Number of careers explorers rated 4 or 5 stars."
        ></DataBox>
      </DataBoxGroup>
    </DataBoxGroup>
  );
}
