import React from "react";

import { CareerIcon } from "../../../../components/career-icon";

import { useApiRequest } from "../../../../lib/http-client";
import { setCareerInfo } from "../../../../services/insights.utils";
import { sortByNameFn } from "../../../../services/insights.utils";
import container from "../../../../container";

import "./trainee.ce-top-careers.scss";

const MaxCareersDisplay = 3;

export function TraineeCeTopCareers({ trainee }) {
  const { userId } = trainee;
  const { insightsService } = container;

  const { response: topCareers } = useApiRequest(async () => {
    const topCareers = await insightsService.getCEUserTopCareers(userId);
    const careersInfo = await setCareerInfo(topCareers);

    return careersInfo
      .slice(0, MaxCareersDisplay)
      .map((c) => ({
        name: c.occupationName,
        code: c.clusterCode,
        rating: c.starRating,
      }))
      .sort(sortByNameFn);
  });

  return (
    topCareers &&
    topCareers?.length > 0 && (
      <div className="highest-rated-container">
        <h2>Highest Rated Careers</h2>
        <div>
          {topCareers?.map((c, index) => (
            <CareerIcon
              key={`${c.code}-${index}`}
              name={c.name}
              clusterCode={c.code}
              rating={c.rating}
            />
          ))}
        </div>
      </div>
    )
  );
}
