import clsx from "clsx";
import React from "react";

import "./shortcuts.scss";

export default ({ children, className }) => {
  return (
    <div className={clsx("shortcuts-container", className)}>{children}</div>
  );
};
