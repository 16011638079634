import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  Loader,
  RequiredItem,
} from "@transfr-inc/dashboard-components";

import {
  Button,
  CheckboxStates,
  TriCheckBox,
} from "@transfr-inc/dashboard-components/forms";

import { useOrderer, OrderMove } from "@transfr-inc/dashboard-components/hooks";

import { ModuleCheckboxAccordion } from "../../components/module-checkbox.accordion";
import { ListItemEdition, Movement } from "../../components";

import "./edit-modules.modal.scss";

export const EditModulesModal = ({
  open,
  onClose,
  onApplyChanges,
  async,
  className,
  modules,
  category,
  checkVersionNumber,
}) => {
  const { CHECKED, EMPTY, INDETERMINATE } = CheckboxStates;
  const [selectAllItems, setSelectAllItems] = useState(true);
  const [loading, setLoading] = useState();
  const [animationParent] = useAutoAnimate();

  const {
    items: moduleItems = [],
    updateItems: updateModules,
    move,
    isFirstItem,
    isLastItem,
  } = useOrderer({
    data: modules,
    idProp: "moduleId",
    orderProp: "orderNumber",
  });

  const moduleCount = useMemo(
    () => moduleItems?.filter((m) => m.isVisible).length,
    [moduleItems]
  );

  const checkboxValue = useMemo(() => {
    const selected = moduleItems?.filter((c) => c.isVisible).length;
    if (selected === moduleItems?.length) {
      return CHECKED;
    } else {
      return selected === 0 ? EMPTY : INDETERMINATE;
    }
  }, [moduleItems]);

  const updateAllItems = (isAllSelected) => {
    updateModules(
      moduleItems.map((module) => ({
        ...module,
        isVisible: isAllSelected,
      }))
    );
    setSelectAllItems(isAllSelected);
  };

  const onSelectAllItems = () => {
    const selectAllItemsValue = !selectAllItems;
    updateAllItems(selectAllItemsValue);
  };

  const onApply = async () => {
    setLoading(true);
    const isVersionNumberValid = await checkVersionNumber();
    if (!isVersionNumberValid) {
      setLoading();
      handleClose();
      return;
    }
    setLoading();
    if (async) {
      setLoading(true);
      await onApplyChanges?.(moduleItems);
      setLoading();
    } else {
      onApplyChanges?.(moduleItems);
    }
  };

  const handleClose = () => {
    updateModules([]);
    onClose();
  };

  const onModuleSelected = (index) => {
    updateModules(
      moduleItems.map((module, i) => {
        if (i === index) {
          module.isVisible = !module.isVisible;
        }
        return module;
      })
    );
  };

  const onChangeOrder = (movement, index) => {
    const orderMove =
      movement === Movement.Up ? OrderMove.Backward : OrderMove.Forward;
    move(orderMove, index);
  };

  useEffect(() => {
    if (open) {
      const modulesWithIsAllSimsSelected = modules?.map((module, index) => ({
        ...module,
        orderNumber: index,
        isAllSimsSelected: !module?.moduleSimulations?.some(
          (s) => !s.isVisible
        ),
      }));

      updateModules(modulesWithIsAllSimsSelected);
    }
  }, [open]);

  return (
    <Modal
      modalClassName={clsx("edit-modules-modal", className)}
      open={open}
      onClose={handleClose}
      preventClose={loading}
    >
      {loading && <Loader overlay />}
      <ModalHeader
        icon={["fa-regular", "head-side-goggles"]}
        label={category?.categoryName}
      >
        <div className="modal-title">
          <h2>Modules</h2>
          <Badge value={moduleCount} />
        </div>
      </ModalHeader>

      <ModalBody>
        <div>
          <span>Check the modules that you would like included.</span>
          <br />
          <span>Unchecked modules can be recovered later.</span>
          <br />
          <RequiredItem
            className="required-container"
            text="At least 1 module must be selected"
          />
        </div>
        <div className="actions-container">
          <TriCheckBox
            labelPresent
            value={checkboxValue}
            onChange={onSelectAllItems}
          ></TriCheckBox>
        </div>
        <div className="module-list" ref={animationParent}>
          {moduleItems?.map((module, index) => (
            <ListItemEdition
              key={`${module.moduleId}`}
              isChecked={module?.isVisible}
              onChecked={() => onModuleSelected(index)}
              isDownEnabled={!isLastItem(module)}
              isUpEnabled={!isFirstItem(module)}
              onChangeOrder={(movement) => onChangeOrder(movement, index)}
              isIndeterminate={!module.isAllSimsSelected}
            >
              <ModuleCheckboxAccordion
                className={clsx(module.isVisible && "selected")}
                module={module}
                type={"edition"}
              ></ModuleCheckboxAccordion>
            </ListItemEdition>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          className="btn-edit-module"
          primary
          disabled={moduleCount === 0}
          onClick={onApply}
        >
          <span>Apply</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
