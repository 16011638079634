import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Loader,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { ClassroomDropdownList } from "../../custom/dropdowns";

import "./add-trainee-classroom.modal.scss";

export default ({
  open,
  orgCode,
  onCancel,
  onAddTrainee,
  async,
  bulk = false,
}) => {
  const [selectedClassroom, setSelectedClassroom] = useState();
  const [notification, setNotification] = useState();
  const [loader, setLoader] = useState();

  const onConfirm = () => {
    setNotification();

    if (async) {
      asyncHandler();
    } else {
      onAddTrainee(selectedClassroom);
    }
  };

  const asyncHandler = () => {
    setLoader(true);
    onAddTrainee(selectedClassroom)
      .catch(showErrorNotification)
      .finally(setLoader);
  };

  const showErrorNotification = (error) => {
    console.error("[Add Trainee to Classroom] - Error:", error);
    setNotification({
      type: NotificationType.error,
      message: "Something went wrong. Please try again.",
      closable: true,
      onClose: () => setNotification(),
    });
  };

  useEffect(() => {
    if (open) {
      setSelectedClassroom();
      setNotification();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      backdropClassName="add-trainee-classroom-modal"
      onClose={onCancel}
    >
      {loader && <Loader overlay></Loader>}
      <ModalHeader
        icon={["fa-regular", "screen-users"]}
        title={bulk ? "Add to Classroom" : "Add Trainee to Classroom"}
      ></ModalHeader>
      <ModalBody>
        {notification && (
          <Notification {...notification}>{notification.message}</Notification>
        )}
        <ClassroomDropdownList
          className={"classroom-dropdown"}
          placeholder="Select a classroom."
          icon={["fa-light", "magnifying-glass"]}
          orgCode={orgCode}
          selectedClassroom={selectedClassroom}
          onClassroomSelected={setSelectedClassroom}
        ></ClassroomDropdownList>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel}>Cancel</Button>
        <Button primary disabled={!selectedClassroom} onClick={onConfirm}>
          Add to Classroom
        </Button>
      </ModalFooter>
    </Modal>
  );
};
