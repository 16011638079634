import React, { useEffect, useState } from "react";
import { Controller, useForm, FormProvider } from "react-hook-form";
import {
  Loader,
  Notification,
  NotificationType,
  NotificationStack,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  RequiredItem,
} from "@transfr-inc/dashboard-components";
import {
  Button,
  Input,
  Textarea,
} from "@transfr-inc/dashboard-components/forms";
import "./edit-category.modal.scss";
import { CategoryImageDropdown } from "../../components/category-images.dropdown";
import container from "../../../../../../../container";

export function EditCategoryModal({
  category,
  classroom,
  open,
  onCancel,
  onEditCategoryHandler,
  onDeleteCategoryClickHandler,
  checkVersionNumber,
}) {
  const [loading, setLoading] = useState();
  const [notifications, setNotifications] = useState();
  const [responseNotification, setResponseNotification] = useState();
  const { menuBuilderService } = container;

  const methods = useForm({
    criteriaMode: "all",
    mode: "onBlur",
    defaultValues: { ...category },
  });

  const { handleSubmit, reset, formState, control, setValue } = methods;

  const { errors, isValid } = formState;

  const showError = (...errors) => {
    setNotifications(
      errors.map((e) => ({
        type: NotificationType.error,
        message: e,
      }))
    );
  };

  const checkErrors = () => {
    if (Object.keys(errors).length > 0) {
      const messages = new Set(Object.values(errors).map((e) => e.message));
      showError(...messages.values());
    } else {
      setNotifications();
    }
  };

  useEffect(() => {
    if (errors) {
      checkErrors();
    }
  }, [formState]);

  useEffect(() => {
    !open && reset();
    setValue("name", category?.categoryName);
    setValue("image", category?.categoryImage);
    setValue("description", category?.categoryDescription);
  }, [open]);

  const onCloseHandler = () => {
    onCancel && onCancel();
    setNotifications();
    reset(category, { keepDefaultValues: true });
  };

  const submitForm = async (values) => {
    setLoading(true);
    const isVersionNumberValid = await checkVersionNumber();
    if (!isVersionNumberValid) {
      setLoading();
      onCloseHandler();
      return;
    }
    setLoading();
    const data = {
      categoryName: values.name,
      categoryImage: values.image,
      categoryDescription: values.description,
    };

    const updatedCategory = await editCategory(data);
    if (updatedCategory.error) {
      setResponseNotification({
        type: NotificationType.error,
        message: updatedCategory.error,
      });
      setLoading();
    }

    setNotifications();
    setLoading();
    onCloseHandler();
    onEditCategoryHandler(category);
    return true;
  };

  const editCategory = async (data) => {
    const updatedCategory = await menuBuilderService.updateCustomCategory(
      category?.categoryId,
      classroom?.classroomId,
      data
    );
    await menuBuilderService.getClassroomCategories(classroom?.classroomId);
    return updatedCategory;
  };

  const onImageSelected = async (image) => {
    setValue("image", image);
  };

  const editCustomCategoryHandler = () => {
    handleSubmit(submitForm)();
  };

  const onClickDeleteCategory = () => {
    onCancel();
    onDeleteCategoryClickHandler();
  };

  return (
    <Modal
      modalClassName={"edit-category-modal"}
      open={open}
      onClose={onCloseHandler}
      preventClose={loading}
    >
      {loading && <Loader overlay></Loader>}
      <ModalHeader
        className="blue-icon"
        icon={["fa-regular", "plus"]}
        title="Edit Category Details"
      ></ModalHeader>
      <ModalBody>
        <div className="validation-errors">
          {notifications?.length && (
            <NotificationStack
              notifications={notifications}
            ></NotificationStack>
          )}
          {responseNotification?.type == NotificationType.error && (
            <div className="error">
              <Notification
                {...responseNotification}
                closable
                onClose={() => setResponseNotification()}
              >
                {responseNotification.message}
              </Notification>
            </div>
          )}
        </div>
        <div className="text-with-info">
          <label>
            Required Fields<sup>*</sup>
          </label>
        </div>
        <FormProvider {...methods}>
          <form className="edit-category-form">
            <Controller
              control={control}
              name="name"
              defaultValue={category?.categoryName}
              rules={{
                required: {
                  value: true,
                  message: "Required fields are missing.",
                },
              }}
              render={({ field: { onBlur, onChange, value, ref } }) => {
                return (
                  <Input
                    label={<RequiredItem text="Name" />}
                    type="text"
                    name="name"
                    errors={errors?.name}
                    ref={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    required
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="image"
              defaultValue={category?.categoryImage}
              rules={{
                required: {
                  value: true,
                  message: "Required fields are missing.",
                },
              }}
              render={({ field: { value } }) => {
                return (
                  <CategoryImageDropdown
                    className="category-image-dropdown"
                    onCategoryImageSelected={(image) => onImageSelected(image)}
                    selectedImage={value}
                  />
                );
              }}
            />
            <Controller
              control={control}
              defaultValue={category?.categoryDescription}
              name="description"
              rules={{
                required: {
                  value: true,
                  message: "Required fields are missing.",
                },
              }}
              render={({ field: { onBlur, onChange, value, ref } }) => {
                return (
                  <Textarea
                    label={<RequiredItem text="Description" />}
                    name="categoryDescription"
                    errors={errors?.name}
                    ref={ref}
                    onBlur={onBlur}
                    onInputChange={onChange}
                    value={value}
                    required
                  />
                );
              }}
            />
          </form>
        </FormProvider>
        <div>
          <Button
            className="delete-category-button"
            size="small"
            icon={["fa-light", "trash-can"]}
            onClick={onClickDeleteCategory}
          >
            Delete Category
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCloseHandler}>Cancel</Button>
        <Button primary onClick={editCustomCategoryHandler} disabled={!isValid}>
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
}
