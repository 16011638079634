import React, { useMemo } from "react";

import {
  Modal,
  ModalBody,
  ModalHeader,
} from "@transfr-inc/dashboard-components";

import {
  CareerGoalsTitleType,
  getTotalCareerGoals,
} from "../../../models/career-goal";

import "./career-plan-goals.modal.scss";

export const CareerPlanGoalsModal = ({ open, onClose, user, goals }) => {
  const userFullName = useMemo(
    () => `${user?.firstName} ${user?.lastName}`.trim(),
    [user]
  );

  const totalGoals = useMemo(() => getTotalCareerGoals(goals), [goals]);

  return (
    <Modal
      open={open}
      modalClassName="carer-plan-goals-modal"
      onClose={onClose}
    >
      <ModalHeader
        label={userFullName}
        title={
          <div className="modal-title-badge">
            {"Goals"}
            <div className="badge">{totalGoals}</div>
          </div>
        }
      />
      <ModalBody>
        <div className="goals-container">
          {goals &&
            Object.entries(CareerGoalsTitleType).map(([type, title]) => (
              <div key={`${type}-goals`} className="goals-type-section">
                <h5>{title}</h5>
                <ul className="goals-type-list">
                  {goals[type]?.length > 0 ? (
                    goals[type].map((g) => (
                      <li key={`${type}-goal-${g.id}`}>{g.goalText}</li>
                    ))
                  ) : (
                    <i className="no-goal-text">None</i>
                  )}
                </ul>
              </div>
            ))}
        </div>
      </ModalBody>
    </Modal>
  );
};
