import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { useUnloadWarning } from "@transfr-inc/dashboard-components/hooks";

import { DashboardBanner, DashboardInsights } from "../../components";

import { Product, Role } from "../../../../models";

import { useApiRequest } from "../../../../lib/http-client";
import {
  exportOrganizationStats,
  getOrganizationDailyStats,
  getOrganizationStats as getOrganizationGeneralStats,
  getStatsFileName,
} from "../../../../services";

import container from "../../../../container";

export const TsTab = () => {
  const defaultModuleOption = { displayName: "All Modules" };
  const defaultFilterCount = 1;
  const { classroomService } = container;
  const { state: locationState } = useLocation();
  const { currentUser, role } = useStoreState((store) => store.account);
  const { setDashboardLoaded } = useStoreActions((store) => store.app);
  const { organizationName, organizationCode, userId } = currentUser;
  const [orgName, setOrgName] = useState();
  const [dateRange, setDateRange] = useState({});
  const [generalStats, setGeneralStats] = useState({ isLoading: true });
  const [dailyStats, setDailyStats] = useState({ isLoading: true });
  const { organization } = locationState ?? {};
  const [classroomSelected, setClassroomSelected] = useState();
  const [moduleSelected, setModuleSelected] = useState(defaultModuleOption);
  const [filterCount, setFilterCount] = useState(defaultFilterCount);

  const getOrgCode = () => organization?.code ?? organizationCode;

  const { loading: downloadingFile, sendRequest: exportInsights } =
    useApiRequest(() => {
      const moduleId = moduleSelected?.moduleId ?? undefined;
      const filters = { dateRange, moduleId };
      const fileName = getStatsFileName(orgName, dateRange);
      const code = role === Role.INSTRUCTOR.id ? userId : getOrgCode();

      return classroomSelected
        ? classroomService.exportClassroomStats(
            classroomSelected.classroomId,
            filters,
            fileName
          )
        : exportOrganizationStats(role, code, filters, fileName, false);
    }, false);

  useUnloadWarning({ trigger: downloadingFile });

  useEffect(() => {
    const name = organization?.name ?? organizationName;
    setOrgName(name);
  }, []);

  useEffect(() => {
    if (!generalStats.isLoading && !dailyStats.isLoading) {
      setDashboardLoaded();
    }
  }, [generalStats, dailyStats]);

  useEffect(() => {
    const { startDate, endDate } = dateRange;
    const moduleId = moduleSelected?.moduleId ?? undefined;
    const filters = {
      dateRange,
      moduleId,
    };
    if (startDate && endDate) {
      if (classroomSelected) {
        getClassroomStats(classroomSelected.classroomId, filters);
      } else {
        getOrganizationStats(getOrgCode(), filters);
      }
    }
  }, [dateRange, classroomSelected, moduleSelected]);

  const updateFilterCount = (classroom, module) => {
    let count = defaultFilterCount;
    if (classroom) {
      count += 1;
    }
    if (module) {
      count += 1;
    }
    setFilterCount(count);
  };

  const onApplyFilters = ({ dateRange, classroom, module }) => {
    const { startDate, endDate } = dateRange || {};
    clearStats();
    setDateRange({ startDate, endDate });
    setClassroomSelected(classroom);
    setModuleSelected(module);
    updateFilterCount(classroom, module);
  };

  const clearStats = () => {
    setDailyStats({ isLoading: true });
    setGeneralStats({ isLoading: true });
  };

  const getClassroomStats = (classroomId, filters) => {
    Promise.all([
      classroomService
        .getClassroomStats(classroomId, filters)
        .then((response) => setGeneralStats({ isLoading: false, response })),
      classroomService
        .getClassroomDailyStats(classroomId, filters)
        .then((response) => setDailyStats({ isLoading: false, response })),
    ]).catch(() => {
      setGeneralStats({ isLoading: false });
      setDailyStats({ isLoading: false });
    });
  };

  const getOrganizationStats = (orgCode, filters) => {
    Promise.all([
      getOrganizationGeneralStats(userId, role, orgCode, filters).then(
        (response) => setGeneralStats({ isLoading: false, response })
      ),
      getOrganizationDailyStats(userId, role, orgCode, filters).then(
        (response) => setDailyStats({ isLoading: false, response })
      ),
    ]).catch(() => {
      setGeneralStats({ isLoading: false });
      setDailyStats({ isLoading: false });
    });
  };

  const onExportStatsData = () => {
    const { startDate, endDate } = dateRange;
    if (startDate && endDate) {
      exportInsights();
    }
  };
  return (
    <>
      <DashboardBanner product={Product.TS} />
      <DashboardInsights
        onApplyFilters={onApplyFilters}
        orgCode={getOrgCode()}
        classroomFilterEnabled
        userInfo={{ userId, role }}
        generalStats={generalStats}
        dailyStats={dailyStats}
        onExportData={onExportStatsData}
        exportDataDisabled={downloadingFile}
        moduleFilterEnabled
        filterCount={filterCount}
      ></DashboardInsights>
      <div className="notification-loader-container">
        {downloadingFile && (
          <Notification
            type={NotificationType.info}
            closable
            icon={["fa-solid", "spinner"]}
            iconConfig={{ spin: true }}
          >
            We’re preparing a download of{" "}
            <strong>{`${
              classroomSelected ? classroomSelected.name : orgName
            }'s`}</strong>{" "}
            insights. Please wait...
          </Notification>
        )}
      </div>
    </>
  );
};
