import React, { useEffect, useState } from "react";
import {
  Loader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@transfr-inc/dashboard-components";
import { Button, Select } from "@transfr-inc/dashboard-components/forms";
import { CategoryOptionItem } from "./category-option-item";

import "./add-category.modal.scss";
import container from "../../../../../../../container";
import { useStoreState } from "easy-peasy";
import { Role } from "../../../../../../../models";

export function AddCategoryModal({
  open,
  onCancel,
  classroom,
  onCreateCategory,
  onAddCategories,
}) {
  const { organizationService, userService, menuBuilderService } = container;
  const { currentUser, role } = useStoreState((store) => store.account);
  const { organizationCode, userId } = currentUser;
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    const fetchCategoriesList = async () => {
      setLoading(true);
      let data = [];
      if (role === Role.MULTI_ORG_ADMIN.id) {
        data = await organizationService.getMultiOrganizationCustomCategories(organizationCode);
      } else if (role === Role.ORG_ADMIN.id) {
        data = await organizationService.getOrganizationCustomCategories(organizationCode);
      } else if (role === Role.INSTRUCTOR.id) {
        data = await userService.getCustomCategories(userId);
      }
      setCategories(data.filter(({ classroomId }) => classroomId !== classroom.classroomId));
      setLoading();
    }
    fetchCategoriesList();
  }, [organizationCode]);

  const onAddCategoriesHandler = async () => {
    setLoading(true);
    await menuBuilderService.addCustomCategories(
      classroom?.classroomId,
      selectedCategories.map(({
          classroomId,
          categoryId,
          categoryName,
          categoryImage,
          categoryDescription
        }) => ({
          classroomId,
          categoryId,
          categoryName,
          categoryImage,
          categoryDescription
        }))
    );
    setLoading();
    onAddCategories(selectedCategories);
  }

  const onCloseHandler = () => {
    onCancel && onCancel();
  };

  return (
    <Modal
      modalClassName={"add-category-modal"}
      open={open}
      onClose={onCloseHandler}
    >
      {loading && <Loader overlay></Loader>}
      <ModalHeader
        className="blue-icon"
        icon={["fa-regular", "plus"]}
        title="Add a Category"
      ></ModalHeader>
      <ModalBody>
        <Button
          className="create-category-btn"
          icon={["fa-regular", "layer-plus"]}
          onClick={onCreateCategory}
        >
          Create Category
        </Button>
        OR
        <Select
          icon={["fa-light", "fa-magnifying-glass"]}
          placeholder="Add one or more existing categories..."
          onOptionsChange={(options) => setSelectedCategories(options.map(({ data }) => data))}
          options={categories.map((category) => new CategoryOptionItem(category))}
          clearEnabled={false}
          searchEnabled
          displayHeader
        ></Select>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCloseHandler}>Cancel</Button>
        <Button
          primary
          disabled={selectedCategories.length === 0}
          onClick={onAddCategoriesHandler}
        >
          Add Category
        </Button>
      </ModalFooter>
    </Modal>
  );
}
