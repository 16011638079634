import React, { useState, useEffect, useRef } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import clsx from "clsx";

import {
  PageLayout,
  PageHeader,
} from "@transfr-inc/dashboard-components/layouts";

import {
  Loader,
  Toolbar,
  Notification,
  NotificationType,
  MenuOptionSpec,
  BarOptionDefinition,
  CountText,
} from "@transfr-inc/dashboard-components";

import { UpdatePasswordSuccess } from "../../classrooms/details/components/menu-builder/utils/notification.constants";

import { Button } from "@transfr-inc/dashboard-components/forms";

// View components
import { UsersTabs } from "./users.tabs";
import { OrganizationFilter } from "../../../components/custom/forms";

// Modals
import { NewUserModal } from "../new-user/new-user-modal.jsx";
import { DeleteUserModal } from "../../../components/common/delete-user-modal";

import {
  ChangePasswordModal,
  EditUserModal,
  AddTraineeClassroomModal,
} from "../../../components/modals";
import ResendInviteModal from "./resend-invite-modal";

// Utils
import { AllRoles, Role } from "../../../models/role";
import { useApiRequest } from "../../../lib/http-client";
import container from "../../../container";

import "./index.scss";

const getRoleMap = (data) => {
  const roleMap = new Map();

  for (const user of data) {
    if (!roleMap.has(user.roleId)) {
      roleMap.set(user.roleId, []);
    }

    const userArray = roleMap.get(user.roleId);
    userArray.push(user);
  }

  for (const role of AllRoles) {
    if (!roleMap.has(role.id)) {
      roleMap.set(role.id, []);
    }
  }
  return roleMap;
};

export default function UserList({ responsiveStyles = {}, className = "" }) {
  // For MVP we will retrieve all of the users at once and not refresh a clicked tab. The user will need to reload
  // the page in order to see updated data in any of the tabs
  const originalDataRef = useRef();
  const organizationFilterRef = useRef();

  const [roleMap, setRoleMap] = useState(new Map());
  const [stateNotification, setStateNotification] = useState();
  const [stateShowEditDetails, setStateShowEditDetails] = useState(false);
  const [stateUser, setStateUser] = useState();
  const [openPasswordModal, setOpenPasswordModal] = useState();
  const [openResendModal, setOpenResendModal] = useState();
  const [openAddToClassroom, setOpenAddToClassroom] = useState();
  const [dataFiltered, setDataFiltered] = useState();

  const { role, features, currentUser } = useStoreState(
    (store) => store.account
  );

  const { users } = useStoreState((store) => store.organization);

  const { refreshOrganizationUsers } = useStoreActions(
    (store) => store.organization
  );

  const { userId, organizationCode } = currentUser;

  const { userService, classroomService } = container;

  const { loading: loadingUsers, sendRequest: getUsers } = useApiRequest(
    () => refreshOrganizationUsers({ role, orgCode: organizationCode, userId }),
    false
  );

  const onOrganizationChange = (option) => {
    const originalData = originalDataRef.current;

    let filteredData = [];
    if (option) {
      filteredData = originalData.filter(
        (value) => value.organizationCode == option.code
      );
      setDataFiltered(true);
    } else {
      filteredData = originalData;
      setDataFiltered(false);
    }

    organizationFilterRef.current = option;
    setRoleMap(getRoleMap(filteredData));
  };

  const [show, setShow] = useState(false);

  const onSetShow = () => {
    setShow(true);
  };

  const [showDelete, setShowDelete] = useState(false);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [addToClassroomList, setAddToClassroomList] = useState([]);

  const refreshUsersData = () => {
    const organizationSelected = organizationFilterRef.current;
    originalDataRef.current = users;
    onOrganizationChange(organizationSelected);
  };

  useEffect(() => {
    if (users) {
      refreshUsersData();
    }
  }, [users]);

  const toolbar = (
    <Toolbar>
      {features.canCreateUser && role != Role.INSTRUCTOR.id && (
        <Button
          icon={["fa-regular", "user-plus"]}
          primary
          title={"Create User"}
          tooltip={"Create User"}
          onClick={() => setShow(true)}
          size="small"
        ></Button>
      )}
    </Toolbar>
  );

  const onDelete = (usersToDelete) => {
    setSelectedUserList(usersToDelete);
    setShowDelete(true);
  };

  const onUpdatePassword = (usersToUpdate) => {
    setSelectedUserList(usersToUpdate);
    setOpenPasswordModal(true);
  };

  const onRowRemoved = () => {
    setSelectedUserList([]);
    getUsers();
  };

  const onPasswordUpdatedHandler = (response) => {
    setOpenPasswordModal();
    onRowRemoved();
    launchNotification({
      type: NotificationType.success,
      message: UpdatePasswordSuccess(response),
      icon: ["fa-solid", "circle-check"],
    });
  };

  const onInvitationSentHandler = () => {
    setOpenResendModal();
    launchNotification({
      type: NotificationType.success,
      message: (
        <div>
          An account creation email has been sent to
          <b>
            {" "}
            {stateUser.firstName} {stateUser.lastName}
          </b>
        </div>
      ),
      icon: ["fa-solid", "circle-check"],
    });
  };

  const onCloseChangePassword = () => {
    setOpenPasswordModal();
  };

  const onCloseResendInvite = () => {
    setOpenResendModal();
  };

  const onCloseEditDetails = () => {
    setStateShowEditDetails(false);
  };

  const onUserCreated = async (notification) => {
    launchNotification(notification);
    notification && getUsers();
  };

  const launchNotification = async (notification) => {
    setStateNotification(notification);
    setTimeout(() => {
      setStateNotification();
    }, 10000);
  };

  const onUpdateUser = async (user) => {
    try {
      const updatedUser = await userService.updateUserMetadata(user);
      launchNotification({
        type: NotificationType.success,
        message: `User ${updatedUser.firstName} ${updatedUser.lastName} has been updated successfully.`,
        icon: ["fa-solid", "circle-check"],
      });

      onCloseEditDetails();
      getUsers();
      return updatedUser;
    } catch (error) {
      console.log(error);
      const { data } = error;
      user.error =
        data?.detail ?? "Something went wrong, please try again later.";
      return user;
    }
  };

  const onDeleteUser = async (keys, data) => {
    onDelete([data]);
  };

  const onEditDetails = async (keys, data) => {
    const user = await userService.getUserDetails(data.userId);
    setStateUser(user);
    setStateShowEditDetails(true);
  };

  const onChangePassword = async (keys, data) => {
    setSelectedUserList([data]);
    setOpenPasswordModal(true);
  };

  const onResendInvite = async (keys, data) => {
    setStateUser(data);
    setOpenResendModal(true);
  };

  const onOpenAddToClassroom = async (keys, data) => {
    data ? setAddToClassroomList([data]) : setAddToClassroomList(keys);
    setOpenAddToClassroom(true);
  };

  const onAddTraineeToClassroom = async (classroom) => {
    const { classroomId, name } = classroom;
    for (const t of addToClassroomList) {
      await classroomService.addTrainee(classroomId, t.userId);
    }

    setOpenAddToClassroom();
    getUsers();
    launchNotification({
      type: NotificationType.success,
      message:
        addToClassroomList.length == 1 ? (
          <>
            <strong>{`${addToClassroomList[0].firstName} ${addToClassroomList[0].lastName}`}</strong>{" "}
            has been added to classroom <strong>{name}</strong>
          </>
        ) : (
          <>
            <strong>{`${addToClassroomList.length} Trainees`}</strong> have been
            added to classroom <strong>{name}</strong>
          </>
        ),
      icon: ["fa-solid", "circle-check"],
    });
  };

  const bottomBarOptions = {
    addToClassroom: new BarOptionDefinition(onOpenAddToClassroom, {
      text: "Add to Classroom",
      icon: ["fa-light", "screen-users"],
      destructive: false,
    }),
    updatePasswords: new BarOptionDefinition(onUpdatePassword, {
      text: "Change Password",
      icon: ["fa-light", "fa-key"],
      destructive: false,
    }),
    delete: new BarOptionDefinition(onDelete, {
      text: "Delete",
      icon: ["fa-light", "fa-trash-can"],
      destructive: true,
    }),
  };

  const actionMenuOptions = {
    delete: new MenuOptionSpec(
      "Delete",
      ["fa-light", "fa-trash-can"],
      onDeleteUser,
      "delete"
    ),
    edit: new MenuOptionSpec(
      "Edit Details",
      ["fa-light", "fa-edit"],
      onEditDetails,
      "edit"
    ),
    changePassword: new MenuOptionSpec(
      "Change Password",
      ["fa-light", "fa-key"],
      onChangePassword,
      "change_password"
    ),
    resendInvite: new MenuOptionSpec(
      "Resend Invite",
      ["fa-light", "fa-paper-plane"],
      onResendInvite,
      "resend_invite"
    ),
    addToClassroom: new MenuOptionSpec(
      "Add to Classroom",
      ["fa-light", "screen-users"],
      onOpenAddToClassroom,
      "add_to_classroom"
    ),
  };

  const orgFilter = (role === Role.MULTI_ORG_ADMIN.id ||
    role === Role.MULTI_ORG_VIEWER.id) && (
    <OrganizationFilter
      orgCode={organizationCode}
      onOrganizationSelected={onOrganizationChange}
    />
  );

  const pageHeader = (
    <PageHeader
      title={"Users"}
      badge={loadingUsers ? "..." : <CountText count={users?.length} />}
      middlePane={orgFilter}
    >
      {toolbar}
    </PageHeader>
  );

  return (
    <PageLayout
      className={clsx("users-page", className)}
      header={pageHeader}
      responsiveStyles={responsiveStyles}
    >
      {loadingUsers && <Loader overlay fixed />}
      <UsersTabs
        data={roleMap}
        responsiveStyles={responsiveStyles}
        bottomBarOptions={bottomBarOptions}
        actionMenuOptions={actionMenuOptions}
        onSetShowNewUser={onSetShow}
        dataFiltered={dataFiltered}
        creatableUsers={features.creatableUsers}
      ></UsersTabs>
      {features.canCreateUser && show && (
        <NewUserModal
          show={show}
          setShow={() => setShow()}
          role={role}
          onUserCreated={onUserCreated}
          {...features}
        />
      )}
      <DeleteUserModal
        onCancel={() => setShowDelete()}
        show={showDelete}
        deleteUserList={selectedUserList}
        onRowRemoved={onRowRemoved}
      />
      {stateShowEditDetails && stateUser && (
        <EditUserModal
          open={stateShowEditDetails}
          onUpdateUser={onUpdateUser}
          onClose={onCloseEditDetails}
          user={stateUser}
        />
      )}
      <ChangePasswordModal
        open={openPasswordModal}
        userIds={selectedUserList.map((u) => u.userId)}
        onCancel={onCloseChangePassword}
        onPasswordChanged={onPasswordUpdatedHandler}
      ></ChangePasswordModal>
      <ResendInviteModal
        open={openResendModal}
        userId={stateUser?.userId}
        onClose={onCloseResendInvite}
        onInvitationSent={onInvitationSentHandler}
      ></ResendInviteModal>
      <AddTraineeClassroomModal
        open={openAddToClassroom}
        orgCode={organizationFilterRef.current?.code}
        onCancel={() => setOpenAddToClassroom()}
        onAddTrainee={onAddTraineeToClassroom}
        bulk
        async
      ></AddTraineeClassroomModal>
      <div className="notification-container success-notification">
        {stateNotification && (
          <Notification
            type={stateNotification.type}
            icon={stateNotification.icon}
            onClose={() => {
              setStateNotification();
            }}
            closable
          >
            {stateNotification.message}
          </Notification>
        )}
      </div>
    </PageLayout>
  );
}
