import { formatTimestap } from "@transfr-inc/dashboard-components/utils";
import { saveAs } from "file-saver";
import Papa from "papaparse";

export const ExportStatus = {
  creating: 0,
  complete: 1,
  error: 2,
};

export const doExportData = async (fileName, data) => {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
  saveAs(blob, `${fileName} - ${formatTimestap()}.csv`);
};
