import React, { useState, useEffect } from "react";
import { useStoreState } from "easy-peasy";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";

import { Button, OrFormDivider } from "@transfr-inc/dashboard-components/forms";
import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";

import { UserSelect } from "./user-select";
import { UserOptionItem } from "./user-option-item";

import CreateInstructorModal from "./create-instructor-modal";

import { Role } from "../../../models/role";

// CA TODO: Combine with TraineesEditor somehow???
export function InstructorsEditor({
  instructorData,
  onCreateInstructor,
  instructorCreationEnabled = true,
  className,
}) {
  const { currentUser, role, features } = useStoreState(
    (store) => store.account
  );
  const { control, setValue } = useFormContext();
  const [showCreateInstructorModal, setShowCreateInstructorModal] = useState();
  const [successfullyCreatedInstructor, setSuccessfullyCreatedInstructor] =
    useState();

  const onCreateInstructorModalClose = (instructor) => {
    if (!instructor || instructor.canceled) {
      setSuccessfullyCreatedInstructor();
    } else {
      setSuccessfullyCreatedInstructor(instructor);
    }

    setShowCreateInstructorModal(false);
  };

  useEffect(() => {
    const userFound =
      role === Role.INSTRUCTOR.id &&
      instructorData.find((x) => x.userId == currentUser.userId);
    const defaultInstructor = userFound ? [new UserOptionItem(userFound)] : [];

    setValue("instructors", defaultInstructor);
  }, []);

  return (
    <div className={clsx("add-instructors-editor", className)}>
      {instructorCreationEnabled && features.instructorCreationEnabled && (
        <>
          {successfullyCreatedInstructor && (
            <Notification
              type={NotificationType.success}
              closable
              onClose={() => {
                setSuccessfullyCreatedInstructor();
              }}
            >
              {successfullyCreatedInstructor.firstName}{" "}
              {successfullyCreatedInstructor.lastName} has been created as an
              instructor.
            </Notification>
          )}
          <div>
            <Button
              primary={false}
              size="small"
              icon={["fa-light", "user-plus"]}
              onClick={() => setShowCreateInstructorModal(true)}
              className="main-dialog"
            >
              Create Instructor
            </Button>
          </div>

          <OrFormDivider />
        </>
      )}

      <Controller
        control={control}
        name="instructors"
        render={({ field: { onChange, value } }) => {
          return (
            <UserSelect
              defaultText={"Add one or more additional instructors..."}
              onChange={onChange}
              data={instructorData}
              selectedUsers={value}
              name={"instructors"}
            ></UserSelect>
          );
        }}
      />
      <CreateInstructorModal
        onCreateInstructor={onCreateInstructor}
        open={showCreateInstructorModal}
        onClose={onCreateInstructorModalClose}
      />
    </div>
  );
}
