import container from "../../container";
import { useEffect, useState } from "react";

export const useTopCareersSelected = ({ classroomId, orgCode } = {}) => {
  const { trekService, simulationsService } = container;
  const [topCareers, setTopCareers] = useState();
  const [loading, setLoading] = useState();

  const getTopCareers = async ({ classroomId, orgCode, filters }) => {
    setLoading(true);
    try {
      const simulationsRequest = classroomId
        ? simulationsService.getClassroomSimulations(classroomId)
        : simulationsService.getAllSimulations();

      const data = await Promise.allSettled([
        simulationsRequest,
        trekService.getTopCareers({ classroomId, orgCode, filters }),
      ]).then(([simulationsResult, careersResult]) => {
        const { value: careers } = careersResult;
        const { value: simulations } = simulationsResult;

        const occupationMap = new Map(
          simulations
            ?.filter((s) => s.occupationCode)
            .map((s) => [s.occupationCode, s])
        );

        for (const career of careers) {
          career.simulations = career.occupationCodes?.map(
            (code) => occupationMap.get(code)?.name
          );
          career.totalSelected = career.users.length;
        }

        return careers;
      });

      setTopCareers(data);
    } finally {
      setLoading();
    }
  };

  useEffect(() => {
    if (classroomId || orgCode) {
      getTopCareers({ classroomId, orgCode });
    }
  }, []);

  return { topCareers, loading, refreshData: getTopCareers };
};
