import React from "react";
import clsx from "clsx";

import {
  Column,
  ColumnType,
  SortDirection,
  DataTable,
} from "@transfr-inc/dashboard-components/data-table";

import { ZeroStateMessage } from "./zero-state-message";
import { DataTableColumnDefinition } from "./data-table-column-definition";

import "./admin-users-tab.scss";

export function AdminUsersTab({
  data,
  role,
  responsiveStyles,
  bottomBarOptions,
  actionMenuOptions,
  onSetShowNewUser,
  dataFiltered,
  className = "admin-users-tab",
}) {
  const columns = [
    ...(bottomBarOptions?.length > 0
      ? [new Column("select", /*title:*/ undefined, ColumnType.SELECTION)]
      : []),
    DataTableColumnDefinition.AVATAR,
    DataTableColumnDefinition.LAST_NAME(),
    DataTableColumnDefinition.FIRST_NAME(),
    DataTableColumnDefinition.ORGANIZATION,
    DataTableColumnDefinition.EMAIL,
  ];

  const zeroStateMessage = (
    <ZeroStateMessage
      role={role}
      onShowButton={onSetShowNewUser}
      filtered={dataFiltered}
    ></ZeroStateMessage>
  );

  return (
    <DataTable
      columns={columns}
      data={data}
      className={clsx(
        "admin-users-table",
        className,
        bottomBarOptions && "selection"
      )}
      keyFields={["userId"]}
      keyPrefix={"admin-users-" + role.id}
      defaultSortPropName="lastName"
      defaultSortDirection={SortDirection.ASCENDING}
      responsiveStyles={responsiveStyles}
      zeroStateMessage={zeroStateMessage}
      bottomBarOptions={bottomBarOptions}
      tableContentName={{ singular: "user", plural: "users" }}
      actionMenuOptions={actionMenuOptions}
      rowHeightConfig={{ medium: 120, small: 140 }}
      virtualRowEnabled
    />
  );
}
