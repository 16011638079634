import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import PrivateRoute from "./components/common/routing/private-route";
import { AppLayout } from "./layouts";
import {
  Login,
  ForgotPassword,
  ResetPassword,
  CreateAccount,
  ProProfsAuth,
  ProProfsLogout,
} from "./views";
import { RootPath } from "./lib/routes";
import { useStoreState } from "easy-peasy";

export default () => {
  const { proProfsEnabled } = useStoreState((state) => state.app);

  return (
    <Router>
      <Switch>
        <PrivateRoute path={RootPath}>
          <AppLayout />
        </PrivateRoute>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/create-account" component={CreateAccount} />
        {proProfsEnabled && (
          <Route path="/proprofs-auth" component={ProProfsAuth} />
        )}
        {proProfsEnabled && (
          <Route path="/proprofs-logout" component={ProProfsLogout} />
        )}
        <Route path="*">
          <Redirect to={RootPath} />
        </Route>
      </Switch>
    </Router>
  );
};
