import React, { useState } from "react";
import { useStoreState } from "easy-peasy";
import { Button, FileInput } from "@transfr-inc/dashboard-components/forms";
import {
  Notification,
  NotificationType,
  TextTooltip,
} from "@transfr-inc/dashboard-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import container from "../../../container";
import { getErrorMessage } from "../../../lib/browser-utils";
import "./upload-footer.scss";

const TEMPLATE_DOWNLOAD_INFO_TEXT =
  `Please download and fill out the trainee template. Once completed press Bulk Upload and locate your file. Any ` +
  `trainees not currently in the system will be created. ` +
  `Larger uploads will continue to process in the background. We will send you an email when the file is finished.`;

const downloadBulkUploadTemplate = () => {
  saveAs(
    `/templates/bulk-user-upload-template.csv`,
    "bulk-user-upload-template.csv"
  );
};

export function UploadFooter({ rosteringEnabled, setOpenRosterModal }) {
  const [loading, setLoading] = useState(false);
  const [uploadNotification, setUploadNotification] = useState(false);
  const { userService } = container;
  const { currentUser } = useStoreState((store) => store.account);

  const onHandleFile = async (file) => {
    setLoading(true);
    try {
      const isValidCsv = await userService.uploadUsersAsync(
        file,
        currentUser.organizationCode
      );
      if (isValidCsv == true) {
        setUploadNotification({
          message:
            `Your file has been successfully uploaded. Due to its size this file will ` +
            `continue to process in the background. Feel free to continue and we will ` +
            `send you an email when it's finished. `,
          type: NotificationType.success,
        });
      }
    } catch (error) {
      setUploadNotification({
        message: getErrorMessage(error.data),
        type: NotificationType.error,
      });
    }
    setLoading(false);
  };

  if (rosteringEnabled) {
    return (
      <div className="student-rostering">
        <div className="roster-container">
          {rosteringEnabled && (
            <Button
              icon={["fa-regular", "clipboard-list-check"]}
              onClick={() => setOpenRosterModal(true)}
              size="small"
            >
              Roster
            </Button>
          )}
          <div className="message">
            Use Roster to quickly create trainees across your organization.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="student-upload">
      <div className="message">
        Do you need to upload trainees and classrooms across your organization?
      </div>
      <div className="button-row">
        <FileInput
          primary={false}
          size="small"
          icon={["fa-light", "upload"]}
          onHandleFile={onHandleFile}
          className={"main-dialog"}
          disabled={loading}
          loader={loading}
          label={"Bulk Upload (.csv)"}
        ></FileInput>
        <Button
          primary={false}
          size="small"
          icon={["fa-light", "download"]}
          onClick={() => downloadBulkUploadTemplate()}
          className={"main-dialog"}
          disabled={loading}
        >
          Template (.csv)
        </Button>
        <div className="template-download-info">
          <TextTooltip text={TEMPLATE_DOWNLOAD_INFO_TEXT}>
            <FontAwesomeIcon icon="fa-regular fa-circle-info" />
          </TextTooltip>
        </div>
      </div>
      {uploadNotification && (
        <div className="notification-container">
          <Notification
            type={uploadNotification.type}
            closable
            onClose={() => {
              setUploadNotification();
            }}
          >
            {uploadNotification.message}
          </Notification>
        </div>
      )}
    </div>
  );
}
