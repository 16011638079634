import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useStoreState } from "easy-peasy";

import { ChartFilter } from "../../../components/custom/forms";
import { Product } from "../../../models";
import { DashboardChart } from "./dashboard.chart";
import { TopCareersSelected } from "../../../components/top-careers-selected";
import { TrekDataboxes } from "./trek.databoxes";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "@transfr-inc/dashboard-components";
import { TrekFeedbackForm } from "../../../models";

export function TrekInsights({
  userInfo,
  orgCode,
  generalStats,
  dailyStats,
  onApplyFilters,
  classroomFilterEnabled,
  organizationEnabled,
  className,
  topCareers,
  isLoadingTopCareers,
  users,
  onExportData,
  exportDataDisabled,
}) {
  const [categorySelected, setCategorySelected] = useState();
  const { lastDateFilter } = useStoreState((store) => store.date);
  const [dateFilter, setDateFilter] = useState(lastDateFilter);
  const [allTimeStartDate, setAllTimeStartDate] = useState();
  const [truncatedDailyStats, setTruncatedDailyStats] = useState(dailyStats);

  useEffect(() => {
    setTruncatedDailyStats(dailyStats);
    if (!dailyStats.isLoading && dateFilter > 365) {
      let firstDataIndex = dailyStats.response.findIndex(
        (item) => Object.keys(item).length > 2
      );
      firstDataIndex = firstDataIndex == -1 ? 0 : firstDataIndex;
      const truncated = dailyStats.response.slice(firstDataIndex);

      setTruncatedDailyStats({
        ...dailyStats,
        response: truncated,
      });
      setAllTimeStartDate(truncated[0].date);
    }
  }, [dailyStats, dateFilter]);

  const onToggleAllTime = (allTimeSelected) => {
    if (allTimeSelected) {
      setDateFilter(366);
    } else {
      setDateFilter();
      setAllTimeStartDate();
    }
  };

  return (
    <div className={clsx("dashboard-insights", className)}>
      <div className="subheader">
        <ChartFilter
          onApplyFilters={onApplyFilters}
          userInfo={userInfo}
          orgCode={orgCode}
          classroomEnabled={classroomFilterEnabled}
          organizationEnabled={organizationEnabled}
          product={Product.TRK}
          allTimeStartDate={allTimeStartDate}
          onToggleAllTime={onToggleAllTime}
        />
        <div className="right-hand-buttons">
          <Button size="small">
            <Link to={TrekFeedbackForm.url} underline={false} isExternal>
              Feedback <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </Link>
          </Button>
          <Button
            primary
            size="small"
            icon={["fa-light", "file-export"]}
            onClick={onExportData}
            disabled={exportDataDisabled}
          >
            Export Data (.csv)
          </Button>
        </div>
      </div>
      <TopCareersSelected
        breadcrumbLabel="dashboard"
        topCareers={topCareers}
        isLoading={isLoadingTopCareers}
        activeUsers={generalStats?.totalActiveUsers}
        users={users}
      />
      <TrekDataboxes
        onCategoryChange={setCategorySelected}
        data={generalStats}
      />
      <DashboardChart
        className="chart"
        dailyStats={truncatedDailyStats}
        filterSelected={categorySelected}
      />
    </div>
  );
}
