import React, { useEffect } from "react";
import { useState } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";

import {
  RequiredFieldsText,
  RequiredItem,
} from "@transfr-inc/dashboard-components";

import { OrganizationDropdownList } from "../../../components/custom/dropdowns/organization-dropdown-list";
import { ProductDropDownList } from "../../../components/custom/dropdowns/product-dropdown-list";
import { ClassroomDetailsEditor } from "./classroom-details-editor";

import { UserOptionItem } from "./user-option-item";
import { Role, Product } from "../../../models";
import { catchUserCreationError } from "../../../services/user.util";

import container from "../../../container";

export function ClassroomInfo({
  orgSelectionEnabled = true,
  ceEnabled = true,
  trekEnabled = true,
  products,
  instructorCreationEnabled = true,
  className = "",
  bulkUploadEnabled = false,
}) {
  const { userService } = container;

  const { reset, control, setValue, getValues } = useFormContext();

  const [productOptions, setProductsOptions] = useState(products);
  const [stateInstructorData, setStateInstructorData] = useState();
  const [stateTraineeData, setStateTraineeData] = useState();

  const selectedProduct = useWatch({ name: "product" });
  const selectedOrg = useWatch({ name: "organization" });

  async function addTraineesToForm(...trainees) {
    const selectedTrainees = getValues("trainees");
    const updatedTrainees = [...selectedTrainees, ...trainees];

    setValue("trainees", updatedTrainees);
  }

  async function addInstructorsToForm(instructors) {
    const selectedInstructors = getValues("instructors");
    const updatedInstructors = [...selectedInstructors, instructors];

    setValue("instructors", updatedInstructors);
  }

  async function fetchInstructorUsers() {
    const instructorData = await userService.getOrganizationUsersForRoles(
      selectedOrg.code,
      [Role.INSTRUCTOR.id, Role.ORG_ADMIN.id]
    );

    setStateInstructorData(instructorData);
  }

  async function fetchStudentUsers() {
    const studentUsers = await userService.getOrganizationUsersForRole(
      selectedOrg.code,
      Role.STUDENT.id
    );
    setStateTraineeData(studentUsers);
  }

  const onOrgSelected = async (org) => {
    reset({
      name: "",
    });
    setValue("organization", org);
  };

  const onProductSelected = async (selectedProduct) => {
    await fetchInstructorUsers();
    await fetchStudentUsers();

    setValue("product", selectedProduct);
  };

  const onCreateInstructor = async (user) => {
    let instructor;

    try {
      instructor = await userService.createNewUser(user, selectedOrg.code);
    } catch (error) {
      let errorMessage;
      if (error.status == 409) {
        errorMessage = "Email is not unique";
      } else {
        errorMessage =
          error.data && error.data.detail
            ? error.data.detail
            : "Unable to create instructor";
      }

      return { error: errorMessage };
    }

    await fetchInstructorUsers();

    const instructorOption = new UserOptionItem(instructor);
    addInstructorsToForm(instructorOption);

    return instructor;
  };

  const onCreateTrainee = async (user) => {
    console.log("onCreateTrainee");
    console.log(user);
    let student;

    try {
      student = await userService.createNewUser(user, selectedOrg.code, [
        selectedProduct.id,
      ]);
    } catch (error) {
      return catchUserCreationError(error);
    }

    await fetchStudentUsers();

    const studentOption = new UserOptionItem(student);
    addTraineesToForm(studentOption);

    return student;
  };

  const onAddTrainees = async (users) => {
    if (users && users.length > 0) {
      console.log("onaddtrainees users", users);
      await fetchStudentUsers();

      const optionItems = users.map((user) => new UserOptionItem(user));
      addTraineesToForm(...optionItems);
    }
  };

  useEffect(() => {
    const data = selectedOrg?.products ?? products;
    let dataFiltered = ceEnabled ? data : data.filter((p) => p !== Product.CE);

    dataFiltered = trekEnabled
      ? dataFiltered
      : dataFiltered.filter((p) => p !== Product.TRK);

    setProductsOptions(dataFiltered);
  }, [selectedOrg]);

  return (
    <div className={className}>
      <div className="text-with-info">
        <RequiredFieldsText />
      </div>
      {orgSelectionEnabled && (
        <div className="organization">
          <div className="section-title">
            <RequiredItem text="Organization" />
          </div>

          <Controller
            control={control}
            name="organization"
            rules={{ required: true }}
            render={({ field: { value } }) => {
              return (
                <OrganizationDropdownList
                  className="org-dropdown"
                  onOrgSelected={(org) => {
                    onOrgSelected(org);
                  }}
                  selectedOrg={value}
                />
              );
            }}
          />
        </div>
      )}
      <div className="classroom-section">
        {(!orgSelectionEnabled || selectedOrg) && (
          <div className="classroom-product">
            <div className="section-title">
              <RequiredItem text="Product" />
            </div>
            <Controller
              control={control}
              name="product"
              rules={{ required: true }}
              render={({ field: { value } }) => {
                return (
                  <ProductDropDownList
                    products={productOptions}
                    className="org-dropdown"
                    onProductSelected={(product) => {
                      onProductSelected(product);
                    }}
                    defaultProduct={value}
                    cePlusTrk
                  />
                );
              }}
            />
          </div>
        )}
      </div>
      <div className="info-section">
        {(!orgSelectionEnabled || selectedOrg) &&
          selectedProduct &&
          stateInstructorData &&
          stateTraineeData && (
            <div className="user-details">
              <div className="section-title">
                <RequiredItem text="Classroom Details" />
              </div>
              <ClassroomDetailsEditor
                instructorData={stateInstructorData}
                onCreateInstructor={onCreateInstructor}
                traineeData={stateTraineeData}
                onCreateTrainee={onCreateTrainee}
                instructorCreationEnabled={instructorCreationEnabled}
                onAddTrainees={onAddTrainees}
                bulkUploadEnabled={bulkUploadEnabled}
              />
            </div>
          )}
      </div>
    </div>
  );
}
