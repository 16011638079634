import React, { useState, useMemo, useCallback } from "react";
import { useStoreState } from "easy-peasy";
import clsx from "clsx";
import {
  DataTable,
  Column,
  ColumnOptions,
  ColumnType,
  SortDirection,
} from "@transfr-inc/dashboard-components/data-table";
import {
  BarOptionDefinition,
  Loader,
  NotificationStack,
  NotificationType,
  MenuOptionSpec,
} from "@transfr-inc/dashboard-components";

import {
  DataTableColumnDefinition,
  getUserDetailPathConfig,
} from "../../../users/list/data-table-column-definition";
import { BulkRemoveUserModal } from "../../../../components/common/bulk-remove-user-modal";
import { getUserLabelsByProduct } from "../utils/labels";
import { getColumnsDefinitionByProduct } from "../utils/user-table.columns";
import { Product } from "../../../../models";
import container from "../../../../container";
import { ChangePasswordModal } from "../../../../components/modals";
import { UpdatePasswordSuccess } from "./menu-builder/utils/notification.constants";

export function ClassroomUsersMasteryTable({
  data,
  responsiveStyles,
  getUsers,
  enableEdit,
  classroom,
  showSuccessNotification,
}) {
  const { features } = useStoreState((store) => store.account);
  const { deleteableUsers, editableUsers } = features;
  const classroomProduct = classroom?.products[0];
  const isTrek = classroom?.products?.includes(Product.TRK);
  const userLabels = getUserLabelsByProduct(classroomProduct);
  const columnsDefinition = getColumnsDefinitionByProduct(classroomProduct);
  const { userService } = container;
  const breadcrumbLabel = "Classroom";
  const selectedColumn = enableEdit
    ? [new Column("select", undefined, ColumnType.SELECTION)]
    : [];
  const { classroomId, clientName, name: classroomName } = classroom;

  const columns = [
    ...selectedColumn,
    DataTableColumnDefinition.AVATAR,
    DataTableColumnDefinition.LAST_NAME(
      getUserDetailPathConfig({ breadcrumbLabel, classroomId })
    ),
    DataTableColumnDefinition.FIRST_NAME(
      getUserDetailPathConfig({ breadcrumbLabel, classroomId })
    ),
    new Column(
      "username",
      "Username",
      ColumnType.BASIC,
      new ColumnOptions({ sortingEnabled: true, overflowEnabled: true })
    ),
    DataTableColumnDefinition.EMAIL,
    ...columnsDefinition,
  ];

  const [showDelete, setShowDelete] = useState(false);
  const [selectedTraineeList, setSelectedTraineeList] = useState([]);
  const [notifications, setNotifications] = useState();
  const [loading, setLoading] = useState();

  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const errorIcon = ["fa-solid", "triangle-exclamation"];
  const successIcon = ["fa-solid", "circle-check"];

  const onCloseNotification = (index) => {
    setNotifications(notifications.filter((n, i) => i !== index));
  };

  const showNotification = (type, message, icon) => {
    const notification = { type, message, icon };
    setNotifications((n) => {
      const notificationsUpdated = n ?? [];
      return [...notificationsUpdated, notification];
    });
  };

  const onDelete = (traineesToRemove) => {
    setSelectedTraineeList(traineesToRemove);
    setShowDelete(true);
  };

  const onUpdatePassword = (traineesToUpdate) => {
    setSelectedTraineeList(traineesToUpdate);
    setOpenPasswordModal(true);
  };

  const onPasswordUpdatedHandler = (response) => {
    setOpenPasswordModal();
    onRowRemoved();
    showSuccessNotification(UpdatePasswordSuccess(response));
  };

  const onResendInvite = async (traineesToResend) => {
    const userIds = traineesToResend.map((user) => user.userId);
    await inviteRequest(userIds);
  };

  const getTraineeName = useCallback(
    (userId) => {
      const user = data.find((u) => u.id === +userId);
      const { firstName, lastName } = user ?? {};
      return { firstName, lastName };
    },
    [data]
  );

  const inviteRequest = async (selectedTrainees) => {
    setLoading(true);
    try {
      const inviteResponse = await userService.sendTrekInvites(
        selectedTrainees,
        classroomId
      );
      const failures = inviteResponse?.failures?.length;
      const successes = inviteResponse?.successes?.length;

      if (failures === 1) {
        const { firstName, lastName } = getTraineeName(
          inviteResponse.failures[0]
        );

        showNotification(
          NotificationType.error,
          `${firstName} ${lastName} is missing an email and we could not send an invite.`,
          errorIcon
        );
      } else if (failures > 1) {
        showNotification(
          NotificationType.error,
          `${failures} invites failed to send due to missing emails.`,
          errorIcon
        );
      }

      if (successes === 1) {
        const { firstName, lastName } = getTraineeName(
          inviteResponse.successes[0]
        );

        showNotification(
          NotificationType.success,
          `${firstName} ${lastName} has been invited to ${clientName}`,
          successIcon
        );
      } else if (successes > 1) {
        showNotification(
          NotificationType.success,
          `${successes} users have been invited to ${clientName}.`,
          successIcon
        );
      }
    } catch {
      showNotification(
        NotificationType.error,
        `An error has occured.`,
        errorIcon
      );
    } finally {
      setLoading();
      setTimeout(() => {
        setNotifications();
      }, 10000);
    }
  };

  const bottomBarOptions = useMemo(() => {
    const options = [
      new BarOptionDefinition(onUpdatePassword, {
        text: "Change Password",
        icon: ["fa-light", "fa-key"],
        destructive: false,
      }),
      new BarOptionDefinition(onDelete, {
        text: "Remove",
        icon: ["fa-light", "fa-xmark"],
        destructive: true,
      }),
    ];

    if (isTrek) {
      options.unshift(
        new BarOptionDefinition(onResendInvite, {
          text: "Send Invitation",
          icon: ["fa-light", "fa-paper-plane"],
        })
      );
    }
    return options;
  }, [isTrek]);

  const actionMenuOptions = {
    delete: new MenuOptionSpec(
      "Remove",
      ["fa-light", "fa-user-times"],
      (_id, user) => onDelete([user]),
      "delete"
    ),
    changePassword: new MenuOptionSpec(
      "Change Password",
      ["fa-light", "fa-key"],
      (_id, user) => onUpdatePassword([user]),
      "change_password"
    ),
    resendInvite: new MenuOptionSpec(
      "Resend Invite",
      ["fa-light", "fa-paper-plane"],
      (_id, user) => onResendInvite([user]),
      "resend_invite"
    ),
  };

  const onRowRemoved = () => {
    getUsers();
    setSelectedTraineeList([]);
  };

  return (
    <>
      {loading && <Loader overlay></Loader>}
      <DataTable
        className={clsx(
          "classroom-trainees-mastery-table",
          enableEdit && "selection",
          Product.TS == classroomProduct && "ts",
          responsiveStyles
        )}
        bottomBarOptions={bottomBarOptions}
        columns={columns}
        data={data}
        keyFields={["userId"]}
        responsiveStyles={responsiveStyles}
        tableContentName={userLabels}
        defaultSortPropName={"isAnonymous"}
        defaultSortDirection={SortDirection.ASCENDING}
        actionMenuOptions={[
          deleteableUsers.canDeleteSTUDENTUser && actionMenuOptions.delete,
          editableUsers.canEditSTUDENTUser && actionMenuOptions.changePassword,
          editableUsers.canEditSTUDENTUser &&
            isTrek &&
            actionMenuOptions.resendInvite,
        ]}
      ></DataTable>
      <BulkRemoveUserModal
        onCancel={() => setShowDelete()}
        show={showDelete}
        deleteList={selectedTraineeList}
        onRowRemoved={onRowRemoved}
        classroomName={classroomName}
        classroomId={classroomId}
        userLabel={userLabels}
      />
      <ChangePasswordModal
        open={openPasswordModal}
        userIds={selectedTraineeList.map((u) => u.userId)}
        onCancel={() => setOpenPasswordModal()}
        onPasswordChanged={onPasswordUpdatedHandler}
      ></ChangePasswordModal>
      <div className="notification-container">
        {notifications?.length > 0 && (
          <NotificationStack
            notifications={notifications}
            onClose={onCloseNotification}
            closable
          ></NotificationStack>
        )}
      </div>
    </>
  );
}
