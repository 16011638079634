import container from "../container";
import { Role } from "../models/role";

export function getClassroomsStats(userId, role, orgCode) {
  const { classroomService } = container;

  return role === Role.INSTRUCTOR.id
    ? classroomService.getClassroomsStatsForInstructor(userId)
    : classroomService.getClassroomsStatsForOrg(orgCode);
}

export function getClassrooms(userId, role, orgCode) {
  const { classroomService } = container;

  const getClassroomRequest = () => {
    if (role === Role.INSTRUCTOR.id) {
      return classroomService.getClassroomsStatsForInstructor(userId);
    } else if (role === Role.ORG_ADMIN.id) {
      return classroomService.getClassroomsStatsForOrg(orgCode);
    } else {
      return classroomService.getMultiOrgClassroomsStats(orgCode);
    }
  };

  return getClassroomRequest();
}
