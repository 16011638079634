const ClusterIcon = [
  {
    code: "NURSE",
    icon: "/images/clusters/nurse.png",
  },
  {
    code: "AGRIC",
    icon: "/images/clusters/agriculture_food_natural_resources.png",
  },
  {
    code: "ARCHI",
    icon: "/images/clusters/architecture_construction.png",
  },
  {
    code: "ARTS",
    icon: "/images/clusters/arts_and_communications.png",
  },
  {
    code: "BUSIN",
    icon: "/images/clusters/business_admin.png",
  },
  {
    code: "EDUCA",
    icon: "/images/clusters/education_and_training.png",
  },
  {
    code: "FINAN",
    icon: "/images/clusters/finance.png",
  },
  {
    code: "GOVER",
    icon: "/images/clusters/government_admin.png",
  },
  {
    code: "HEALT",
    icon: "/images/clusters/health_science.png",
  },
  {
    code: "HOSPI",
    icon: "/images/clusters/hospitality_tourism.png",
  },
  {
    code: "HUMAN",
    icon: "/images/clusters/human_services.png",
  },
  {
    code: "INFOR",
    icon: "/images/clusters/information_technology.png",
  },
  {
    code: "LAW",
    icon: "/images/clusters/law_safety_corrections.png",
  },
  {
    code: "MANUF",
    icon: "/images/clusters/manufacturing.png",
  },
  {
    code: "MARKE",
    icon: "/images/clusters/marketing.png",
  },
  {
    code: "SCIEN",
    icon: "/images/clusters/science_technology_mathematics.png",
  },
  {
    code: "TRANS",
    icon: "/images/clusters/transportation_logistic.png",
  },
  {
    code: "CONST",
    icon: "/images/clusters/architecture_construction.png",
  },
];

const ClusterIconMap = new Map(ClusterIcon.map((c) => [c.code, c.icon]));

export const getClusterIcon = (careerCode) => ClusterIconMap.get(careerCode);
