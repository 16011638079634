import { action } from "easy-peasy";

export default ({ dateSettingsCache }) => {
  const dateSettings = dateSettingsCache.get();

  const persistData = (data) => {
    const cache = dateSettingsCache.get();
    dateSettingsCache.set({ ...cache, ...data });
  };

  return {
    lastDateRange: dateSettings?.lastDateRange,
    lastDateFilter: dateSettings?.lastDateFilter,
    setLastDateRange: action((state, value) => {
      if (state.lastDateRange !== value) {
        state.lastDateRange = value;
        state.lastDateFilter = undefined;
        const data = { lastDateRange: value, lastDateFilter: undefined };
        persistData(data);
      }
    }),
    setLastDateFilter: action((state, value) => {
      if (state.lastDateFilter !== value) {
        state.lastDateFilter = value;
        state.lastDateRange = undefined;
        const data = { lastDateFilter: value, lastDateRange: undefined };
        persistData(data);
      }
    }),
  };
};
