import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./instructor.email-box.scss";

export function InstructorEmailBox({ instructor }) {
  const { firstName, lastName, email } = instructor;
  const mailToInstructor = `mailto:${email}`;

  const capitalize = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  return (
    <a className="instructor-email-box-link" href={mailToInstructor}>
      <div className="instructor-email-box">
        <FontAwesomeIcon className="email-icon" icon="fa-regular fa-envelope" />
        <div>{`${capitalize(lastName)}, ${capitalize(firstName)}`}</div>
      </div>
    </a>
  );
}
