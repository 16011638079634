import React from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import "./remove-integration.modal.scss";

export default ({ open, integration, onCancel, onRemoveIntegration }) => {
  const onConfirm = () => {
    onRemoveIntegration(integration);
  };

  return (
    <Modal
      open={open}
      backdropClassName="sync-integration-modal"
      onClose={onCancel}
    >
      <ModalHeader
        icon={["fa-regular", "cross"]}
        title="Remove Integration"
      ></ModalHeader>
      <ModalBody>
        <div>
          Are you sure that you would like to remove your integration with{" "}
          <span className="integration-name">{integration.providerName}</span>?
          Any roster data you have pulled into Transfr will remain in tact.
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel}>Cancel</Button>
        <Button destructive onClick={onConfirm}>
          Yes, Remove
        </Button>
      </ModalFooter>
    </Modal>
  );
};
