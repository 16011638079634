import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// stupid react router guys
export function useQueryString() {
  const { search } = useLocation();
  return (
    search
      .slice(1)
      .split("&")
      // eslint-disable-next-line unicorn/no-array-reduce
      .reduce((acc, kvp) => {
        const [key, value] = kvp.split("=");
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {})
  );
}

export function useTimer() {
  const [timeLeft, setTimeLeft] = useState();
  const [isDone, setIsDone] = useState(false);
  const [timeFormatted, setTimeFormatted] = useState();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((t) => (t > 0 ? t - 1 : t));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setIsDone(timeLeft <= 0);
    setTimeFormatted(formatTime(timeLeft ?? 0));
  }, [timeLeft]);

  const setTimer = (time) => setTimeLeft(Math.trunc(time));

  return {
    setTimer,
    timeLeft,
    isDone,
    timeFormatted,
  };
}

export function useHistoryState() {
  const history = useHistory();
  const { location } = history;

  return {
    currentState: location.state,
    cleanState: () => history.replace({ state: {} }),
    addStateProps: (newProps) =>
      history.replace({ state: { ...location.state, ...newProps } }),
    removeStateProps: (propsToRemove) => {
      const oldState = location.state;
      delete oldState[propsToRemove];
      history.replace({ state: { ...oldState } });
    },
  };
}

const formatTime = (seconds) => {
  const sec = seconds % 60;
  const minutes = Number.parseInt(seconds / 60);
  return `${minutes}:${sec > 9 ? "" : "0"}${sec}`.trim();
};

export const useNotificationStack = (initialValue = []) => {
  const idCounter = useRef(0);
  const [notifications, setNotifications] = useState(initialValue);

  const closeNotification = ({ index, id }) => {
    setNotifications((value) =>
      value.filter((n, i) => (id ? n.id !== id : i !== index))
    );
  };

  const addNotification = ({ type, message, icon }) => {
    const id = `notification_${type}_${idCounter.current}`;

    setNotifications((n) => {
      const notificationsUpdated = n ?? [];
      return [...notificationsUpdated, { type, message, icon, id }];
    });

    idCounter.current += 1;
    return id;
  };

  const closeNotificationTimeout = (notificationId, timeout = 3000) => {
    const timeoutId = setTimeout(() => {
      closeNotification({ id: notificationId });
    }, timeout);

    return () => clearTimeout(timeoutId);
  };

  return {
    notifications,
    closeNotification,
    closeNotificationTimeout,
    addNotification,
  };
};
