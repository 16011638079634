import React, { useState } from "react";
import {
  Input,
  Button,
  ValidationItem,
} from "@transfr-inc/dashboard-components/forms";

import { Slider, Slide, useSlider } from "@transfr-inc/dashboard-components";

import { StudentTerms } from "./student-terms-and-conditions";

export const StudentCreateAccountForm = ({
  errors,
  dirtyFields,
  register,
  getValues,
  email,
  blocking,
  isValid,
  handleChecked,
  onSetLayoutTitle,
}) => {
  const totalSlides = 2;
  const slider = useSlider({ totalSlides });
  const [slideIndex, setSlideIndex] = useState(0);

  const next = () => {
    slider.nextSlide();
    setSlideIndex(slider.currentIndex + 1);
    onSetLayoutTitle(["Terms &", "Conditions"]);
  };

  return (
    <Slider totalSlides={totalSlides} currentSlideIndex={slideIndex}>
      <Slide>
        <div className="account-form">
          <div className="instructions">
            Please enter a password for your new Transfr account.
          </div>
          <div className="inputs">
            <Input
              label="Email Address"
              name="email-address"
              type="email"
              value={email}
              disabled="disabled"
              required
            />
            <Input
              label="Password"
              type="password"
              required
              errors={errors.password}
              {...register("password", {
                validate: {
                  count: (v) =>
                    v.length >= 4 || "Password must be at least 4 characters",
                  hasLetter: (v) =>
                    /[A-Za-z]/.test(v) || "Password must include a letter",
                  whiteSpace: (v) =>
                    /^\S*$/.test(v) || "White spaces are not allowed",
                  hasOnlyAllowedSpecials: (v) =>
                    !/[^\w!,.?@-]+/.test(v) ||
                    "Password cannot include special characters other than @ ! ? , . - _",
                },
              })}
            />

            <Input
              label="Confirm Password"
              type="password"
              required
              errors={errors.passwordMatch}
              {...register("passwordMatch", {
                validate: {
                  matches: (v) => {
                    const password = getValues("password");
                    return password === v || "match";
                  },
                },
              })}
            />
          </div>
          <div className="requirements">
            <ul>
              <li>
                <div className="title">Password Requirements</div>
              </li>
              <li>
                <ValidationItem
                  label={"Minimum of 4 characters"}
                  valid={!errors.password?.types.count}
                  enabled={dirtyFields.password}
                />
              </li>
              <li>
                <ValidationItem
                  label={"At least one letter"}
                  valid={!errors.password?.types.hasLetter}
                  enabled={dirtyFields.password}
                />
              </li>
              <li>
                <ValidationItem
                  label={"No whitespace allowed"}
                  valid={!errors.newPassword?.types.whiteSpace}
                  enabled={dirtyFields.password}
                />
              </li>
              <li>
                <ValidationItem
                  label={"No special characters other than @ ! ? , . - _"}
                  valid={!errors.password?.types.hasOnlyAllowedSpecials}
                  enabled={dirtyFields.password}
                />
              </li>
              <li>
                <ValidationItem
                  label={"Confirm your new password"}
                  valid={!errors.passwordMatch?.types?.matches}
                  enabled={dirtyFields.password && dirtyFields.passwordMatch}
                />
              </li>
            </ul>
          </div>
          <div>
            <Button
              className="primary block"
              label="Next"
              disabled={!isValid || blocking}
              onClick={next}
            />
          </div>
        </div>
      </Slide>
      <Slide>
        <StudentTerms
          blocking={blocking}
          handleChecked={handleChecked}
        ></StudentTerms>
      </Slide>
    </Slider>
  );
};
