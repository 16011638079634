import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import "./delete-classroom.modal.scss";

export function ConfirmCancelModal({
  open,
  onBackToModal,
  onConfirmClose,
  userLabel,
}) {
  const onBackToAddTraineeModalHandler = () => {
    onBackToModal && onBackToModal();
  };

  return (
    <Modal
      modalClassName="are-you-sure-modal"
      open={open}
      onClose={onBackToAddTraineeModalHandler}
      preventClose
    >
      <ModalHeader
        className="red-icon"
        icon={["fa-light", "user-xmark"]}
        title={`${userLabel} Not Enrolled`}
        closeButton={false}
      ></ModalHeader>
      <ModalBody>
        Are you sure you would like to cancel? The {userLabel} that have been
        created so far are not enrolled in this classroom.
      </ModalBody>
      <ModalFooter>
        <Button onClick={onBackToAddTraineeModalHandler}>No, Continue</Button>
        <Button destructive onClick={onConfirmClose}>
          Yes, Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
