import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";

import "./classroom-sim-pass.header.scss";

export const ClassroomSimPassHeader = ({ generatingExport, onExportClick }) => {
  return (
    <div className="classroom-sim-pass-tab-header">
      <div className="title">
        <img src="/images/key.png" alt="sim key pass icon" />
        <div>
          <h3>Sim Pass</h3>
          <h4>
            Allow your explorers to skip login and go directly into their
            simulations with a temporary keycode.
          </h4>
          <div className="note">
            <FontAwesomeIcon icon={["fa-regular", "circle-info"]} />
            <span>
              Select explorers to generate your passes. Passes will expire after
              1 hour.
            </span>
          </div>
        </div>
      </div>
      <Button
        icon={["fa-regular", "print"]}
        onClick={onExportClick}
        disabled={generatingExport}
      >
        Export (.pdf)
      </Button>
    </div>
  );
};
