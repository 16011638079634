import React, { useState } from "react";

import { Loader } from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { CareerIcon } from "../career-icon";
import { CareersSelectedLeaderboardModal } from "../modals/careers-selected-leaderboard/careers-selected-leaderboard.modal";

import container from "../../container";

import "./top-careers-selected.scss";

const MaxCareersDisplay = 3;

export const TopCareersSelected = ({
  topCareers,
  isLoading,
  activeUsers,
  users,
  limit = MaxCareersDisplay,
  breadcrumbLabel,
}) => {
  const { trekService } = container;

  const [openModal, setOpenModal] = useState();
  const [careerSelected, setCareerSelected] = useState();

  const openLeaderboardModal = (career) => {
    setCareerSelected(career);
    setOpenModal(true);
  };

  if (isLoading) {
    return <Loader></Loader>;
  }

  return (
    topCareers?.length > 0 && (
      <>
        <div className="trek-insights-header">
          <h2>Top Careers Selected</h2>
        </div>
        <div className="top-careers-selected-container">
          {topCareers?.slice(0, limit).map((career, index) => (
            <CareerIcon
              key={`${career.code}-${index}`}
              name={career.title}
              badge={career.totalSelected}
              onClick={() => openLeaderboardModal(career)}
              iconUrl={trekService.getCareerImageUrl(career.transfrId)}
            />
          ))}
          {topCareers?.length > limit && (
            <div className="view-all-button">
              <Button
                onClick={() => openLeaderboardModal()}
                title={"View all high rated careers"}
              >
                +{topCareers.length - limit}
              </Button>
              <h3>View All</h3>
            </div>
          )}
        </div>
        {topCareers && (
          <CareersSelectedLeaderboardModal
            open={openModal}
            onCancel={() => setOpenModal()}
            career={careerSelected}
            topCareers={topCareers}
            activeUsers={activeUsers}
            breadcrumbLabel={breadcrumbLabel}
            users={users}
          />
        )}
      </>
    )
  );
};
