import React, { useState, useEffect } from "react";
import clsx from "clsx";

import { Filter } from "@transfr-inc/dashboard-components/forms";
import { useStoreState } from "easy-peasy";

import "./organization-filter.scss";

export function OrganizationFilter({
  onOrganizationSelected,
  placeholder = "Filter by Organization",
  className,
  product,
}) {
  const defaultOption = { name: "All Organizations" };
  const [organizations, setOrganizations] = useState([defaultOption]);

  const { organizations: storeOrganizations } = useStoreState(
    (store) => store.organization
  );

  useEffect(() => {
    if (product && storeOrganizations) {
      const filteredResponse = storeOrganizations.filter((org) =>
        org.products.includes(product)
      );
      setOrganizations([defaultOption, ...filteredResponse]);
    } else {
      storeOrganizations &&
        setOrganizations([defaultOption, ...storeOrganizations]);
    }
  }, [storeOrganizations]);

  return (
    <Filter
      className={clsx("organization-filter small", className)}
      placeholder={placeholder}
      data={organizations}
      clearValue={organizations[0]}
      onFilterSelected={onOrganizationSelected}
      labelKey={"name"}
    ></Filter>
  );
}
