import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  getProductBanner,
  getProductDescription,
  getProductDisplayName,
  getProductIcon,
} from "../../../models";

import "./dashboard.banner.scss";

export function DashboardBanner({ product, name, description, icon, image }) {
  return (
    <div className="dashboard-banner">
      <div className="text">
        <h2>
          <FontAwesomeIcon icon={icon ?? getProductIcon(product)} />{" "}
          {name ?? getProductDisplayName(product)}
        </h2>
        <p>{description ?? getProductDescription(product)}</p>
      </div>
      <div className="image">
        <img src={image ?? getProductBanner(product)} alt="banner hero" />
      </div>
    </div>
  );
}
