import React, { useEffect, useRef, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
} from "@transfr-inc/dashboard-components";
import {
  RequestProgressEvent,
  RequestProgressStatus,
} from "../../../lib/http-client";

import "./request-progress.modal.scss";

export default () => {
  const [openModal, setOpenModal] = useState();
  const requestsSetRef = useRef(new Set());

  const timeoutListener = ({ detail }) => {
    const { url, status } = detail;
    const { current: requestsSet } = requestsSetRef;

    if (status === RequestProgressStatus.Start) {
      requestsSet.add(url);
      setOpenModal(true);
      console.error("ERROR: Request progress timeout! url:", url);
    } else {
      requestsSet.delete(url);
    }

    if (requestsSet.size === 0) {
      setOpenModal();
    }
  };

  useEffect(() => {
    document.addEventListener(RequestProgressEvent, timeoutListener);
    return () =>
      document.removeEventListener(RequestProgressEvent, timeoutListener);
  }, []);

  return (
    <Modal
      open={openModal}
      modalClassName="request-progress-modal"
      onClose={() => setOpenModal()}
    >
      <ModalHeader
        title="We are still working on it..."
        icon="fa-regular fa-clock"
      ></ModalHeader>
      <ModalBody>
        We are still processing your request. Thanks for your patience.
      </ModalBody>
    </Modal>
  );
};
