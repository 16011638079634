import { getTTL } from "../views/users/list/common-utils";

const getMostRecentCodeByKey = (values) => {
  return Object.entries(values).reduce((acc, [key, codes]) => {
    const mostRecent = codes.toSorted(
      (a, b) => new Date(b.expiry).getTime() - new Date(a.expiry).getTime()
    )[0];

    const code = {
      code: mostRecent.code,
      ttl: getTTL(mostRecent.expiry),
      expiry: mostRecent.expiry,
      simulationId: mostRecent.simulationId,
    };

    if (code.ttl > 0) {
      acc[key] = code;
    }
    return acc;
  }, {});
};

export const filterClassroomLoginCodesByUserId = (codes, userId) => {
  const codesByUserId = codes.reduce((acc, code) => {
    if (!code.simulationId) {
      const userCodes = acc[code.userId] ?? [];
      userCodes.push(code);
      acc[code.userId] = userCodes;
    }

    return acc;
  }, {});

  const mostRecentCodesByUserId = getMostRecentCodeByKey(codesByUserId);

  if (userId) {
    return mostRecentCodesByUserId[userId];
  }
  return mostRecentCodesByUserId;
};

export const filterUserLoginCodesByLEId = (
  codes,
  userId,
  learningExperienceId
) => {
  const codesByUserId = codes.reduce((acc, code) => {
    if (code.simulationId) {
      const userCodes = acc[code.userId] ?? {};
      const simulationCodes = userCodes[code.simulationId] ?? [];
      simulationCodes.push(code);
      userCodes[code.simulationId] = simulationCodes;
      acc[code.userId] = userCodes;
    }

    return acc;
  }, {});

  const mostRecentCodesByLEId = Object.entries(codesByUserId).reduce(
    (acc, [userId, userCodes]) => {
      acc[userId] = getMostRecentCodeByKey(userCodes);
      return acc;
    },
    {}
  );

  if (learningExperienceId && userId) {
    return mostRecentCodesByLEId[userId][learningExperienceId];
  } else if (userId) {
    return mostRecentCodesByLEId[userId];
  }
  return mostRecentCodesByLEId;
};
