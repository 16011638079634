import React from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  DataTable,
  Column,
  ColumnOptions,
  ColumnType,
  BasicCell,
  SortDirection,
  TimeDataCell,
} from "@transfr-inc/dashboard-components/data-table";
import { Rating } from "@transfr-inc/dashboard-components";
import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";

import {
  DataTableColumnDefinition,
  getUserDetailPathConfig,
} from "../../../users/list/data-table-column-definition";

import "./classroom.ce-mastery.table.scss";
import { ColumnHeaderInformationTooltip } from "../../../../components/custom/content/column-header-information-tooltip";

export function ClassroomCeMasteryTable({ data, responsiveStyles }) {
  const { classroomId } = useLocation().state;
  const breadcrumbLabel = "Classroom";
  const columns = [
    DataTableColumnDefinition.AVATAR,
    DataTableColumnDefinition.LAST_NAME(
      getUserDetailPathConfig({ breadcrumbLabel, classroomId })
    ),
    DataTableColumnDefinition.FIRST_NAME(
      getUserDetailPathConfig({ breadcrumbLabel, classroomId })
    ),
    DataTableColumnDefinition.USERNAME,
    new Column(
      "timestamp",
      (
        <ColumnHeaderInformationTooltip
          headerTitle={"Date"}
          tooltipText={"Last attempted date"}
        ></ColumnHeaderInformationTooltip>
      ),
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => {
          const formattedDate = value ? formatDateDigits(value) : undefined;
          return <BasicCell value={formattedDate}></BasicCell>;
        },
      })
    ),
    new Column(
      "timeSpent",
      "Total Time",
      ColumnType.CUSTOM,
      new ColumnOptions({ sortingEnabled: true, customFunction: TimeDataCell })
    ),
    new Column(
      "attempts",
      "Attempts",
      ColumnType.COUNT,
      new ColumnOptions({ sortingEnabled: true })
    ),
    new Column(
      "rating",
      (
        <ColumnHeaderInformationTooltip
          headerTitle={"Rating"}
          tooltipText={"Last attempted rating (1-5)"}
        ></ColumnHeaderInformationTooltip>
      ),
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => <Rating value={value ?? 0} />,
      })
    ),
    new Column(
      "completed",
      (
        <ColumnHeaderInformationTooltip
          headerTitle={"Complete"}
          tooltipText={
            "Careers where trainees have reached the end. May or may not include a rating."
          }
          position={"top-end"}
        ></ColumnHeaderInformationTooltip>
      ),
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value, row) => {
          const icon =
            value || row.rating > 0
              ? ["fa-solid", "fa-circle-check"]
              : ["fa-regular", "fa-circle"];
          return (
            <div className="completed-cell">
              <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
            </div>
          );
        },
      })
    ),
  ];

  return (
    <DataTable
      className={clsx("classroom-ce-mastery-table", responsiveStyles)}
      columns={columns}
      data={data}
      keyFields={["userId"]}
      defaultSortPropName={"latestTimeStamp"}
      defaultSortDirection={SortDirection.DESCENDING}
      responsiveStyles={responsiveStyles}
      rowHeightConfig={{ large: 54, medium: 160, small: 280 }}
    ></DataTable>
  );
}
