import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { useTimer } from "../../lib/hooks";
import "./sim-pass.cell.scss";

export const SimPassCell = ({
  code,
  disabled,
  onCreateCode,
  onClickCode,
  simulation,
  classname,
}) => {
  const [loading, setLoading] = useState();
  const timer = useTimer();

  const onCreateCodeHandler = async () => {
    setLoading(true);
    await onCreateCode?.(simulation);
    setLoading();
  };

  useEffect(() => {
    timer.setTimer(simulation?.ttl * 60 ?? 0);
  }, [code]);

  return (
    <div className={clsx("sim-pass-table-cell", classname)}>
      {code ? (
        <Button
          onClick={() => onClickCode(simulation, timer.timeLeft)}
          className="sim-pass-code"
          disabled={disabled}
        >
          {code}
        </Button>
      ) : (
        <Button
          onClick={onCreateCodeHandler}
          className="plus-button"
          disabled={loading}
          loader={loading}
        >
          <FontAwesomeIcon icon="fa-regular fa-plus"></FontAwesomeIcon>
        </Button>
      )}
    </div>
  );
};
