import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";

import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { useQueryString } from "../../../lib/hooks";
import { RootPath } from "../../../lib/routes";

export default function PrivateRoute({ children, ...rest }) {
  const queryParams = useQueryString();
  const isRedirectPath = !!useRouteMatch(`${RootPath}/redirect`);
  const isLoggedIn = useStoreState((store) => store.account.isLoggedIn);
  const { setRedirect } = useStoreActions((store) => store.app);

  useEffect(() => {
    if (isRedirectPath) {
      setRedirect({
        action: queryParams.act,
        token: queryParams.v,
      });
    }
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
