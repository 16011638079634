import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useStoreActions } from "easy-peasy";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useTimer } from "../../lib/hooks";
import { getRoleIconClassName, getRoleName } from "../../models/role";

import "./client-services-header.scss";

export function ClientServicesHeader({ role, currentUser }) {
  const { logout } = useStoreActions((state) => state.account);
  const history = useHistory();
  const timer = useTimer();

  useEffect(() => {
    const currentDateTime = Date.now();
    const timeTilExpire =
      currentUser.exp - Number.parseInt(currentDateTime / 1000);
    timer.setTimer(timeTilExpire);
  }, [currentUser.exp]);

  useEffect(() => {
    if (timer.isDone) {
      logout({ logOutRequested: true });
      history.replace("/login");
    }
  }, [timer.isDone]);

  return (
    <div className={clsx("client-services-header", getRoleIconClassName(role))}>
      <div className="info-aligned-left">
        <h2>{currentUser.organizationName}</h2>
        <span>
          Logged in as {currentUser.firstName} {currentUser.lastName} (
          {getRoleName(role)}) <u>{currentUser.email}</u>
        </span>
      </div>
      <div className="info-aligned-right">
        <FontAwesomeIcon className="mask-icon" icon="fa-regular fa-mask" />
        <div className="warning">
          Any changes made will be permanent. Session expires in
          <span className="timer">{timer.timeFormatted}</span>
          minutes.
        </div>
      </div>
    </div>
  );
}
