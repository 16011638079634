import clsx from "clsx";
import React, { useState } from "react";

import { Loader, SlashCircleIcon } from "@transfr-inc/dashboard-components";

//Recharts
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { Color } from "@transfr-inc/dashboard-components/utils";
import { getNearestMaxValue } from "../../../lib/chart-utils";

import "./dashboard.chart.scss";

export function DashboardChart({ className, dailyStats, filterSelected }) {
  const [tickCount, setTickCount] = useState(6);
  const { isLoading, response: data } = dailyStats || {};

  function showNoDataMessage() {
    return (
      !isLoading &&
      !(data?.length > 0 && !!data?.find((d) => d[filterSelected.key] > 0))
    );
  }

  const getMaxValue = (dataMax) => {
    const { maxValue, numberTicks } = getNearestMaxValue(dataMax, 5);
    setTickCount(numberTicks);
    return maxValue;
  };

  const noDataMessage = (
    <div className="no-data-container">
      <div className="message">
        <SlashCircleIcon className="icon"></SlashCircleIcon>
        <span>No data to display. Please try adjusting your filters</span>
      </div>
    </div>
  );

  return (
    <div className={clsx("dashboard-chart", className)}>
      <div
        className="chart-container"
        aria-label="This chart illustrates key data points for this organization. Please press the Export .csv button to explore more detailed information. "
      >
        {showNoDataMessage() && noDataMessage}
        {isLoading && <Loader overlay />}
        {
          <ResponsiveContainer width="100%" height={280}>
            <BarChart
              data={data}
              margin={{
                top: 20,
                right: 10,
                left: 10,
                bottom: 5,
              }}
              maxBarSize={5}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="date"
                axisLine={false}
                tickMargin={12}
                tickSize={0}
                tick={!isLoading && !!data}
                fontFamily={"Manrope"}
              />
              <YAxis
                allowDecimals={false}
                axisLine={false}
                tickMargin={10}
                tickSize={0}
                fontFamily={"Manrope"}
                fill="#898A8D"
                fontWeight={400}
                width={30}
                tickCount={tickCount}
                domain={[0, getMaxValue]}
              />
              {data?.length > 0 && <Tooltip />}
              <Bar
                dataKey={filterSelected?.key}
                name={filterSelected?.label}
                stackId="a"
                fill={Color.chartBlue}
              />
            </BarChart>
          </ResponsiveContainer>
        }
      </div>
    </div>
  );
}
