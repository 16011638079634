import React from "react";
import clsx from "clsx";

import { getProductName } from "@transfr-inc/dashboard-components/utils";

import "./product.scss";

export function Product({ productId, size = "small", className }) {
  const imagePath = "/images/products/";
  const productName = getProductName(productId);
  const productIconName = `${productName}.${size}.svg`.toLowerCase();

  return (
    <img
      className={clsx("product", size, className)}
      src={`${imagePath}${productIconName}`}
    />
  );
}
