// Calculates correct sim status
export const mapHeadsetStatus = (info) => {
  if (!info.isActive) {
    return "disconnected";
  } else if (info.simCompletionPercentage === 100) {
    return "complete";
  } else if (info.isStuck) {
    return "stuck";
  } else {
    return "inProgress";
  }
};

// Calculates correct user status
export const mapUserStatus = (info) => {
  if (info.isActive && !info.isIdle) {
    return "active";
  } else if (!info.isActive) {
    return "inactive";
  } else if (info.isIdle) {
    return "idle";
  }
};

export const sortObjectAlphabetically = (list, userArray) => {
  const sorted = Object.keys(list).sort((a, b) => {
    const userA = userArray.find((user) => user.userId === a);
    const userB = userArray.find((user) => user.userId === b);

    const lastNameA = userA?.lastName || "";
    const lastNameB = userB?.lastName || "";

    return lastNameA.localeCompare(lastNameB);
  });

  return sorted;
};

export const checkObjectIsPopulated = (object) => {
  return Object.values(object).some((value) => value != undefined);
};
