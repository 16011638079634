import React from "react";
import clsx from "clsx";

import { Rating } from "@transfr-inc/dashboard-components";
import { getClusterIcon } from "./career.icon.const";

import "./career.icon.scss";

const defaultCode = "AGRIC";

export const CareerIcon = ({
  name,
  iconUrl,
  clusterCode,
  onClick,
  rating,
  badge,
  className,
}) => {
  const icon =
    iconUrl ?? getClusterIcon(clusterCode) ?? getClusterIcon(defaultCode);

  return (
    <button
      className={clsx("career-icon-container", className)}
      onClick={onClick}
      disabled={!onClick}
    >
      <img src={icon} alt={`${name} career icon`} />
      {badge && <span className="badge">{badge}</span>}
      {name && (
        <div className="career-info-container">
          <h3>{name}</h3>
          {rating && <Rating value={rating} />}
        </div>
      )}
    </button>
  );
};
