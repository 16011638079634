import React from "react";
import { useState, useEffect } from "react";
import { useStoreState } from "easy-peasy";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";

import { useApiRequest } from "../../../lib/http-client";
import { getClassroomsStats } from "../../../services/classroom.utils";

export function ClassroomDropdownList({
  className,
  onClassroomSelected,
  selectedClassroom,
  orgCode,
  placeholder = "Select a classroom...",
  icon,
}) {
  const [classrooms, setClassrooms] = useState();
  const { currentUser, role } = useStoreState((store) => store.account);
  const { userId, organizationCode } = currentUser;

  const getOrg = () => {
    return orgCode ?? organizationCode;
  };

  const {
    loading,
    response,
    sendRequest: getClassrooms,
  } = useApiRequest(() => getClassroomsStats(userId, role, getOrg()));

  useEffect(() => {
    getClassrooms();
  }, [orgCode]);

  useEffect(() => {
    setClassrooms(response);
  }, [response]);

  return (
    <div className={className}>
      <div className="section-title"> Classroom </div>
      <Dropdown
        selected={selectedClassroom}
        onOptionSelected={onClassroomSelected}
        disabled={loading}
      >
        <DropdownButton placeholder={placeholder} icon={icon} required>
          {selectedClassroom?.name}
        </DropdownButton>
        <DropdownItems>
          {classrooms?.map((classroom, i) => (
            <DropdownItem key={`${classroom.id}-cd-${i}`} value={classroom}>
              {classroom.name}
            </DropdownItem>
          ))}
        </DropdownItems>
      </Dropdown>
    </div>
  );
}
