import React, { useState } from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./my-work-ce.status-dropdown-list.scss";
import {
  getCareerStatus,
  getCareerStatuses,
  StatusFilter,
} from "../../models/career-status";

export function StatusDropdownList({
  masteryStats,
  dataGroupedByOccupation,
  onStatusSelected,
  className,
  selectedStatus,
}) {
  const { totalExplored = 0, totalHighlyRated = 0 } = masteryStats || {};
  const allCount = dataGroupedByOccupation
    ? Object.keys(dataGroupedByOccupation).length
    : 0;
  const totalNotExplored = allCount - totalExplored;

  const careerStatuses = getCareerStatuses();

  const [dropdownStatusSelected, setDropdownStatusSelected] = useState(
    selectedStatus ? selectedStatus : StatusFilter.All
  );

  const onOptionSelected = (option) => {
    setDropdownStatusSelected(option);
    onStatusSelected(option);
  };

  const badgeCountMap = new Map();
  badgeCountMap.set(StatusFilter.All, allCount);
  badgeCountMap.set(StatusFilter.CareersExplored, totalExplored);
  badgeCountMap.set(StatusFilter.CareersNotExplored, totalNotExplored);
  badgeCountMap.set(StatusFilter.CareersHighlyRated, totalHighlyRated);

  return (
    <div className={className}>
      <Dropdown
        selected={dropdownStatusSelected}
        onOptionSelected={onOptionSelected}
      >
        <DropdownButton placeholder={"Select a status..."} required>
          {dropdownStatusSelected && (
            <div className="simulations-dropdown-selected-item">
              <div className="simulations-dropdown-selected-icon-container">
                <FontAwesomeIcon
                  className="simulations-dropdown-selected-icon"
                  icon={`fa-light ${
                    getCareerStatus(dropdownStatusSelected)?.dropdownIcon
                  }`}
                />
              </div>
              {getCareerStatus(dropdownStatusSelected)?.label}
              {careerStatuses && (
                <div className="badge">
                  {dropdownStatusSelected &&
                    badgeCountMap.get(
                      getCareerStatus(dropdownStatusSelected)?.statusFilter
                    )}
                </div>
              )}
            </div>
          )}
        </DropdownButton>
        <DropdownItems>
          {careerStatuses?.map((status, i) => {
            return (
              <DropdownItem
                key={`${status.label}-sd-${i}`}
                value={status.statusFilter}
              >
                <div className="simulations-dropdown-item">
                  <div className="simulations-dropdown-icon-container">
                    <FontAwesomeIcon
                      className="simulations-dropdown-icon"
                      icon={`fa-regular ${status.dropdownIcon}`}
                    />
                  </div>
                  {status.label}
                  <div className="badge">
                    {badgeCountMap.get(status.statusFilter)}
                  </div>
                </div>
              </DropdownItem>
            );
          })}
        </DropdownItems>
      </Dropdown>
    </div>
  );
}
