import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { useStoreState } from "easy-peasy";

import {
  Link,
  ProfileAvatar,
  RoleBadge,
} from "@transfr-inc/dashboard-components";

import { useScreenBreakpoint } from "@transfr-inc/dashboard-components/hooks";

import { Role, getRoleAvatarColor } from "../../models/role";

import { useRouteMatch } from "react-router-dom";
import { RootPath } from "../../lib/routes";
import { useApiRequest } from "../../lib/http-client";
import container from "../../container";
import { avatarMap } from "../../lib/avatar-map";

import "./navbar-item.profile.scss";

export function NavbarItemProfile({ isExpanded, className }) {
  const { url: rootPath } = useRouteMatch(RootPath);
  const { currentUser, role } = useStoreState((store) => store.account);
  const { userId } = currentUser;
  const roleFillColor = getRoleAvatarColor(role);
  const { isMobile } = useScreenBreakpoint();

  const [user, setUser] = useState();
  const [navLabel, setNavLabel] = useState();

  const { loading: loadingUser } = useApiRequest(() =>
    container.userService.getUserDetails(userId).then(setUser)
  );

  useEffect(() => {
    if (user && role != Role.STUDENT.id) {
      setNavLabel(
        `${user.firstName} ${isExpanded ? user.lastName : ""}`.trim()
      );
    } else {
      setNavLabel("Profile");
    }
  }, [user]);

  return (
    !loadingUser && (
      <Link
        underline={false}
        to={`${rootPath}/profile`}
        className={clsx(
          "profile-item",
          !isExpanded && "collapsed",
          isMobile && "mobile",
          className
        )}
      >
        <ProfileAvatar
          fillColor={user?.avatarId ? avatarMap[user.avatarId] : roleFillColor}
          className={clsx("image", className)}
        />
        <div className="description">
          <div
            className={clsx(
              "name",
              !isExpanded && "collapsed",
              role == Role.STUDENT.id && className
            )}
          >
            {navLabel}
          </div>
          <RoleBadge
            className={clsx("role", !isExpanded && "hidden")}
            small
            role={role}
            fillColor={roleFillColor}
          />
        </div>
      </Link>
    )
  );
}
