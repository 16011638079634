import React, { useState, useEffect } from "react";
import { useStoreState } from "easy-peasy";

import {
  SlashCircleIcon,
  Tile,
  Loader,
} from "@transfr-inc/dashboard-components";
import { useLiveClassroomData } from "@transfr-inc/dashboard-sockets";
import { formatTime } from "@transfr-inc/dashboard-components/utils";

import container from "../../../../container";
import { useApiRequest } from "../../../../lib/http-client";
import { ShortcutAction } from "../../../../components/shortcuts";
import "./classroom.mission-control.tab.scss";
import {
  checkObjectIsPopulated,
  mapHeadsetStatus,
  mapUserStatus,
  sortObjectAlphabetically,
} from "../utils/mission-control.utils";

export const ClassroomMissionControlTab = ({ classroom, users = [] }) => {
  const socketUrl = process.env.SOCKET_BRIDGE_URL;
  const { currentUser } = useStoreState((store) => store.account);
  const { missionControlEnabled } = useStoreState((state) => state.app);
  const { token } = currentUser;
  const [initSorting, setInitSorting] = useState();
  const [sortedMissionControlData, setSortedMissionControlData] = useState([]);
  const [loading, setLoading] = useState(false);

  const classroomId = classroom?.classroomId;
  const clientCode = classroom?.clientCode;

  const { insightsService } = container;

  const missionControlClassroom = missionControlEnabled
    ? useLiveClassroomData(socketUrl, token, clientCode, classroomId)
    : {};

  const showZero = Object.keys(missionControlClassroom).length > 0;
  const zeroState = (
    <ShortcutAction className="no-data-message" disabled>
      <SlashCircleIcon></SlashCircleIcon>
      {
        "No users to display, please wait until there are live users in the classroom"
      }
    </ShortcutAction>
  );

  // Gets mastery for star ratings
  const { response: ceResultsResponse } = useApiRequest(() =>
    insightsService.getClassroomCeResults(classroomId)
  );

  useEffect(() => {
    const isDataPopulated = checkObjectIsPopulated(missionControlClassroom);

    if (isDataPopulated) {
      setLoading(true);

      if (initSorting) {
        // Find new keys that aren't already in sortedMissionControlData
        const existingKeys = new Set(sortedMissionControlData);
        const newKeys = Object.keys(missionControlClassroom).filter(
          (key) => !existingKeys.has(key)
        );

        // Append new keys to the existing sorted data
        if (newKeys.length > 0) {
          setSortedMissionControlData((prevData) => [...prevData, ...newKeys]);
        }
        setLoading(false);
      } else {
        // Initial sorting logic
        const timeoutId = setTimeout(() => {
          const sorted = sortObjectAlphabetically(
            missionControlClassroom,
            users
          );

          setInitSorting(true);
          setSortedMissionControlData(sorted);
          setLoading(false);
        }, 5000);

        return () => clearTimeout(timeoutId);
      }
    }
  }, [users]);

  return (
    <div className="mission-control-main-container">
      {!showZero ? (
        zeroState
      ) : (
        <div className="tile-container">
          {loading && <Loader overlay></Loader>}
          {sortedMissionControlData.map((value, i) => {
            const headsetInfo = missionControlClassroom[value];
            const userInfo = users.find((user) => user.userId === value);
            const simStatus = mapHeadsetStatus(headsetInfo);
            const userStatus = mapUserStatus(headsetInfo);
            const showIdle = headsetInfo.isIdle && headsetInfo.isActive;
            const matchingLearningExperienceResults = ceResultsResponse.get(
              headsetInfo.learningExperienceId
            );
            const studentResults = matchingLearningExperienceResults
              ? matchingLearningExperienceResults.get(value)
              : {};

            return (
              <Tile
                key={i + headsetInfo.dateTime}
                firstName={userInfo?.firstName}
                lastName={userInfo?.lastName}
                percentage={headsetInfo.simCompletionPercentage}
                status={simStatus}
                simName={headsetInfo.simDisplayName}
                simStep={headsetInfo.currentStepDisplayName}
                timeInHeadset={headsetInfo.timeInHeadset}
                batteryPercentage={headsetInfo.batteryLife}
                wifiSignal={headsetInfo.wifiStrength}
                userStatus={userStatus}
                idleTime={
                  showIdle ? `: ${formatTime(headsetInfo.idleTime)}` : ""
                }
                isAnonymous={userInfo?.isAnonymous}
                rating={studentResults?.completed && studentResults?.rating}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
