import clsx from "clsx";
import React from "react";
import { useStoreState } from "easy-peasy";

import orgHeader from "../../../../public/images/org-banner.jpg";

import {
  Shortcut,
  Shortcuts,
  ShortcutsHeader,
  ShortcutsList,
  ShortcutsTally,
  ShortcutsTitle,
  ShortcutAction,
} from "../../../components/shortcuts";

import { useHistory, useRouteMatch } from "react-router-dom";
import { OrgsRoute, RootPath } from "../../../lib/routes";

export function OrgsShortcuts({ className }) {
  const { url } = useRouteMatch(`${RootPath}`);
  const history = useHistory();
  const orgsPath = `${url}${OrgsRoute.path}`;
  const { organizations, totalClassrooms, totalInstructors, totalTrainees } =
    useStoreState((store) => store.organization);

  const orgStats = [
    {
      description: "Orgs",
      value: organizations.length > 0 ? organizations.length : "-",
    },
    {
      description: "Classrooms",
      value: totalClassrooms || "-",
    },
    {
      description: "Instructors",
      value: totalInstructors || "-",
    },
    {
      description: "Trainees",
      value: totalTrainees || "-",
    },
  ];

  const onManageOrgsClick = () => {
    history.push(orgsPath);
  };

  return (
    <Shortcuts className={clsx("orgs-shortcuts-container", className)}>
      <ShortcutsHeader
        image={orgHeader}
        icon="fa-light fa-sitemap"
      ></ShortcutsHeader>
      <ShortcutsTitle text="Organizations"></ShortcutsTitle>
      <ShortcutsTally columns={orgStats}></ShortcutsTally>
      <ShortcutsList>
        {organizations.map((d, i) => (
          <Shortcut
            key={i}
            to={{
              pathname: `${orgsPath}/${d.code}`,
              state: { organization: d, breadcrumbLabel: "Dashboard" },
            }}
            products={d.products}
            title={d.name}
            subtitle={`${d.totalTrainees} Users`}
          ></Shortcut>
        ))}
      </ShortcutsList>
      <div className="manage-action">
        <ShortcutAction onClick={onManageOrgsClick}>
          Manage Organizations
        </ShortcutAction>
      </div>
    </Shortcuts>
  );
}
