import React, { useState } from "react";
import IntegrationDate from "./integration-date";
import SyncBadge from "./sync-badge";

const IntegrationDateWithBadge = ({ connection }) => {
  const { lastRunDate, id, lastRunSyncJobId, lastRunStatus } = connection;
  const [isLastSyncFailed, setIsLastSyncFailed] = useState(
    lastRunStatus === "Failed"
  );
  return (
    <>
      <IntegrationDate date={lastRunDate} error={isLastSyncFailed} />
      <SyncBadge
        connectionId={id}
        jobId={lastRunSyncJobId}
        runStatus={lastRunStatus}
        setFailed={setIsLastSyncFailed}
      />
    </>
  );
};

export default IntegrationDateWithBadge;
