import React, { useEffect, useState } from "react";
import { useContainerQuery } from "@transfr-inc/dashboard-components/container-query";

import { Loader, SlashCircleIcon } from "@transfr-inc/dashboard-components";

import {
  Filter,
  SearchBox,
  useFilter,
  useSearch,
} from "@transfr-inc/dashboard-components/forms";

import { ShortcutAction } from "../../../../components/shortcuts";
import { TraineeSimsTable } from "./trainee.sims-table";

import "./trainee.simulations.scss";
import { useStoreState } from "easy-peasy";
import { Role } from "../../../../models";

const AllModulesOption = "All Modules";

const query = {
  "small-panel": {
    minWidth: 320,
    maxWidth: 767,
  },
  "medium-panel": {
    minWidth: 768,
    maxWidth: 1080,
  },
  "large-panel": {
    minWidth: 1080,
  },
};

export function TraineeSimulations({
  data,
  classroom,
  codeGeneratedMapping,
  onCodeGenerated,
}) {
  const { role, currentUser } = useStoreState((store) => store.account);
  const [simsNames, setSimsNames] = useState();
  const [filters, setFilters] = useState([AllModulesOption]);
  const [params, containerRef] = useContainerQuery(query);

  const [simulations, setSimulations] = useState(data);

  const simsNamesSearch = useSearch({
    data: simulations,
    searchKeys: ["displayName"],
  });

  const simsModuleFilter = useFilter({
    data: simulations,
    filterKey: "moduleName",
  });

  const onFilterStatusSelected = (value) => {
    const filterSelected = value === filters[0] ? "" : value;
    const results = simsModuleFilter.queryData(filterSelected);
    setSimulations(simsNamesSearch.updateData(results));
  };

  const onSimsNameTermChange = (term) => {
    const results = simsNamesSearch.queryData(term);
    setSimulations(results);
  };

  const updateFilterOptions = (data) => {
    const modulesSet = new Set(data.map((d) => d.moduleName));
    const modulesNames = [...modulesSet];
    setFilters([AllModulesOption, ...modulesNames]);
    simsModuleFilter.updateData(data);
  };

  useEffect(() => {
    if (data) {
      setSimulations(data);
      setSimsNames(data.map((s) => s.displayName));
      simsNamesSearch.updateData(data);
      updateFilterOptions(data);
    }
  }, [data]);

  return (
    <div className="modules-section">
      {role !== Role.STUDENT.id && (
        <div className="modules-header">
          <div className="filters-section">
            <SearchBox
              className="sims-search"
              placeholder="Search"
              data={simsNames}
              disabled={!simulations}
              onSearchTermChange={onSimsNameTermChange}
              onResultSelected={onSimsNameTermChange}
            ></SearchBox>
            <Filter
              className="module-filter"
              placeholder="Filter by Status"
              defaultValue={filters[0]}
              data={filters}
              onFilterSelected={onFilterStatusSelected}
              disabled={!simulations}
            ></Filter>
          </div>
        </div>
      )}
      {simulations?.length === 0 && (
        <ShortcutAction className="no-data-message" disabled>
          <SlashCircleIcon></SlashCircleIcon>
          No data to display. Please try adjusting your search or filters.
        </ShortcutAction>
      )}
      {!simulations && (
        <div>
          <Loader height={280}></Loader>
        </div>
      )}
      {simulations?.length > 0 && (
        <div className="modules-accordions" ref={containerRef}>
          <TraineeSimsTable
            sims={simulations}
            responsiveStyles={params}
            isStudent={role == Role.STUDENT.id}
            classroom={classroom}
            user={currentUser}
            codeGeneratedMapping={codeGeneratedMapping}
            onCodeGenerated={onCodeGenerated}
          ></TraineeSimsTable>
        </div>
      )}
    </div>
  );
}
