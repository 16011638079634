import React from "react";
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IntegrationSelect from "./components/integration-select";

import "./zero-state-message.scss";

export function ZeroStateMessage({
  className,
  onActionClicked,
  providers,
  add = false,
}) {
  const emptyStateText =
    "There are no third-party integrations for your organization.";

  const addText = "Add a new third-party integration for your organization.";

  return (
    <div className={clsx("zero-state-message", className)}>
      <div className="text">
        <FontAwesomeIcon icon={["fa-light", "swap"]} />
        <span>{add ? addText : emptyStateText}</span>
        <div className="integration-link">
          <IntegrationSelect
            providers={providers}
            onSelectProvider={onActionClicked}
          />
        </div>
      </div>
    </div>
  );
}
