// hex colors
export const avatarMap = {
  1: "#4D7AC3",
  2: "#EF8C78",
  3: "#0F2D69",
  4: "#898A8D",
  5: "#FFFFFF",
  6: "#F7CF46",
  7: "#EB562E",
  8: "#6F2A31",
  9: "#6D8045",
  10: "#D0DB46",
  11: "#4AA4DC",
  12: "#3B0693",
  13: "#727376",
  14: "#586F8D",
  15: "#1E252B",
  16: "#C03D1E",
  17: "#B124D9",
  18: "#D37F2E",
  19: "#66DE62",
  20: "#CE2C9E",
};
