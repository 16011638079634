import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Badge } from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { useTimer } from "../../lib/hooks";
import { SimPassDeleteModal } from "./sim-pass.delete.modal";

import GradientLoader from "../../../public/images/gradient-loader.svg?react";

import "./simple-sim-pass-button.scss";

export const SimpleSimPassButton = ({
  deletingSimPass,
  generatingSimPass,
  simPass,
  user,
  onClick,
  onDelete,
}) => {
  const [expired, setExpired] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const timer = useTimer();

  useEffect(() => {
    setExpired(timer.isDone);
  }, [timer.isDone]);

  useEffect(() => {
    setExpired(false);
    if (simPass) {
      timer.setTimer(simPass.ttl * 60);
    }
  }, [simPass]);

  const handleClick = () => {
    if (simPass && !expired) {
      setShowDeleteModal(true);
    } else if (!generatingSimPass) {
      onClick();
    }
  };

  return (
    <>
      <div
        className={clsx("simple-sim-pass-button", {
          "has-code": simPass && !expired,
          loading: generatingSimPass,
        })}
        onClick={handleClick}
      >
        <Button
          primary
          icon={(!simPass || expired) && "fa-regular fa-key-skeleton"}
          disabled={deletingSimPass || generatingSimPass}
        >
          Sim Pass
          {generatingSimPass && <GradientLoader className="animate-spin" />}
          {simPass && !expired && (
            <>
              <Badge className="sim-pass-code" value={simPass.code} />
              <FontAwesomeIcon
                icon="fa-regular fa-times"
                className="sim-pass-delete"
              />
            </>
          )}
        </Button>
        {simPass && !expired && (
          <span className="sim-pass-timer">
            <FontAwesomeIcon icon="fa-regular fa-clock" />
            {timer.timeFormatted}
          </span>
        )}
      </div>
      {createPortal(
        <SimPassDeleteModal
          user={user}
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={onDelete}
        />,
        document.body
      )}
    </>
  );
};
