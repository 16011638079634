import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import "./trainee-modals.scss";

const getTraineeName = (trainee) =>
  `${trainee.firstName} ${trainee.lastName}`.trim();

export function DeleteTraineeModal({
  open,
  trainee,
  onDeleteTrainee,
  onClose,
}) {
  const onCloseHandler = () => {
    onClose && onClose();
  };

  const onDeleteTraineeHandler = () => {
    onCloseHandler();
    onDeleteTrainee && onDeleteTrainee(trainee);
  };

  return (
    <Modal
      modalClassName={"delete-trainee-modal"}
      open={open}
      onClose={onCloseHandler}
    >
      <ModalHeader
        className="red-icon small"
        icon={["fa-regular", "trash-can"]}
        title="Delete Trainee"
      ></ModalHeader>
      <ModalBody className={"delete-modal-message"}>
        Are you sure you would like to delete the trainee{" "}
        <strong>{getTraineeName(trainee)}</strong> ? This action cannot be
        undone.
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCloseHandler}>No, Cancel</Button>
        <Button destructive onClick={onDeleteTraineeHandler}>
          Yes, Delete Trainee
        </Button>
      </ModalFooter>
    </Modal>
  );
}
