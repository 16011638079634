import React, { useState } from "react";

import { HyphenIcon } from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { formatDateLocaleMonth } from "@transfr-inc/dashboard-components/utils";

import { ChartFilterModal } from "../../modals";

import "./chart.filter.scss";

export const ChartFilter = ({
  onApplyFilters,
  orgCode,
  userInfo,
  classroomEnabled,
  organizationEnabled,
  moduleEnabled,
  product,
  filterCount,
  loading,
  allTimeStartDate,
  onToggleAllTime,
}) => {
  const [openFilterModal, setOpenFilterModal] = useState();
  const [dateRangeSelected, setDateRangeSelected] = useState();

  const onApplyFiltersHandler = (filters) => {
    const { dateRange } = filters;
    setDateRangeSelected(dateRange);
    onApplyFilters && onApplyFilters(filters);
    setOpenFilterModal();
  };

  return (
    <>
      <div className="chart-filter-container">
        <Button
          icon={["fa-regular", "sliders"]}
          onClick={() => setOpenFilterModal(true)}
        >
          Filter
          {filterCount > 0 && <span className="badge">{filterCount}</span>}
        </Button>
        <div className="values-container">
          {!loading && (
            <div className="date-range-selected">
              {allTimeStartDate
                ? formatDateLocaleMonth(allTimeStartDate)
                : formatDateLocaleMonth(dateRangeSelected?.startDate)}
              <HyphenIcon />
              {formatDateLocaleMonth(dateRangeSelected?.endDate)}
            </div>
          )}
        </div>
      </div>
      <ChartFilterModal
        open={openFilterModal}
        onCancel={() => setOpenFilterModal()}
        onApplyFilters={onApplyFiltersHandler}
        classroomEnabled={classroomEnabled}
        organizationEnabled={organizationEnabled}
        moduleEnabled={moduleEnabled}
        userInfo={userInfo}
        orgCode={orgCode}
        product={product}
        filterCount={filterCount}
        onToggleAllTime={onToggleAllTime}
      />
    </>
  );
};
