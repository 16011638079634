import React from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";
import { TextAvatar } from "@transfr-inc/dashboard-components";

import "./instructor.box.scss";

export function InstructorBox({ instructor, onRemove, enableRemove }) {
  const { firstName, lastName } = instructor;
  return (
    <div className="instructor-box">
      <TextAvatar text={[lastName, firstName]}></TextAvatar>
      <div>{`${lastName}, ${firstName}`}</div>
      {enableRemove && (
        <Button
          borderless
          icon={["fa-regular", "xmark"]}
          onClick={() => onRemove && onRemove(instructor)}
        ></Button>
      )}
    </div>
  );
}
