import React, { useMemo } from "react";

import { Product } from "../../../../models";

import { ClassroomProgressCeTab } from "./classroom.progress.ce.tab";
import { ClassroomProgressDemoTab } from "./classroom.progress.demo.tab";
import { ClassroomProgressMultiProductTab } from "./classroom.progress.multi.tab";
import { ClassroomProgressTsTab } from "./classroom.progress.ts.tab";
import { ClassroomProgressTrekTab } from "./classroom.progress.trek.tab";

export const ClassroomProgressTab = ({ classroom, users, productId }) => {
  const { products, isDemo } = classroom;

  const classroomProductId = useMemo(() => {
    // productId has priority over classroom product list
    if (productId) {
      return productId;
    } else {
      return products.length === 1 ? products[0] : Product.MULTI;
    }
  }, [productId, classroom]);

  if (isDemo) {
    return <ClassroomProgressDemoTab />;
  }

  switch (classroomProductId) {
    case Product.TS:
      return <ClassroomProgressTsTab trainees={users} classroom={classroom} />;
    case Product.CE:
      return <ClassroomProgressCeTab classroom={classroom} explorers={users} />;
    case Product.TRK:
      return <ClassroomProgressTrekTab classroom={classroom} users={users} />;
    case Product.MULTI:
      return (
        <ClassroomProgressMultiProductTab classroom={classroom} users={users} />
      );
  }
};
