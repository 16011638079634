import clsx from "clsx";
import React from "react";

export const CareerSelectedHeader = ({ career }) => {
  const simsTitle = career.simulations?.length > 1 ? "Sims: " : "Sim: ";
  const simsNames = career.simulations
    ? career.simulations.join(", ")
    : undefined;

  return (
    <div className="careers-selected-slide-header">
      <div>
        Career Cluster: <span>{career?.cluster.name}</span>
      </div>
      {simsNames && (
        <div>
          {simsTitle}
          <span className={clsx(!career.simulations && "no-sims-message")}>
            {simsNames}
          </span>
        </div>
      )}
    </div>
  );
};
