export const Category = {
  ALL: 0,
  TRANSFR: 1,
  CUSTOM: 2,
  INTERNAL: 3,
};

const categories = new Map();
categories.set(Category.ALL, {
  id: Category.ALL,
  name: "All",
  key: "All",
  label: "All",
});
categories.set(Category.TRANSFR, {
  id: Category.TRANSFR,
  name: "Transfr",
  key: "Transfr Categories",
  label: "Transfr Categories",
});
categories.set(Category.CUSTOM, {
  id: Category.CUSTOM,
  name: "Custom",
  key: "Custom Categories",
  label: "Custom Categories",
});

export function getCategory(categoryId) {
  return categories.get(categoryId);
}

export function getCategoryName(categoryId) {
  return categories.get(categoryId).name;
}

export function getCategoryKey(categoryId) {
  return categories.get(categoryId).key;
}

export function getCategoryFilterLabel(categoryId) {
  return categories.get(categoryId).label;
}
export function getCategories() {
  return [...categories.values()];
}
export function isCustomCategory(categoryId) {
  return categoryId === Category.CUSTOM;
}
