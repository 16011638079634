import React from "react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  NotificationType,
  Notification,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { getRoleName } from "../../models/role";
import container from "../../container";

import "./delete-modal.scss";

export const DeleteUserModal = ({
  onCancel,
  show,
  deleteUserList,
  onRowRemoved,
}) => {
  const [notification, setNotification] = useState();
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const { userService } = container;

  const onNotificationClose = () => {
    setNotification();
  };

  const { handleSubmit, reset } = useForm({
    defaultValues: { ...deleteUserList },
  });

  const getContent = (deleteUserList) => {
    return deleteUserList.length == 1 ? (
      <div className="delete-content">
        Are you sure you would like to delete the{" "}
        {getRoleName(deleteUserList[0].roleId)?.toLowerCase()}
        <b>
          {" "}
          {deleteUserList[0].firstName} {deleteUserList[0].lastName}{" "}
        </b>
        ? This action cannot be undone.
      </div>
    ) : (
      <div className="delete-content">
        Are you sure you would like to delete
        <b> {deleteUserList.length} </b>
        {getRoleName(deleteUserList[0]?.roleId)?.toLowerCase()}s? This action
        cannot be undone.
      </div>
    );
  };

  async function submitForm(values, e) {
    try {
      const userIds = values.map((x) => x["userId"]);
      await userService.deleteUsers({
        userIds: userIds,
        roleId: values[0]["roleId"],
      });
      onRowRemoved(userIds);
      setNotification({
        type: NotificationType.success,
        message: (
          <div>
            <b>{deleteUserList.length} </b>{" "}
            {userIds.length > 1 ? "users have" : "user has"} been deleted
          </div>
        ),
      });
    } catch {
      console.log("failure");
      setNotification({
        type: NotificationType.error,
        message: "Something went wrong",
      });
    } finally {
      onCancel();
      setIsAlertVisible(true);
      setTimeout(() => {
        setIsAlertVisible(false);
        setNotification();
      }, 10000);
    }
  }

  const onClick = (values, e) => {
    handleSubmit(submitForm)(e);
  };

  useEffect(() => {
    reset(deleteUserList);
  }, [deleteUserList]);

  return (
    <div className={"delete-modal-container"}>
      <Modal modalClassName={"delete-modal"} open={show} onClose={onCancel}>
        <ModalHeader
          className="red-icon"
          icon={["fa-regular", "fa-trash-can"]}
          title={deleteUserList.length > 1 ? "Delete Users" : "Delete User"}
        ></ModalHeader>
        <ModalBody className="delete-body">
          {getContent(deleteUserList)}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCancel}>Cancel</Button>
          <Button destructive onClick={onClick}>
            {deleteUserList.length > 1
              ? `Yes, Delete ${getRoleName(deleteUserList[0]?.roleId)}s`
              : `Yes, Delete ${getRoleName(deleteUserList[0]?.roleId)}`}
          </Button>
        </ModalFooter>
      </Modal>
      <div className="success-notification">
        {isAlertVisible && notification && (
          <Notification
            {...notification}
            closable
            onClose={onNotificationClose}
          >
            {notification.message}
          </Notification>
        )}
      </div>
    </div>
  );
};
