import container from "../container";
import { Role } from "../models/role";

export function getOrganizationUsers(role, orgCode, userId) {
  const { userService } = container;

  const getOrganizationUsersRequest = () => {
    if (role === Role.MULTI_ORG_ADMIN.id || role === Role.MULTI_ORG_VIEWER.id) {
      return userService.getMultiOrgUsers(orgCode);
    } else if (role === Role.INSTRUCTOR.id) {
      return userService.getOrganizationUsersForInstructor(orgCode, userId);
    } else {
      return userService.getOrganizationUsers(orgCode);
    }
  };

  return getOrganizationUsersRequest();
}

export function catchUserCreationError(error, role = "trainee") {
  let errorMessage;

  if (error.status == 409) {
    errorMessage = "username or email is not unique";
  } else {
    errorMessage =
      error.data && error.data.detail
        ? error.data.detail
        : `Unable to create ${role}`;
  }

  return { error: errorMessage };
}

export function catchEditUserError(response, roleId) {
  let errorMessage = "Something went wrong. Please try again.";
  if (response.status == 409) {
    errorMessage =
      roleId == Role.STUDENT.id
        ? "Record already exists for this email or username"
        : "Email is not unique.";
  }

  return errorMessage;
}
