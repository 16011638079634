import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useLocation, useRouteMatch } from "react-router-dom";

import { NavBarItem } from "@transfr-inc/dashboard-components";

import {
  ClassroomRoute,
  SupportRoute,
  CurricularRoute,
  MultiOrgDashboardRoute,
  SingleOrgDashboardRoute,
  OrgsRoute,
  UsersRoute,
  ProductCatalogRoute,
  isEntryPointPath,
  RootPath,
  MyWorkRoute,
  IntegrationsRoute,
  FundingRoute,
} from "../../lib/routes";
import { Role, hasRole } from "../../models/role";

export function NavbarRoutes({ isExpanded, mode = "full" }) {
  const { url } = useRouteMatch(RootPath);
  const { role, currentUser } = useStoreState((store) => store.account);
  const {
    organizations,
    users,
    classrooms,
    classroomsLoaded,
    organizationsLoaded,
    usersLoaded,
    integrationsLoaded,
    integrationsList,
  } = useStoreState((store) => store.organization);
  const { lastEntryPoint, integrationsEnabled } = useStoreState(
    (store) => store.app
  );
  const { getUsers, getOrganizations, getClassrooms, getIntegrations } =
    useStoreActions((store) => store.organization);
  const { setLastEntryPoint } = useStoreActions((store) => store.app);
  const { length: orgsCount } = organizations;
  const { userId, organizationCode, integrations } = currentUser;
  const location = useLocation();
  const showIntegrationsTab = integrations && integrationsEnabled;

  useEffect(() => {
    if (!usersLoaded) {
      getUsers({ role, orgCode: organizationCode, userId });
    }
    if (!classroomsLoaded) {
      getClassrooms({ role, orgCode: organizationCode, userId });
    }
    if (
      !organizationsLoaded &&
      (role === Role.MULTI_ORG_ADMIN.id || role === Role.MULTI_ORG_VIEWER.id)
    ) {
      getOrganizations(organizationCode);
    }
    if (!integrationsLoaded) {
      getIntegrations({ orgCode: organizationCode });
    }
  }, [organizationCode, classroomsLoaded, usersLoaded, organizationsLoaded]);

  useEffect(() => {
    const path =
      isEntryPointPath(location.pathname) && !location.state
        ? location.pathname
        : lastEntryPoint;
    setLastEntryPoint(path);
  }, [location]);

  const dashboardRoute = hasRole(MultiOrgDashboardRoute.roles, role)
    ? MultiOrgDashboardRoute
    : SingleOrgDashboardRoute;

  //Encrypted userid
  const id = window.btoa(userId);

  const dashboardNavItem = (
    <NavBarItem
      to={`${url}${dashboardRoute.path}`}
      isExpanded={isExpanded}
      isActiveRoute={lastEntryPoint?.includes(dashboardRoute.path)}
      icon={dashboardRoute.icon}
      label={dashboardRoute.label}
      exact={true}
      component={dashboardRoute.component}
    ></NavBarItem>
  );

  const orgsNavItem = hasRole(OrgsRoute.roles, role) && (
    <NavBarItem
      to={`${url}${OrgsRoute.path}`}
      isExpanded={isExpanded}
      isActiveRoute={lastEntryPoint?.includes(OrgsRoute.path)}
      icon={OrgsRoute.icon}
      label={OrgsRoute.label}
      labelAbbr={OrgsRoute.labelAbbr}
      exact={true}
      badge={orgsCount || undefined}
      component={OrgsRoute.component}
    ></NavBarItem>
  );

  const classroomsNavItem = (
    <NavBarItem
      to={`${url}${ClassroomRoute.path}`}
      isExpanded={isExpanded}
      isActiveRoute={lastEntryPoint?.includes(ClassroomRoute.path)}
      icon={ClassroomRoute.icon}
      label={ClassroomRoute.label}
      exact={true}
      badge={classrooms?.length}
      component={ClassroomRoute.component}
    ></NavBarItem>
  );

  const usersNavItem = (
    <NavBarItem
      to={`${url}${UsersRoute.path}`}
      isExpanded={isExpanded}
      isActiveRoute={lastEntryPoint?.includes(UsersRoute.path)}
      icon={UsersRoute.icon}
      label={UsersRoute.label}
      exact={true}
      // TODO: Find a way to update this count without doing a double api call (navbar and users component)
      // Maybe passing the users to the component or easy-peasy?
      badge={users?.length}
      component={UsersRoute.component}
    ></NavBarItem>
  );

  const integrationsNavItem = (
    <NavBarItem
      to={`${url}${IntegrationsRoute.path}`}
      isExpanded={isExpanded}
      isActiveRoute={lastEntryPoint?.includes(IntegrationsRoute.path)}
      icon={IntegrationsRoute.icon}
      label={IntegrationsRoute.label}
      exact={true}
      badge={integrationsList?.length}
      component={IntegrationsRoute.component}
    ></NavBarItem>
  );

  const standardsNavItem = (
    <NavBarItem
      to={CurricularRoute.path}
      isExternalLink={true}
      isExpanded={isExpanded}
      icon="circle-nodes"
      label={CurricularRoute.label}
      labelAbbr={CurricularRoute.labelAbbr}
    ></NavBarItem>
  );

  const productCatalogNavItem = (
    <NavBarItem
      to={ProductCatalogRoute.path}
      isExternalLink={true}
      isExpanded={isExpanded}
      icon="books"
      label={ProductCatalogRoute.label}
      labelAbbr={ProductCatalogRoute.labelAbbr}
    ></NavBarItem>
  );

  const supportNavItem = (
    <NavBarItem
      to={`${SupportRoute.path}/?pi=${id}`}
      isExternalLink={true}
      isExpanded={isExpanded}
      icon="question-circle"
      label={SupportRoute.label}
      labelAbbr={SupportRoute.labelAbbr}
    ></NavBarItem>
  );

  const fundingNavItem = (
    <NavBarItem
      to={`${FundingRoute.path}`}
      isExternalLink={true}
      isExpanded={isExpanded}
      icon="money-check-dollar-pen"
      label={FundingRoute.label}
      labelAbbr={FundingRoute.labelAbbr}
    ></NavBarItem>
  );

  const myWorkNavItem = (
    <NavBarItem
      to={`${url}${MyWorkRoute.path}`}
      isExpanded={isExpanded}
      isActiveRoute={lastEntryPoint?.includes(MyWorkRoute.path)}
      icon={MyWorkRoute.icon}
      label={MyWorkRoute.label}
      exact={true}
      component={MyWorkRoute.component}
    ></NavBarItem>
  );

  if (role === Role.STUDENT.id) {
    return <>{myWorkNavItem}</>;
  } else {
    if (mode === "full") {
      return (
        <>
          {dashboardNavItem}
          {orgsNavItem}
          {classroomsNavItem}
          {usersNavItem}
          {showIntegrationsTab && integrationsNavItem}
          {standardsNavItem}
          {productCatalogNavItem}
          {supportNavItem}
          {fundingNavItem}
        </>
      );
    } else if (mode === "mobile") {
      return (
        <>
          {dashboardNavItem}
          {orgsNavItem}
          {classroomsNavItem}
          {usersNavItem}
          {showIntegrationsTab && integrationsNavItem}
        </>
      );
    } else if (mode === "more") {
      return (
        <>
          {standardsNavItem}
          {productCatalogNavItem}
          {supportNavItem}
          {fundingNavItem}
        </>
      );
    }
  }
}
