import React from "react";

import { Route } from "react-router-dom";

export default function ContainerRoute({
  component: Component,
  responsiveStyles,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return (
          <Component responsiveStyles={responsiveStyles} {...routeProps} />
        );
      }}
    />
  );
}
