import React from "react";

import { SlashCircleIcon } from "@transfr-inc/dashboard-components";
import { ShortcutAction } from "../../../../components/shortcuts";

import "./classroom.progress.demo.tab.scss";

export const ClassroomProgressDemoTab = () => {
  return (
    <ShortcutAction className="demo-message-container">
      <SlashCircleIcon></SlashCircleIcon>
      Progress data is unavailable. Classroom is for demo purposes only.
    </ShortcutAction>
  );
};
