import React, { useState, useEffect } from "react";
import clsx from "clsx";

import { Filter } from "@transfr-inc/dashboard-components/forms";
import container from "../../../container";
import { useApiRequest } from "../../../lib/http-client";

export function ModuleFilter({
  orgCode,
  onModuleSelected,
  placeholder = "Filter by Module",
  className,
  isParentOrg = false,
}) {
  const defaultOption = { displayName: "All Modules" };
  const { insightsService } = container;
  const [modules, setModules] = useState([defaultOption]);

  const { sendRequest: getModules } = useApiRequest(
    () =>
      insightsService
        .getModulesForOrg(orgCode, isParentOrg)
        .then((response) => setModules([defaultOption, ...response])),
    false
  );

  useEffect(() => {
    getModules();
  }, [isParentOrg, orgCode]);

  return (
    <Filter
      className={clsx("classroom-filter small", className)}
      placeholder={placeholder}
      data={modules}
      clearValue={modules[0]}
      onFilterSelected={onModuleSelected}
      labelKey={"displayName"}
    ></Filter>
  );
}
