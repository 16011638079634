import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Loader,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import "./remove-instructor.modal.scss";

const getInstructorName = (instructor) =>
  `${instructor?.firstName} ${instructor?.lastName}`.trim();

export function RemoveInstructorModal({
  open,
  onCancel,
  onRemoveInstructor,
  instructor,
  classroom,
}) {
  const [loading, setLoading] = useState();

  const onCloseHandler = () => {
    onCancel && onCancel();
  };

  const onRemoveInstructorHandler = () => {
    setLoading(true);
    onRemoveInstructor &&
      onRemoveInstructor(instructor, classroom).finally(setLoading);
  };

  return (
    <Modal
      modalClassName="remove-instructor-modal"
      open={open}
      onClose={onCloseHandler}
      preventClose={loading}
    >
      {loading && <Loader overlay></Loader>}
      <ModalHeader
        className="red-icon"
        icon={["fa-light", "user-xmark"]}
        title="Remove Instructor"
      ></ModalHeader>
      <ModalBody>
        Are you sure you would like to remove{" "}
        <strong>{getInstructorName(instructor)}</strong> from{" "}
        <strong>{classroom.name}</strong> ?
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCloseHandler} disabled={loading}>
          No, Cancel
        </Button>
        <Button
          destructive
          onClick={onRemoveInstructorHandler}
          disabled={loading}
        >
          Yes, Remove
        </Button>
      </ModalFooter>
    </Modal>
  );
}
