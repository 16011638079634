import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";
import { useLocation } from "react-router-dom";

import { PageLayout } from "@transfr-inc/dashboard-components/layouts";

import {
  Tab,
  TabHeaders,
  Tabs,
  Notification,
  NotificationType,
  Loader,
} from "@transfr-inc/dashboard-components";

import { ProductTabHeader } from "../../../components/custom/tabs/product-tab-header";
import { ShortcutAction } from "../../../components/shortcuts";

import { TraineeHeader } from "./components/trainee.header";
import { TraineeClassroomTab } from "./components/trainee.classroom.tab";
import { TraineeToolbar } from "./components/trainee.toolbar";
import { AddTraineeClassroomModal } from "../../../components/modals";

import { useApiRequest } from "../../../lib/http-client";
import container from "../../../container";

import "./index.scss";

export default () => {
  const {
    userId,
    breadcrumbLabel,
    classroomId,
    product,
    customBreadcrumbRoute,
  } = useLocation().state;
  const [isLoading, setIsLoading] = useState(true);
  const [trainee, setTrainee] = useState();
  const [classrooms, setClassrooms] = useState();
  const [notification, setNotification] = useState();
  const [openAddToClassroom, setOpenAddToClassroom] = useState();
  const { userService, organizationService, classroomService } = container;
  const { features } = useStoreState((store) => store.account);
  const { canEditSTUDENTUser: editEnabled } = features.editableUsers;

  const { loading: loadingTrainee } = useApiRequest(() =>
    userService.getUserDetails(userId).then(setTrainee)
  );

  const { response: organization, sendRequest: getOrgDetails } = useApiRequest(
    () => organizationService.getOrganizationDetails(trainee?.organizationCode),
    false
  );

  const { loading: loadingClassrooms } = useApiRequest(() =>
    userService.getUserClassrooms(userId).then(setClassrooms)
  );

  const onEditTrainee = async (updatedTrainee) => {
    try {
      const updatedUser = await userService.updateUserMetadata({
        userId,
        ...updatedTrainee,
      });
      setTrainee(updatedUser);
      const { firstName, lastName } = updatedTrainee;
      showSuccessNotification(
        <>
          The deteails for <strong>{`${firstName} ${lastName}`.trim()}</strong>
          &nbsp;has been updated.
        </>
      );
      return updatedUser;
    } catch (error) {
      console.error(error);
      const { data, status } = error;
      updatedTrainee.error =
        data?.detail ?? "Something went wrong, please try again later.";
      updatedTrainee.status = status;
      return updatedTrainee;
    }
  };

  const onRemoveTraineeFromClassroom = (trainee, classroom) => {
    const { classroomId, name } = classroom;
    return classroomService
      .removeTrainee(classroomId, trainee.userId)
      .then(() => {
        setClassrooms(classrooms.filter((c) => c.classroomId !== classroomId));
        showSuccessNotification(
          <>
            Trainee has been removed from classroom <strong>{name}</strong>
          </>
        );
      });
  };

  const onAddTraineeToClassroom = (classroom) => {
    const { classroomId, name } = classroom;
    const { userId, firstName, lastName } = trainee;

    return classroomService.addTrainee(classroomId, userId).then(() => {
      setOpenAddToClassroom();
      showSuccessNotification(
        <>
          <strong>{`${firstName} ${lastName}`}</strong> has been added to
          classroom <strong>{name}</strong>
        </>
      );
      setClassrooms([...classrooms, classroom]);
    });
  };

  const onPasswordUpdated = () => {
    showSuccessNotification("Password successfully changed.");
  };

  const showSuccessNotification = (message) => {
    setNotification({
      type: NotificationType.success,
      message,
      icon: ["fa-solid", "circle-check"],
    });
    setTimeout(() => setNotification(), 10000);
  };

  const onCloseNotification = () => setNotification();

  useEffect(() => {
    !loadingTrainee && getOrgDetails();
  }, [loadingTrainee]);

  useEffect(() => {
    setIsLoading(loadingClassrooms || loadingTrainee || !organization);
  }, [loadingClassrooms, loadingTrainee, organization]);

  const getSelectedTab = () => {
    const findFn = classroomId
      ? (c) => c.classroomId === classroomId
      : (c) => c.products.includes(product);
    return classroomId || product
      ? classrooms.find((c) => findFn(c)).classroomId
      : classrooms[0]?.classroomId;
  };

  const pageHeader = (
    <TraineeHeader
      organization={organization}
      trainee={trainee}
      breadcrumbLabel={breadcrumbLabel}
      customBreadcrumbRoute={customBreadcrumbRoute}
    >
      <TraineeToolbar
        trainee={trainee}
        onEditTrainee={onEditTrainee}
        onPasswordUpdated={onPasswordUpdated}
        editEnabled={editEnabled}
      ></TraineeToolbar>
    </TraineeHeader>
  );

  return !isLoading ? (
    <PageLayout className={"trainee-detail-page"} header={pageHeader}>
      <div className="trainee-detail-content">
        <div className="trainee-classrooms-header">
          <h2>
            Classrooms{" "}
            <span className="badge">
              {isLoading ? "..." : classrooms.length}
            </span>
          </h2>
        </div>
        {classrooms?.length > 0 ? (
          <Tabs
            className="trainee-detail-tabs"
            selectedTabName={isLoading ? "" : getSelectedTab()}
          >
            <TabHeaders className="classroom-tabs">
              {classrooms?.map((c) => (
                <ProductTabHeader
                  key={c.classroomId}
                  name={c.classroomId}
                  productId={c.products[0]}
                  text={c.name}
                />
              ))}
            </TabHeaders>
            {classrooms?.map((classroom) => (
              <Tab
                key={classroom.classroomId}
                name={classroom.classroomId}
                className={"classroom-tab"}
              >
                <TraineeClassroomTab
                  trainee={trainee}
                  classroom={classroom}
                  organization={organization}
                  onRemoveTrainee={onRemoveTraineeFromClassroom}
                  removeTraineeEnabled={editEnabled && classrooms?.length > 1}
                />
              </Tab>
            ))}
          </Tabs>
        ) : (
          <div className="no-classrooms-container">
            <ShortcutAction
              icon={["fa-light", "screen-users"]}
              onClick={() => setOpenAddToClassroom(true)}
              disabled={!editEnabled}
            >
              {editEnabled
                ? "Add Trainee to a Classroom"
                : "This trainee is not enrolled in a classroom."}
            </ShortcutAction>
          </div>
        )}
        <div className="notification-container">
          {notification && (
            <Notification
              className="classroom-notification"
              type={notification.type}
              icon={notification.icon}
              onClose={onCloseNotification}
              closable
            >
              {notification.message}
            </Notification>
          )}
        </div>
      </div>
      <AddTraineeClassroomModal
        open={openAddToClassroom}
        orgCode={trainee?.organizationCode}
        onCancel={() => setOpenAddToClassroom()}
        onAddTrainee={onAddTraineeToClassroom}
        async
      ></AddTraineeClassroomModal>
    </PageLayout>
  ) : (
    <Loader overlay fixed />
  );
};
