import React, { useMemo, useState } from "react";
import clsx from "clsx";

import {
  Accordion,
  AccordionHeader,
  AccordionHeaderName,
  AccordionBody,
  HyphenIcon,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { DeleteCategoryModal } from "../modals/category.delete.modal";
import {
  AddModulesModal,
  CategoryImage,
  ModuleList,
  EditModulesModal,
} from "..";
import { getCategoryImage } from "../utils/categories-images.mapper";

import { Category, isCustomCategory } from "../../../../../../models";
import { createPortal } from "react-dom";

import "./category.accordion.scss";
import { EditCategoryModal } from "../modals/edit-category/edit-category.modal";

export const CategoryAccordion = ({
  category,
  onEditModules,
  onEditSimulations,
  onEditCategory,
  className,
  onDeleteCategory,
  allModules,
  onApplyModulesHandler,
  classroom,
  enableEdit,
  checkVersionNumber,
}) => {
  const { categoryImage, categoryType, categoryDescription, categoryModules } =
    category || {};
  const [showHeaderDetail, setShowHeaderDetail] = useState();
  const [addModulesModalOpen, setAddModulesModalOpen] = useState();
  const [editModulesModalOpen, setEditModulesModalOpen] = useState();
  const [openEditCategoryModal, setOpenEditCategoryModal] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState();

  const categoryImageConfig = getCategoryImage(categoryImage);

  const editionEnabled = useMemo(
    () => isCustomCategory(categoryType) && enableEdit,
    [category]
  );

  const onEditSimulationsHandler = ({ module, updatedSimulations }) => {
    return onEditSimulations(category, module, updatedSimulations);
  };

  const onEditCategoryHandler = (category) => {
    return onEditCategory(category).finally(() => setOpenEditCategoryModal());
  };

  const onEditModulesHandler = () => {
    setEditModulesModalOpen(true);
  };

  const onAddModulesHandler = () => {
    setAddModulesModalOpen(true);
  };

  const onApplyHandler = async (selectedModules) => {
    return onApplyModulesHandler(category, selectedModules).then(() =>
      setAddModulesModalOpen()
    );
  };

  const onCancel = () => {
    setShowDeleteModal(false);
  };

  const onApplyEditModulesHandler = (modules) => {
    return onEditModules(category, modules).then(() =>
      setEditModulesModalOpen()
    );
  };

  const handleCategoryDelete = () => {
    setShowDeleteModal(true);
  };

  return (
    <>
      <Accordion
        className={clsx("category-accordion", className)}
        onExpand={() => setShowHeaderDetail(true)}
        onCollapse={() => setShowHeaderDetail()}
      >
        <AccordionHeader>
          <AccordionHeaderName>
            <div className="category-container">
              <div className="category-name">
                <span>{category.categoryName}</span>
                {category?.categoryType == Category.CUSTOM && (
                  <span className="badge">Custom</span>
                )}
              </div>
              <div className="button-container">
                {editionEnabled && (
                  <>
                    <Button
                      className={clsx(showHeaderDetail && "display-button")}
                      icon={["fa-regular", "pen-to-square"]}
                      onClick={() => setOpenEditCategoryModal(true)}
                    >
                      Edit Details
                    </Button>
                  </>
                )}
              </div>
            </div>
          </AccordionHeaderName>
          <div
            className={clsx("category-details", showHeaderDetail && "expanded")}
          >
            <span>Image</span>
            <CategoryImage categoryImageConfig={categoryImageConfig} />
            <span>Description</span>
            <div>{categoryDescription ?? <HyphenIcon />}</div>
          </div>
        </AccordionHeader>
        <AccordionBody>
          <ModuleList
            modules={categoryModules}
            onAddModules={onAddModulesHandler}
            onEditModules={onEditModulesHandler}
            onEditSimulations={onEditSimulationsHandler}
            editionEnabled={editionEnabled}
            checkVersionNumber={checkVersionNumber}
          />
        </AccordionBody>
      </Accordion>
      {createPortal(
        <AddModulesModal
          open={addModulesModalOpen}
          onClose={() => setAddModulesModalOpen()}
          modules={allModules || []}
          categoryModules={categoryModules || []}
          onApplyChanges={onApplyHandler}
          checkVersionNumber={checkVersionNumber}
          async
        ></AddModulesModal>,
        document.body
      )}
      {createPortal(
        <EditModulesModal
          open={editModulesModalOpen}
          onClose={() => setEditModulesModalOpen()}
          modules={category?.categoryModules || []}
          category={category}
          onApplyChanges={onApplyEditModulesHandler}
          allModules={allModules}
          checkVersionNumber={checkVersionNumber}
          async
        ></EditModulesModal>,
        document.body
      )}
      {createPortal(
        <EditCategoryModal
          category={category}
          open={openEditCategoryModal}
          onCancel={() => setOpenEditCategoryModal()}
          classroom={classroom}
          onEditCategoryHandler={onEditCategoryHandler}
          onDeleteCategoryClickHandler={handleCategoryDelete}
          checkVersionNumber={checkVersionNumber}
          async
        ></EditCategoryModal>,
        document.body
      )}
      {createPortal(
        <DeleteCategoryModal
          open={showDeleteModal}
          onCancel={onCancel}
          onDeleteCategory={() => onDeleteCategory(category)}
        />,
        document.body
      )}
    </>
  );
};
