import clsx from "clsx";
import React from "react";

import "./shortcuts.tally.scss";

export default ({ columns, className }) => {
  return (
    <div className={clsx("shortcuts-columns", className)}>
      {columns.map((i, index) => (
        <div className="column" key={index}>
          <h4 className="value">{i.value}</h4>
          <span className="description">{i.description}</span>
        </div>
      ))}
    </div>
  );
};