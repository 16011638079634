import React from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import "../common/delete-modal.scss";

export function SimPassDeleteModal({ user, open, onClose, onDelete }) {
  const doDelete = async () => {
    try {
      onDelete();
    } finally {
      onClose();
    }
  };

  return (
    <Modal open={open} modalClassName="delete-modal" onClose={onClose}>
      <ModalHeader
        className="red-icon"
        icon={["fa-regular", "fa-key-skeleton"]}
        title="Remove Sim Pass?"
      ></ModalHeader>
      <ModalBody>
        Are you sure you would like to remove the sim pass for {user.firstName}{" "}
        {user.lastName}?
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>No, Cancel</Button>
        <Button destructive onClick={doDelete}>
          Yes, Remove
        </Button>
      </ModalFooter>
    </Modal>
  );
}
