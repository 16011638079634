import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";

import { PageHeader } from "@transfr-inc/dashboard-components/layouts";
import { Breadcrumb, Link, EmailLink } from "@transfr-inc/dashboard-components";

import { useDrawerColor } from "@transfr-inc/dashboard-components/hooks";

import {
  Color,
  getRandomContrastColors,
} from "@transfr-inc/dashboard-components/utils";

import {
  OrgsRoute,
  RootPath,
  SingleOrgDashboardRoute,
} from "../../../../lib/routes";

import {
  getRandomTraineeAvatar,
  TraineeAvatar,
} from "../../../../components/avatars";

import { getRoleFeatures, Role } from "../../../../models/role";

import "./trainee.header.scss";

export function TraineeHeader({
  trainee,
  organization,
  breadcrumbLabel,
  customBreadcrumbRoute,
  children,
}) {
  const { url } = useRouteMatch(RootPath);
  const history = useHistory();

  const [avatar, setAvatar] = useState();
  const { firstName, lastName, username, email } = trainee;
  const { name: orgName, code } = organization;
  const fullName = `${firstName} ${lastName}`;
  const { toggleColor, revertColor } = useDrawerColor({ bgColor: Color.sky });

  const { role } = useStoreState((store) => store.account);

  const hasMultiOrgAccess = getRoleFeatures(role).superAdminsTabEnabled;
  const dashboardPath = hasMultiOrgAccess
    ? `${url}${OrgsRoute.path}/${code}`
    : `${url}${SingleOrgDashboardRoute.path}`;

  useEffect(() => {
    const [color, accentColor] = getRandomContrastColors();
    setAvatar({
      color,
      accentColor,
      icon: getRandomTraineeAvatar(),
    });
  }, []);

  useEffect(() => {
    toggleColor();
    return () => revertColor();
  }, []);

  const subTitle = (
    <ul className="metadata">
      {username && <li>{username}</li>}
      {email && (
        <li>
          <EmailLink>{email}</EmailLink>
        </li>
      )}
      <li>
        {role != Role.STUDENT.id ? (
          <Link
            to={{
              pathname: dashboardPath,
              state: {
                organization: hasMultiOrgAccess && organization,
                breadcrumbLabel: `Trainee`,
              },
            }}
          >
            {orgName}
          </Link>
        ) : (
          orgName
        )}
      </li>
    </ul>
  );

  const breadcrumbFn = customBreadcrumbRoute
    ? () => history.push(customBreadcrumbRoute)
    : undefined;

  return (
    <PageHeader
      className="trainee-detail-header"
      title={fullName}
      subTitle={subTitle}
      breadcrumb={
        role != Role.STUDENT.id && (
          <Breadcrumb
            label={breadcrumbLabel}
            onClick={breadcrumbFn}
          ></Breadcrumb>
        )
      }
      image={<TraineeAvatar {...avatar}></TraineeAvatar>}
    >
      {children}
    </PageHeader>
  );
}
