import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";

import {
  Modal,
  ModalBody,
  ModalHeader,
  Slider,
  Slide,
  useSlider,
  Breadcrumb,
  Badge,
} from "@transfr-inc/dashboard-components";

import { CareerIcon } from "../../career-icon/career.icon";

import { CareerSelectedHeader } from "./career-selected.header";
import { CareerSelectedUsersList } from "./career-selected-users.list";
import { CareersSelectedList } from "./careers-selected.list";

import container from "../../../container";

import "./careers-leaderboard.modal.scss";

const LeaderboardTitle = "Career Leaderboard";
const CareerTitle = "Career:";

export const CareersSelectedLeaderboardModal = ({
  open,
  onCancel,
  topCareers,
  activeUsers,
  classroom,
  users,
  career,
  breadcrumbLabel,
  backdropClassName,
  modalClassName,
}) => {
  const { trekService } = container;
  const totalSlides = 2;
  const slider = useSlider({ totalSlides });
  const [selectedCareer, setSelectedCareer] = useState();
  const [modalTitle, setModalTitle] = useState(LeaderboardTitle);

  const usersMap = useMemo(
    () => new Map(users?.map((u) => [u.userId, u])),
    [users]
  );

  const onCareerSelected = async (career) => {
    const users = career.users.map((u) => usersMap.get(u)).filter((u) => u);
    setSelectedCareer({ ...career, users });
    setModalTitle(`${CareerTitle} ${career.title}`);
    slider.nextSlide();
  };

  const goLeaderboardSlide = () => {
    setSelectedCareer();
    setModalTitle(LeaderboardTitle);
    slider.previousSlide();
  };

  useEffect(() => {
    if (open) {
      if (!career) {
        goLeaderboardSlide();
      } else {
        slider.nextSlide();
        onCareerSelected(career);
      }
    }
  }, [open]);

  const modalTitleElement = (
    <>
      <div className="title-container">
        {selectedCareer && (
          <CareerIcon
            iconUrl={trekService.getCareerImageUrl(selectedCareer.transfrId)}
            clusterCode={selectedCareer.name}
          />
        )}
        <h2>{modalTitle}</h2>
        {selectedCareer && <Badge value={selectedCareer.users.length} />}
      </div>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={onCancel}
      backdropClassName={backdropClassName}
      preventClose
      modalClassName={clsx(
        "careers-selected-leaderboard-modal",
        modalClassName
      )}
    >
      <ModalHeader
        title={modalTitleElement}
        label={
          selectedCareer && (
            <Breadcrumb onClick={goLeaderboardSlide} label="View All" />
          )
        }
      ></ModalHeader>
      <ModalBody>
        <Slider
          className="leaderboard-modal-body"
          totalSlides={totalSlides}
          currentSlideIndex={slider.currentIndex}
        >
          <Slide>
            <div className="careers-selected-slide-header">
              <div>
                Active Explorers: <span>{activeUsers}</span>
              </div>
            </div>
            <CareersSelectedList
              careers={topCareers}
              onCareerSelected={onCareerSelected}
            />
          </Slide>
          <Slide>
            {selectedCareer && (
              <>
                <CareerSelectedHeader career={selectedCareer} />
                <CareerSelectedUsersList
                  data={selectedCareer.users}
                  classroom={classroom}
                  breadcrumbLabel={breadcrumbLabel}
                />
              </>
            )}
          </Slide>
        </Slider>
      </ModalBody>
    </Modal>
  );
};
