import React from "react";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionHeaderName,
  OverflowText,
} from "@transfr-inc/dashboard-components";

import { ClassroomMasteryTable } from "./classroom.mastery-table";

export function ClassroomSimulationAccordion({
  module,
  className,
  isOpen,
  responsiveStyles,
}) {
  return (
    <Accordion className={className} isOpen={isOpen}>
      <AccordionHeader>
        <AccordionHeaderName>
          <OverflowText text={module?.displayName} className="simulation-name">
            {module?.displayName}
          </OverflowText>
          <div className="simulation-module-name">{module?.moduleName}</div>
        </AccordionHeaderName>
      </AccordionHeader>
      <AccordionBody className="simulation-accordion-body">
        <ClassroomMasteryTable
          data={module.mastery}
          responsiveStyles={responsiveStyles}
        ></ClassroomMasteryTable>
      </AccordionBody>
    </Accordion>
  );
}
