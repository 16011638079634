import React from "react";
import { TermsAndConditions } from "../../../components/custom/content";

import {
  Input,
  Button,
  ValidationItem,
} from "@transfr-inc/dashboard-components/forms";

export const NonStudentSignupForm = ({
  errors,
  dirtyFields,
  register,
  getValues,
  email,
  blocking,
  isValid,
  handleChecked,
  notAcceptedNotification,
  termsChecked,
}) => {
  return (
    <>
      <div className="instructions">
        Please enter a password for your new Transfr account.
      </div>
      <div className="inputs">
        <Input
          label="Email Address"
          name="email-address"
          type="email"
          value={email}
          disabled="disabled"
          required
        />
        <Input
          label="Password"
          type="password"
          required
          errors={errors.password}
          {...register("password", {
            validate: {
              count: (v) =>
                v.length >= 8 || "Password must be at least 8 characters",
              hasLetter: (v) =>
                /[A-Za-z]/.test(v) || "Password must include a letter",
              hasNumber: (v) =>
                /\d+/.test(v) || "Password must include a number",
              hasSpecial: (v) =>
                /[\s!,.?@_-]+/.test(v) ||
                "Password must include a special character",
              hasOnlyAllowedSpecials: (v) =>
                !/[^\w!,.?@-]+/.test(v) ||
                "Password cannot include special characters other than @ ! ? , . - _",
            },
          })}
        />

        <Input
          label="Confirm Password"
          type="password"
          required
          errors={errors.passwordMatch}
          {...register("passwordMatch", {
            validate: {
              matches: (v) => {
                const password = getValues("password");
                return password === v || "match";
              },
            },
          })}
        />
      </div>
      <div className="requirements">
        <ul>
          <li>
            <div className="title">Password Requirements</div>
          </li>
          <li>
            <ValidationItem
              label={"Minimum of 8 characters"}
              valid={!errors.password?.types.count}
              enabled={dirtyFields.password}
            />
          </li>
          <li>
            <ValidationItem
              label={"At least one letter"}
              valid={!errors.password?.types.hasLetter}
              enabled={dirtyFields.password}
            />
          </li>
          <li>
            <ValidationItem
              label={"At least one number"}
              valid={!errors.password?.types.hasNumber}
              enabled={dirtyFields.password}
            />
          </li>
          <li>
            <ValidationItem
              label={"At least one special character"}
              valid={!errors.password?.types.hasSpecial}
              enabled={dirtyFields.password}
            />
          </li>
          <li>
            <ValidationItem
              label={"No special characters other than @ ! ? , . - _"}
              valid={!errors.password?.types.hasOnlyAllowedSpecials}
              enabled={dirtyFields.password}
            />
          </li>
          <li>
            <ValidationItem
              label={"Confirm your new password"}
              valid={!errors.passwordMatch?.types?.matches}
              enabled={dirtyFields.password && dirtyFields.passwordMatch}
            />
          </li>
        </ul>
      </div>
      <TermsAndConditions
        termsChecked={termsChecked}
        handleChecked={handleChecked}
        errors={notAcceptedNotification}
      />
      <div>
        <Button
          className="primary block"
          type="submit"
          label="Sign Up"
          disabled={!isValid || blocking}
          loader={blocking}
        />
      </div>
    </>
  );
};
