/* eslint-disable no-undef */
const { error, debug } = console;

/**
 * A single keyed cache that can be composed into a larger cache mechanism
 */
class LocalStorageCache {
  /**
   *Creates an instance of LocalStorageCache.
   *
   * @param {string} key cache key
   * @param {number} ttl time to live in seconds
   * @memberof LocalStorageCache
   */
  constructor(key, ttl = 86400) {
    Object.assign(this, { key, ttl });
  }

  /**
   *
   *
   * @returns {object|undefined}
   * @memberof LocalStorageCache
   */
  get() {
    try {
      let cached = localStorage.getItem(this.key);
      cached = cached ? JSON.parse(cached) : undefined;
      if (!cached || this.isValid(cached) || !cached.payload) {
        debug(`cache miss for ${this.key}`);
        return;
      } else {
        debug(`cache hit for ${this.key}`);
        return cached.payload;
      }
    } catch (error_) {
      error(error_);
      return;
    }
  }

  isValid(cached) {
    return cached.exp === -1 ? true : cached.exp < this.nowInSeconds;
  }

  /**
   * @param {object} payload
   * @param {object} options.exp
   * @memberof LocalStorageCache
   */
  set(payload, { exp } = {}) {
    localStorage.setItem(
      this.key,
      JSON.stringify({
        exp: exp || this.nowInSeconds + this.ttl,
        payload,
      })
    );
  }

  /**
   *
   */
  remove() {
    localStorage.removeItem(this.key);
  }

  /**
   * @private
   */
  get nowInSeconds() {
    return Math.floor(Date.now() / 1000);
  }
}

export const uuid = () => {
  const url = URL.createObjectURL(new Blob());
  return url.slice(Math.max(0, url.lastIndexOf("/") + 1));
};

export const debounce = (callback, wait) => {
  let timeoutId;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

export default LocalStorageCache;
