export const Role = {
  INSTRUCTOR: {
    id: 1,
    displayName: "Instructor",
    pluralDisplayName: "Instructors",
    icon: "fa-light fa-chalkboard-user",
    displayOrder: 4,
    description: "Can view/edit data across a single classroom.",
    creationDisplayName: "Instructor",
    features: {
      canCreateUser: true,
      creatableUsers: {
        canCreateMULTI_ORG_ADMINUser: false,
        canCreateORG_ADMINUser: false,
        canCreateMULTI_ORG_VIEWERUser: false,
        canCreateINSTRUCTORUser: false,
        canCreateSTUDENTUser: true,
      },
      deleteableUsers: {
        canDeleteMULTI_ORG_ADMINUser: false,
        canDeleteORG_ADMINUser: false,
        canDeleteMULTI_ORG_VIEWERUser: false,
        canDeleteINSTRUCTORUser: false,
        canDeleteSTUDENTUser: true,
      },
      editableUsers: {
        canEditMULTI_ORG_ADMINUser: false,
        canEditORG_ADMINUser: false,
        canEditMULTI_ORG_VIEWERUser: false,
        canEditINSTRUCTORUser: false,
        canEditSTUDENTUser: true,
      },
      orgSelectionEnabled: true, // For user creation only - TODO: store this and other flags elsewhere
      general_orgSelectionEnabled: false, // Instructors cannot generally select organizations for really anything
      classroomSelectionEnabled: true,
      editClassroomEnabled: true,
      createClassroomEnabled: true,
      deleteClassroomEnabled: true,
      get instructorCreationEnabled() {
        return this.creatableUsers.canCreateINSTRUCTORUser;
      },
      superAdminsTabEnabled: false,
      reportersTabEnabled: false,
    },
    roleIconClassName: "instructor",
    avatarColor: "#00A7E1",
  },
  STUDENT: {
    id: 2,
    displayName: "Trainee",
    pluralDisplayName: "Trainees",
    icon: "fa-light fa-head-side-goggles",
    displayOrder: 5,
    description: "Works on assigned simulations.",
    creationDisplayName: "Trainee",
    features: {
      canCreateUser: false,
      creatableUsers: {
        canCreateMULTI_ORG_ADMINUser: false,
        canCreateORG_ADMINUser: false,
        canCreateMULTI_ORG_VIEWERUser: false,
        canCreateINSTRUCTORUser: false,
        canCreateSTUDENTUser: false,
      },
      deleteableUsers: {
        canDeleteMULTI_ORG_ADMINUser: false,
        canDeleteORG_ADMINUser: false,
        canDeleteMULTI_ORG_VIEWERUser: false,
        canDeleteINSTRUCTORUser: false,
        canDeleteSTUDENTUser: false,
      },
      editableUsers: {
        canEditMULTI_ORG_ADMINUser: false,
        canEditORG_ADMINUser: false,
        canEditMULTI_ORG_VIEWERUser: false,
        canEditINSTRUCTORUser: false,
        canEditSTUDENTUser: false,
      },
      orgSelectionEnabled: true, // For user creation only - TODO: store this and other flags elsewhere
      general_orgSelectionEnabled: false, // Students cannot generally select organizations for really anything
      classroomSelectionEnabled: true,
      editClassroomEnabled: false,
      createClassroomEnabled: false,
      deleteClassroomEnabled: false,
      get instructorCreationEnabled() {
        return this.creatableUsers.canCreateINSTRUCTORUser;
      },
      superAdminsTabEnabled: false,
      reportersTabEnabled: false,
    },
    avatarColor: "#CDDB00",
  },
  MULTI_ORG_ADMIN: {
    id: 6,
    displayName: "Super Admin",
    pluralDisplayName: "Super Admins",
    icon: "fa-light fa-users-gear",
    displayOrder: 1,
    description:
      "Can view/edit data and manage users across multiple organizations.",
    creationDisplayName: "Super Admin",
    features: {
      canCreateUser: true,
      creatableUsers: {
        canCreateMULTI_ORG_ADMINUser: true,
        canCreateORG_ADMINUser: true,
        canCreateMULTI_ORG_VIEWERUser: true,
        canCreateINSTRUCTORUser: true,
        canCreateSTUDENTUser: true,
      },
      deleteableUsers: {
        canDeleteMULTI_ORG_ADMINUser: true,
        canDeleteORG_ADMINUser: true,
        canDeleteMULTI_ORG_VIEWERUser: true,
        canDeleteINSTRUCTORUser: true,
        canDeleteSTUDENTUser: true,
      },
      editableUsers: {
        canEditMULTI_ORG_ADMINUser: true,
        canEditORG_ADMINUser: true,
        canEditMULTI_ORG_VIEWERUser: true,
        canEditINSTRUCTORUser: true,
        canEditSTUDENTUser: true,
      },
      orgSelectionEnabled: false, // For user creation only - TODO: store this and other flags elsewhere
      general_orgSelectionEnabled: true, // Multi-org admins *can* generally select organizations
      classroomSelectionEnabled: false,
      editClassroomEnabled: true,
      createClassroomEnabled: true,
      deleteClassroomEnabled: true,
      get instructorCreationEnabled() {
        return this.creatableUsers.canCreateINSTRUCTORUser;
      },
      superAdminsTabEnabled: true,
      reportersTabEnabled: true,

      // CA TODO: Oops, not part of this story
      // canEditUserRole: true,
      // roleUpdates: {
      //   canUpdateMULTI_ORG_ADMINUserRole: true,
      //   canUpdateMULTI_ORG_VIEWERUserRole: true,
      //   canUpdateORG_ADMINUserRole: true,
      //   canUpdateINSTRUCTORUserRole: true,
      //   canUpdateSTUDENTUserRole: false,
      // },
    },
    roleIconClassName: "super-admin",
    avatarColor: "#FFCD00",
  },
  MULTI_ORG_VIEWER: {
    id: 7,
    displayName: "Reporter",
    pluralDisplayName: "Reporters",
    icon: "fa-light fa-user-lock",
    displayOrder: 2,
    description: "Can only view data and users across multiple organizations.",
    creationDisplayName: "Reporter",
    features: {
      canCreateUser: false,
      creatableUsers: {
        canCreateMULTI_ORG_ADMINUser: false,
        canCreateORG_ADMINUser: false,
        canCreateMULTI_ORG_VIEWERUser: false,
        canCreateINSTRUCTORUser: false,
        canCreateSTUDENTUser: false,
      },
      deleteableUsers: {
        canDeleteMULTI_ORG_ADMINUser: false,
        canDeleteORG_ADMINUser: false,
        canDeleteMULTI_ORG_VIEWERUser: false,
        canDeleteINSTRUCTORUser: false,
        canDeleteSTUDENTUser: false,
      },
      editableUsers: {
        canEditMULTI_ORG_ADMINUser: false,
        canEditORG_ADMINUser: false,
        canEditMULTI_ORG_VIEWERUser: false,
        canEditINSTRUCTORUser: false,
        canEditSTUDENTUser: false,
      },
      orgSelectionEnabled: false, // For user creation only - TODO: store this and other flags elsewhere
      general_orgSelectionEnabled: false, // Reporters cannot generally select organizations for really anything
      classroomSelectionEnabled: false,
      editClassroomEnabled: false,
      createClassroomEnabled: false,
      deleteClassroomEnabled: false,
      get instructorCreationEnabled() {
        return this.creatableUsers.canCreateINSTRUCTORUser;
      },
      superAdminsTabEnabled: true,
      reportersTabEnabled: true,
    },
    roleIconClassName: "reporter",
    avatarColor: "#FF8672",
  },
  ORG_ADMIN: {
    id: 8,
    displayName: "Admin",
    pluralDisplayName: "Admins",
    icon: "fa-light fa-user-gear",
    displayOrder: 3,
    description:
      "Can view/edit data and manage users across a single organization.",
    creationDisplayName: "Admin",
    features: {
      canCreateUser: true,
      creatableUsers: {
        canCreateMULTI_ORG_ADMINUser: false,
        canCreateORG_ADMINUser: true,
        canCreateMULTI_ORG_VIEWERUser: false,
        canCreateINSTRUCTORUser: true,
        canCreateSTUDENTUser: true,
      },
      deleteableUsers: {
        canDeleteMULTI_ORG_ADMINUser: false,
        canDeleteORG_ADMINUser: true,
        canDeleteMULTI_ORG_VIEWERUser: false,
        canDeleteINSTRUCTORUser: true,
        canDeleteSTUDENTUser: true,
      },
      editableUsers: {
        canEditMULTI_ORG_ADMINUser: false,
        canEditORG_ADMINUser: true,
        canEditMULTI_ORG_VIEWERUser: false,
        canEditINSTRUCTORUser: true,
        canEditSTUDENTUser: true,
      },
      orgSelectionEnabled: true, // For user creation only - TODO: store this and other flags elsewhere
      general_orgSelectionEnabled: false, // single org admins generally cannot select organizations
      classroomSelectionEnabled: false,
      editClassroomEnabled: true,
      createClassroomEnabled: true,
      deleteClassroomEnabled: true,
      get instructorCreationEnabled() {
        return this.creatableUsers.canCreateINSTRUCTORUser;
      },
      superAdminsTabEnabled: false,
      reportersTabEnabled: false,
    },
    roleIconClassName: "org-admin",
    avatarColor: "#CDDB00",
  },
};

export const AllRoles = Object.values(Role);

export const hasRole = (values = [], roleId) => {
  return values.find((r) => r.id === roleId);
};

export const getRoleIconClassName = (id) => {
  return hasRole(AllRoles, id)?.roleIconClassName;
};

export const getRoleAvatarColor = (id) => {
  return hasRole(AllRoles, id)?.avatarColor;
};

export const getRoleName = (id) => {
  return hasRole(AllRoles, id)?.displayName;
};

export const getRoleFeatures = (id) => {
  return hasRole(AllRoles, id)?.features;
};

export const getRole = (id) => {
  return hasRole(AllRoles, id);
};

export const areRolesEqual = (lhs, rhs) => {
  return lhs.id === rhs.id;
};
