import { action, thunk } from "easy-peasy";

export default ({ integrationService }) => ({
  connections: {},

  setConnection: action((state, { connectionId, connection }) => {
    state.connections[connectionId] = connection;
  }),

  setConnections: action((state, connectionsArr) => {
    for (let connection of connectionsArr) {
      state.connections[connection.id] = connection;
    }
  }),

  loadConnections: thunk(
    async (actions, connectionId, { getState, getStoreState }) => {
      const { connections } = getState();
      if (!connections[connectionId]) {
        const { currentUser } = getStoreState().account;
        const { organizationCode } = currentUser;
        try {
          const response = await integrationService.getConnectionsList(
            organizationCode
          );
          actions.setConnections(response);
        } catch (error) {
          console.error("Failed to load connections:", error);
        }
      }
    }
  ),
});
