import container from "../container";

export function getConnectionsList(orgId) {
  const { integrationService } = container;

  const getConnectionsListRequest = () => {
    return integrationService.getConnectionsList(orgId);
  };

  return getConnectionsListRequest();
}
