import {
  formatDateDigits,
  getDateFromString,
  getDatesInRangeMap,
} from "@transfr-inc/dashboard-components/utils";

export function getNearestMaxValue(dataMax, multiple) {
  const nextMaxValue = dataMax % multiple === 0 ? dataMax + multiple : dataMax;
  const maxValue =
    dataMax > 0 ? Math.ceil(nextMaxValue / multiple) * multiple : multiple;
  const numberTicks =
    maxValue > multiple ? Math.ceil(maxValue / multiple) + 1 : multiple + 1;
  return { maxValue, numberTicks };
}

export function getChartData(data, template) {
  return template.map((c) => ({
    label: c.label,
    value: data[c.key] ?? 0,
  }));
}

export function setDailyStats(filters, data) {
  const { startDate, endDate } = filters.dateRange;
  const dates = getDatesInRangeMap(
    getDateFromString(startDate),
    getDateFromString(endDate)
  );

  for (const d of data) {
    const date = getDateFromString(d.date);
    const dateKey = formatDateDigits(date);
    dates.set(dateKey, { ...d, date: dateKey });
  }

  return [...dates.values()];
}
