import React from "react";

export const ResetToDefaultSuccess =
  "This menu has been successfully reset. All custom changes have been removed.";

export const AddModulesSuccess = (categoryName, modules) => {
  const isMoreThanOne = modules.length > 1;
  const modulesNames = isMoreThanOne
    ? modules.map((m) => m.displayName).join(", ")
    : modules[0]?.displayName;
  const verb = isMoreThanOne ? "were" : "was";

  return modules.length > 0 ? (
    <>
      <strong>{modulesNames}</strong> {verb} added to {categoryName}
    </>
  ) : (
    <>
      Modules for category <strong>{categoryName}</strong> have been updated.
    </>
  );
};

export const CreateCategorySuccess = (categoryName) => {
  return (
    <>
      Category <strong>{categoryName}</strong> has been created
    </>
  );
};

export const EditCategorySuccess = (categoryName) => {
  return (
    <>
      Category <strong>{categoryName}</strong> has been updated.
    </>
  );
};

export const AddCategorySuccess = (categories) => {
  if (categories.length === 1) {
    return (
      <>
        The category {categories[0]?.categoryName} has been copied and added to this menu.
      </>
    );
  }

  return (
    <>
      {categories.length} categories have been copied and added to this menu.
    </>
  );
};

export const UpdateModuleSimulationsSuccess = (module) => {
  const { moduleName } = module;
  return (
    <>
      Simulations for module <strong>{moduleName}</strong> have been updated.
    </>
  );
};

export const UpdateModulesSuccess = (modules) => {
  return (
    <>
      <strong>{modules.length}</strong> modules have been updated.
    </>
  );
};

export const UpdateCategoriesSuccess = () => {
  return <>Categories have been updated.</>;
};

export const UpdatePasswordSuccess = (response) => {
  const message = response.successes
    ? `${response.successes.length} password(s) updated successfully${
        response.failures.length > 0
          ? `, ${response.failures.length} password(s) did not update`
          : ""
      }`
    : "Password successfully changed.";
  return message;
};

export const GenericError = "Something went wrong. Please try again.";
