import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { useTimer } from "../../lib/hooks";
import { Timer } from "../timer";
import { SimPassModal } from "./sim-pass.modal";

import GradientLoader from "../../../public/images/gradient-loader.svg?react";

import "./sim-pass-button.scss";

export const SimPassButton = ({
  classroom,
  deletingSimPass,
  generatingSimPass,
  simPass,
  onClick,
  onDelete,
}) => {
  const [expired, setExpired] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);

  const timer = useTimer();

  useEffect(() => {
    setExpired(timer.isDone);
  }, [timer.isDone]);

  useEffect(() => {
    setExpired(false);
    if (simPass) {
      timer.setTimer(simPass.ttl * 60);
    }
  }, [simPass]);

  const handleClick = () => {
    if (simPass && !expired) {
      setOpenDetails(true);
    } else if (!generatingSimPass) {
      onClick();
    }
  };

  return (
    <>
      <button
        className={clsx("sim-pass-button", {
          "has-code": simPass && !expired,
          loading: generatingSimPass,
        })}
        disabled={deletingSimPass}
        onClick={handleClick}
      >
        {generatingSimPass ? (
          <>
            <div className="sim-pass-unlocking">Unlocking</div>
            <GradientLoader className="animate-spin sim-pass-loader" />
            <div className="sim-pass-unlocking-title">Sim Pass</div>
          </>
        ) : (
          <div className="sim-pass-text">
            {!simPass?.code || expired ? (
              <>
                <div className="sim-pass-header">
                  <span>Get </span>
                  <span>Sim Pass</span>
                  <FontAwesomeIcon icon="fa-regular fa-arrow-right" />
                </div>
                <div className="sim-pass-desc">
                  Quickly access sims on your headset.
                </div>
              </>
            ) : (
              <>
                <div className="sim-pass-header">
                  <div className="sim-pass-title-and-timer">
                    <span>Sim Pass</span>
                    <Timer timeLeft={timer.timeFormatted} />
                  </div>
                  <div className="sim-pass-desc">
                    Enter this code into your headset.
                  </div>
                </div>
                <div className="sim-pass-code">{simPass.code}</div>
                <div className="sim-pass-view-details">View Details</div>
              </>
            )}
          </div>
        )}
        <div className="sim-pass-key" />
      </button>
      {createPortal(
        <SimPassModal
          open={openDetails}
          classroom={classroom}
          code={simPass?.code}
          timer={timer}
          onClose={() => setOpenDetails()}
          onStartOver={() => {
            onDelete();
            setOpenDetails();
          }}
        />,
        document.body
      )}
    </>
  );
};
