import clsx from "clsx";
import React from "react";

import { CountText } from "@transfr-inc/dashboard-components";

import "./career-rating-reference.scss";

export const RatingReferenceType = {
  High: "high",
  Low: "low",
  Unrated: "unrated",
};

export const CareerRatingReference = ({ value, isReference = false, type }) => {
  return (
    <div
      className={clsx(
        "rating-reference-item",
        isReference && "reference-only",
        type
      )}
    >
      {!isReference && <CountText count={value} />}
    </div>
  );
};
