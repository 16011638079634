import React, { useState } from "react";
import container from "../../../../container";

import {
  Loader,
  NotificationStack,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { useFilter } from "@transfr-inc/dashboard-components/forms";

import { useApiRequest } from "../../../../lib/http-client";

import {
  MenuBuilderCategories,
  MenuBuilderHeader,
} from "../components/menu-builder";

import { Category } from "../../../../models";
import {
  ResetToDefaultSuccess,
  AddModulesSuccess,
  CreateCategorySuccess,
  GenericError,
  UpdateModuleSimulationsSuccess,
  UpdateModulesSuccess,
  UpdateCategoriesSuccess,
  EditCategorySuccess,
  AddCategorySuccess,
} from "../components/menu-builder/utils/notification.constants";

import "./classroom.menu-builder.tab.scss";

export const ClassroomMenuBuilderTab = ({ classroom, enableEdit }) => {
  const { menuBuilderService, insightsService } = container;

  const [notifications, setNotifications] = useState([]);
  const [categories, setCategories] = useState();
  const [filteredCategories, setFilteredCategories] = useState();
  const [versionNumber, setVersionNumber] = useState();

  const { loading, sendRequest: getCategories } = useApiRequest(() =>
    menuBuilderService
      .getClassroomCategories(classroom?.classroomId)
      .then((response) => initCategories(response))
      .catch(() => {
        showNotification(NotificationType.error, GenericError);
        return;
      })
  );

  const { response: menuDetails, sendRequest: getMenuDetails } = useApiRequest(
    () =>
      menuBuilderService
        .getMenuDetails({ classroomId: classroom?.classroomId })
        .then((response) => {
          if (response === null || response === undefined) {
            setVersionNumber(0);
          } else {
            setVersionNumber(response["version_number"]);
          }
          return response;
        })
        .catch(() => {
          showNotification(NotificationType.error, GenericError);
          return;
        }),
    false
  );

  const { response: allModules } = useApiRequest(() =>
    insightsService
      .getModulesForOrg(classroom?.clientCode, false)
      .catch((error) => console.error(error))
  );

  const onEditCategoriesHandler = async (data) => {
    const { classroomId, clientCode } = classroom;

    const categoryData = data.map((c) => {
      const { categoryId, orderNumber, isVisible } = c;
      return { categoryId, orderNumber, isVisible };
    });

    try {
      await menuBuilderService.updateCategories(
        classroomId,
        clientCode,
        categoryData
      );
      showSuccessNotification(UpdateCategoriesSuccess());
      getCategories();
    } catch {
      showNotification(NotificationType.error, GenericError);
    }
  };

  const categoryFilter = useFilter({
    data: categories,
    queryFn: (data, categorySelected) => {
      if (categorySelected.id === Category.ALL) {
        return data;
      }
      if (categorySelected.id === Category.TRANSFR) {
        return data.filter(
          (d) =>
            d.categoryType === Category.TRANSFR ||
            d.categoryType === Category.INTERNAL
        );
      }
      return data.filter((d) => d.categoryType === categorySelected.id);
    },
  });

  const initCategories = (data) => {
    setCategories(data);
    categoryFilter.updateData(data);
    setFilteredCategories(categoryFilter.queryData());
    getMenuDetails();
  };

  const onCreateCategoryHandler = (category) => {
    const name = category["category_name"] || "";
    showSuccessNotification(CreateCategorySuccess(name));
    getCategories();
  };

  const onAddCategoriesHandler = (categories) => {
    showSuccessNotification(AddCategorySuccess(categories));
    getCategories();
  };

  const onEditCategoryHandler = (category) => {
    const name = category["categoryName"] || "";
    showSuccessNotification(EditCategorySuccess(name));
    getCategories();
  };

  const onCategoryFilterSelected = (category) => {
    const results = categoryFilter.queryData(category);
    setFilteredCategories(results);
  };

  const onActivityLogHandler = () => {
    console.log(`onActivityLogHandler`);
  };

  const onResetMenu = async () => {
    const { classroomId, clientCode } = classroom;
    const versionNumberIsValid = await checkVersionNumber();
    if (!versionNumberIsValid) {
      return;
    }
    try {
      await menuBuilderService.resetMenuToDefault(classroomId, clientCode);
      showSuccessNotification(ResetToDefaultSuccess);
      getCategories();
    } catch {
      showNotification(NotificationType.error, GenericError);
    }
  };

  const onEditModulesHandler = async (category, updatedModules) => {
    const versionNumberIsValid = await checkVersionNumber();
    if (!versionNumberIsValid) {
      return;
    }
    const requestConfig = {
      modules: updatedModules.map((s, index) => {
        const { moduleId, isVisible } = s;
        return { moduleId, isVisible, orderNumber: index };
      }),
    };

    try {
      await menuBuilderService.updateModulesInCustomCategory(
        classroom.classroomId,
        category.categoryId,
        requestConfig
      );
      showSuccessNotification(UpdateModulesSuccess(updatedModules));
      getCategories();
    } catch {
      showNotification(NotificationType.error, GenericError);
    }
  };

  const onEditSimulationsHandler = async (
    category,
    module,
    updatedSimulations
  ) => {
    const versionNumberIsValid = await checkVersionNumber();
    if (!versionNumberIsValid) {
      return;
    }
    const requestConfig = {
      classroomId: classroom.classroomId,
      categoryId: category.categoryId,
      moduleId: module.moduleId,
      simulations: updatedSimulations.map((s) => {
        const { simulationCode, orderNumber, isVisible } = s;
        return { simulationCode, orderNumber, isVisible };
      }),
    };

    try {
      await menuBuilderService.updateModuleSimulations(requestConfig);
      showSuccessNotification(UpdateModuleSimulationsSuccess(module));
      getCategories();
    } catch {
      showNotification(NotificationType.error, GenericError);
    }
  };

  const onDeleteCategory = async (category) => {
    const versionNumberIsValid = await checkVersionNumber();
    if (!versionNumberIsValid) {
      return;
    }
    try {
      const resp = await menuBuilderService.deleteCustomCategory(
        classroom?.classroomId,
        category?.categoryId
      );
      showSuccessNotification(
        `Category ${category.categoryName} successfully deleted.`
      );
      getCategories();
    } catch {
      showNotification(
        NotificationType.error,
        "Something went wrong. Please try again."
      );
    }
  };

  const onCloseNotification = (indexToRemove) => {
    setNotifications(
      notifications.filter((n, index) => index !== indexToRemove)
    );
  };

  const showSuccessNotification = (message) => {
    showNotification(NotificationType.success, message, "circle-check");
  };

  const onApplyModulesHandler = async (category, modules) => {
    const versionNumberIsValid = await checkVersionNumber();
    if (!versionNumberIsValid) {
      return;
    }
    const oldModules = category?.categoryModules ?? [];
    const oldModulesIds = new Set(oldModules.map((m) => m.moduleId));
    const filteredModules = modules.filter((m) => m.isSelected);
    const newModules = filteredModules.filter(
      (m) => !oldModulesIds.has(m.moduleId)
    );
    const modulesRequestBody = filteredModules.map((m) => ({
      module_id: m.moduleId,
    }));
    try {
      await menuBuilderService.addModulesToCustomCategory(
        classroom?.classroomId,
        category?.categoryId,
        { modules: modulesRequestBody }
      );
      getCategories();
      showSuccessNotification(
        AddModulesSuccess(category?.categoryName, newModules)
      );
    } catch {
      showNotification(NotificationType.error, GenericError);
    }
  };

  const showNotification = (type, message, icon) => {
    const notification = { type, message, icon };
    setNotifications((n) => {
      const notificationsUpdated = n ?? [];
      return [...notificationsUpdated, notification];
    });
  };

  const checkVersionNumber = async () => {
    const currentVersionNumber = versionNumber;

    try {
      const latestMenuDetails = await menuBuilderService.getMenuDetails({
        classroomId: classroom?.classroomId,
      });
      let latestVersionNumber =
        latestMenuDetails && (latestMenuDetails["version_number"] ?? undefined);
      if (latestVersionNumber === null || latestVersionNumber === undefined) {
        setVersionNumber(0);
        latestVersionNumber = 0;
      }
      if (currentVersionNumber !== latestVersionNumber) {
        throw new Error(
          "Menu version is outdated. Any edits have not been saved. Please refresh the page and try again."
        );
      } else {
        return true;
      }
    } catch (error) {
      showNotification(NotificationType.error, error.message);
      return false;
    }
  };

  return (
    <div className="menu-builder-container">
      {loading && <Loader overlay fixed />}
      <MenuBuilderHeader
        categories={filteredCategories}
        onEditCategoriesClick={onEditCategoriesHandler}
        onCreateCategory={onCreateCategoryHandler}
        onAddCategories={onAddCategoriesHandler}
        onEditCategory={onEditCategoryHandler}
        onCategoryFilterSelected={onCategoryFilterSelected}
        onActivityLogClick={onActivityLogHandler}
        onResetMenu={onResetMenu}
        classroom={classroom}
        enableEdit={enableEdit}
        checkVersionNumber={checkVersionNumber}
      ></MenuBuilderHeader>
      <MenuBuilderCategories
        categories={filteredCategories}
        onEditModules={onEditModulesHandler}
        onEditSimulations={onEditSimulationsHandler}
        onEditCategory={onEditCategoryHandler}
        onDeleteCategory={onDeleteCategory}
        allModules={allModules}
        onApplyModulesHandler={onApplyModulesHandler}
        classroom={classroom}
        enableEdit={enableEdit}
        checkVersionNumber={checkVersionNumber}
      />
      <div className="notification-container">
        <NotificationStack
          notifications={notifications}
          onClose={onCloseNotification}
          closable
        />
      </div>
    </div>
  );
};
