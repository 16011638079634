import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useStoreState } from "easy-peasy";

import {
  Slider,
  Slide,
  useSlider,
  Loader,
} from "@transfr-inc/dashboard-components";

import { AccountLayoutComponent } from "./components/account-layout.component";
import { SignupForm } from "./components/signup-form.component";
import { createAccountFields } from "./components/utils";
import container from "../../container";
import "./self-signup.scss";

export const SelfSignup = () => {
  const state = useStoreState((store) => store.account);
  const [createError, setCreateError] = useState();
  const [loader, setLoader] = useState();
  const { classroomId, organizationCode, userId } = state?.selfSignupData ?? {};
  const { userService } = container;

  const methods = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      formInputs: createAccountFields,
    },
  });
  const { handleSubmit } = methods;

  const totalSlides = 2;
  const slider = useSlider({ totalSlides });

  const [slideIndex, setSlideIndex] = useState(0);

  const next = () => {
    console.log("Slide Index:", slideIndex);
    slider.nextSlide();
    setSlideIndex(slider.currentIndex + 1);
  };

  const handle = async (data) => {
    // eslint-disable-next-line unicorn/no-array-reduce
    const signupData = data?.formInputs.reduce(
      (acc, input) => {
        acc[input.key] = input[input.key];
        return acc;
      },
      {
        classrooms: [classroomId],
        student_self_signup: true,
        roleId: 2,
        externalId: userId,
      }
    );
    try {
      setLoader(true);
      await userService.createNewUser(signupData, organizationCode);
      next();
    } catch (error) {
      let errorMessage;
      if (error.status == 409) {
        errorMessage = "Record already exists for this email or username";
        setCreateError(errorMessage);
      } else {
        errorMessage =
          error.data && error.data.detail
            ? error.data.detail
            : "Unable to create student";
        setCreateError(errorMessage);
      }

      return { error: errorMessage };
    } finally {
      setLoader();
    }
  };
  const handleSignup = (event) => {
    event.preventDefault();
    handleSubmit(handle)();
  };

  const description =
    "We’ve sent an account confirmation to the email address provided. If you are having trouble finding the email please check your spam folder. ";

  return (
    <>
      {loader && <Loader overlay></Loader>}
      <Slider
        className="signup-slider-container"
        totalSlides={totalSlides}
        currentSlideIndex={slider.currentIndex}
      >
        <Slide>
          <AccountLayoutComponent title={["Create", "Account"]}>
            <div className="slide-main-container">
              <FormProvider {...methods}>
                <SignupForm
                  onSubmit={handleSignup}
                  buttonText={"Next"}
                  error={createError}
                  loader={loader}
                />
              </FormProvider>
            </div>
          </AccountLayoutComponent>
        </Slide>
        <Slide>
          <AccountLayoutComponent title={["Confirm", "Email"]}>
            <div className="slide-main-container">
              <div className="text-box">{description}</div>
            </div>
          </AccountLayoutComponent>
        </Slide>
      </Slider>
    </>
  );
};
