import React from "react";

import { Loader } from "@transfr-inc/dashboard-components";
import { TraineeCeSimulations } from "./trainee.ce-simulations";
import { TraineeCeInsights } from "./trainee.ce.insights";
import { TraineeCeTopCareers } from "./trainee.ce-top-careers";

import { useApiRequest } from "../../../../lib/http-client";
import container from "../../../../container";

export function TraineeClassroomCeTab({
  trainee,
  classroom,
  onRemoveTrainee,
  removeTraineeEnabled,
}) {
  const { simulationsService, insightsService } = container;

  const { response: simulations, loading: loadingSims } = useApiRequest(() =>
    simulationsService.getClassroomSimulations(classroom.classroomId)
  );

  const { response: masteryData, loading: loadingMastery } = useApiRequest(() =>
    insightsService.getUserCEMasteryInfo(trainee?.userId)
  );

  return (
    <>
      {(loadingSims || loadingMastery) && <Loader overlay />}
      <TraineeCeTopCareers trainee={trainee} />
      <TraineeCeInsights
        trainee={trainee}
        classroom={classroom}
        onRemoveTrainee={onRemoveTrainee}
        removeTraineeEnabled={removeTraineeEnabled}
      ></TraineeCeInsights>
      {simulations && masteryData && (
        <TraineeCeSimulations
          simulations={simulations}
          masteryData={masteryData}
        />
      )}
    </>
  );
}
