import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { formatTime } from "@transfr-inc/dashboard-components/utils";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { ClassroomRoute, RootPath } from "../../../../lib/routes";
import { Product } from "../../../../models";
import { classroomUrlMap } from "../../../../lib/classroom-url-map";

export const CareerPathwayHeader = ({
  masteryData,
  classroom,
  onDownloadPathwayPDF,
}) => {
  const history = useHistory();
  const { url } = useRouteMatch(`${RootPath}`);

  const goClassroomDetailsPage = () => {
    const location = {
      pathname: `${url}${ClassroomRoute.path}/${classroomUrlMap(
        classroom.products
      )}/${classroom.code}`,
      state: {
        classroomId: classroom.classroomId,
        breadcrumbLabel: `Trainee`,
        selectedProduct: Product.TRK,
      },
    };
    history.push(location);
  };

  return (
    <div className="title-section">
      <div className="pathway-title">Career Pathway</div>
      <div className="actions-section">
        <div className="time-spent-section">
          <h3 className="headset-time-title">Time in headset</h3>
          <span className="headset-time">
            {masteryData?.totalTimespent ? (
              formatTime(masteryData?.totalTimespent)
            ) : (
              <FontAwesomeIcon icon={"fa-solid fa-hyphen"} />
            )}
          </span>
        </div>
        <Button
          size="small"
          icon={["fa-light", "screen-users"]}
          onClick={goClassroomDetailsPage}
        >
          View Classroom
        </Button>
        <Button
          size="small"
          primary
          icon={["fa-light", "fa-file-pdf"]}
          onClick={onDownloadPathwayPDF}
          disabled={!onDownloadPathwayPDF}
        >
          View Full Pathway
        </Button>
      </div>
    </div>
  );
};
