import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { OverflowText } from "@transfr-inc/dashboard-components";

import {
  CareerRatingReference,
  RatingReferenceType,
} from "./career-rating-reference";

export const LeaderboardCareers = ({ topCareers, onCareerSelected }) => {
  return (
    <div className="modal-body-list-container">
      <div>
        {topCareers?.map((career, index) => (
          <button
            className="career-item"
            key={career.learningExperienceId}
            onClick={() => onCareerSelected(career)}
          >
            <div className="career-item-index">{index + 1}</div>
            <div className="career-item-info">
              <OverflowText className="subtitle" text={career.clusterName}>
                {career.clusterName}
              </OverflowText>
              <OverflowText className="main-title" text={career.occupationName}>
                {career.occupationName}
              </OverflowText>
            </div>
            <div className="career-item-ratings-reference">
              <CareerRatingReference
                value={career.totalHighlyRated}
                type={RatingReferenceType.High}
              />
              <CareerRatingReference
                value={career.totalLowlyRated}
                type={RatingReferenceType.Low}
              />
              <CareerRatingReference
                value={career.totalUnrated}
                type={RatingReferenceType.Unrated}
              />
            </div>
            <FontAwesomeIcon icon="fa-light fa-chevron-right" />
          </button>
        ))}
      </div>
    </div>
  );
};
