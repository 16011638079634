import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";

import { useFilter } from "@transfr-inc/dashboard-components/forms";

import { TraineeInsights } from "./trainee.insights";
import { TraineeSimulations } from "./trainee.simulations";

import { MasteryStatus } from "../../../../models/mastery";
import container from "../../../../container";
import { Role } from "../../../../models";

const getSimulationsData = (data) => {
  const sims = [];
  data.map((r) => {
    sims.push(
      ...r.simulations.map((s) => ({
        ...s,
        moduleName: r.displayName,
        moduleId: r.moduleId,
        appDescription: r.appDescription,
      }))
    );
  });
  return sims;
};

export const StatusFilter = {
  Started: "STARTED",
  Mastered: MasteryStatus.Mastered,
  NeedsWork: MasteryStatus.NeedsWork,
  Stuck: MasteryStatus.Stuck,
  All: "ALL",
};

export function TraineeClassroomTsTab({
  trainee,
  classroom,
  organization,
  onRemoveTrainee,
  removeTraineeEnabled,
}) {
  const [simulations, setSimulations] = useState();
  const [filteredSims, setFilteredSims] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [codeGeneratedMapping, setCodeGeneratedMapping] = useState([]);

  const { role } = useStoreState((store) => store.account);
  const isStudent = role == Role.STUDENT.id;

  const { insightsService } = container;

  const simsModuleFilter = useFilter({
    data: simulations,
    searchKeys: ["moduleName"],
  });

  const statusFilter = useFilter({
    data: simulations,
    queryFn: (data, statusSelected) => {
      if (statusSelected === StatusFilter.All) {
        return data;
      } else if (statusSelected === StatusFilter.Started) {
        return data.filter(
          (d) => d.status && d?.status !== MasteryStatus.NotStarted
        );
      } else {
        return data.filter((d) => d.status === statusSelected);
      }
    },
  });

  useEffect(() => {
    insightsService
      .getUserModulesWithMasteryInfo(
        organization?.code,
        trainee.userId,
        isStudent,
        classroom?.classroomId
      )
      .then((response) => initSimulations(getSimulationsData(response)))
      .catch(setSimulations);

    return () => insightsService.cancelRequest();
  }, []);

  const initSimulations = (value) => {
    setSimulations(value);
    statusFilter.updateData(value);
    simsModuleFilter.updateData(value);

    const initialFilter = isStudent ? StatusFilter.All : StatusFilter.Started;
    setFilteredSims(statusFilter.queryData(initialFilter));
    setSelectedStatus(initialFilter);

    const codeMapping = value.reduce((acc, sim) => {
      acc[sim.learningExperienceId] = {
        generated: false,
        codes: [],
      };
      return acc;
    }, {});

    setCodeGeneratedMapping(codeMapping);
  };

  const onStatusSelected = (status) => {
    setSelectedStatus(status);
    const results = statusFilter.queryData(status);
    setFilteredSims(results);
  };

  const onCodeGenerated = (leId, codes) => {
    const updatedCodes = { ...codeGeneratedMapping };

    let codeList = [];
    for (const cat of codes) {
      codeList.push({ code: cat.code, name: cat.name });
    }

    updatedCodes[leId].generated = true;
    updatedCodes[leId].codes = codeList;
    setCodeGeneratedMapping(updatedCodes);
  };

  return (
    <>
      <TraineeInsights
        trainee={trainee}
        classroom={classroom}
        simulations={simulations}
        onRemoveTrainee={onRemoveTrainee}
        removeTraineeEnabled={removeTraineeEnabled}
        onStatusSelected={onStatusSelected}
        selectedStatus={selectedStatus}
        role={role}
      ></TraineeInsights>
      <TraineeSimulations
        data={filteredSims}
        trainee={trainee}
        orgCode={organization.code}
        classroom={classroom}
        codeGeneratedMapping={codeGeneratedMapping}
        onCodeGenerated={onCodeGenerated}
      ></TraineeSimulations>
    </>
  );
}
