import React from "react";
import clsx from "clsx";

import {
  Column,
  ColumnType,
  SortDirection,
  DataTable,
} from "@transfr-inc/dashboard-components/data-table";

import { ZeroStateMessage } from "./zero-state-message";

import { DataTableColumnDefinition } from "./data-table-column-definition";
import { Role } from "../../../models/role";

import "./instructor-users-tab.scss";

export function InstructorUsersTab({
  data,
  responsiveStyles,
  bottomBarOptions,
  actionMenuOptions,
  onSetShowNewUser,
  dataFiltered,
  className = "",
}) {
  const columns = [
    ...(bottomBarOptions?.length > 0
      ? [new Column("select", /*title:*/ undefined, ColumnType.SELECTION)]
      : []),
    DataTableColumnDefinition.AVATAR,
    DataTableColumnDefinition.LAST_NAME(),
    DataTableColumnDefinition.FIRST_NAME(),
    DataTableColumnDefinition.ORGANIZATION,
    DataTableColumnDefinition.CLASSROOM,
    DataTableColumnDefinition.EMAIL,
  ];

  const zeroStateMessage = (
    <ZeroStateMessage
      role={Role.INSTRUCTOR}
      onShowButton={onSetShowNewUser}
      filtered={dataFiltered}
    ></ZeroStateMessage>
  );

  return (
    <DataTable
      columns={columns}
      data={data}
      className={clsx(
        "instructor-users-table",
        className,
        bottomBarOptions && "selection"
      )}
      keyFields={["id"]}
      keyPrefix="instructor-users"
      defaultSortPropName="lastName"
      defaultSortDirection={SortDirection.ASCENDING}
      zeroStateMessage={zeroStateMessage}
      responsiveStyles={responsiveStyles}
      bottomBarOptions={bottomBarOptions}
      tableContentName={{ singular: "user", plural: "users" }}
      actionMenuOptions={actionMenuOptions}
      rowHeightConfig={{ medium: 160, small: 200 }}
      virtualRowEnabled
    />
  );
}
