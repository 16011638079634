import simsStartedIcon from "../../../../public/images/databox/sims-started.svg";
import simsMasteredIcon from "../../../../public/images/databox/sims-mastered.svg";
import simsUnxploredIcon from "../../../../public/images/databox/careers-unexplored.svg";
import simsAllIcon from "../../../../public/images/databox/sims-all.svg";

export const StatusFilter = {
  All: {
    key: "allCareers",
    label: "View All",
  },
  CareersExplored: {
    key: "careersExplored",
    label: "Explored",
  },
  CareersNotExplored: {
    key: "careersNotExplored",
    label: "Not Explored",
  },
  CareersHighlyRated: {
    key: "careersHighlyRated",
    label: "Careers With 4-5 Stars",
  },
};

export const CareerStatusMap = new Map();
CareerStatusMap.set("ALLCAREERS", {
  key: StatusFilter.All.key,
  statusFilter: StatusFilter.All,
  label: StatusFilter.All.label,
  description: "All careers regardless of status.",
  icon: simsAllIcon,
  dropdownIcon: "fa-list",
});
CareerStatusMap.set("CAREERSEXPLORED", {
  key: StatusFilter.CareersExplored.key,
  statusFilter: StatusFilter.CareersExplored,
  label: StatusFilter.CareersExplored.label,
  description: "Number of careers explorer has completed.",
  icon: simsStartedIcon,
  dropdownIcon: "fa-person-running",
});
CareerStatusMap.set("CAREERSNOTEXPLORED", {
  key: StatusFilter.CareersNotExplored.key,
  statusFilter: StatusFilter.CareersNotExplored,
  label: StatusFilter.CareersNotExplored.label,
  description: "Number of careers explorer has completed.",
  icon: simsUnxploredIcon,
  dropdownIcon: "fa-hiking",
});
CareerStatusMap.set("CAREERSHIGHLYRATED", {
  key: StatusFilter.CareersHighlyRated.key,
  statusFilter: StatusFilter.CareersHighlyRated,
  label: StatusFilter.CareersHighlyRated.label,
  description: "Number of careers explorer has rated 4-5 stars.",
  icon: simsMasteredIcon,
  dropdownIcon: "fa-medal",
});

export function getCareerStatus(status) {
  return CareerStatusMap.get(status.key.toUpperCase());
}

export function getCareerStatuses() {
  return [...CareerStatusMap.values()];
}

export const AllCareerStatuses = Object.values(StatusFilter);
