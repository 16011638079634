import React from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Badge } from "@transfr-inc/dashboard-components";

import {
  Column,
  ColumnType,
  SortDirection,
  ColumnOptions,
  DataTable,
  BasicCell,
} from "@transfr-inc/dashboard-components/data-table";

import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";

import { CheckedCell } from "../../../../components/table-cells";
import { ColumnHeaderInformationTooltip } from "../../../../components/custom/content/column-header-information-tooltip";

import "./trainee.trek.table.scss";

export function TraineeTrekTable({ careers, responsiveStyles }) {
  const columns = [
    new Column(
      "title",
      "Name",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: false,
        overflowEnabled: true,
        customFunction: (value, row) => {
          return (
            <div className="career-name-cell">
              {`${value}`}{" "}
              {row.selected && (
                <Badge className={"selected"} value={"SELECTED"} />
              )}
            </div>
          );
        },
      })
    ),
    new Column(
      "clusterName",
      "Cluster",
      ColumnType.BASIC,
      new ColumnOptions({ sortingEnabled: true, overflowEnabled: true })
    ),
    new Column(
      "viewedAt",
      "Date",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => {
          const formattedDate = value ? formatDateDigits(value) : undefined;
          return <BasicCell value={formattedDate}></BasicCell>;
        },
      })
    ),
    new Column(
      "favorite",
      (
        <ColumnHeaderInformationTooltip
          headerTitle={"Favorited"}
          tooltipText={
            "Favorited from within the Trek app. This does not include simulations favorited in the headset."
          }
        ></ColumnHeaderInformationTooltip>
      ),
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => {
          return (
            <div className="completed-cell">
              {value ? (
                <FontAwesomeIcon icon={"fa-solid fa-star"} />
              ) : (
                <BasicCell />
              )}
            </div>
          );
        },
      })
    ),
    new Column(
      "exploredVr",
      "Explored VR",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => <CheckedCell value={value} nullable />,
      })
    ),
  ];

  return (
    <>
      <DataTable
        className={clsx("trainee-trek-table")}
        columns={columns}
        defaultSortDirection={SortDirection.DESCENDING}
        defaultSortPropName={"date"}
        responsiveStyles={responsiveStyles}
        data={careers}
        keyFields={["transfrId"]}
        rowHeightConfig={{ large: 54, medium: 160, small: 350 }}
        virtualRowEnabled
      ></DataTable>
    </>
  );
}
