import React, { useEffect, useState } from "react";

import {
  Badge,
  Notification,
  NotificationType,
  SlashCircleIcon,
  Link,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { useContainerQuery } from "@transfr-inc/dashboard-components/container-query";
import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";

import {
  SortDirection,
  getSortMethod,
} from "@transfr-inc/dashboard-components/data-table";

import {
  SearchBox,
  SortHeader,
  useSearch,
  useSortHeader,
} from "@transfr-inc/dashboard-components/forms";

import { CareerPlanGoalsModal } from "../../../../components/modals";
import { ShortcutAction } from "../../../../components/shortcuts";
import { ClassroomTrekCareerExploredAccordion } from "./classroom.trek-career-explored.accordion";

import { ExportStatus, doExportData } from "../../../../lib/export-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { TrekFeedbackForm } from "../../../../models";

import "./classroom.trek-careers-explored.scss";

const query = {
  "small-panel": {
    minWidth: 320,
    maxWidth: 850,
  },
  "medium-panel": {
    minWidth: 700,
    maxWidth: 900,
  },
  "large-panel": {
    minWidth: 900,
  },
};

export const ClassroomTrekCareersExplored = ({ classroom, data }) => {
  const [careersExplored, setCareersExplored] = useState();
  const [filtersApplied, setFiltersApplied] = useState();
  const [params, containerRef] = useContainerQuery(query);
  const [exportStatus, setExportStatus] = useState();
  const [selectedRow, setSelectedRow] = useState();

  const careersSearch = useSearch({
    careers: data,
    searchKeys: ["title"],
  });

  const sortHeader = useSortHeader({
    data: [
      {
        title: "Career Name",
        propName: "title",
      },
      {
        title: "Views",
        propName: "totalViews",
      },
      {
        title: "Career Selected",
        propName: "totalSelected",
      },
    ],
    onSortHandler: (header, sortDirection) => {
      const sortDirectionChar =
        sortDirection === SortDirection.DESCENDING ? "-" : "+";
      const sortParam = [`${sortDirectionChar}${header.propName}`];
      const sortFunc = getSortMethod(sortParam);
      careersExplored.sort(sortFunc);
    },
  });

  const onCareerTermSelected = (option) => {
    onCareerSearch(option?.title);
  };

  const onCareerSearch = (term) => {
    const results = careersSearch.queryData(term);
    setCareersExplored(results);
    setFiltersApplied(!!term);
  };

  const onExportData = async () => {
    setExportStatus(ExportStatus.creating);
    try {
      const csvData = careersExplored.flatMap((row) =>
        row.users.map((user) => ({
          "Career Name": row.title,
          "Last Name": user.lastName,
          "First Name": user.firstName,
          Username: user.username,
          "Date Viewed": user.viewedAt
            ? formatDateDigits(user.viewedAt)
            : undefined,
          Goals: Object.values(user.goals ?? {}).flatMap((goals) =>
            goals.map((goal) => goal.goalText)
          ),
          Favorited: user.isFavorited,
          "Explored VR": user.isExploredVR ?? "N/A",
          "Career Selected": user.isSelected,
        }))
      );
      const fileName = `${classroom.name} - Trek`;
      await doExportData(fileName, csvData);
      setExportStatus(ExportStatus.complete);
    } catch {
      setExportStatus(ExportStatus.error);
    }
  };

  useEffect(() => {
    if (data) {
      const results = careersSearch.updateData(data);
      setCareersExplored(results);
    }
  }, [data]);

  const onGoalsClick = (career) => {
    setSelectedRow(career);
  };

  return (
    <div className="trek-careers-explored-section">
      <div className="trek-careers-explored-header">
        <div className="header-left">
          <h2>Careers Explored</h2>
          <Badge value={careersExplored?.length} />
          <div className="filters">
            <SearchBox
              className="career-search"
              placeholder="Search Careers..."
              disabled={!data?.length}
              query={careersSearch.queryData}
              onSearchTermChange={onCareerSearch}
              onResultSelected={onCareerTermSelected}
              customOptionLabel={(r) => `${r.title}`}
            ></SearchBox>
          </div>
        </div>
        <div className="header-right">
          <Button size="small">
            <Link to={TrekFeedbackForm.url} underline={false} isExternal>
              Feedback <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </Link>
          </Button>
          <Button
            primary
            size="small"
            icon={["fa-light", "file-export"]}
            onClick={onExportData}
            disabled={!data?.length || exportStatus === ExportStatus.creating}
          >
            Export Data (.csv)
          </Button>
        </div>
        <div className="notification-loader-container">
          {exportStatus === ExportStatus.creating && (
            <Notification
              type={NotificationType.info}
              icon={["fa-solid", "spinner"]}
              iconConfig={{ spin: true }}
            >
              We’re preparing a download of{" "}
              <strong>{`${classroom.name}'s`}</strong> Trek insights. Please
              wait...
            </Notification>
          )}
          {exportStatus === ExportStatus.error && (
            <Notification
              type={NotificationType.error}
              closable
              onClose={() => setExportStatus()}
            >
              There was an error generating the export. Please try again.
            </Notification>
          )}
        </div>
      </div>
      <div className="trek-careers-explored-accordions" ref={containerRef}>
        {careersExplored?.length === 0 || !data?.length ? (
          <ShortcutAction className="no-data-message" tabIndex={-1}>
            <div>
              <SlashCircleIcon></SlashCircleIcon>
              {filtersApplied
                ? "No data to display. Please try adjusting your search or filters."
                : "There are no explored careers for this classroom."}
            </div>
          </ShortcutAction>
        ) : (
          <>
            <SortHeader
              headers={sortHeader.headers}
              onSort={sortHeader.onSort}
            ></SortHeader>
            {careersExplored?.map((c) => (
              <ClassroomTrekCareerExploredAccordion
                key={c.transfrId}
                career={c}
                responsiveStyles={params}
                onGoalsClick={onGoalsClick}
              />
            ))}
          </>
        )}
      </div>
      <CareerPlanGoalsModal
        open={!!selectedRow}
        onClose={() => setSelectedRow()}
        goals={selectedRow?.goals}
        user={selectedRow}
      />
    </div>
  );
};
