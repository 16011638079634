import React, { useEffect } from "react";

import { HyphenIcon } from "@transfr-inc/dashboard-components";
import { Timer } from "../../../../../components/timer";
import { useTimer } from "../../../../../lib/hooks";

import "./timer-cell.scss";

export const TimerCell = ({ ttl }) => {
  const timer = useTimer();

  useEffect(() => {
    if (ttl) {
      timer.setTimer(ttl);
    }
  }, [ttl]);

  return !timer.isDone && timer.timeLeft ? (
    <Timer className="timer-cell" timeLeft={timer.timeFormatted} />
  ) : (
    <HyphenIcon />
  );
};
