import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { OverflowText, Rating, Link } from "@transfr-inc/dashboard-components";
import { SearchBox, useSearch } from "@transfr-inc/dashboard-components/forms";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { RootPath, UsersRoute } from "../../../lib/routes";
import { Product } from "../../../models";

const getFullName = (user) => `${user.firstName} ${user.lastName}`.trim();

export const CareerExplorers = ({
  explorers: data,
  classroom,
  breadcrumbLabel,
}) => {
  const { url } = useRouteMatch(`${RootPath}`);
  const [explorers, setExplorers] = useState(data);
  const explorersSearch = useSearch({
    data: explorers,
    searchKeys: ["firstName", "lastName"],
  });

  const onExplorerTermChange = (term) => {
    const results = explorersSearch.queryData(term);
    setExplorers(results);
  };

  const onExplorerSearchSelected = (explorer) => {
    setExplorers([explorer]);
  };

  useEffect(() => {
    const results = explorersSearch.updateData(data);
    setExplorers(results);
  }, [data]);

  return (
    <div className="career-explorers-container">
      <h3>Rated Explorers</h3>
      <SearchBox
        disabled={!explorers}
        placeholder="Search"
        query={explorersSearch.queryData}
        onSearchTermChange={onExplorerTermChange}
        onResultSelected={onExplorerSearchSelected}
        customOptionLabel={getFullName}
      />
      <div className="explorers-container modal-body-list-container">
        <div>
          {explorers?.map((explorer) => {
            const fullName = getFullName(explorer);
            return (
              <Link
                key={explorer.userId}
                to={{
                  pathname: `${url}${UsersRoute.path}/${explorer.id}`,
                  state: {
                    userId: explorer.userId,
                    classroomId: classroom?.classroomId,
                    breadcrumbLabel: breadcrumbLabel,
                    product: Product.CE,
                  },
                }}
                underline={false}
                className="explorer-item"
              >
                <div className="explorer-item-info">
                  <OverflowText className="main-title" text={fullName}>
                    {fullName}
                  </OverflowText>
                </div>
                <Rating
                  className="explorer-item-ratings-reference "
                  value={explorer.rating}
                />
                <FontAwesomeIcon icon="fa-light fa-chevron-right" />
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};
