import container from "../container";
import { Product } from "../models";

import {
  getCeOrganizationDailyStats,
  getCeOrganizationStats,
} from "./organization.utils";

export const getTrekDashboardStats = ({
  classroomId,
  orgCode,
  role,
  userId,
  filters,
  isMultiOrg,
}) => {
  const { classroomService, trekService } = container;
  const filtersWithProduct = { productId: Product.TRK, ...filters };

  const promises = [trekService.getInsights({ classroomId, orgCode, filters })];

  if (classroomId) {
    promises.push(
      classroomService.getCeClassroomStats(classroomId, filtersWithProduct)
    );
  } else {
    promises.push(
      getCeOrganizationStats(
        userId,
        role,
        orgCode,
        filtersWithProduct,
        isMultiOrg
      )
    );
  }

  return Promise.allSettled(promises).then((results) => {
    const [trekGenStats, { careersExplored: totalVRSimsExplored }] =
      results.map((r) => r.value);

    return { ...trekGenStats, totalVRSimsExplored };
  });
};

export const getTrekDashboardDailyStats = ({
  classroomId,
  orgCode,
  role,
  userId,
  filters,
  isMultiOrg,
}) => {
  const { classroomService, trekService } = container;
  const filtersWithProduct = { productId: Product.TRK, ...filters };

  const promises = [
    trekService.getInsights({ classroomId, orgCode, filters, daily: true }),
  ];

  if (classroomId) {
    promises.push(
      classroomService.getCeClassroomDailyStats(classroomId, filtersWithProduct)
    );
  } else {
    promises.push(
      getCeOrganizationDailyStats(
        userId,
        role,
        orgCode,
        filtersWithProduct,
        isMultiOrg
      )
    );
  }

  return Promise.allSettled(promises).then((results) => {
    const [trekDailyStats, ceDailyStats] = results.map((r) => r.value);

    return mergeCeAndTrekDailyStats(ceDailyStats, trekDailyStats);
  });
};

const mergeCeAndTrekDailyStats = (ceDailyStats, trekDailyStats) => {
  const trekDailyStatsByDate = {};
  for (const stats of trekDailyStats) {
    trekDailyStatsByDate[stats.date] = stats;
  }

  for (const stats of ceDailyStats) {
    if (stats.date in trekDailyStatsByDate) {
      trekDailyStatsByDate[stats.date].totalVRSimsExplored =
        stats.careersExplored;
    } else {
      trekDailyStatsByDate[stats.date] = {
        date: stats.date,
        totalVRSimsExplored: stats.careersExplored,
      };
    }
  }

  return Object.values(trekDailyStatsByDate).sort((a, b) => a.date < b.date);
};
