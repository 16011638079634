import React from "react";
import { useState, useEffect } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Notification,
  Loader,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import container from "../../../container";

export function ResendInviteModal({ onClose, open, userId, onInvitationSent }) {
  const { accountService } = container;
  const [showError, setError] = useState();
  const [loader, setLoader] = useState();

  const resendInvite = () => {
    setLoader(true);
    return accountService
      .resendInvite(userId)
      .then(onInvitationSent)
      .catch((error) => {
        console.error("[Resend Invite] - Error:", error);
        setError(true);
      })
      .finally(setLoader);
  };

  useEffect(() => {
    if (open) {
      setError();
    }
  }, [open]);

  return (
    <div>
      <Modal
        modalClassName={"resend-invite-modal"}
        open={open}
        onClose={onClose}
        uniqueName="resend-invite-modal"
      >
        {loader && <Loader overlay></Loader>}
        <ModalHeader
          className="blue-icon small"
          icon={["fa-light", "fa-paper-plane"]}
          title="Resend Invite"
          uniqueName="Resend Invite"
        ></ModalHeader>
        <ModalBody>
          {showError && (
            <Notification type={"error"}>
              Something went wrong. Please try again.
            </Notification>
          )}
          <form>
            <div>Are you sure you would like to resend an invite?</div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            No, Cancel
          </Button>
          <Button primary onClick={resendInvite}>
            Yes, Resend
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ResendInviteModal;
