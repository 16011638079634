import {
  FormColumn,
  FormColumnType,
  FormColumnOptions,
} from "@transfr-inc/dashboard-components/data-table";
import Papa from "papaparse";

export const DataTableColumnDefinition = {
  FIRST_NAME: new FormColumn(
    "firstName",
    "First Name",
    FormColumnType.INPUT,
    new FormColumnOptions({
      sortingEnabled: true,
      validation: {
        required: {
          value: true,
          message: `First name is required.`,
        },
        maxLength: {
          value: 50,
          message: "First name must be less than 50 characters",
        },
        pattern: {
          value: /^[\d',.a-z-]+$/i,
          message:
            "First name formatted incorrectly. Only letters, hyphens and numbers are allowed.",
        },
      },
    })
  ),
  LAST_NAME: new FormColumn(
    "lastName",
    "Last Name",
    FormColumnType.INPUT,
    new FormColumnOptions({
      sortingEnabled: true,
      validation: {
        required: {
          value: true,
          message: `Last name is required.`,
        },
        maxLength: {
          value: 50,
          message: "Last name must be less than 50 characters",
        },
        pattern: {
          value: /^[\d',.a-z-]+$/i,
          message:
            "Last name formatted incorrectly. Only letters, hyphens and numbers are allowed.",
        },
      },
    })
  ),
  CLASSROOM_CODE: (codes) =>
    new FormColumn(
      "classroomCode",
      "Classroom Code",
      FormColumnType.DROPDOWN,
      new FormColumnOptions({
        data: codes,
        sortingEnabled: true,
        validation: {
          required: {
            value: true,
            message: `Classroom code is required.`,
          },
          maxLength: {
            value: 50,
            message: "Classroom code must be less than 50 characters",
          },
          pattern: {
            value: /^[\d',.a-z-]+$/i,
            message: `Classroom code formatted incorrectly.`,
          },
        },
      })
    ),
  USERNAME: new FormColumn(
    "username",
    "Username",
    FormColumnType.INPUT,
    new FormColumnOptions({
      sortingEnabled: true,
      validation: {
        required: {
          value: true,
          message: `Username is required.`,
        },
        maxLength: {
          value: 50,
          message: "Username must be less than 50 characters",
        },
        pattern: {
          value: /^[\d',.@a-z-]+$/i,
          message:
            "Username formatted incorrectly. Only letters, hyphens, numbers, and '@' symbols are allowed.",
        },
      },
    })
  ),
  EMAIL: new FormColumn(
    "email",
    "Email",
    FormColumnType.INPUT,
    new FormColumnOptions({
      sortingEnabled: true,
      validation: {
        required: false,
        maxLength: {
          value: 50,
          message: "Email must be less than 50 characters",
        },
        pattern: {
          value:
            /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/,
          message: "Email formatted incorrectly.",
        },
      },
    })
  ),
  PASSWORD: new FormColumn(
    "password",
    "Password",
    FormColumnType.INPUT,
    new FormColumnOptions({
      validation: {
        required: {
          value: true,
          message: `Password is required.`,
        },
        maxLength: {
          value: 50,
          message: "Password must be less than 50 characters",
        },
        validate: {
          count: (v) =>
            v.length >= 4 || "Password must be at least 4 characters",
          hasLetter: (v) =>
            /[A-Za-z]/.test(v) || "Password must include a letter",
          whiteSpace: (v) =>
            /^\S*$/.test(v) || "White spaces are not allowed in password",
          hasOnlyAllowedSpecials: (v) =>
            !/[^\w!,.?@-]+/.test(v) ||
            "Password cannot include special characters other than @ ! ? , . - _",
        },
      },
      isSecured: true,
      hasInfo: "Password is required.",
    })
  ),
};

export const TEMPLATE_DOWNLOAD_INFO_TEXT =
  `Please download and fill out the trainee template. Once completed press Import and locate your file. Any ` +
  `trainees not currently in the system will be created. ` +
  `Larger uploads will continue to process in the background. We will send you an email when the file is finished.`;

export const cancelBodyTitle = "Cancel Roster Updates";

export const cancelBodyText =
  "Are you sure you would like to cancel and exit the roster? Any changes you have made will be lost. This action cannot be undone.";

export const convertToCSV = (objArray) => {
  const csv = Papa.unparse(objArray);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
  return blob;
};
