import React from "react";
import clsx from "clsx";

import { DataBox, DataBoxGroup } from "@transfr-inc/dashboard-components";

import simsStartedIcon from "../../../../../public/images/databox/sims-started.svg";
import simsMasteredIcon from "../../../../../public/images/databox/sims-mastered.svg";
import simsNeedsWorkIcon from "../../../../../public/images/databox/unique-trainees.svg";
import simsStuckIcon from "../../../../../public/images/databox/sims-stuck.svg";
import simsAllIcon from "../../../../../public/images/databox/sims-all.svg";

import { StatusFilter } from "../views/classroom.progress.ts.tab";

import "./classroom.databoxes.scss";

export function ClassroomSimulationsFilter({
  masteryStats,
  onStatusSelected,
  className,
  disabled,
}) {
  const {
    totalInProgress = 0,
    totalMastered = 0,
    totalWheelSpinning = 0,
    totalSims = 0,
  } = masteryStats || {};
  const totalStarted = totalInProgress + totalMastered + totalWheelSpinning;

  return (
    <div className={clsx("databoxes-section", className)}>
      <DataBoxGroup>
        <DataBox
          icon={simsStartedIcon}
          label="Started"
          value={totalStarted ?? ""}
          description="Sims that have been mastered, need work, or where the trainees are stuck."
          onClick={() => onStatusSelected(StatusFilter.Started)}
          disabled={disabled}
        ></DataBox>
        <DataBox
          icon={simsMasteredIcon}
          label="Mastered"
          value={totalMastered ?? ""}
          description="The trainees have completed the sim and received a score of 90% or higher."
          onClick={() => onStatusSelected(StatusFilter.Mastered)}
          disabled={disabled}
        ></DataBox>
        <DataBox
          icon={simsNeedsWorkIcon}
          label="Needs work"
          value={totalInProgress ?? ""}
          description="The trainees have started the sim but haven't achieved mastery yet."
          onClick={() => onStatusSelected(StatusFilter.NeedsWork)}
          disabled={disabled}
        ></DataBox>
        <DataBox
          icon={simsStuckIcon}
          label="Stuck"
          value={totalWheelSpinning ?? ""}
          description="The trainees have not made progress in improving their sim score."
          onClick={() => onStatusSelected(StatusFilter.Stuck)}
          disabled={disabled}
        ></DataBox>
        <DataBox
          icon={simsAllIcon}
          label="All"
          value={totalSims ?? ""}
          description="All sims regardless of status."
          onClick={() => onStatusSelected(StatusFilter.All)}
          disabled={disabled}
        ></DataBox>
      </DataBoxGroup>
    </div>
  );
}
