import React, { useState, useEffect } from "react";
import {
  Loader,
  SlashCircleIcon,
  Badge,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { SearchBox, useSearch } from "@transfr-inc/dashboard-components/forms";

import { useStoreState } from "easy-peasy";

import { ShortcutAction } from "../../../../components/shortcuts";
import { TraineeTrekTable } from "./trainee.trek.table";
import { useContainerQuery } from "@transfr-inc/dashboard-components/container-query";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { ExportStatus, doExportData } from "../../../../lib/export-utils";

import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";

import "./trainee.trek.careers.scss";

const query = {
  "small-panel": {
    minWidth: 320,
    maxWidth: 850,
  },
  "medium-panel": {
    minWidth: 700,
    maxWidth: 900,
  },
  "large-panel": {
    minWidth: 900,
  },
};

export function TraineeTrekCareers({ careers, trainee }) {
  const [params, containerRef] = useContainerQuery(query);
  const [exportStatus, setExportStatus] = useState();
  const { currentUser } = useStoreState((store) => store.account);
  const [filteredCareers, setFilteredCareers] = useState(careers);
  const [careerNames, setCareerNames] = useState();

  const onExportData = async () => {
    setExportStatus(ExportStatus.creating);
    try {
      const data = careers.map((row) => ({
        "Career Name": row.title,
        "Career Selected": row.selected,
        Cluster: row.clusterName,
        Date: row.viewedAt ? formatDateDigits(row.viewedAt) : undefined,
        Favorited: row.favorite,
        "Explored VR": row.exploredVr ?? "N/A",
      }));
      const fileName = `${trainee.firstName}_${trainee.lastName}  - Trek`;
      await doExportData(fileName, data);
      setExportStatus(ExportStatus.complete);
    } catch {
      setExportStatus(ExportStatus.error);
    }
  };

  const careerNamesSearch = useSearch({
    data: filteredCareers,
    searchKeys: ["title"],
  });

  const onCareerNameTermChange = (term) => {
    const results = careerNamesSearch.queryData(term);
    setFilteredCareers(results);
  };

  useEffect(() => {
    if (careers) {
      setFilteredCareers(careers);
      setCareerNames(careers.map((s) => s.title));
      careerNamesSearch.updateData(careers);
    }
  }, [careers]);

  return (
    <div className="careers-explored-container">
      <div className="careers-explored-section">
        <div className="careers-explored-header">
          <div className="left-section">
            Career Explored{" "}
            <Badge className={"badge"} value={filteredCareers?.length} />
          </div>
          <div className="right-section">
            <SearchBox
              className="career-search"
              placeholder="Search Careers"
              data={careerNames}
              disabled={!filteredCareers}
              onSearchTermChange={onCareerNameTermChange}
              onResultSelected={onCareerNameTermChange}
            ></SearchBox>
            <Button
              size="small"
              icon={["fa-light", "file-export"]}
              onClick={onExportData}
              disabled={
                !careers?.length || exportStatus === ExportStatus.creating
              }
            >
              Export Data
            </Button>
          </div>
          <div className="notification-loader-container">
            {exportStatus === ExportStatus.creating && (
              <Notification
                type={NotificationType.info}
                icon={["fa-solid", "spinner"]}
                iconConfig={{ spin: true }}
              >
                We’re preparing a download of{" "}
                <strong>{`${trainee.firstName}'s`}</strong> Trek insights.
                Please wait...
              </Notification>
            )}
            {exportStatus === ExportStatus.error && (
              <Notification
                type={NotificationType.error}
                closable
                onClose={() => setExportStatus()}
              >
                There was an error generating the export. Please try again.
              </Notification>
            )}
          </div>
        </div>
        {filteredCareers?.length === 0 && (
          <ShortcutAction className="no-data-message" disabled>
            <SlashCircleIcon></SlashCircleIcon>
            No data to display. Please try adjusting your search or filters.
          </ShortcutAction>
        )}
        {!filteredCareers && (
          <div>
            <Loader height={280}></Loader>
          </div>
        )}
        {filteredCareers?.length > 0 && (
          <div ref={containerRef}>
            <TraineeTrekTable
              careers={filteredCareers}
              user={currentUser}
              responsiveStyles={params}
            ></TraineeTrekTable>
          </div>
        )}
      </div>
    </div>
  );
}
