import React from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { HyphenIcon, TextTooltip } from "@transfr-inc/dashboard-components";
import { truncateNumber } from "@transfr-inc/dashboard-components/utils";

import { MasteryStatus, getStatusIcon } from "../../models";

import "./mastery.cell.scss";

const getStatusConfig = (status) => {
  const config = {};
  switch (status) {
    case MasteryStatus.Mastered:
      config.classname = "mastered";
      break;
    case MasteryStatus.Stuck:
      config.classname = "stuck";
      break;
    case MasteryStatus.NeedsWork:
      config.classname = "needs-work";
      break;
  }
  config.icon = config.classname ? getStatusIcon(status) : undefined;
  return config;
};

export default ({ status, score }) => {
  const { classname, icon } = getStatusConfig(status);

  return (
    <div className={clsx("mastery-cell", classname)}>
      {status !== MasteryStatus.NotStarted && status ? (
        <>
          <TextTooltip
            text={status}
            className="mastery-value"
            distance={12}
            theme={`gb-${classname}`}
          >
            <FontAwesomeIcon icon={icon} />
            <span>{truncateNumber(score)}%</span>
          </TextTooltip>
        </>
      ) : (
        <HyphenIcon />
      )}
    </div>
  );
};
