import React, { useState } from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { CareerIcon } from "../../../../components/career-icon";
import { CareerLeaderboardModal } from "../../../../components/modals";

import "./classroom.ce-top-careers.scss";

const MaxCareersDisplay = 3;

export function ClassroomCeTopCareers({
  topCareers,
  careers,
  insights,
  classroom,
}) {
  const [openLeaderboard, setOpenLeaderboard] = useState();
  const [selectedCareer, setSelectedCareer] = useState();

  const getExplorers = (career) => {
    const careerFound = careers.find(
      (c) => c.learningExperienceId === career.learningExperienceId
    );
    return careerFound?.explorers;
  };

  const openLeaderboardModal = (career) => {
    setSelectedCareer(career);
    setOpenLeaderboard(true);
  };

  const onCloseLeaderboardModal = () => {
    setSelectedCareer();
    setOpenLeaderboard();
  };

  const onlyHighlyRated = topCareers?.filter((x) => x.totalHighlyRated > 0);

  return (
    onlyHighlyRated &&
    onlyHighlyRated.length > 0 && (
      <>
        <div className="classroom-top-careers-container">
          <h2>Top Careers Rated 4-5 Stars</h2>
          <div className="top-careers">
            {topCareers?.slice(0, MaxCareersDisplay).map((c, index) => (
              <CareerIcon
                key={`${c.code}-${index}`}
                name={c.occupationName}
                clusterCode={c.clusterCode}
                badge={c.totalHighlyRated}
                onClick={() => openLeaderboardModal(c)}
              />
            ))}
            {topCareers?.length > MaxCareersDisplay && (
              <div className="view-all-button">
                <Button
                  onClick={() => openLeaderboardModal()}
                  title={"View all hight rated careers"}
                >
                  +{topCareers.length - MaxCareersDisplay}
                </Button>
                <h3>View All</h3>
              </div>
            )}
          </div>
        </div>
        <CareerLeaderboardModal
          open={openLeaderboard}
          onCancel={onCloseLeaderboardModal}
          topCareers={topCareers}
          customExplorersFn={getExplorers}
          activeExplorers={insights?.totalActiveExplorers}
          classroom={classroom}
          breadcrumbLabel={classroom?.name}
          career={selectedCareer}
        />
      </>
    )
  );
}
