import container from "../container";
import {
  formatDateTwoDigits,
  formatTimestap,
} from "@transfr-inc/dashboard-components/utils";
import { Product, Role } from "../models";

export function getOrganizationStats(
  userId,
  role,
  orgCode,
  filters,
  getMultiOrg = false
) {
  const { organizationService } = container;

  const getOrganizationStatsRequest = () => {
    if (role === Role.INSTRUCTOR.id) {
      return organizationService.getInstructorStats(userId, filters);
    } else if (
      (role === Role.MULTI_ORG_ADMIN.id || role === Role.MULTI_ORG_VIEWER.id) &&
      getMultiOrg
    ) {
      return organizationService.getMultiOrganizationStats(orgCode, filters);
    } else {
      return organizationService.getOrganizationStats(orgCode, filters);
    }
  };

  return getOrganizationStatsRequest();
}

export function getOrganizationDailyStats(
  userId,
  role,
  orgCode,
  filters,
  getMultiOrg = false
) {
  const { organizationService } = container;

  const getOrganizationDailyStatsRequest = () => {
    if (role === Role.INSTRUCTOR.id) {
      return organizationService.getInstructorDailyStats(userId, filters);
    } else if (
      (role === Role.MULTI_ORG_ADMIN.id || role === Role.MULTI_ORG_VIEWER.id) &&
      getMultiOrg
    ) {
      return organizationService.getMultiOrganizationDailyStats(
        orgCode,
        filters
      );
    } else {
      return organizationService.getOrganizationDailyStats(orgCode, filters);
    }
  };

  return getOrganizationDailyStatsRequest();
}

export function getCeOrganizationStats(
  userId,
  role,
  orgCode,
  filters,
  getMultiOrg = false
) {
  const { organizationService } = container;
  const filtersWithProduct = { productId: Product.CE, ...filters };

  const getCeOrganizationStatsRequest = () => {
    if (role === Role.INSTRUCTOR.id) {
      return organizationService.getInstructorCeStats(
        userId,
        filtersWithProduct
      );
    } else if (
      (role === Role.MULTI_ORG_ADMIN.id || role === Role.MULTI_ORG_VIEWER.id) &&
      getMultiOrg
    ) {
      return organizationService.getMultiOrganizationCeStats(
        orgCode,
        filtersWithProduct
      );
    } else {
      return organizationService.getOrganizationCeStats(
        orgCode,
        filtersWithProduct
      );
    }
  };

  return getCeOrganizationStatsRequest();
}

export function getCeOrganizationTopCareers(
  userId,
  role,
  orgCode,
  filters,
  getMultiOrg = false
) {
  const { organizationService } = container;
  const getCeOrganizationTopCareersRequest = () => {
    if (role === Role.INSTRUCTOR.id) {
      return organizationService.getInstructorTopCareers(userId, filters);
    } else if (
      (role === Role.MULTI_ORG_ADMIN.id || role === Role.MULTI_ORG_VIEWER.id) &&
      getMultiOrg
    ) {
      return organizationService.getMultiOrganizationTopCareers(
        orgCode,
        filters
      );
    } else {
      return organizationService.getOrganizationTopCareers(orgCode, filters);
    }
  };
  return getCeOrganizationTopCareersRequest();
}

export function getCeOrganizationDailyStats(
  userId,
  role,
  orgCode,
  filters,
  getMultiOrg = false
) {
  const { organizationService } = container;
  const filtersWithProduct = { productId: Product.CE, ...filters };

  const getCeOrganizationDailyStatsRequest = () => {
    if (role === Role.INSTRUCTOR.id) {
      return organizationService.getInstructorDailyCeStats(
        userId,
        filtersWithProduct
      );
    } else if (
      (role === Role.MULTI_ORG_ADMIN.id || role === Role.MULTI_ORG_VIEWER.id) &&
      getMultiOrg
    ) {
      return organizationService.getMultiOrganizationDailyCeStats(
        orgCode,
        filtersWithProduct
      );
    } else {
      return organizationService.getOrganizationDailyCeStats(
        orgCode,
        filtersWithProduct
      );
    }
  };

  return getCeOrganizationDailyStatsRequest();
}
export function exportOrganizationStats(
  role,
  code,
  filters,
  fileName,
  getMultiOrg = false
) {
  const { organizationService } = container;

  const getExportOrganizationStatsRequest = () => {
    if (role === Role.INSTRUCTOR.id) {
      return organizationService.exportInstructorStats(code, filters, fileName);
    } else if (
      (role === Role.MULTI_ORG_ADMIN.id || role === Role.MULTI_ORG_VIEWER.id) &&
      getMultiOrg
    ) {
      return organizationService.exportMultiOrganizationStats(
        code,
        filters,
        fileName
      );
    } else {
      return organizationService.exportOrganizationStats(
        code,
        filters,
        fileName
      );
    }
  };

  return getExportOrganizationStatsRequest();
}

export function exportOrganizationCeStats(
  role,
  orgCodeOrUserId,
  filters,
  fileName,
  getMultiOrg = false,
  options
) {
  const { organizationService } = container;
  const filtersWithProduct = { productId: Product.CE, ...filters };

  const getExportOrganizationCeStatsRequest = () => {
    if (role === Role.INSTRUCTOR.id) {
      return organizationService.exportInstructorCeStats(
        orgCodeOrUserId,
        filtersWithProduct,
        fileName,
        options
      );
    } else if (
      (role === Role.MULTI_ORG_ADMIN.id || role === Role.MULTI_ORG_VIEWER.id) &&
      getMultiOrg
    ) {
      return organizationService.exportMultiOrganizationCeStats(
        orgCodeOrUserId,
        filtersWithProduct,
        fileName,
        options
      );
    } else {
      return organizationService.exportOrganizationCeStats(
        orgCodeOrUserId,
        filtersWithProduct,
        fileName,
        options
      );
    }
  };
  return getExportOrganizationCeStatsRequest();
}

export function getStatsFileName(orgName, dateRange) {
  const { startDate, endDate } = dateRange;
  const startDateFormatted = formatDateTwoDigits(startDate.split("-"));
  const endDateFormatted = formatDateTwoDigits(endDate.split("-"));

  return `${orgName} Insights [${startDateFormatted} - ${endDateFormatted}] - ${formatTimestap()}`;
}
