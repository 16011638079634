import React, { useState } from "react";
import clsx from "clsx";
import { useStoreState } from "easy-peasy";
import { formatDateLocaleMonth } from "@transfr-inc/dashboard-components/utils";

import { DashboardHeader, OrgsShortcuts, UploadFooter } from "./components";

import { ProductTabs } from "./views/multi-org/product.tabs";
import { RosterModal } from "../users/roster/roster-modal";

import "./dashboard.scss";

export default ({ className, responsiveStyles = {} }) => {
  const { currentUser, products, features } = useStoreState(
    (store) => store.account
  );
  const { rosteringEnabled } = useStoreState((store) => store.app);
  const { firstName, organizationName } = currentUser;
  const [openRosterModal, setOpenRosterModal] = useState();
  const { canCreateUser } = features;

  return (
    <div className={clsx("dashboard", className)}>
      <div className="dashboard-container">
        <DashboardHeader
          title="Dashboard"
          subtitle={
            <div>
              Hi <strong>{firstName}</strong>! Here&apos;s your{" "}
              <strong>{organizationName}</strong> update for{" "}
              {formatDateLocaleMonth()}.
            </div>
          }
        ></DashboardHeader>
        <ProductTabs products={products}></ProductTabs>
        {canCreateUser && (
          <UploadFooter
            setOpenRosterModal={setOpenRosterModal}
            rosteringEnabled={rosteringEnabled}
          />
        )}
      </div>
      <div className="side-shortcuts">
        <OrgsShortcuts></OrgsShortcuts>
      </div>
      {rosteringEnabled && (
        <RosterModal
          onClose={() => setOpenRosterModal(false)}
          show={openRosterModal}
          responsiveStyles={responsiveStyles}
        ></RosterModal>
      )}
    </div>
  );
};
