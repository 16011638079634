import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  NotificationType,
  NotificationStack,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import container from "../../container";

import "./delete-modal.scss";

export const BulkDeleteClassroomModal = ({
  onCancel,
  show,
  className,
  deleteList,
  onRowRemoved,
}) => {
  const [notifications, setNotifications] = useState();
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const { classroomService } = container;

  const onCloseNotification = (index) => {
    setNotifications(notifications.filter((n, i) => i !== index));
  };

  const showNotification = (type, message, icon) => {
    const notification = { type, message, icon };
    setNotifications((n) => {
      const notificationsUpdated = n ?? [];
      return [...notificationsUpdated, notification];
    });
  };

  const { handleSubmit, formState, reset } = useForm({
    defaultValues: { ...deleteList },
  });

  const getContent = (deleteList) => {
    return deleteList.length == 1 ? (
      <div className="delete-content">
        Are you sure you would like to delete <b>{deleteList[0].name}</b>? This
        action cannot be undone. A classroom can not be deleted while trainees
        are enrolled.
      </div>
    ) : (
      <div className="delete-content">
        Are you sure you would like to delete
        <b> {deleteList.length} </b>
        classrooms? This action cannot be undone. A classroom can not be deleted
        while trainees are enrolled.
      </div>
    );
  };

  async function submitForm(values, e) {
    try {
      // Not allowing user to delete classroom with students
      const [students, noStudents] = values.reduce(
        ([p, f], e) => (e.totalTrainees > 0 ? [[...p, e], f] : [p, [...f, e]]),
        [[], []]
      );

      if (students.length > 0) {
        showNotification(
          NotificationType.error,
          students.length > 1 ? (
            <div>
              <b>{students.length} </b> classrooms with trainees enrolled could
              not be deleted.
            </div>
          ) : (
            <div>
              <b>{students[0].name} </b> cannot be deleted while there are
              trainees enrolled.
            </div>
          )
        );
      }

      if (noStudents.length > 0) {
        const classroomIds = noStudents.map((x) => x["classroomId"]);
        await classroomService.deleteClassrooms({
          classroomIds: classroomIds,
        });
        onRowRemoved(classroomIds);
        showNotification(
          NotificationType.success,
          noStudents.length > 1 ? (
            <div>
              <b>{noStudents.length} </b> classrooms have been deleted.
            </div>
          ) : (
            <div>
              <b>{noStudents[0].name} </b> has been deleted.
            </div>
          )
        );
      }
    } catch (error) {
      console.log(error);
      console.trace();
      showNotification(NotificationType.error, "Something went wrong");
    } finally {
      onCancel();
      setIsAlertVisible(true);
      setTimeout(() => {
        setIsAlertVisible(false);
        setNotifications();
      }, 10000);
    }
  }

  const onClick = (values, e) => {
    handleSubmit(submitForm)(e);
  };

  useEffect(() => {
    reset(deleteList);
  }, [deleteList]);

  return (
    <div className={"delete-modal-container"}>
      <Modal modalClassName={"delete-modal"} open={show} onClose={onCancel}>
        <ModalHeader
          className="red-icon"
          icon={["fa-regular", "fa-trash-can"]}
          title={
            deleteList.length > 1 ? "Delete Classrooms" : "Delete Classroom"
          }
        ></ModalHeader>
        <ModalBody className="delete-body">{getContent(deleteList)}</ModalBody>
        <ModalFooter>
          <Button onClick={onCancel}>Cancel</Button>
          <Button destructive onClick={onClick}>
            {deleteList.length > 1
              ? `Yes, Delete Classrooms`
              : `Yes, Delete Classroom`}
          </Button>
        </ModalFooter>
      </Modal>
      <div className="success-notification">
        {isAlertVisible && notifications?.length > 0 && (
          <NotificationStack
            notifications={notifications}
            onClose={onCloseNotification}
            closable
          ></NotificationStack>
        )}
      </div>
    </div>
  );
};
