import React, { useState } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Input, ToggleButton } from "@transfr-inc/dashboard-components/forms";
import { TextTooltip } from "@transfr-inc/dashboard-components";

import { NotificationStack } from "@transfr-inc/dashboard-components";

import { InstructorsEditor } from "./instructors-editor";
import { TraineesEditor } from "./trainees-editor";
import { getUserLabelsByProduct } from "../details/utils/labels";
import { Product } from "../../../models";

export function ClassroomDetailsEditor({
  instructorData,
  onCreateInstructor,
  traineeData,
  onCreateTrainee,
  instructorCreationEnabled = true,
  className,
  onAddTrainees,
  bulkUploadEnabled = false,
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [notifications, setNotifications] = useState();

  const { id: selectedProductId } = useWatch({ name: "product" });
  const { userTabTitle, singular, plural } =
    getUserLabelsByProduct(selectedProductId);

  const onAddTraineeHandler = ({ trainees, success, error }) => {
    if (success) {
      const { type, message, icon } = success;
      showNotification(type, message, icon);
    }

    if (error) {
      const { type, message, icon } = error;
      showNotification(type, message, icon);
    }

    return onAddTrainees(trainees);
  };

  const showNotification = (type, message, icon) => {
    const notification = { type, message, icon };
    setNotifications((n) => {
      const notificationsUpdated = n ?? [];
      return [...notificationsUpdated, notification];
    });
  };

  const onCloseNotification = (index) => {
    setNotifications(notifications.filter((n, i) => i !== index));
  };

  return (
    <div className={className}>
      <Controller
        control={control}
        name="name"
        rules={{ required: true, maxLength: 50 }}
        render={({ field: { onBlur, onChange, value, ref } }) => {
          return (
            <Input
              className="name-input"
              label="Name"
              type="text"
              name="name"
              errors={errors?.name}
              ref={ref}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              maxLength={50}
              required
            />
          );
        }}
      />
      <div className="section-title">Instructor</div>
      <InstructorsEditor
        instructorData={instructorData}
        onCreateInstructor={onCreateInstructor}
        instructorCreationEnabled={instructorCreationEnabled}
      />

      <div className="section-title notification-stack-section">
        {userTabTitle}
      </div>
      {notifications?.length > 0 && (
        <NotificationStack
          notifications={notifications}
          onClose={onCloseNotification}
          closable
        ></NotificationStack>
      )}
      <TraineesEditor
        traineeData={traineeData}
        onCreateTrainee={onCreateTrainee}
        onAddTrainees={onAddTraineeHandler}
        userLabels={{ singular, plural }}
        classroomProduct={selectedProductId}
        bulkUploadEnabled={bulkUploadEnabled}
      />
      {selectedProductId === Product.CE && (
        <Controller
          control={control}
          name="isDemo"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="is-demo-input">
                <h4>Demo</h4>
                <ToggleButton
                  onChange={onChange}
                  value={value}
                  affirmText={"YES"}
                  negateText={"NO"}
                />
                <div>Is this classroom for demo purposes?</div>
                <TextTooltip
                  text={"Data for demo classrooms will not be stored"}
                >
                  <FontAwesomeIcon icon="fa-light fa-circle-info" />
                </TextTooltip>
              </div>
            );
          }}
        />
      )}
    </div>
  );
}
