import React, { useEffect, useState } from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";
import { Loader } from "@transfr-inc/dashboard-components";

import { CareerIcon } from "../../../components/career-icon";
import { CareerLeaderboardModal } from "../../../components/modals";
import { setCareerInfo, sortByNameFn } from "../../../services";

import "./ce.top-careers.scss";

const MaxCareersDisplay = 3;

export function CeTopCareers({
  activeExplorers,
  topCareers: data,
  breadcrumbLabel,
  customExplorersFn,
  classroom,
}) {
  const [selectedCareer, setSelectedCareer] = useState();
  const [topCareers, setTopCareers] = useState();
  const [openLeaderboard, setOpenLeaderboard] = useState();

  const openLeaderboardModal = (career) => {
    setSelectedCareer(career);
    setOpenLeaderboard(true);
  };

  const onCloseLeaderboardModal = () => {
    setSelectedCareer();
    setOpenLeaderboard();
  };

  useEffect(() => {
    setCareerInfo(data)
      .then((reponse) => reponse.sort(sortByNameFn))
      .then((response) => response.filter((c) => c.totalHighlyRated > 0))
      .then(setTopCareers);
  }, [data]);

  const viewAllElement = (
    <div className="view-all-button">
      <Button
        onClick={() => openLeaderboardModal()}
        title={"View all high rated careers"}
      >
        +{topCareers?.length - MaxCareersDisplay}
      </Button>
      <h3>View All</h3>
    </div>
  );

  return (
    topCareers &&
    topCareers[0]?.totalHighlyRated > 0 && (
      <>
        <div className="organization-top-careers-container">
          <h2>Top Careers Rated 4-5 Stars</h2>
          <div className="top-careers">
            {topCareers ? (
              <>
                {topCareers.slice(0, MaxCareersDisplay).map((c, index) => (
                  <CareerIcon
                    key={`${c.code}-${index}`}
                    name={c.occupationName}
                    clusterCode={c.clusterCode}
                    badge={c.totalHighlyRated}
                    onClick={() => openLeaderboardModal(c)}
                  />
                ))}
                {topCareers?.length > MaxCareersDisplay && viewAllElement}
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
        <CareerLeaderboardModal
          open={openLeaderboard}
          onCancel={onCloseLeaderboardModal}
          topCareers={topCareers}
          activeExplorers={activeExplorers}
          breadcrumbLabel={breadcrumbLabel}
          customExplorersAsyncFn={customExplorersFn}
          classroom={classroom}
          career={selectedCareer}
        />
      </>
    )
  );
}
