import React from "react";
import clsx from "clsx";
import { TextTooltip } from "@transfr-inc/dashboard-components";
import "./integration-date.scss";

const IntegrationDate = ({ date, error = false }) => {
  return (
    <div className={clsx("integration-date", error && "error")}>
      <TextTooltip text="UTC">{mmddyyyyFormat(date)}</TextTooltip>
    </div>
  );
};

const mmddyyyyFormat = (t = new Date()) => {
  t = t instanceof Date ? t : new Date(t);
  return t.toLocaleDateString("en-US", {
    month: "2-digit",
    year: "numeric",
    day: "2-digit",
    timeZone: "UTC",
  });
};

export default IntegrationDate;
