import React, { useEffect } from "react";

import { DataBoxGroup } from "@transfr-inc/dashboard-components";

import databoxTraineesIcon from "../../../../public/images/databox/unique-trainees.svg";
import databoxMasteredIcon from "../../../../public/images/databox/sims-mastered.svg";
import databoxCompletedIcon from "../../../../public/images/databox/sims-completed.svg";

const ChartFilter = {
  ActiveTrainee: {
    key: "uniqueTraineeCount",
    label: "Active Trainees",
  },
  SimsMastered: {
    key: "simsMastered",
    label: "Sims Mastered",
  },
  SimsCompleted: {
    key: "simsCompleted",
    label: "Sims Completed",
  },
};

export function DashboardDataboxes({ data, onCategoryChange }) {
  const dataBoxConfig = [
    {
      label: ChartFilter.ActiveTrainee.label,
      key: ChartFilter.ActiveTrainee.key,
      alt: "Icon for a user avatar wearing a hardhat.",
      description: "Trainees who have started at least one sim during the time period.",
      icon: databoxTraineesIcon,
      className: "data-box",
      onClick: () => onCategoryChange(ChartFilter.ActiveTrainee),
    },
    {
      label: ChartFilter.SimsCompleted.label,
      key: ChartFilter.SimsCompleted.key,
      alt: "Icon for a checkmark within a circle.",
      description: "Sims taken where trainees have reached the end.",
      icon: databoxCompletedIcon,
      className: "data-box",
      onClick: () => onCategoryChange(ChartFilter.SimsCompleted),
    },
    {
      label: ChartFilter.SimsMastered.label,
      key: ChartFilter.SimsMastered.key,
      alt: "Icon for a medal.",
      description: "Sims completed with scores of 90% or higher.",
      icon: databoxMasteredIcon,
      className: "data-box",
      onClick: () => onCategoryChange(ChartFilter.SimsMastered),
    },
  ];

  useEffect(() => {
    dataBoxConfig[0].onClick();
  }, []);

  return (
    <DataBoxGroup data={data} dataBoxConfig={dataBoxConfig} className="rows" />
  );
}
