import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { useUnloadWarning } from "@transfr-inc/dashboard-components/hooks";

import { Product, Role } from "../../../../models";
import { CeInsights, DashboardBanner } from "../../components";

import {
  exportOrganizationCeStats,
  getCareerRatings,
  getCeOrganizationDailyStats,
  getCeOrganizationStats,
  getCeOrganizationTopCareers,
  getStatsFileName,
} from "../../../../services";

import container from "../../../../container";
import { useApiRequest } from "../../../../lib/http-client";

import "./ce.tab.scss";

export const CeTab = () => {
  const { state: locationState } = useLocation();
  const [topCareers, setTopCareers] = useState();
  const [orgStats, setOrgStats] = useState({ isLoading: true });
  const [dailyOrgStats, setOrgDailyStats] = useState({ isLoading: true });
  const [dateRange, setDateRange] = useState({});
  const [classroomSelected, setClassroomSelected] = useState();
  const [orgName, setOrgName] = useState();
  const { currentUser, role } = useStoreState((store) => store.account);
  const { students } = useStoreState((store) => store.organization);
  const { setDashboardLoaded } = useStoreActions((store) => store.app);
  const { organizationCode, userId, organizationName } = currentUser;
  const { organization } = locationState ?? {};
  const { classroomService } = container;
  const explorersMap = new Map(students?.map((s) => [s.userId, s]));

  const getOrgCode = () => organization?.code ?? organizationCode;

  const { loading: downloadingFile, sendRequest: exportInsights } =
    useApiRequest(() => {
      const filters = { dateRange };
      const fileName = getStatsFileName(orgName, dateRange);
      const code = role === Role.INSTRUCTOR.id ? userId : getOrgCode();

      return classroomSelected
        ? classroomService.exportClassroomCeStats(
            classroomSelected.classroomId,
            filters,
            fileName
          )
        : exportOrganizationCeStats(role, code, filters, fileName, false);
    }, false);

  useUnloadWarning({ trigger: downloadingFile });

  const getCeOrganizationSelectedStats = (orgCode, filters) => {
    Promise.all([
      getCeOrganizationDailyStats(userId, role, orgCode, filters).then(
        (response) => setOrgDailyStats({ isLoading: false, response })
      ),
      getCeOrganizationStats(userId, role, orgCode, filters).then((response) =>
        setOrgStats({ isLoading: false, response })
      ),
      getCeOrganizationTopCareers(userId, role, orgCode, filters).then(
        setTopCareers
      ),
    ]).catch(() => {
      setOrgDailyStats({ isLoading: false });
      setOrgStats({ isLoading: false });
    });
  };

  const getExplorersRatings = async (career) => {
    const ratings = await getCareerRatings({
      orgCode: getOrgCode(),
      userId,
      role,
      classroomId: classroomSelected?.classroomId,
      learningExperienceId: career.learningExperienceId,
      dateRange,
    });

    return ratings.map((r) => {
      const { userId, rating } = r;
      const user = explorersMap.get(userId);
      return { ...user, rating };
    });
  };

  const getCeClassroomStats = (classroomId, filters) => {
    const filtersWithProduct = { productId: Product.CE, ...filters };

    Promise.all([
      classroomService
        .getCeClassroomStats(classroomId, filtersWithProduct)
        .then((response) => setOrgStats({ isLoading: false, response })),
      classroomService
        .getCeClassroomDailyStats(classroomId, filtersWithProduct)
        .then((response) => setOrgDailyStats({ isLoading: false, response })),
      classroomService
        .getClassroomTopCareers(classroomId, filters)
        .then(setTopCareers),
    ]).catch(() => {
      setOrgDailyStats({ isLoading: false });
      setOrgStats({ isLoading: false });
    });
  };

  const onApplyFilters = ({ dateRange, classroom }) => {
    const { startDate, endDate } = dateRange || {};
    clearOrgStats();
    setDateRange({ startDate, endDate });
    setClassroomSelected(classroom);
  };

  const clearOrgStats = () => {
    setOrgDailyStats({ isLoading: true });
    setOrgStats({ isLoading: true });
  };

  useEffect(() => {
    const name = organization?.name ?? organizationName;
    setOrgName(name);
  }, []);

  useEffect(() => {
    const { startDate, endDate } = dateRange;
    const filters = { dateRange };
    if (startDate && endDate) {
      if (classroomSelected) {
        getCeClassroomStats(classroomSelected.classroomId, filters);
      } else {
        getCeOrganizationSelectedStats(getOrgCode(), filters);
      }
    }
  }, [dateRange, classroomSelected]);

  useEffect(() => {
    if (!orgStats.isLoading && !dailyOrgStats.isLoading) {
      setDashboardLoaded();
    }
  }, [orgStats, dailyOrgStats]);

  const onExportStatsData = () => {
    const { startDate, endDate } = dateRange;
    if (startDate && endDate) {
      exportInsights();
    }
  };

  return (
    <div className="ce-single-tab">
      <DashboardBanner product={Product.CE} />
      <CeInsights
        userInfo={{ userId, role }}
        orgCode={getOrgCode()}
        classroomFilterEnabled
        generalStats={orgStats?.response}
        dailyStats={dailyOrgStats}
        onApplyFilters={onApplyFilters}
        topCareers={topCareers}
        onExportData={onExportStatsData}
        exportDataDisabled={downloadingFile}
        customExplorersFn={getExplorersRatings}
        classroom={classroomSelected}
      ></CeInsights>
      <div className="notification-loader-container">
        {downloadingFile && (
          <Notification
            type={NotificationType.info}
            closable
            icon={["fa-solid", "spinner"]}
            iconConfig={{ spin: true }}
          >
            We’re preparing a download of{" "}
            <strong>{`${
              classroomSelected ? classroomSelected.name : orgName
            }'s`}</strong>{" "}
            insights. Please wait...
          </Notification>
        )}
      </div>
    </div>
  );
};
