import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  Loader,
} from "@transfr-inc/dashboard-components";

import {
  Button,
  SearchBox,
  useSearch,
} from "@transfr-inc/dashboard-components/forms";

import { ModuleCheckboxAccordion } from "../../components/module-checkbox.accordion";
import { ListItemEdition } from "../..";

import "./add-modules.modal.scss";

export const AddModulesModal = ({
  open,
  onClose,
  onApplyChanges,
  async,
  className,
  modules,
  categoryModules,
  checkVersionNumber,
}) => {
  const [moduleItems, setModuleItems] = useState([]);
  const [loading, setLoading] = useState();
  const [allModuleItems, setAllModuleItems] = useState([]);

  const moduleCount = useMemo(
    () => allModuleItems?.filter((m) => m.isSelected).length,
    [allModuleItems]
  );

  const moduleNamesSearch = useSearch({
    data: moduleItems,
    searchKeys: ["displayName"],
  });

  const onApply = async () => {
    setLoading(true);
    const isVersionNumberValid = await checkVersionNumber();
    if (!isVersionNumberValid) {
      setLoading();
      handleClose();
      return;
    }
    setLoading();
    if (async) {
      setLoading(true);
      await onApplyChanges?.(allModuleItems);
      setLoading();
    } else {
      onApplyChanges?.(allModuleItems);
    }
  };

  const handleClose = () => {
    setModuleItems([]);
    setAllModuleItems([]);
    moduleNamesSearch.reset();
    onClose();
  };

  const onModuleSelected = (module, index) => {
    const selectedModules = moduleItems.map((m, i) =>
      i === index ? { ...m, isSelected: !m.isSelected } : m
    );
    const selectedIndex = allModuleItems.findIndex(
      (m) => m.moduleId == module.moduleId
    );
    let newAllModules = [...allModuleItems];
    newAllModules[selectedIndex] = {
      ...module,
      isSelected: !module.isSelected,
    };
    setAllModuleItems(newAllModules);
    setModuleItems(selectedModules);
  };

  const onModuleNameTermChange = (term) => {
    const results = moduleNamesSearch.queryData(term);
    setModuleItems(results);
  };

  const onModuleNameSelected = (module) => {
    setModuleItems([module]);
  };

  const getIsSelected = (module) => {
    if (module) {
      const selectedModule = allModuleItems.find(
        (m) => m.moduleId == module.moduleId
      );
      return selectedModule?.isSelected;
    }
    return false;
  };

  useEffect(() => {
    const selectedModules = categoryModules?.map((m) => m.moduleId) ?? [];
    const mappedModuleItems = modules.map((m) => {
      return selectedModules.includes(m.moduleId)
        ? { ...m, isSelected: true }
        : { ...m, isSelected: false };
    });
    setModuleItems(mappedModuleItems);
    setAllModuleItems(mappedModuleItems);
    moduleNamesSearch.updateData(mappedModuleItems);
  }, [open, categoryModules, modules]);

  return (
    <Modal
      modalClassName={clsx("add-modules-modal", className)}
      open={open}
      onClose={handleClose}
      preventClose={true}
    >
      {loading && <Loader overlay />}
      <ModalHeader icon={["fa-regular", "layer-plus"]} label={"New Category"}>
        <div className="modal-title">
          <h2>Add Module</h2>
        </div>
      </ModalHeader>

      <ModalBody>
        <SearchBox
          placeholder="Search by module name..."
          onSearchTermChange={onModuleNameTermChange}
          onResultSelected={onModuleNameSelected}
          query={moduleNamesSearch.queryData}
          customOptionLabel={(r) => `${r.displayName}`}
          disabled={!open}
        ></SearchBox>
        <div className="module-list">
          {moduleItems?.map((module, index) => {
            let isSelected = getIsSelected(module);
            return (
              <ListItemEdition
                key={`${module.moduleId}.${index}`}
                isChecked={isSelected}
                onChecked={() => onModuleSelected(module, index)}
              >
                <ModuleCheckboxAccordion
                  className={clsx(isSelected && "selected")}
                  module={module}
                ></ModuleCheckboxAccordion>
              </ListItemEdition>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          className="btn-add-module"
          primary
          disabled={moduleCount === 0}
          onClick={onApply}
        >
          <span>Add</span>
          <Badge className="btn-badge-counter" value={moduleCount} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
