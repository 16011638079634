import React from "react";
import { Component } from "react";

import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";

import "./error-boundary.scss";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Notification
          type={NotificationType.error}
          className="error-boundary-message"
        >
          An error has occurred. Please try reloading the page. If this issue
          persists please contact customer support.
        </Notification>
      );
    }

    return this.props.children;
  }
}
