import { StatusFilter } from "../views/users/trainee-detail/components/trainee.classroom.ts.tab";

export const SimulationStatus = {
  Started: "Started",
  Mastered: "Mastered",
  NeedsWork: "Needs Work",
  Stuck: "Stuck",
  All: "View All",
};

export const SimulationStatusMap = new Map();
SimulationStatusMap.set("STARTED", {
  key: "Started",
  statusFilter: StatusFilter.Started,
  label: SimulationStatus.Started,
  description:
    "Sims that have been mastered, need work, or where the trainee is stuck.",
  icon: "fa-person-running",
});
SimulationStatusMap.set("MASTERED", {
  key: "Mastered",
  statusFilter: StatusFilter.Mastered,
  label: SimulationStatus.Mastered,
  description:
    "The trainee has completed the sim and received a score of 90% or higher.",
  icon: "fa-medal",
});
SimulationStatusMap.set("NEEDS WORK", {
  key: "NeedsWork",
  statusFilter: StatusFilter.NeedsWork,
  label: SimulationStatus.NeedsWork,
  description:
    "The trainee has started the sim but hasn't achieved mastery yet.",
  icon: "fa-user-hard-hat",
});
SimulationStatusMap.set("STUCK", {
  key: "Stuck",
  statusFilter: StatusFilter.Stuck,
  label: SimulationStatus.Stuck,
  description:
    "The trainee has not made progress in improving their sim score.",
  icon: "fa-hand",
});
SimulationStatusMap.set("ALL", {
  key: "All",
  statusFilter: StatusFilter.All,
  label: SimulationStatus.All,
  description: "All sims regardless of status.",
  icon: "fa-list",
});

export function getSimulationStatus(status) {
  return SimulationStatusMap.get(status.toUpperCase());
}

export function getSimulationStatuses() {
  return [...SimulationStatusMap.values()];
}

export const AllSimulationStatuses = Object.values(SimulationStatus);
