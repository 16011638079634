import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useStoreState } from "easy-peasy";

import { Breadcrumb, Link } from "@transfr-inc/dashboard-components";
import { PageHeader } from "@transfr-inc/dashboard-components/layouts";
import { Product } from "../../../../components/products/product";
import { DemoBadge } from "../../../../components/custom/content";

import {
  OrgsRoute,
  SingleOrgDashboardRoute,
  RootPath,
} from "../../../../lib/routes";

import { getProductName } from "../../../../models/product";
import { getRoleFeatures } from "../../../../models/role";

import "./classroom.header.scss";

export function ClassroomHeader({ classroom, breadcrumbLabel, children }) {
  const { url } = useRouteMatch(RootPath);
  const productId = classroom?.products[0];
  const showProductInfo = classroom?.products.length === 1;
  const { getOrganizationByCode } = useStoreState(
    (store) => store.organization
  );

  const { role } = useStoreState((store) => store.account);

  const hasMultiOrgAccess = getRoleFeatures(role).superAdminsTabEnabled;
  const organization = hasMultiOrgAccess
    ? getOrganizationByCode(classroom.clientCode)
    : undefined;
  const dashboardPath = hasMultiOrgAccess
    ? `${url}${OrgsRoute.path}/${organization?.code}`
    : `${url}${SingleOrgDashboardRoute.path}`;

  const subTitle = (
    <ul className="metadata">
      {showProductInfo && <li>Product: {getProductName(productId)}</li>}
      <li>
        <Link
          to={{
            pathname: dashboardPath,
            state: {
              organization,
              breadcrumbLabel: `Classroom`,
            },
          }}
        >
          {classroom.clientName}
        </Link>
      </li>
      <li className="classroom-code">Code: {classroom.code}</li>
      {classroom.isDemo && (
        <li className="classroom-demo-badge">
          <DemoBadge isDemo />
        </li>
      )}
    </ul>
  );

  const productImage = showProductInfo && (
    <Product productId={productId} size="large"></Product>
  );

  return (
    <PageHeader
      className="classroom-details-header"
      title={classroom.name}
      subTitle={subTitle}
      breadcrumb={<Breadcrumb label={breadcrumbLabel}></Breadcrumb>}
      image={productImage}
    >
      {children}
    </PageHeader>
  );
}
