import React from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import "./sync-integration.modal.scss";

export default ({ open, integration, onCancel, onSyncIntegration }) => {
  const onConfirm = () => {
    onSyncIntegration(integration);
  };

  return (
    <Modal
      open={open}
      backdropClassName="sync-integration-modal"
      onClose={onCancel}
    >
      <ModalHeader
        icon={["fa-regular", "rotate"]}
        title="Sync Integration"
      ></ModalHeader>
      <ModalBody>
        <div>
          Are you sure you would like to update your roster integration with{" "}
          <span className="integration-name">{integration.providerName}</span>?
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel}>Cancel</Button>
        <Button primary onClick={onConfirm}>
          Yes, Sync
        </Button>
      </ModalFooter>
    </Modal>
  );
};
