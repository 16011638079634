import { useHistory } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect } from "react";

import { Redirects } from "../components/common/routing/redirect";

import container from "../container";
import { Loader } from "@transfr-inc/dashboard-components";
import { RootPath, UsersRoute } from "../lib/routes";

export default () => {
  const { userService } = container;
  const history = useHistory();

  const { redirect } = useStoreState((store) => store.app);
  const { setRedirect } = useStoreActions((store) => store.app);
  const { currentUser } = useStoreState((store) => store.account);

  useEffect(() => {
    if (redirect?.action) {
      onRedirect();
    }
  }, [redirect]);

  const onRedirect = async () => {
    let redirectFn;

    if (redirect.action === Redirects.TrekStudentComplete) {
      redirectFn = async () => {
        const { id, userId, classroomId, productId } =
          await userService.validateTrekCompleteToken(
            currentUser.userId,
            redirect.token
          );

        history.replace(`${RootPath}${UsersRoute.path}/${id}`, {
          userId,
          classroomId,
          product: productId,
          selectedProduct: productId,
          customBreadcrumbRoute: `${RootPath}/dashboard`,
          breadcrumbLabel: "dashboard",
        });
      };
    }

    try {
      await redirectFn?.();
    } catch (error) {
      console.error("There was an error while processing redirection", error);
      setRedirect({ error: true });
      history.replace(RootPath);
    }
  };

  return (
    <div
      className="redirect-page"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
        justifyContent: "center",
      }}
    >
      <Loader />
      <h3>Redirecting...</h3>
    </div>
  );
};
