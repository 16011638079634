import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionHeaderName,
  OverflowText,
  TextTooltip,
} from "@transfr-inc/dashboard-components";

import { ClassroomTrekCareersExploredTable } from "./classroom.trek-careers-explored.table";

export const ClassroomTrekCareerExploredAccordion = ({
  career,
  responsiveStyles,
  onGoalsClick,
}) => {
  return (
    <Accordion className="career-explored-accordion">
      <AccordionHeader>
        <AccordionHeaderName>
          <div className="header-column career-title">
            <OverflowText text={career.title} className="career-name">
              {career.title}
            </OverflowText>
            <TextTooltip text={career.description}>
              <FontAwesomeIcon icon={["fa-regular", "circle-info"]} />
            </TextTooltip>
          </div>
          <div className="header-column">{career.totalViews}</div>
          <div className="header-column">{career.totalSelected}</div>
        </AccordionHeaderName>
      </AccordionHeader>
      <AccordionBody>
        <ClassroomTrekCareersExploredTable
          data={career.users}
          responsiveStyles={responsiveStyles}
          onGoalsClick={onGoalsClick}
        />
      </AccordionBody>
    </Accordion>
  );
};
