import { Loader } from "@transfr-inc/dashboard-components";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";

import {
  CareerPlanGoalsModal,
  CareerSkillsModal,
} from "../../../../components/modals";
import container from "../../../../container";
import { useApiRequest } from "../../../../lib/http-client";
import { TraineeCareerPathway } from "./trainee.career-pathway";
import { CareerPathwayHeader } from "./trainee.career-pathway.header";
import { TraineeTrekCareers } from "./trainee.trek.careers";

import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { formatTimestap } from "@transfr-inc/dashboard-components/utils";

import "./trainee.classroom.trek.tab.scss";

const calculateExploredVr = (occupationCodes, occupationsMap, simsExplored) => {
  // Return undefined if no ocupation code or occupation code not in list of all sims' occupation codes
  if (
    occupationCodes == undefined ||
    occupationCodes.length === 0 ||
    !Object.keys(occupationsMap).some((occ) => occupationCodes.includes(occ))
  ) {
    return;
  }

  // Return true if user has explored sim with the same occupation code as the trek career
  const simsExploredSet = new Set(simsExplored);
  for (let occ of occupationCodes) {
    if (occupationsMap[occ].some((leid) => simsExploredSet.has(leid))) {
      return true;
    }
  }
  return false;
};

export function TraineeClassroomTrekTab({ trainee, classroom }) {
  const { trekService, simulationsService, insightsService } = container;
  const [data, setData] = useState([]);
  const [generatingPDF, setGeneratingPDF] = useState();
  const [notification, setNotification] = useState();

  const { userId } = trainee ?? {};

  const { loading: loadingCareers, response: careersResponse } = useApiRequest(
    () =>
      trekService
        .getUsersExportData({
          classroomId: classroom.classroomId,
          userId,
          options: {
            include_career_goals: false,
          },
        })
        .then((response) => {
          const { favoritedCareers, exploredCareers, selectedCareer } =
            response[userId] ?? {};
          const favorites = new Set(favoritedCareers?.map((f) => f.transfrId));

          return exploredCareers?.map((career) => ({
            ...career,
            favorite: favorites.has(career.transfrId),
            selected: selectedCareer?.transfrId === career.transfrId,
          }));
        })
  );

  const { response: simulations, loading: loadingSims } = useApiRequest(() =>
    simulationsService.getClassroomSimulations(classroom.classroomId)
  );

  const { response: masteryData, loading: loadingMastery } = useApiRequest(() =>
    insightsService.getUserCEMasteryInfo(userId)
  );

  const [openGoalsModal, setOpenGoalsModal] = useState();
  const [openSkillsModal, setOpenSkillsModal] = useState();

  const { loading, response: pathwaysInfo = {} } = useApiRequest(() =>
    trekService.getCareerPathway(trainee.userId)
  );

  const mergeTraineeTrekData = (careersData, simulations, masteryData) => {
    const leidsExplored = [...masteryData.keys()];
    const occupationToLeidsMap = {};

    for (const item of simulations) {
      const occupationCode = item.occupationCode;
      const learningExperienceId = item.learningExperienceId;
      if (!occupationToLeidsMap[occupationCode]) {
        occupationToLeidsMap[occupationCode] = [];
      }
      occupationToLeidsMap[occupationCode].push(learningExperienceId);
    }

    // Flattening the careers explored data response
    const careerFormatted = careersData.map((c) => {
      const { cluster, occupationCodes, ...career } = c;
      const { name: clusterName, description: clusterDescription } =
        cluster || {};

      const exploredVr = calculateExploredVr(
        occupationCodes,
        occupationToLeidsMap,
        leidsExplored
      );

      return {
        ...career,
        clusterName,
        clusterDescription,
        exploredVr,
      };
    });
    setData(careerFormatted);
  };

  useEffect(() => {
    careersResponse &&
      simulations &&
      masteryData &&
      mergeTraineeTrekData(careersResponse, simulations, masteryData);
  }, [careersResponse, simulations, masteryData]);

  // Only allow downloading the PDF if the user has submitted their career plan
  const doDownloadPathwayPDF =
    pathwaysInfo?.submittedAt &&
    (async () => {
      setGeneratingPDF(true);
      try {
        const pdfBlob = await trekService.getPathwayPDF(trainee.userId);

        const filename = `${classroom.name} - Trek Pathway - ${
          trainee.firstName
        } ${trainee.lastName} - ${formatTimestap()}.pdf`;
        saveAs(pdfBlob, filename);

        setNotification({
          type: NotificationType.success,
          message: "Successfully generated PDF",
          icon: ["fa-solid", "circle-check"],
        });
      } catch {
        setNotification({
          type: NotificationType.error,
          message: "Something went wrong while generating the PDF",
          icon: ["fa-light", "fa-xmark"],
        });
      }
      setGeneratingPDF(false);
    });

  if (loadingCareers || loadingSims || loadingMastery || loading) {
    return <Loader overlay />;
  }

  return (
    <>
      <div className="trainee-classroom-trek-pathway-container">
        <CareerPathwayHeader
          masteryData={masteryData}
          classroom={classroom}
          onDownloadPathwayPDF={doDownloadPathwayPDF}
        />
        <TraineeCareerPathway
          pathwayInfo={pathwaysInfo}
          onGoalsClick={() => setOpenGoalsModal(true)}
          onSkillsClick={() => setOpenSkillsModal(true)}
        />
      </div>
      <TraineeTrekCareers careers={data} trainee={trainee}></TraineeTrekCareers>
      <CareerPlanGoalsModal
        open={openGoalsModal}
        onClose={() => setOpenGoalsModal()}
        user={trainee}
        goals={pathwaysInfo?.goals}
      />
      <CareerSkillsModal
        open={openSkillsModal}
        onClose={() => setOpenSkillsModal()}
        user={trainee}
        skills={pathwaysInfo?.career?.skills}
      />
      <div className="update-notification-container">
        {generatingPDF && (
          <Notification
            type={NotificationType.info}
            icon={["fa-solid", "spinner"]}
            iconConfig={{ spin: true }}
          >
            <div className="update-message">Generating PDF...</div>
          </Notification>
        )}
        {notification && (
          <Notification
            type={notification.type}
            icon={notification.icon}
            closable
            onClose={() => setNotification()}
          >
            {notification.message}
          </Notification>
        )}
      </div>
    </>
  );
}
