import React, { useMemo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import container from "../../../../container";
import { JobOutlook } from "../../../../components/job-outlook/job-outlook";
import { getTotalCareerGoals } from "../../../../models";
import { useApiRequest } from "../../../../lib/http-client";
import { CheckedCell } from "../../../../components/table-cells";
import "./trainee.career-pathway.scss";

const percentileIndicators = {
  min: 10,
  max: 90,
  average: 50,
};

const getEarningsValue = (data, percentile) => {
  const earning = data?.find((d) => d.percentile === percentile)?.earnings ?? 0;
  return Math.trunc(earning);
};

const getEarningsRange = (data) => {
  return {
    min: getEarningsValue(data, percentileIndicators.min),
    max: getEarningsValue(data, percentileIndicators.max),
    average: getEarningsValue(data, percentileIndicators.average),
  };
};

export const TraineeCareerPathway = ({
  pathwayInfo,
  onGoalsClick,
  onSkillsClick,
}) => {
  if (!pathwayInfo?.submittedAt) {
    return (
      <div className="trek-zero-state-container">
        <div className="trek-zero-state">
          <FontAwesomeIcon icon={"fa-solid fa-hiking"} />
          Trainee hasn&apos;t selected a career pathway in Trek
        </div>
      </div>
    );
  }

  const { trekService } = container;

  const careerInfo = pathwayInfo.career;
  let imageUri = trekService.getCareerImageUrl(careerInfo?.transfrId);

  const { response: journeyStatus = {} } = useApiRequest(() =>
    trekService.getUserJourneyStatus(pathwayInfo?.userId)
  );

  const pathSteps = useMemo(() => {
    let steps = [
      {
        completed: journeyStatus?.intInv,
        name: "Get Ready",
      },
      {
        completed: journeyStatus?.matches,
        name: "Explore",
      },
      {
        completed:
          journeyStatus?.highSch ||
          journeyStatus?.postHighSch ||
          journeyStatus?.share ||
          journeyStatus?.goals,
        name: "Selected Pathway",
      },
      {
        completed: journeyStatus?.goals,
        name: "Setup Goals",
      },
    ];

    return steps;
  }, [journeyStatus]);

  const isDataNational = !careerInfo?.regional;
  const filteredCareerData = isDataNational
    ? careerInfo?.national
    : careerInfo?.regional;

  const regionalEarnings = getEarningsRange(filteredCareerData.earnings);
  const totalGoals = getTotalCareerGoals(pathwayInfo.goals);

  return (
    <div className="pathway-main-container">
      <div className="pathway-lower-container">
        <div className="pathway-info-container">
          <div className="pathway-image">
            <img
              className="path-img"
              src={imageUri}
              alt={`${careerInfo?.title} career icon`}
            ></img>
          </div>
          <div className="pathway-info">
            <div className="pathway-info-title">{careerInfo?.title}</div>
            <div className="pathway-info-subtitle">
              {careerInfo?.shortDescription}
            </div>
            <div className="career-info-main-container">
              <div className="career-info-container">
                ${regionalEarnings.average}/hr
              </div>
              <div className="career-info-container">
                <div className="status">
                  <JobOutlook
                    value={filteredCareerData?.outlook}
                    national={isDataNational}
                  />
                </div>
              </div>
              <div className="career-info-container">
                Goals{" "}
                <button className="link-button" onClick={onGoalsClick}>
                  {totalGoals}
                </button>
              </div>
              <div className="career-info-container">
                Skills{" "}
                <button className="link-button" onClick={onSkillsClick}>
                  {careerInfo.skills?.length || 0}
                </button>
              </div>
            </div>
            <div className="disclaimer">
              *Data gathered from recent job descriptions.
            </div>
          </div>
        </div>
        <div className="trek-progress-main-container">
          <div className="current-step">Current Step </div>
          {pathSteps.map((step) => (
            <div className="trek-progress-steps">
              <CheckedCell value={step.completed} />
              <div>{step.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
