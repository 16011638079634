import { Product } from "../../../../models";

const tsLabels = {
  userTabTitle: "Trainees",
  addUser: "Add Trainee",
  singular: "trainee",
  plural: "trainees",
};

const ceLabels = {
  userTabTitle: "Explorers",
  addUser: "Add Explorer",
  singular: "explorer",
  plural: "explorers",
};

const labelsMap = new Map();
labelsMap.set(Product.TS, tsLabels);
labelsMap.set(Product.CE, ceLabels);
labelsMap.set(Product.TRK, ceLabels);

export const getUserLabelsByProduct = (productId) => labelsMap.get(productId);
