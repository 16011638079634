import React, { useEffect, useState } from "react";
import { useContainerQuery } from "@transfr-inc/dashboard-components/container-query";

import {
  Accordion,
  AccordionHeader,
  AccordionHeaderName,
  AccordionBody,
  Badge,
} from "@transfr-inc/dashboard-components";

import { TraineeCeSimulationsTable } from "./trainee.ce-simulations.table";

import "./trainee.ce-simulations.scss";

const query = {
  "small-panel": {
    minWidth: 320,
    maxWidth: 767,
  },
  "medium-panel": {
    minWidth: 768,
    maxWidth: 1080,
  },
  "large-panel": {
    minWidth: 1080,
  },
};

const mergeMasteryData = (simulations, masteryData) => {
  const explored = [];
  const unexplored = [];

  for (const sim of simulations) {
    const mastery = masteryData.get(sim.learningExperienceId);
    if (mastery) {
      explored.push({ ...sim, ...mastery });
    } else {
      unexplored.push({ ...sim });
    }
  }
  return { explored, unexplored };
};

export const TraineeCeSimulations = ({ simulations, masteryData }) => {
  const [careerExplored, setCareerExplored] = useState([]);
  const [careerUnexplored, setCareerUnexplored] = useState([]);
  const [params, containerRef] = useContainerQuery(query);

  useEffect(() => {
    if (simulations && masteryData) {
      const { explored, unexplored } = mergeMasteryData(
        simulations,
        masteryData
      );
      setCareerExplored([...explored]);
      setCareerUnexplored([...unexplored]);
    }
  }, [simulations, masteryData]);

  return (
    <div className="careers-container" ref={containerRef}>
      {careerExplored.length > 0 && (
        <Accordion isOpen={true}>
          <AccordionHeader>
            <AccordionHeaderName>
              Career Explored <Badge value={careerExplored.length} />
            </AccordionHeaderName>
          </AccordionHeader>
          <AccordionBody>
            <TraineeCeSimulationsTable
              responsiveStyles={params}
              sims={careerExplored}
            />
          </AccordionBody>
        </Accordion>
      )}
      {careerUnexplored.length > 0 && (
        <Accordion isOpen={careerExplored.length === 0}>
          <AccordionHeader>
            <AccordionHeaderName>
              Career Unexplored <Badge value={careerUnexplored.length} />
            </AccordionHeaderName>
          </AccordionHeader>
          <AccordionBody>
            <TraineeCeSimulationsTable
              responsiveStyles={params}
              sims={careerUnexplored}
            />
          </AccordionBody>
        </Accordion>
      )}
    </div>
  );
};
