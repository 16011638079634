import React from "react";
import clsx from "clsx";

import {
  Modal,
  ModalHeader,
  ModalBody,
  SlashCircleIcon,
} from "@transfr-inc/dashboard-components";
import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";

import "./sync-integration-errors.modal.scss";
import ShortcutAction from "../../shortcuts/shortcut.action";

export default ({ open, onClose, errors }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      modalClassName={clsx("sync-errors-modal")}
    >
      <ModalHeader
        icon={["fa-regular", "triangle-exclamation"]}
        title="Errors"
        className={clsx("error-icon")}
      ></ModalHeader>
      <ModalBody>
        {(!errors || errors?.length === 0) && (
          <ShortcutAction className="no-data-message">
            <div>
              <SlashCircleIcon></SlashCircleIcon>
              No errors to display.
            </div>
          </ShortcutAction>
        )}
        {errors?.length > 0 && (
          <ul className="errors-list">
            {errors?.map((item, index) => (
              <li className="error-item" key={`log.${index}`}>
                <span className="log-date">
                  {formatDateDigits(item.timestamp, true)}
                </span>
                <span className="error-description">
                  {item.message
                    ? item.message.replace(/\\u0027/g, "'")
                    : item["exception_message"]}
                </span>
                <span className="error-payload">{item.payload}</span>
              </li>
            ))}
          </ul>
        )}
      </ModalBody>
    </Modal>
  );
};
