import React, { useEffect, useState } from "react";
// import { useStoreState } from "easy-peasy";
import container from "../../../../../../container";
import {
  Loader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import "./category.delete.modal.scss";

export function DeleteCategoryModal({ onCancel, open, onDeleteCategory }) {
  const [loading, setLoading] = useState();

  const deleteCategory = async () => {
    setLoading(true);
    try {
      onDeleteCategory();
    } finally {
      setLoading();
      onCancel();
    }
  };

  return (
    <Modal
      open={open}
      modalClassName={"delete-modal"}
      onClose={onCancel}
      preventClose={loading}
    >
      {loading && <Loader overlay></Loader>}
      <ModalHeader
        className="red-icon"
        icon={["fa-solid", "fa-clock-rotate-left"]}
        title="Delete Category"
      ></ModalHeader>
      <ModalBody>
        Are you sure that you would like to delete this custom category? This
        action cannot be undone.
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel}>Cancel</Button>
        <Button class="red-icon" destructive onClick={deleteCategory}>
          Yes, Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
}
