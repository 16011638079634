import React, { useState, useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import container from "../../../container";
import {
  Notification,
  NotificationStack,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { saveAs } from "file-saver";

import "./download-results.scss";
const ERROR_CSV_FILE = "errors.csv";
const CHANGES_CSV_FILE = "changes.csv";

export default () => {
  const { bulkUploadId } = useStoreState((store) => store.account);
  const actions = useStoreActions((store) => store.account);
  const { userService } = container;
  const [loading, setLoading] = useState();
  const [orgName, setOrgName] = useState();
  const [hasErrors, setHasErrors] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [newTraineesTotal, setNewTraineesTotal] = useState();
  const [updatedTraineesTotal, setUpdatedTraineesTotal] = useState();
  const [notifications, setNotifications] = useState();
  const [csvStrings, setCsvStrings] = useState();

  const downloadResultsCsv = async (downloadType) => {
    setLoading(true);

    const csvString =
      downloadType == "errors"
        ? csvStrings.errorsCsvString
        : csvStrings.successCsvString;

    const fileName =
      downloadType == "errors" ? ERROR_CSV_FILE : CHANGES_CSV_FILE;

    downloadType == "errors" && setHasErrors();
    downloadType == "successes" && setHasSuccess();

    try {
      const blob = new Blob([csvString], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, fileName);
      setNotifications((n) => {
        const notificationsUpdated = n ?? [];
        return [
          ...notificationsUpdated,
          {
            type: NotificationType.success,
            message: `Successfully downloaded ${fileName}`,
            icon: ["fa-solid", "circle-check"],
          },
        ];
      });
    } catch {
      setNotifications((n) => {
        const notificationsUpdated = n ?? [];
        return [
          ...notificationsUpdated,
          {
            type: NotificationType.error,
            message: "Something went wrong while downloading rostering csv",
            icon: ["fa-light", "fa-xmark"],
          },
        ];
      });
    }
    setLoading();
    actions.setBulkUploadId();
  };

  const onDownload = (downloadType) => {
    downloadResultsCsv(downloadType);
  };

  const onCloseNotification = (index) => {
    setNotifications(notifications.filter((n, i) => i !== index));
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await userService.getRosterResults(bulkUploadId);
        const strings = await userService.getRosterResultsCsv(data.result_dict);
        setCsvStrings(strings);
        setOrgName(data.organization_name);
        setNewTraineesTotal(data.result_dict.create_total);
        setUpdatedTraineesTotal(data.result_dict.update_total);
        if (
          data.result_dict.create_total > 0 ||
          data.result_dict.update_total > 0
        )
          setHasSuccess(true);
        if (data.result_dict.errors.length > 0) setHasErrors(true);
      } catch (error) {
        let errorMessage = "";
        errorMessage =
          error.status === 401
            ? "Incorrect account, please logout & try again"
            : "Something went wrong while retrieving rostering data";
        setNotifications((n) => {
          const notificationsUpdated = n ?? [];
          return [
            ...notificationsUpdated,
            {
              type: NotificationType.error,
              message: errorMessage,
              icon: ["fa-light", "fa-xmark"],
            },
          ];
        });
      }
    };
    if (bulkUploadId) {
      getData();
    }
  }, [bulkUploadId]);

  return (
    <div className="update-notification-container">
      {!loading && hasErrors && (
        <Notification
          type={NotificationType.error}
          closable
          onClose={() => setHasErrors()}
        >
          <div className="initial-error-message">
            We encountered problems with your roster request for{" "}
            <b>{orgName}</b>. Please download this
            <u onClick={() => onDownload("errors")} className="errors-link">
              <b>errors.csv</b>
            </u>
            for more information.
          </div>
        </Notification>
      )}
      {!loading && hasSuccess && (
        <Notification
          type={NotificationType.success}
          closable
          onClose={() => setHasSuccess()}
        >
          <div className="initial-error-message">
            We have successfully finished processing your roster request.{" "}
            {newTraineesTotal} new{" "}
            {newTraineesTotal == 0 || newTraineesTotal > 1
              ? "trainees have"
              : "trainee has"}{" "}
            been created and {updatedTraineesTotal}{" "}
            {updatedTraineesTotal == 0 || updatedTraineesTotal > 1
              ? "trainees have"
              : "trainee has"}{" "}
            been updated for your organization <b>{orgName}</b>. Please download
            <u onClick={() => onDownload("successes")} className="errors-link">
              <b>changes.csv</b>.
            </u>
          </div>
        </Notification>
      )}
      {loading && (
        <Notification
          type={NotificationType.info}
          icon={["fa-solid", "spinner"]}
          iconConfig={{ spin: true }}
        >
          <div className="update-message">Downloading file</div>
        </Notification>
      )}
      {notifications?.length > 0 && (
        <NotificationStack
          notifications={notifications}
          onClose={onCloseNotification}
          closable
        ></NotificationStack>
      )}
    </div>
  );
};
