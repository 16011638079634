import React from "react";

import { MyWorkCeTab } from "./my-work.ce.tab";
import { TraineeClassroomTsTab } from "../../users/trainee-detail/components/trainee.classroom.ts.tab";
import { Product } from "../../../models";

export function MyWorkTab({
  trainee,
  classroom,
  organization,
  onRemoveTrainee,
  removeTraineeEnabled,
}) {
  const { products } = classroom;
  const selectedProductId = products.find((product) => product !== Product.TRK);

  const renderClassroomTab = (classroomProduct) => {
    switch (classroomProduct) {
      case Product.TS:
        return (
          <TraineeClassroomTsTab
            trainee={trainee}
            classroom={classroom}
            organization={organization}
            onRemoveTrainee={onRemoveTrainee}
            removeTraineeEnabled={removeTraineeEnabled}
          />
        );
      case Product.CE:
        return (
          <MyWorkCeTab
            trainee={trainee}
            classroom={classroom}
            onRemoveTrainee={onRemoveTrainee}
            removeTraineeEnabled={removeTraineeEnabled}
          />
        );
    }
  };

  return <>{renderClassroomTab(selectedProductId)}</>;
}
