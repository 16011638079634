import React, { useState } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";

import { TextTooltip } from "@transfr-inc/dashboard-components";

import "./role-selector.scss";

/**
 * Formerly part of new-user-modal
 */
export function RoleSelector({
  roles,
  onRoleSelected,
  className,
  selectedRole,
}) {
  // State variables & functions for dropdown
  const [selected, setSelected] = useState(selectedRole);

  const onOptionSelected = (optionSelected) => {
    setSelected(optionSelected);
    onRoleSelected(optionSelected);
  };

  return (
    <div className={clsx("role-selector", className)}>
      <div className="text-with-info">
        <div className="role-info">
          <span>
            Role{"\u00A0"}
            <sup>*</sup>
            {"\u00A0"}
          </span>
        </div>
      </div>
      <Dropdown selected={selected} onOptionSelected={onOptionSelected}>
        <DropdownButton
          placeholder={"Select a Role"}
          icon={selected?.icon}
          required
        >
          {selected?.creationDisplayName}
        </DropdownButton>
        <DropdownItems>
          {roles?.map((role) => (
            <DropdownItem key={role.id} value={role} icon={role.icon}>
              {role.creationDisplayName}
              {role.description && (
                <TextTooltip text={role.description} theme="dropdown">
                  <FontAwesomeIcon icon="fa-solid fa-circle-info" />
                </TextTooltip>
              )}
            </DropdownItem>
          ))}
        </DropdownItems>
      </Dropdown>
    </div>
  );
}
