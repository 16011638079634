import React from "react";

import { Filter } from "@transfr-inc/dashboard-components/forms";

import "./category.filter.scss";
import { Category, getCategories, getCategory } from "../../../../../../models";

const CategoryOptions = getCategories();

export const CategoryFilter = ({ onFilterSelected, disabled }) => {
  return (
    <Filter
      className="category-filter"
      data={CategoryOptions}
      labelKey="label"
      defaultValue={getCategory(Category.ALL)}
      onFilterSelected={onFilterSelected}
      disabled={disabled}
    />
  );
};
