export const MasteryStatus = {
  Mastered: "Mastered",
  NeedsWork: "Needs Work",
  Stuck: "Stuck",
  NotStarted: "Not Started",
};

const StatusMap = new Map();
StatusMap.set("MASTERED", MasteryStatus.Mastered);
StatusMap.set("IN PROGRESS", MasteryStatus.NeedsWork);
StatusMap.set("WHEEL SPINNING", MasteryStatus.Stuck);
StatusMap.set("NOT ATTEMPTED", MasteryStatus.NotStarted);

const StatusIconMap = new Map();
StatusIconMap.set(MasteryStatus.Mastered, "fa-light fa-medal");
StatusIconMap.set(MasteryStatus.NeedsWork, "fa-light fa-person-running");
StatusIconMap.set(MasteryStatus.Stuck, "fa-light fa-hand");

export function getStatusValue(insightStatus) {
  return StatusMap.get(insightStatus.toUpperCase());
}

export function getStatusIcon(status) {
  return StatusIconMap.get(status);
}

export function getAllStatus() {
  return [...StatusMap.values()];
}
