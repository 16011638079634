import React, { useMemo } from "react";
import clsx from "clsx";

import { Badge, HyphenIcon } from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { ModuleAccordion } from "./module.accordion";

import "./module.list.scss";

export const ModuleList = ({
  modules,
  onEditModules,
  onAddModules,
  onEditSimulations,
  editionEnabled,
  className,
  checkVersionNumber,
}) => {
  if (!modules || modules?.length === 0) {
    return (
      <div className="no-modules-container">
        <span className="no-modules-message">
          There are no modules for this category.
        </span>
        {editionEnabled && (
          <Button
            icon={["fa-regular", "head-side-goggles"]}
            onClick={onAddModules}
          >
            Add Modules
          </Button>
        )}
      </div>
    );
  }

  const modulesCount = useMemo(
    () => modules?.filter((s) => s.isVisible).length,
    [modules]
  );

  return (
    <div className="module-list-container">
      <div className={clsx("modules-header", className)}>
        <span>Modules</span>
        <Badge value={modulesCount ?? <HyphenIcon />} />
        {editionEnabled && (
          <>
            <Button
              onClick={onEditModules}
              icon={["fa-regular", "pen-to-square"]}
            />
            <Button onClick={onAddModules} icon={["fa-regular", "plus"]} />
          </>
        )}
      </div>
      {modules?.map(
        (module, index) =>
          module.isVisible && (
            <ModuleAccordion
              key={`${module.moduleId}.${index}`}
              module={module}
              onEditSimulations={onEditSimulations}
              editionEnabled={editionEnabled}
              checkVersionNumber={checkVersionNumber}
            />
          )
      )}
    </div>
  );
};
