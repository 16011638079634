import { AllRoles, Role } from "../models/role";

import {
  Organizations,
  MultiOrgDashboard,
  SingleOrgDashboard,
  Users,
  Profile,
  Classrooms,
  Integrations,
} from "../views";

import { default as MyWork } from "../views/my-work/trainee-detail";

export const RootPath = "/app";

// Check if the path is a the entry point of the nav bar options
export const EntryPointPathRegex = new RegExp(`${RootPath}/([A-Za-z-])+$`);
export const isEntryPointPath = (path) => EntryPointPathRegex.test(path);

export const MultiOrgDashboardRoute = {
  path: "/dashboard",
  component: MultiOrgDashboard,
  exact: true,
  icon: "gauge",
  label: "Dashboard",
  roles: [Role.MULTI_ORG_VIEWER, Role.MULTI_ORG_ADMIN],
};

export const SingleOrgDashboardRoute = {
  path: "/dashboard",
  component: SingleOrgDashboard,
  exact: true,
  icon: "gauge",
  label: "Dashboard",
  roles: [Role.INSTRUCTOR, Role.ORG_ADMIN],
};

export const OrgsRoute = {
  path: "/orgs",
  component: Organizations,
  exact: false,
  icon: "sitemap",
  label: "Organizations",
  labelAbbr: "Orgs",
  roles: [Role.MULTI_ORG_VIEWER, Role.MULTI_ORG_ADMIN],
};
export const UsersRoute = {
  path: "/users",
  component: Users,
  exact: false,
  icon: "users",
  label: "Users",
  labelAbbr: "Users",
  roles: AllRoles,
};
export const ClassroomRoute = {
  path: "/classrooms",
  component: Classrooms,
  exact: false,
  icon: "screen-users",
  label: "Classrooms",
  roles: AllRoles,
};
export const ProfileRoute = {
  path: "/profile",
  component: Profile,
  exact: true,
  roles: AllRoles,
};

export const SupportRoute = {
  path:
    process.env.PROPROFS_ENABLED.toUpperCase() === "TRUE"
      ? process.env.PROPROFS_SITE_URL
      : process.env.SUPPORT_SITE_URL,
  isExternalLink: true,
  icon: "question-circle",
  label: "Support Center",
  labelAbbr: "Support",
  roles: AllRoles,
};

export const CurricularRoute = {
  path: "https://public.domo.com/embed/pages/1w88V",
  isExternalLink: true,
  icon: "circle-nodes",
  label: "Curricular Mapping",
  labelAbbr: "Mapping",
  roles: AllRoles,
};

export const ProductCatalogRoute = {
  path: "https://cdn.bfldr.com/B93N49H3/as/tjb7qcvkvwxcmf7v6fw5/Transfr_Product_Catalog_May2024",
  isExternalLink: true,
  icon: "books",
  label: "Product Catalog",
  labelAbbr: "Catalog",
  roles: AllRoles,
};

export const MyWorkRoute = {
  path: `/my-work`,
  component: MyWork,
  exact: false,
  icon: "person-running",
  label: "My Work",
  roles: [Role.STUDENT],
};

export const IntegrationsRoute = {
  path: "/integrations",
  component: Integrations,
  exact: false,
  icon: "swap",
  label: "Integrations",
  labelAbbr: "Integrations",
  roles: AllRoles,
};

export const FundingRoute = {
  path: "https://airtable.com/appmp66vdGVeBJktT/shrBA2nv31nWJn3Ku",
  isExternalLink: true,
  icon: "money-check-dollar-pen",
  label: "Funding Support",
  labelAbbr: "Funding",
  roles: AllRoles,
};

export const Routes = [
  MultiOrgDashboardRoute,
  SingleOrgDashboardRoute,
  OrgsRoute,
  UsersRoute,
  ClassroomRoute,
  ProfileRoute,
  SupportRoute,
  CurricularRoute,
  ProductCatalogRoute,
  IntegrationsRoute,
  FundingRoute,
];

export const StudentRoutes = [MyWorkRoute, ProfileRoute];
