import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionHeaderName,
  Badge,
} from "@transfr-inc/dashboard-components";

import "./sync-history.scss";
import IntegrationDate from "../list/components/integration-date";

const SyncHistoryItem = ({ syncRecord }) => {
  const { status, executionDateTime, message, results } = syncRecord;

  const dayOfWeek = new Date(executionDateTime).toLocaleDateString("en-US", {
    weekday: "long",
    timeZone: "UTC",
  });

  const isFailed = status === "Failed";
  const isPending = status === "Pending";

  return (
    <div className={`sync-history-item ${status}`}>
      <Accordion isOpen={false} disabled={isPending || isFailed}>
        <AccordionHeader>
          <AccordionHeaderName>
            <div className="header-name-container">
              <div className="date">
                <Badge
                  value={dayOfWeek}
                  className={`badge ${isFailed ? "error" : ""}`}
                />
                <IntegrationDate date={executionDateTime} />
              </div>
              <div className="message">
                {isFailed && (
                  <FontAwesomeIcon
                    icon={["fa-solid", "triangle-exclamation"]}
                  />
                )}
                {message}
              </div>
            </div>
          </AccordionHeaderName>
        </AccordionHeader>
        <AccordionBody>
          <div className="sync-history-item__results">
            {JSON.stringify(results)}
          </div>
        </AccordionBody>
      </Accordion>
    </div>
  );
};

export default SyncHistoryItem;
