import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Loader,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { UserInfo } from "../../../components/custom/forms";

import { Role } from "../../../models/role";

import "./create-instructor-modal.scss";

export const CreateInstructorModal = ({
  onCreateInstructor,
  open,
  onClose,
  className,
}) => {
  const defaultValues = { email: "", firstName: "", lastName: "" };
  const { handleSubmit, reset, formState, control, register } = useForm({
    criteriaMode: "all",
    mode: "onBlur",
    defaultValues,
  });

  const [stateNotification, setStateNotification] = useState();
  const [loader, setLoader] = useState();

  const submitForm = async (values) => {
    setLoader(true);

    const user = {
      firstName: values.firstName,
      lastName: values.lastName,
      roleId: Role.INSTRUCTOR.id,
    };

    if (values.email) user["email"] = values.email;
    if (values.password) user["password"] = values.password;

    const instructor = await onCreateInstructor(user);
    if (instructor.error) {
      setStateNotification({
        type: NotificationType.error,
        message: instructor.error,
      });
      setLoader();
      return false;
    }

    setStateNotification();
    setLoader();
    onClose(instructor);

    return true;
  };

  const createInstructor = () => {
    handleSubmit(submitForm)();
  };

  useEffect(() => {
    !open && reset();
  }, [open]);

  return (
    <div className={className}>
      <Modal
        modalClassName={"create-instructor-modal"}
        open={open}
        onClose={onClose}
        uniqueName="create-instructor-modal"
      >
        {loader && <Loader overlay></Loader>}
        <ModalHeader
          className="blue-icon small"
          icon={["fa-light", "user-plus"]}
          title="Create Instructor"
          uniqueName="create-instructor-modal"
        ></ModalHeader>
        <ModalBody className={"create-instructor modal-user-details"}>
          <div className="details-section-title"> User Details </div>
          <UserInfo
            register={register}
            control={control}
            formState={formState}
            role={Role.INSTRUCTOR}
            emailOptional={false}
            responseNotification={stateNotification}
            defaultValues={defaultValues}
            onNotificationChange={setStateNotification}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={createInstructor}
            primary={true}
            disabled={!formState.isValid}
          >
            Create Instructor
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CreateInstructorModal;
