import React, { useState } from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";
import {
  getSimulationStatus,
  getSimulationStatuses,
} from "../../../models/simulation-status";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusFilter } from "../../../views/users/trainee-detail/components/trainee.classroom.ts.tab";

import "./status-dropdown-list.scss";

export function StatusDropdownList({
  masteryStats,
  simulations,
  onStatusSelected,
  className,
  selectedStatus,
  isStudent,
}) {
  const {
    totalInProgress = 0,
    totalMastered = 0,
    totalWheelSpinning = 0,
  } = masteryStats || {};
  const totalStarted = totalInProgress + totalMastered + totalWheelSpinning;
  const allCount = simulations?.length;
  const simulationStatuses = getSimulationStatuses();

  const [dropdownStatusSelected, setDropdownStatusSelected] = useState(
    selectedStatus ?? isStudent ? StatusFilter.All : StatusFilter.Started
  );

  const onOptionSelected = (option) => {
    setDropdownStatusSelected(option);
    onStatusSelected(option);
  };

  const badgeCountMap = new Map();
  badgeCountMap.set(StatusFilter.Started, totalStarted);
  badgeCountMap.set(StatusFilter.Mastered, totalMastered);
  badgeCountMap.set(StatusFilter.NeedsWork, totalInProgress);
  badgeCountMap.set(StatusFilter.Stuck, totalWheelSpinning);
  badgeCountMap.set(StatusFilter.All, allCount);

  return (
    <div className={className}>
      <Dropdown
        selected={dropdownStatusSelected}
        onOptionSelected={onOptionSelected}
      >
        <DropdownButton placeholder={"Select a status..."} required>
          {dropdownStatusSelected && (
            <div className="simulations-dropdown-selected-item">
              <div className="simulations-dropdown-selected-icon-container">
                <FontAwesomeIcon
                  className="simulations-dropdown-selected-icon"
                  icon={`fa-light ${
                    getSimulationStatus(dropdownStatusSelected)?.icon
                  }`}
                />
              </div>
              {getSimulationStatus(dropdownStatusSelected)?.label}
              {simulationStatuses && (
                <div className="badge">
                  {dropdownStatusSelected &&
                    badgeCountMap.get(
                      getSimulationStatus(dropdownStatusSelected)?.statusFilter
                    )}
                </div>
              )}
            </div>
          )}
        </DropdownButton>
        <DropdownItems>
          {simulationStatuses?.map((status, i) => {
            return (
              <DropdownItem
                key={`${status.label}-sd-${i}`}
                value={status.statusFilter}
              >
                <div className="simulations-dropdown-item">
                  <div className="simulations-dropdown-icon-container">
                    <FontAwesomeIcon
                      className="simulations-dropdown-icon"
                      icon={`fa-regular ${status.icon}`}
                    />
                  </div>
                  {status.label}
                  <div className="badge">
                    {badgeCountMap.get(status.statusFilter)}
                  </div>
                </div>
              </DropdownItem>
            );
          })}
        </DropdownItems>
      </Dropdown>
    </div>
  );
}
