import React from "react";
import clsx from "clsx";

import {
  Accordion,
  AccordionHeader,
  AccordionHeaderName,
  AccordionBody,
} from "@transfr-inc/dashboard-components";

import "./module-checkbox.accordion.scss";

export const ModuleCheckboxAccordion = ({ module, className, type }) => {
  const moduleName = module?.displayName ?? module?.moduleName;
  const appDescription = module?.appDescription ? module.appDescription : "N/A";
  const moduleSimulations = module?.simulations ?? module?.moduleSimulations;
  const simsLength =
    type == "edition"
      ? moduleSimulations.filter((x) => x.isVisible)?.length
      : moduleSimulations.length;

  return (
    <Accordion className={clsx("module-checkbox-accordion", className)}>
      <AccordionHeader>
        <AccordionHeaderName>
          <div>
            <div className="app-description">App: {appDescription}</div>
            <div className="module-checkbox-container">
              <div className="module-checkbox-name">
                <span>{moduleName}</span>
                <span className="badge">{simsLength}</span>
              </div>
            </div>
          </div>
        </AccordionHeaderName>
      </AccordionHeader>
      <AccordionBody>
        <div className="simulations-list">
          {moduleSimulations?.map(
            (sim, index) =>
              sim.isVisible && (
                <div
                  className="simulations-container"
                  key={`${sim.learningExperienceId}.${index}`}
                >
                  <span>{sim.displayName ?? sim.simulationName}</span>
                </div>
              )
          )}
        </div>
      </AccordionBody>
    </Accordion>
  );
};
