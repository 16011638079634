import React, { useEffect, useState, useMemo } from "react";

import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { Button, Input } from "@transfr-inc/dashboard-components/forms";

import "./signup-form.component.scss";

export const SignupForm = ({ onSubmit, buttonText, error, loader }) => {
  const { control, formState } = useFormContext();
  const { errors, isValid } = formState;
  const [notification, setNotification] = useState();
  const errorArray = (errors?.formInputs || []).filter((e) => e);

  const errorMessages = useMemo(() => {
    if (errorArray?.length) {
      return errorArray.flatMap((errorObj) =>
        Object.entries(errorObj).map(([key, value]) => ({
          key,
          message: value.message,
        }))
      );
    }
    return [];
  }, [errorArray]);

  const { fields } = useFieldArray({
    control,
    name: "formInputs",
  });

  useEffect(() => {
    if (error) {
      setNotification({
        type: NotificationType.error,
        message: error,
        id: "signup-error",
      });
    }
  }, [error]);

  return (
    <div className="signup-form-component">
      <div className="signup-text">
        Welcome to Transfr! Please enter the following information to setup your
        new account.
      </div>
      <div className="text-with-info">
        <label>
          Required Fields<sup>*</sup>
        </label>
      </div>
      {errorMessages.map((error, i) => (
        <Notification
          className={"signup-notification"}
          key={i + error.message}
          type={NotificationType.error}
        >
          {error.message}
        </Notification>
      ))}
      {notification && (
        <Notification
          className={"signup-notification"}
          type={notification.type}
          icon={notification.icon}
          onClose={() => setNotification()}
        >
          {notification.message}
        </Notification>
      )}
      {fields.map((field, index) => (
        <Controller
          control={control}
          name={`formInputs[${index}].${field.key}`}
          key={field.id}
          rules={field.rules}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => {
            return (
              <div className="create-account-input-row">
                <Input
                  className="create-account-input"
                  label={field.label}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  errors={error}
                />
              </div>
            );
          }}
        />
      ))}
      <Button primary onClick={onSubmit} disabled={!isValid || loader}>
        {buttonText}
      </Button>
    </div>
  );
};
