import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Loader,
  NotificationType,
  Notification,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import container from "../../container";

import "./profile-modal.scss";

export const ResetPasswordModal = ({
  onCancel,
  show,
  userId,
  email,
  onResetPassword,
}) => {
  const [loading, setLoading] = useState();
  const [notification, setNotification] = useState();

  const { accountService } = container;

  const onNotificationClose = () => {
    setNotification();
  };

  const { handleSubmit } = useForm({
    defaultValues: { userId: userId, email: email },
  });

  async function submitForm(values) {
    try {
      await accountService.sendResetPassword(values);
      onResetPassword();
    } catch (error) {
      console.log("failure");
      const errorMessage =
        error.data === undefined ? "Something went wrong" : error.data.detail;
      setNotification({
        type: NotificationType.error,
        message: errorMessage,
      });
    } finally {
      setLoading();
    }
  }

  const onClick = () => {
    setLoading(true);
    handleSubmit(submitForm)();
  };

  return (
    <Modal modalClassName={"profile-modal"} open={show} onClose={onCancel}>
      {loading && <Loader overlay />}
      <ModalHeader
        className="blue-icon small"
        icon={["fa-regular", "lock-keyhole"]}
        title="Reset Password"
      ></ModalHeader>
      <ModalBody>
        {notification && (
          <Notification
            {...notification}
            closable
            onClose={onNotificationClose}
          >
            {notification.message}
          </Notification>
        )}
        <form>
          <div>Are you sure you would like to reset your password?</div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel} disabled={loading}>
          No, Cancel
        </Button>
        <Button primary onClick={onClick} disabled={loading}>
          Yes, Reset
        </Button>
      </ModalFooter>
    </Modal>
  );
};
