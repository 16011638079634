import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import "./checked.cell.scss";

export const CheckedCell = ({ value, nullable }) => {
  const icon = value
    ? ["fa-solid", "fa-circle-check"]
    : ["fa-regular", "fa-circle"];

  return (
    <div className={clsx("checked-cell", value && "completed-cell")}>
      {nullable && value === undefined ? (
        "N/A"
      ) : (
        <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
      )}
    </div>
  );
};
