import React from "react";
import clsx from "clsx";

import "./zero-state-message.scss";

export function ZeroStateMessage({ loading, className }) {
  const emptyStateText = "Please import your template to get started.";

  return (
    <div className={clsx("zero-state-message", className)}>
      <div className="text">
        <span>{loading ? "" : emptyStateText}</span>
      </div>
    </div>
  );
}
