import React from "react";
import { Slide } from "@transfr-inc/dashboard-components";
import { useScreenBreakpoint } from "@transfr-inc/dashboard-components/hooks";
import {
  SideImageLayout,
  LegalFooter,
} from "@transfr-inc/dashboard-components/layouts";
import createAcctImage from "../../../../public/images/create.svg";

import { Terms, PrivacyPolicy } from "../../../models/links";

export const AccountLayoutComponent = ({ children, title }) => {
  const { isMobile } = useScreenBreakpoint();

  return (
    <SideImageLayout
      title={title}
      image={createAcctImage}
      showMobileImage={isMobile}
      footer={
        <LegalFooter terms={Terms.url} privacyPolicy={PrivacyPolicy.url} />
      }
    >
      {children}
    </SideImageLayout>
  );
};
