import React, { useMemo } from "react";

import {
  Modal,
  ModalBody,
  ModalHeader,
  TextTooltip,
} from "@transfr-inc/dashboard-components";
import container from "../../../container";
import "./career-skills.modal.scss";

export const CareerSkillsModal = ({ open, onClose, user, skills }) => {
  const { trekService } = container;
  const defaultImageUrl = trekService.getSkillImage("default");

  const userFullName = useMemo(
    () => `${user?.firstName} ${user?.lastName}`.trim(),
    [user]
  );

  const { imagedSkills, totalSkills } = useMemo(() => {
    const filteredSkills = {
      general: [],
      specific: [],
    };

    if (skills?.length) {
      for (const skill of skills) {
        skill.imageUrl = trekService.getSkillImage(skill.id);

        if (skill.type === "general") {
          filteredSkills.general.push({ ...skill });
        } else {
          filteredSkills.specific.push({ ...skill });
        }
      }
    }

    return { imagedSkills: filteredSkills, totalSkills: skills?.length || 0 };
  }, [skills]);

  const handleError = ({ target }) => {
    target.src = defaultImageUrl;
  };

  return (
    <Modal open={open} modalClassName="career-skills-modal" onClose={onClose}>
      <ModalHeader
        label={userFullName}
        title={
          <div className="modal-title-badge">
            {"Skills"}
            <div className="badge">{totalSkills}</div>
          </div>
        }
      />
      <ModalBody>
        <div className="skills-container">
          <div className="skills-type-section">
            <div className="title">General</div>
            <div className="skills-image-container">
              {imagedSkills.general.map((skill) => (
                <div className="skill-tile">
                  <img
                    className="skill-tile-image"
                    src={skill.imageUrl}
                    alt={`${skill.name} icon`}
                    onError={handleError}
                  />
                  <div className="skill-tile-title">{skill.name}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="skills-type-section">
            <div className="title">Specialized</div>
            <div className="skills-image-container">
              {imagedSkills.specific.map((skill) => (
                <div className="skill-tile">
                  <img
                    className="skill-tile-image"
                    src={skill.imageUrl}
                    alt={`${skill.name} icon`}
                    onError={handleError}
                  />
                  <div className="skill-tile-title">{skill.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
