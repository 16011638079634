import React from "react";
import { useState, useEffect } from "react";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";

import { useStoreState } from "easy-peasy";

export function OrganizationDropdownList({
  data,
  className,
  onOrgSelected,
  selectedOrg,
}) {
  const [organizations, setOrganizations] = useState();
  const { organizations: storeOrganizations } = useStoreState(
    (store) => store.organization
  );

  useEffect(() => {
    if (data) {
      setOrganizations(data);
    } else {
      setOrganizations(storeOrganizations);
    }
  }, []);

  return (
    <Dropdown
      className={className}
      selected={selectedOrg}
      onOptionSelected={onOrgSelected}
      disabled={!organizations}
    >
      <DropdownButton
        placeholder={"Select an organization..."}
        icon={selectedOrg?.icon}
        required
      >
        {selectedOrg?.name}
      </DropdownButton>
      <DropdownItems>
        {organizations?.map((organization) => (
          <DropdownItem key={organization.id} value={organization}>
            {organization.name}
          </DropdownItem>
        ))}
      </DropdownItems>
    </Dropdown>
  );
}
