import React, { useEffect } from "react";
import { useState } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";

import {
  RequiredFieldsText,
  RequiredItem,
} from "@transfr-inc/dashboard-components";

import {
  SearchBox,
  useSearch,
  Select,
} from "@transfr-inc/dashboard-components/forms";
import { UserSelect } from "../../../views/classrooms/creation/user-select";
import { SimulationOptionItem } from "../../../views/classrooms/creation/simulation-option-item";

export function ClassroomGradebookFiltersInfo({
  className,
  gradebookData,
  onFiltersChangeHandler,
  defaultModule,
}) {
  const { control, setValue } = useFormContext();

  const [modules, setModules] = useState([]);

  const selectedModule = useWatch({ name: "module" });
  const selectedSimulations = useWatch({ name: "simulations" });
  const selectedTrainees = useWatch({ name: "trainees" });

  const onModulesNameTermChange = (term) => {
    const results = moduleNamesSearch.queryData(term);
    setModules(results);
  };

  const onModuleResultSelected = (option) => {
    setModules([option]);
    setValue("module", option);
    setValue("simulations", []);
    setValue("trainees", []);
    onFiltersChangeHandler({ module: option, sims: [], trainees: [] });
  };

  const moduleNamesSearch = useSearch({
    data: modules,
    searchKeys: ["displayName"],
  });

  useEffect(() => {
    if (gradebookData) {
      const data = [...gradebookData.values()];
      setModules(data);
      setValue("module", defaultModule);
      moduleNamesSearch.updateData(data);
    }
  }, [gradebookData, defaultModule]);

  useEffect(() => {
    onFiltersChangeHandler({
      module: selectedModule ?? {},
      sims: selectedSimulations ?? [],
      trainees: selectedTrainees ?? [],
    });
  }, [selectedModule, selectedSimulations, selectedTrainees]);

  return (
    <div className={className}>
      <div className="text-with-info">
        <RequiredFieldsText />
      </div>
      <div className="module">
        <div className="section-title">
          <RequiredItem text="Module" />
        </div>

        <Controller
          control={control}
          name="module"
          rules={{ required: true }}
          render={() => {
            return (
              <SearchBox
                placeholder={"Search or select a module"}
                onSearchTermChange={onModulesNameTermChange}
                onResultSelected={onModuleResultSelected}
                customOptionLabel={(r) => `${r.displayName}`}
                query={moduleNamesSearch.queryData}
                clearInputEnabled={false}
                showAllDataEnabled
                defaultOptionSelected={defaultModule}
              ></SearchBox>
            );
          }}
        />
      </div>
      <div className="classroom-section">
        <div className="simulations">
          <div className="section-title">
            {"Sims"}
            {selectedModule && `: ${selectedModule?.displayName}`}
          </div>
          <Controller
            control={control}
            name="simulations"
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  icon={["fa-light", "fa-magnifying-glass"]}
                  placeholder={
                    selectedSimulations?.length > 0 ? "" : "All Sims"
                  }
                  onOptionsChange={onChange}
                  options={
                    selectedModule &&
                    selectedModule?.simulations?.map(
                      (sim) => new SimulationOptionItem(sim)
                    )
                  }
                  selected={value}
                  searchEnabled
                ></Select>
              );
            }}
          />
        </div>
      </div>
      <div className="info-section">
        <div className="trainees">
          <div className="section-title">{"Trainees"}</div>
          <Controller
            control={control}
            name="trainees"
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => {
              return (
                <UserSelect
                  defaultText={
                    selectedTrainees?.length > 0 ? "" : "All Trainees"
                  }
                  onChange={onChange}
                  data={(selectedModule && selectedModule?.users) || []}
                  selectedUsers={value}
                  name={"trainees"}
                ></UserSelect>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
