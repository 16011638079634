import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";

import "./timer.scss";

export const Timer = ({ timeLeft, className }) => {
  return (
    <span className={clsx(className, "tx-timer")}>
      <FontAwesomeIcon icon="fa-solid fa-clock" />
      {timeLeft}
    </span>
  );
};
