import clsx from "clsx";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  BarOptionDefinition,
  HyphenIcon,
  MenuOptionSpec,
} from "@transfr-inc/dashboard-components";
import {
  Column,
  ColumnOptions,
  ColumnType,
  DataTable,
  SortDirection,
} from "@transfr-inc/dashboard-components/data-table";

import { ColumnHeaderInformationTooltip } from "../../../../../components/custom/content/column-header-information-tooltip";
import {
  DataTableColumnDefinition,
  getUserDetailPathConfig,
} from "../../../../users/list/data-table-column-definition";
import { TimerCell } from "./timer.cell";

import "./classroom-sim-pass.table.scss";

export const ClassroomSimPassTable = ({
  data,
  onSimPassClick,
  responsiveStyles,
}) => {
  const [loading, setLoading] = useState();
  const { classroomId } = useLocation().state;
  const breadcrumbLabel = "Classroom";

  const onSimPassHandler = async (data) => {
    setLoading(true);

    try {
      await onSimPassClick(data);
      setLoading();
    } catch (error) {
      console.log({ e: error });
      setLoading();
    }
  };

  const columns = [
    new Column("select", undefined, ColumnType.SELECTION),
    DataTableColumnDefinition.AVATAR,
    DataTableColumnDefinition.LAST_NAME(
      getUserDetailPathConfig({ breadcrumbLabel, classroomId })
    ),
    DataTableColumnDefinition.FIRST_NAME(
      getUserDetailPathConfig({ breadcrumbLabel, classroomId })
    ),
    new Column(
      "code",
      (
        <ColumnHeaderInformationTooltip
          headerTitle="Sim Pass"
          tooltipText="Enter your 5 digit sim pass in your VR headset"
        ></ColumnHeaderInformationTooltip>
      ),
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) =>
          value ? (
            <span className="table-cell-alt-code">{value}</span>
          ) : (
            <HyphenIcon />
          ),
      })
    ),
    new Column(
      "ttl",
      "Expires In",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value, row) => (
          <TimerCell ttl={value * 60} key={`ttl-${row.userId}-${row?.code}`} />
        ),
        sortKeyFunctions: [(row) => new Date(row.expiry ?? 0).getTime()],
      })
    ),
  ];

  const actionMenuOptions = [
    new MenuOptionSpec(
      "Create Sim Pass",
      ["fa-light", "fa-key"],
      async (_id, user) => await onSimPassClick([user]),
      "create_sim_pass"
    ),
  ];

  const bottomBarOptions = useMemo(
    () => [
      new BarOptionDefinition(onSimPassHandler, {
        text: "Create Sim Pass",
        icon: ["fa-light", "fa-key"],
        destructive: false,
        primary: true,
        loader: loading,
        disabled: loading,
        cancelFn: () => setLoading(),
      }),
    ],
    [loading]
  );

  return (
    <DataTable
      className={clsx("classroom-sim-pass-table", responsiveStyles)}
      columns={columns}
      data={data}
      bottomBarOptions={bottomBarOptions}
      actionMenuOptions={actionMenuOptions}
      keyFields={["userId"]}
      defaultSortPropName={"latestTimeStamp"}
      defaultSortDirection={SortDirection.DESCENDING}
      responsiveStyles={responsiveStyles}
      rowHeightConfig={{ large: 54, medium: 160, small: 280 }}
    ></DataTable>
  );
};
