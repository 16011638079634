export const createAccountFields = [
  {
    key: "firstName",
    label: "First Name *",
    rules: {
      required: {
        value: true,
        message: "First Name is required.",
      },
      maxLength: 50,
      pattern: {
        value: /^[\d',.a-z-]+$/i,
        message:
          "First name formatted incorrectly. Only letters, hyphens and numbers are allowed.",
      },
    },
  },
  {
    key: "lastName",
    label: "Last Name *",
    rules: {
      required: {
        value: true,
        message: "Last Name is required.",
      },
      maxLength: 50,
      pattern: {
        value: /^[\d',.a-z-]+$/i,
        message:
          "Last name formatted incorrectly. Only letters, hyphens and numbers are allowed.",
      },
    },
  },
  {
    key: "email",
    label: "Email Address *",
    rules: {
      required: {
        value: true,
        message: "Email Address is required.",
      },
      pattern: {
        value:
          /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/,
        message: "Email formatted incorrectly.",
      },
    },
  },
  {
    key: "username",
    label: "Username *",
    rules: {
      required: {
        value: true,
        message: "Username is required.",
      },
      maxLength: 50,
      pattern: {
        value: /^[\d',.@a-z-]+$/i,
        message:
          "Username formatted incorrectly. Only letters, hyphens, numbers, and '@' symbols are allowed.",
      },
    },
  },
];
