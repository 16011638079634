import React from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { Timer } from "../timer";

import "./sim-pass.modal.scss";

export const SimPassModal = ({
  classroom,
  timer,
  code,
  onClose,
  onStartOver,
  open,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      modalClassName="sim-pass-details-modal"
    >
      <ModalHeader>
        <div className="sim-pass-details-modal-header">
          <div className="sim-key-image"></div>
          <div>
            <h5>Sim Pass</h5>
            <h2>{classroom?.name}</h2>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="sim-pass-details-instructions">
          <span>Enter this code into your headset</span>
          <Timer timeLeft={timer?.timeFormatted} />
        </div>
        <div className="sim-pass-details-code">
          {code &&
            [...code].map((digit, index) => (
              <span key={`code-digit-${index}`}>{digit}</span>
            ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon={["fa-regular", "arrow-rotate-left"]}
          onClick={onStartOver}
        >
          Start Over
        </Button>
      </ModalFooter>
    </Modal>
  );
};
