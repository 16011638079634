import React from "react";

import { TextTooltip } from "@transfr-inc/dashboard-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function ColumnHeaderInformationTooltip({
  className,
  tooltipText,
  position,
  headerTitle,
  children,
}) {
  return (
    <div className={className}>
      {headerTitle}{" "}
      <TextTooltip text={tooltipText} position={position}>
        <FontAwesomeIcon icon={"fa-regular fa-circle-info"} />
        {children}
      </TextTooltip>
    </div>
  );
}
