import React from "react";
import clsx from "clsx";

import { Breadcrumb } from "@transfr-inc/dashboard-components";
import { PageHeader } from "@transfr-inc/dashboard-components/layouts";
import { useStoreState } from "easy-peasy";

import "./dashboard.header.scss";

export function DashboardHeader({
  title,
  subtitle,
  toolbar,
  breadcrumbLabel,
  className,
  children,
}) {
  const { ceEnabled } = useStoreState((store) => store.app);
  const breadcrumb = breadcrumbLabel && (
    <Breadcrumb label={breadcrumbLabel}></Breadcrumb>
  );

  return (
    <div className={clsx("dashboard-header", className, ceEnabled && "ce")}>
      <PageHeader title={title} subTitle={subtitle} breadcrumb={breadcrumb}>
        {children}
        {toolbar}
      </PageHeader>
    </div>
  );
}
