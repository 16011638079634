import { useStoreState } from "easy-peasy";
import React, { cloneElement } from "react";

import {
  Tab,
  TabBadge,
  TabHeader,
  TabHeaders,
  Tabs,
} from "@transfr-inc/dashboard-components";

import { AdminUsersTab } from "./admin-users-tab";
import { InstructorUsersTab } from "./instructor-users-tab";
import { TraineeUsersTab } from "./trainee-users-tab";

import { useHistoryState } from "../../../lib/hooks";
import { Role } from "../../../models/role";

import "./users-tab.scss";

export function UsersTabs({
  data,
  responsiveStyles,
  actionMenuOptions,
  bottomBarOptions,
  onSetShowNewUser,
  dataFiltered,
  creatableUsers,
}) {
  const { features, role } = useStoreState((store) => store.account);
  const {
    superAdminsTabEnabled,
    reportersTabEnabled,
    deleteableUsers,
    editableUsers,
  } = features;

  const {
    delete: deleteAction,
    edit: editAction,
    changePassword: passwordAction,
    resendInvite: resendAction,
    addToClassroom: addToClassroomAction,
  } = actionMenuOptions;

  const {
    delete: bulkDeleteAction,
    addToClassroom: bulkAddToClassroomAction,
    updatePasswords: bulkUpdatePasswordsAction,
  } = bottomBarOptions;
  const superAdminData = data.get(Role.MULTI_ORG_ADMIN.id) ?? [];
  const reporterAdminData = data.get(Role.MULTI_ORG_VIEWER.id) ?? [];
  const orgAdminData = data.get(Role.ORG_ADMIN.id) ?? [];
  const instructorData = data.get(Role.INSTRUCTOR.id) ?? [];
  const traineeData = data.get(Role.STUDENT.id) ?? [];

  const { addStateProps, currentState } = useHistoryState();

  const setTabState = (tabName) => addStateProps({ selectedTab: tabName });

  const superAdminsTab = {
    name: "superAdmins",
    header: (
      <TabHeader name="superAdmins" className="super-admins-tab-header">
        <div className="tab-header-text">Super Admins</div>
        <TabBadge count={superAdminData?.length}></TabBadge>
      </TabHeader>
    ),
    content: (
      <Tab name="superAdmins">
        <AdminUsersTab
          data={superAdminData}
          role={Role.MULTI_ORG_ADMIN}
          responsiveStyles={responsiveStyles}
          className="admin-users-tab-data-table"
          bottomBarOptions={[
            deleteableUsers.canDeleteMULTI_ORG_ADMINUser && bulkDeleteAction,
          ].filter((x) => !!x)}
          actionMenuOptions={[
            deleteableUsers.canDeleteMULTI_ORG_ADMINUser && deleteAction,
            editableUsers.canEditMULTI_ORG_ADMINUser && editAction,
            editableUsers.canEditMULTI_ORG_ADMINUser && resendAction,
          ]}
          onSetShowNewUser={
            creatableUsers["canCreateMULTI_ORG_ADMINUser"] && onSetShowNewUser
          }
          dataFiltered={dataFiltered}
        ></AdminUsersTab>
      </Tab>
    ),
  };

  const reportersTab = {
    name: "reporters",
    header: (
      <TabHeader name="reporters" className="reporters-tab-header">
        <div className="tab-header-text">Reporters</div>
        <TabBadge count={reporterAdminData?.length}></TabBadge>
      </TabHeader>
    ),
    content: (
      <Tab name="reporters">
        <AdminUsersTab
          data={reporterAdminData}
          role={Role.MULTI_ORG_VIEWER}
          responsiveStyles={responsiveStyles}
          className="admin-users-tab-data-table"
          bottomBarOptions={[
            deleteableUsers.canDeleteMULTI_ORG_VIEWERUser && bulkDeleteAction,
          ].filter((x) => !!x)}
          actionMenuOptions={[
            deleteableUsers.canDeleteMULTI_ORG_VIEWERUser && deleteAction,
            editableUsers.canEditMULTI_ORG_VIEWERUser && editAction,
            editableUsers.canEditMULTI_ORG_VIEWERUser && resendAction,
          ]}
          onSetShowNewUser={
            creatableUsers["canCreateMULTI_ORG_VIEWERUser"] && onSetShowNewUser
          }
          dataFiltered={dataFiltered}
        ></AdminUsersTab>
      </Tab>
    ),
  };

  const orgAdminsTab = {
    name: "orgAdmins",
    header: (
      <TabHeader name="orgAdmins" className="org-admins-tab-header">
        <div className="tab-header-text">Admins</div>
        <TabBadge count={orgAdminData?.length}></TabBadge>
      </TabHeader>
    ),
    content: (
      <Tab name="orgAdmins">
        <AdminUsersTab
          data={orgAdminData}
          role={Role.ORG_ADMIN}
          responsiveStyles={responsiveStyles}
          className="admin-users-tab-data-table"
          bottomBarOptions={[
            deleteableUsers.canDeleteORG_ADMINUser && bulkDeleteAction,
          ].filter((x) => !!x)}
          actionMenuOptions={[
            deleteableUsers.canDeleteORG_ADMINUser && deleteAction,
            editableUsers.canEditORG_ADMINUser && editAction,
            editableUsers.canEditORG_ADMINUser && resendAction,
          ]}
          onSetShowNewUser={
            creatableUsers["canCreateORG_ADMINUser"] && onSetShowNewUser
          }
          dataFiltered={dataFiltered}
        ></AdminUsersTab>
      </Tab>
    ),
  };

  const instructorsTab = {
    name: "instructors",
    header: (
      <TabHeader name="instructors" className="instructors-tab-header">
        <div className="tab-header-text">Instructors</div>
        <TabBadge count={instructorData?.length}></TabBadge>
      </TabHeader>
    ),
    content: (
      <Tab name="instructors">
        <InstructorUsersTab
          data={instructorData}
          responsiveStyles={responsiveStyles}
          className="instructor-users-tab-data-table"
          bottomBarOptions={[
            deleteableUsers.canDeleteINSTRUCTORUser && bulkDeleteAction,
          ].filter((x) => !!x)}
          actionMenuOptions={[
            deleteableUsers.canDeleteINSTRUCTORUser && deleteAction,
            editableUsers.canEditINSTRUCTORUser && editAction,
            editableUsers.canEditINSTRUCTORUser && resendAction,
          ]}
          onSetShowNewUser={
            creatableUsers["canCreateINSTRUCTORUser"] && onSetShowNewUser
          }
          dataFiltered={dataFiltered}
        ></InstructorUsersTab>
      </Tab>
    ),
  };

  const traineesTab = {
    name: "trainees",
    header: (
      <TabHeader name="trainees" className="trainees-tab-header">
        <div className="tab-header-text">Trainees</div>
        <TabBadge count={traineeData?.length}></TabBadge>
      </TabHeader>
    ),
    content: (
      <Tab name="trainees">
        <TraineeUsersTab
          data={traineeData}
          responsiveStyles={responsiveStyles}
          className="trainee-users-tab-data-table"
          bottomBarOptions={[
            (dataFiltered || role != Role.MULTI_ORG_ADMIN.id) &&
              editableUsers.canEditSTUDENTUser &&
              bulkAddToClassroomAction,
            editableUsers.canEditSTUDENTUser && bulkUpdatePasswordsAction,
            deleteableUsers.canDeleteSTUDENTUser && bulkDeleteAction,
          ].filter((x) => !!x)}
          actionMenuOptions={[
            deleteableUsers.canDeleteSTUDENTUser && deleteAction,
            editableUsers.canEditSTUDENTUser && editAction,
            editableUsers.canEditSTUDENTUser && passwordAction,
            (dataFiltered || role != Role.MULTI_ORG_ADMIN.id) &&
              editableUsers.canEditSTUDENTUser &&
              addToClassroomAction,
          ]}
          dataFiltered={dataFiltered}
        ></TraineeUsersTab>
      </Tab>
    ),
  };

  const getUserTabs = () => {
    const tabs = [];

    superAdminsTabEnabled && tabs.push(superAdminsTab);
    reportersTabEnabled && tabs.push(reportersTab);
    tabs.push(orgAdminsTab, instructorsTab, traineesTab);

    return tabs;
  };

  const tabs = getUserTabs();

  return (
    <Tabs
      selectedTabName={currentState?.selectedTab ?? tabs[0].name}
      className={"users-tabs"}
    >
      <TabHeaders onSelectedTabChange={setTabState}>
        {tabs.map(({ header, name }, i) =>
          cloneElement(header, { key: `tab-header-${name}-${i}` })
        )}
      </TabHeaders>
      {tabs.map(({ content, name }, i) =>
        cloneElement(content, { key: `tab-content-${name}-${i}` })
      )}
    </Tabs>
  );
}
