import React from "react";

import { Route, Switch, useRouteMatch } from "react-router-dom";
import { OrganizationList } from "./organization-list";
import { SingleOrgDashboard } from "../";
import { default as ContainerRoute } from "../../components/common/routing/container-route";

export default function Organizations({ responsiveStyles = {} }) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ContainerRoute
        exact={true}
        path={path}
        component={OrganizationList}
        responsiveStyles={responsiveStyles}
      ></ContainerRoute>

      <Route
        exact={true}
        path={`${path}/:orgCode`}
        component={SingleOrgDashboard}
      ></Route>
    </Switch>
  );
}
