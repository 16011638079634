import { useStoreState } from "easy-peasy";
import React from "react";

import { ProfileAvatar, RoleBadge } from "@transfr-inc/dashboard-components";
import { Button, ToggleButton } from "@transfr-inc/dashboard-components/forms";

import { avatarMap } from "../../lib/avatar-map";
import { getRoleAvatarColor, Product, Role } from "../../models";

import "./profile.scss";

export function ProfileInformation({
  onResetClick,
  onChangePhotoClick,
  onEditNotificationPreferences,
  user,
}) {
  const { role, currentUser } = useStoreState((store) => store.account);
  const {
    firstName,
    lastName,
    email,
    avatarId,
    username,
    notificationPreferences,
  } = user;
  const avatarColor = avatarId ? avatarMap[avatarId] : getRoleAvatarColor(role);

  const onTrekEmailNotificationsToggle = (value) => {
    onEditNotificationPreferences({
      ...notificationPreferences,
      trekPlanSubmittedEmail: value,
    });
  };

  return (
    <div className="profile-information">
      <div className="top">
        <ProfileAvatar className="avatar" fillColor={avatarColor} />
        {role == Role.STUDENT.id && (
          <Button
            className="change-photo-button"
            icon={["fa-light", "fa-camera"]}
            onClick={() => onChangePhotoClick(true)}
          >
            Change Photo
          </Button>
        )}
        <h1 className="name-header">
          {firstName} {lastName}
        </h1>
        <div className="name-subheader">{email}</div>
        {role == Role.STUDENT.id && (
          <div className="name-subheader">{username}</div>
        )}
      </div>
      <div className="bottom">
        <div className="box-section">
          <h6 className="section-name">USER TYPE</h6>
          <RoleBadge role={role} fillColor={getRoleAvatarColor(role)} />
        </div>
        <div className="box-section">
          <h6 className="section-name password">PASSWORD</h6>
          <Button
            icon="fa-regular fa-lock-keyhole"
            onClick={() => onResetClick(true)}
          >
            {role == Role.STUDENT.id ? "Change Password" : "Reset Password"}
          </Button>
        </div>
        {[Role.ORG_ADMIN.id, Role.INSTRUCTOR.id].includes(role) &&
          currentUser.products.includes(Product.TRK) && (
            <div className="box-section">
              <h6 className="section-name password">
                TREK EMAIL NOTIFICATIONS
              </h6>
              <ToggleButton
                onChange={onTrekEmailNotificationsToggle}
                value={notificationPreferences?.trekPlanSubmittedEmail ?? true}
                affirmText="ON"
                negateText="OFF"
              />
            </div>
          )}
        <div className="box-section last">
          <h6 className="section-name">ORGANIZATION</h6>
          <div className="org-name">{currentUser.organizationName}</div>
        </div>
      </div>
    </div>
  );
}
