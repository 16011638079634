import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";

import {
  Loader,
  NotificationStack,
  NotificationType,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { TraineesEditor } from "../../creation/trainees-editor";
import { UserOptionItem } from "../../creation/user-option-item";

import { catchUserCreationError } from "../../../../services";
import { Role } from "../../../../models";
import container from "../../../../container";

import { ConfirmCancelModal } from "./confirm-cancel.modal";

import "./add-user.modal.scss";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useApiRequest } from "../../../../lib/http-client";

export function AddUserModal({
  open,
  orgCode,
  onAddTrainee,
  async,
  classroomId,
  onCancel,
  classroomProduct,
  allProducts,
  openRosterModal,
  rosteringEnabled,
  userLabels = { singular: "trainee", plural: "trainees" },
}) {
  const { singular, plural } = userLabels;
  const [trainees, setTrainees] = useState([]);
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [traineesCreated, setTraineesCreated] = useState(false);
  const [selectedTrainees, setSelectedTrainees] = useState([]);
  const [loading, setLoading] = useState();
  const [notifications, setNotifications] = useState();
  const { userService, classroomService } = container;

  const { refreshOrganizationUsers } = useStoreActions(
    (store) => store.organization
  );
  const { role, currentUser } = useStoreState((store) => store.account);
  const { userId, organizationCode } = currentUser;

  const { sendRequest: getUsers } = useApiRequest(
    () => refreshOrganizationUsers({ role, orgCode: organizationCode, userId }),
    false
  );

  const methods = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      name: "",
      organization: { code: orgCode },
    },
  });

  // Watch for changes in the select user component
  const traineesWatch = methods.watch("trainees");

  useEffect(() => {
    traineesWatch && setSelectedTrainees([...traineesWatch]);
  }, [traineesWatch]);

  const onConfirmCloseHandler = () => {
    setOpenConfirmCancel(false);
    setTraineesCreated(false);
    onCloseHandler(true);
  };

  const onBackToModalHandler = () => {
    setOpenConfirmCancel(false);
    traineesCreated && onCancel(true);
  };

  const onCloseHandler = (confirmed) => {
    if (traineesCreated && !confirmed) {
      onCancel && onCancel();
      setOpenConfirmCancel(true);
    } else {
      onCancel && onCancel();
      methods.reset();
      setNotifications();
    }
  };

  const onAddTraineeHandler = () => {
    setTraineesCreated(false);
    if (async) {
      asyncHandler(selectedTrainees);
    } else {
      onAddTrainee(selectedTrainees);
      getUsers();
    }
  };

  const asyncHandler = (value) => {
    setNotifications();
    setLoading(true);
    onAddTrainee(value)
      .catch(() =>
        showNotification(
          NotificationType.error,
          "Something went wrong. Please try again."
        )
      )
      .finally(() => {
        setLoading(false);
        getUsers();
      });
  };

  const addTraineesToForm = (...trainees) => {
    const updatedTrainees = [...selectedTrainees, ...trainees];
    methods.setValue("trainees", updatedTrainees);
  };

  const onCreateTrainee = async (user) => {
    try {
      return await createUser(user);
    } catch (error) {
      console.log(error);
      return catchUserCreationError(error);
    }
  };

  const createUser = async (user) => {
    const student = await userService.createNewUser(user, orgCode, allProducts);
    await fetchStudentUsers();
    const studentOption = new UserOptionItem(student);
    addTraineesToForm(studentOption);
    setTraineesCreated(true);
    return student;
  };

  const onAddTrainees = async ({ trainees, success, error }) => {
    if (trainees?.length > 0) {
      setTraineesCreated(true);
      await fetchStudentUsers();
      const optionItems = trainees.map((user) => new UserOptionItem(user));
      addTraineesToForm(...optionItems);
    }

    if (success) {
      const { type, message, icon } = success;
      showNotification(type, message, icon);
    }

    if (error) {
      const { type, message, icon } = error;
      showNotification(type, message, icon);
    }
  };

  async function fetchStudentUsers() {
    const studentUsers = await userService.getOrganizationUsersForRole(
      orgCode,
      Role.STUDENT.id
    );
    const alreadyEnrolled = await classroomService.getClassroomTrainees(
      classroomId
    );
    const alreadyEnrolledIds = new Set(alreadyEnrolled.map((x) => x.userId));
    setTrainees(studentUsers.filter((x) => !alreadyEnrolledIds.has(x.userId)));
  }

  const showNotification = (type, message, icon) => {
    const notification = { type, message, icon };
    setNotifications((n) => {
      const notificationsUpdated = n ?? [];
      return [...notificationsUpdated, notification];
    });
  };

  const onCloseNotification = (index) => {
    setNotifications(notifications.filter((n, i) => i !== index));
  };

  useEffect(() => {
    if (open && !traineesCreated) {
      fetchStudentUsers();
      setSelectedTrainees([]);
    }
  }, [open]);

  return (
    <>
      <Modal
        backdropClassName={"add-trainee-modal-backdrop"}
        modalClassName={"add-trainee-modal"}
        open={open}
        onClose={() => onCloseHandler()}
      >
        {loading && <Loader overlay></Loader>}
        <ModalHeader
          className="blue-icon"
          icon={["fa-light", "head-side-goggles"]}
          title={`Add ${singular}`}
        ></ModalHeader>
        <ModalBody>
          {notifications?.length > 0 && (
            <NotificationStack
              notifications={notifications}
              onClose={onCloseNotification}
              closable
            ></NotificationStack>
          )}
          <FormProvider {...methods}>
            <form>
              <TraineesEditor
                traineeData={trainees}
                onAddTrainees={onAddTrainees}
                onCreateTrainee={onCreateTrainee}
                userLabels={userLabels}
                classroomProduct={classroomProduct}
                classroomId={classroomId}
                openRosterModal={openRosterModal}
                rosteringEnabled={rosteringEnabled}
                bulkUploadEnabled
              ></TraineesEditor>
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => onCloseHandler()}>Cancel</Button>
          <Button
            primary
            onClick={onAddTraineeHandler}
            disabled={selectedTrainees.length === 0}
          >
            Add {selectedTrainees.length > 1 ? plural : singular}
          </Button>
        </ModalFooter>
      </Modal>
      <ConfirmCancelModal
        open={openConfirmCancel}
        onConfirmClose={onConfirmCloseHandler}
        onBackToModal={onBackToModalHandler}
        userLabel={plural}
      />
    </>
  );
}
