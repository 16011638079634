import React, { useEffect, useState } from "react";

import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { useUnloadWarning } from "@transfr-inc/dashboard-components/hooks";
import { DashboardBanner, DashboardInsights } from "../../components";

import { Product } from "../../../../models";
import {
  exportOrganizationStats,
  getOrganizationDailyStats,
  getOrganizationStats,
  getStatsFileName,
} from "../../../../services";

import { useStoreActions, useStoreState } from "easy-peasy";
import { useApiRequest } from "../../../../lib/http-client";

export const TsTab = () => {
  const defaultOrgOption = { name: "All Organizations" };
  const defaultModuleOption = { displayName: "All Modules" };
  const defaultFilterCount = 1;
  const { currentUser, role } = useStoreState((store) => store.account);
  const { setDashboardLoaded } = useStoreActions((store) => store.app);
  const { organizationName, organizationCode, userId } = currentUser;
  const [dateRange, setDateRange] = useState({});
  const [orgStats, setOrgStats] = useState({ isLoading: true });
  const [dailyOrgStats, setOrgDailyStats] = useState({ isLoading: true });
  const [organizationSelected, setOrganizationSelected] =
    useState(defaultOrgOption);
  const [moduleSelected, setModuleSelected] = useState(defaultModuleOption);
  const [filterCount, setFilterCount] = useState(defaultFilterCount);

  const { loading: downloadingFile, sendRequest: exportInsights } =
    useApiRequest(() => {
      const moduleId = moduleSelected?.moduleId ?? undefined;
      const selectedName = organizationSelected?.code
        ? organizationSelected.name
        : organizationName;
      const orgCode = organizationSelected?.code ?? organizationCode;
      const fileName = getStatsFileName(selectedName, dateRange);
      const filters = { dateRange, moduleId };

      return exportOrganizationStats(
        role,
        orgCode,
        filters,
        fileName,
        !organizationSelected?.code
      );
    }, false);

  useUnloadWarning({ trigger: downloadingFile });

  useEffect(() => {
    const { startDate, endDate } = dateRange;
    const orgCode = organizationSelected?.code ?? organizationCode;
    const moduleId = moduleSelected?.moduleId ?? undefined;
    const filters = {
      dateRange,
      moduleId,
    };
    if (startDate && endDate) {
      getOrganizationSelectedStats(
        orgCode,
        filters,
        !organizationSelected?.code
      );
    }
  }, [dateRange, organizationSelected, moduleSelected]);

  useEffect(() => {
    if (!orgStats.isLoading && !dailyOrgStats.isLoading) {
      setDashboardLoaded();
    }
  }, [orgStats, dailyOrgStats]);

  const updateFilterCount = (organization, module) => {
    let count = defaultFilterCount;
    if (organization) {
      count += 1;
    }
    if (module) {
      count += 1;
    }
    setFilterCount(count);
  };

  const onApplyFilters = ({ dateRange, organization, module }) => {
    const { startDate, endDate } = dateRange || {};

    clearOrgStats();
    setDateRange({ startDate, endDate });
    setOrganizationSelected(organization);
    setModuleSelected(module);
    updateFilterCount(organization, module);
  };

  const clearOrgStats = () => {
    setOrgDailyStats({ isLoading: true });
    setOrgStats({ isLoading: true });
  };

  const getOrganizationSelectedStats = (orgCode, filters, isMultiOrg) => {
    Promise.all([
      getOrganizationDailyStats(
        userId,
        role,
        orgCode,
        filters,
        isMultiOrg
      ).then((response) => setOrgDailyStats({ isLoading: false, response })),
      getOrganizationStats(userId, role, orgCode, filters, isMultiOrg).then(
        (response) => setOrgStats({ isLoading: false, response })
      ),
    ]).catch(() => {
      setOrgDailyStats({ isLoading: false });
      setOrgStats({ isLoading: false });
    });
  };

  return (
    <>
      <DashboardBanner product={Product.TS} />
      <DashboardInsights
        orgCode={organizationCode}
        generalStats={orgStats}
        dailyStats={dailyOrgStats}
        onApplyFilters={onApplyFilters}
        exportDataDisabled={downloadingFile}
        onExportData={exportInsights}
        organizationFilterEnabled
        moduleFilterEnabled
        filterCount={filterCount}
      ></DashboardInsights>
      <div className="notification-loader-container">
        {downloadingFile && (
          <Notification
            type={NotificationType.info}
            closable
            icon={["fa-solid", "spinner"]}
            iconConfig={{ spin: true }}
          >
            We’re preparing a download of{" "}
            <strong>{`${
              organizationSelected?.code
                ? organizationSelected.name
                : organizationName
            }'s`}</strong>{" "}
            insights. Please wait...
          </Notification>
        )}
      </div>
    </>
  );
};
