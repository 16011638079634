import React, { useEffect, useState } from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { ClassroomGradebookFilterModal } from "../../modals";

import "./classroom-gradebook.filter.scss";

export const ClassroomGradebookFilter = ({
  onApplyFilters,
  gradebookData,
  defaultModule,
}) => {
  const [openFilterModal, setOpenFilterModal] = useState();
  const [filterCount, setFilterCount] = useState(1);

  const onUpdateFilterCount = (count) => {
    setFilterCount(count);
  };

  const onApplyFiltersHandler = (filters) => {
    const sims =
      filters.simulations.length > 0
        ? filters.simulations
        : gradebookData.get(filters.module.moduleId)?.simulations;
    const trainees =
      filters.users.length > 0
        ? filters.users
        : gradebookData.get(filters.module.moduleId)?.users;
    const filtersObject = {
      module: filters.module,
      simulations: sims,
      users: trainees,
    };
    onApplyFilters && onApplyFilters(filtersObject);
    setOpenFilterModal();
  };

  useEffect(() => {
    defaultModule &&
      onApplyFiltersHandler({
        module: defaultModule,
        simulations: [],
        users: [],
      });
  }, [defaultModule]);

  return (
    <>
      <div className="classroom-gradebook-filter-container">
        <Button
          icon={["fa-regular", "sliders"]}
          onClick={() => setOpenFilterModal(true)}
        >
          Filters
          {filterCount > 0 && <span className="badge">{filterCount}</span>}
        </Button>
      </div>
      <ClassroomGradebookFilterModal
        open={openFilterModal}
        onCancel={() => setOpenFilterModal()}
        onApplyFilters={onApplyFiltersHandler}
        onUpdateFilterCount={onUpdateFilterCount}
        gradebookData={gradebookData}
        defaultModule={defaultModule}
      />
    </>
  );
};
