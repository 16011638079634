import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { useUnloadWarning } from "@transfr-inc/dashboard-components/hooks";

import { Product } from "../../../../models";
import {
  getTrekDashboardDailyStats,
  getTrekDashboardStats,
} from "../../../../services";
import { DashboardBanner, TrekInsights } from "../../components";
import { useTopCareersSelected } from "../../../../components/top-careers-selected";

import { useTrekExport } from "../../../../lib/trek-export.hook";

import container from "../../../../container";

import "./trek.tab.scss";

export const TrekTab = () => {
  const { trekService } = container;
  const { state: locationState } = useLocation();
  const { currentUser, role } = useStoreState((store) => store.account);
  const {
    students: users,
    organizations,
    classrooms,
  } = useStoreState((store) => store.organization);
  const { setDashboardLoaded } = useStoreActions((store) => store.app);

  const [organizationSelected, setOrganizationSelected] = useState();
  const [dateRange, setDateRange] = useState({});
  const [generalStats, setGeneralStats] = useState({ isLoading: true });
  const [dailyStats, setDailyStats] = useState({ isLoading: true });

  const { organization } = locationState ?? {};
  const { organizationCode, userId, organizationName } = currentUser;

  const orgCode = organization?.code ?? organizationCode;
  const orgName = organization?.name ?? organizationName;

  const {
    exportTrekData,
    isLoading: exportingData,
    hasError: exportDataError,
    resetStatus,
  } = useTrekExport({ organizations, classrooms });

  useUnloadWarning({ trigger: exportingData });

  const clearStats = () => {
    setDailyStats((p) => ({
      isLoading: p?.isLoading || true,
    }));
    setGeneralStats((p) => ({
      isLoading: p?.isLoading || true,
    }));
  };

  const doApplyFilters = ({ dateRange, organization }) => {
    const { startDate, endDate } = dateRange || {};
    clearStats();
    setDateRange({ startDate, endDate });
    setOrganizationSelected(organization);
  };

  const getTrekStats = ({ orgCode, filters, isMultiOrg }) => {
    getTrekDashboardStats({ orgCode, role, userId, filters, isMultiOrg })
      .then((response) => setGeneralStats({ isLoading: false, response }))
      .catch(() => {
        setGeneralStats((p) => ({
          isLoading: p?.isLoading || false,
        }));
      });
    getTrekDashboardDailyStats({
      orgCode,
      role,
      userId,
      filters,
      isMultiOrg,
    })
      .then((response) => setDailyStats({ isLoading: false, response }))
      .catch(() => {
        setDailyStats((p) => ({
          isLoading: p?.isLoading || false,
        }));
      });
  };

  const onExportData = () => {
    const filters = { dateRange };
    const code = organizationSelected?.code ?? orgCode;
    const prefixFileName = organizationSelected
      ? organizationSelected.name
      : orgName;
    const isMultiOrg = !organizationSelected;

    exportTrekData({
      orgCode: code,
      filters,
      prefixFileName,
      isMultiOrg,
    });
  };

  const {
    topCareers,
    loading,
    refreshData: getTopCareers,
  } = useTopCareersSelected();

  useEffect(() => {
    const { startDate, endDate } = dateRange;
    const filters = { dateRange };
    if (startDate && endDate) {
      Promise.allSettled([
        getTrekStats({
          orgCode: organizationSelected?.code ?? orgCode,
          filters,
          isMultiOrg: !organizationSelected,
        }),
        getTopCareers({
          orgCode: organizationSelected?.code ?? orgCode,
          filters,
        }),
      ]);
    }
  }, [dateRange, organizationSelected, orgCode]);

  useEffect(() => {
    if (!generalStats.isLoading && !dailyStats.isLoading) {
      setDashboardLoaded();
    }
  }, [generalStats, dailyStats]);

  // Cancel ongoing requests when changing tab
  useEffect(() => () => trekService.cancelRequest(), []);

  return (
    <div className="trek-single-tab">
      <DashboardBanner product={Product.TRK} />
      <TrekInsights
        userInfo={{ userId, role }}
        orgCode={orgCode}
        generalStats={generalStats?.response}
        dailyStats={dailyStats}
        onApplyFilters={doApplyFilters}
        topCareers={topCareers}
        isLoadingTopCareers={loading}
        users={users}
        onExportData={onExportData}
        exportDataDisabled={exportingData}
        organizationEnabled
      />
      <div className="notification-loader-container">
        {exportingData && (
          <Notification
            type={NotificationType.info}
            closable
            icon={["fa-solid", "spinner"]}
            iconConfig={{ spin: true }}
          >
            We’re preparing a download for Trek insights. Please wait...
          </Notification>
        )}
        {exportDataError && (
          <Notification
            type={NotificationType.error}
            closable
            animated
            onClose={resetStatus}
          >
            Something when wrong. Please try again.
          </Notification>
        )}
      </div>
    </div>
  );
};
