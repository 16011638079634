import React from "react";
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./shortcut.action.scss";

export default ({ icon, disabled, className, onClick, children }) => {
  const content = (
    <>
      {icon && <FontAwesomeIcon icon={icon} />}
      {children}
    </>
  );

  return onClick ? (
    <button
      className={clsx("shortcut-action", className)}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  ) : (
    <div className={clsx("shortcut-action", className)}>{content}</div>
  );
};
