import React, { useEffect } from "react";
import clsx from "clsx";

import {
  Loader,
  Modal,
  ModalHeader,
  ModalBody,
  SlashCircleIcon,
} from "@transfr-inc/dashboard-components";

import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";

import { useApiRequest } from "../../../../../../../lib/http-client";
import container from "../../../../../../../container";

import "./activity-log.modal.scss";
import ShortcutAction from "../../../../../../../components/shortcuts/shortcut.action";

export const ActivityLogModal = ({ open, onClose, classroom, className }) => {
  const { classroomId } = classroom;
  const { menuBuilderService } = container;

  const {
    response: logs = [],
    loading,
    sendRequest: getActivityLogs,
  } = useApiRequest(
    () => menuBuilderService.getActivityLogs(classroomId),
    false
  );

  useEffect(() => {
    if (open && classroomId) {
      getActivityLogs();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      modalClassName={clsx("activity-logs-modal", className)}
    >
      <ModalHeader
        icon={["fa-regular", "list-timeline"]}
        title="Activity Log"
      ></ModalHeader>
      <ModalBody>
        {loading && <Loader overlay />}
        {!loading && logs?.length === 0 && (
          <ShortcutAction className="no-data-message">
            <div>
              <SlashCircleIcon></SlashCircleIcon>
              No activity to display.
            </div>
          </ShortcutAction>
        )}
        {logs?.length > 0 && (
          <ul className="logs-list">
            {!loading &&
              logs?.map((logItem, index) => (
                <li className="log-item" key={`log.${index}`}>
                  <span className="log-date">
                    {formatDateDigits(logItem.date, true)}
                  </span>
                  <span className="log-description">
                    {logItem.message ?? logItem["log_type_name"]}
                  </span>
                  <span className="log-user">{logItem.user}</span>
                </li>
              ))}
          </ul>
        )}
      </ModalBody>
    </Modal>
  );
};
