import React from "react";

import { createRoot } from "react-dom/client";
import { StoreProvider } from "easy-peasy";

import store from "./store";
import Router from "./router";

import "./index.scss";

import { initIcons } from "./lib/icon-set";

initIcons();

// Log the app version
console.info(
  `%cTransfr Dashboard %cv${dashboardAppVersion}`,
  "font-weight:bold",
  "font-weight:normal"
);

const container = document.querySelector("#root");
createRoot(container).render(
  <StoreProvider store={store}>
    <Router />
  </StoreProvider>
);
