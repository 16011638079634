import React, { useEffect, useState } from "react";

import { useStoreActions, useStoreState } from "easy-peasy";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useQueryString } from "../../lib/hooks";

import {
  Input,
  Button,
  ValidationItem,
} from "@transfr-inc/dashboard-components/forms";
import {
  SideImageLayout,
  LegalFooter,
} from "@transfr-inc/dashboard-components/layouts";
import {
  NotificationType,
  Notification,
} from "@transfr-inc/dashboard-components";
import { Terms, PrivacyPolicy } from "../../models/links";

import forgotPasswordImage from "../../../public/images/forgot-password.svg";

export default function ResetPassword() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, dirtyFields, isValid },
  } = useForm({
    criteriaMode: "all",
    mode: "onChange",
  });

  const actions = useStoreActions((store) => store.resetPassword);
  const state = useStoreState((store) => store.resetPassword);
  const { logout } = useStoreActions((state) => state.account);

  const history = useHistory();
  const queryParams = useQueryString();

  const [layoutTitle, setLayoutTitle] = useState(["Reset", "Password"]);
  const [notification, setNotification] = useState();

  const onNotificationClose = () => {
    setNotification();
  };

  // onload
  useEffect(() => {
    actions.onPageLoad(queryParams);
  }, []);

  useEffect(() => {
    // Any error when validating the token will be handled as an expired token
    if (!state.loadState.blocking && state.loadState.error) {
      setLayoutTitle(["Reset", "Password", "Expired"]);
    }
  }, [state.loadState]);

  // onsave
  useEffect(() => {
    setNotification();
    if (state.saveState.success) {
      goLoginPage();
    } else if (state.saveState.error) {
      setNotification({
        type: NotificationType.error,
        message: "Something went wrong",
        id: "reset-password-error",
      });
    }
  }, [state.saveState]);

  const goLoginPage = () => {
    logout({ logOutRequested: true });
    history.push({
      pathname: "/login",
      state: {
        passwordResetSuccess: true,
      },
    });
  };

  return (
    <SideImageLayout
      title={layoutTitle}
      image={forgotPasswordImage}
      footer={
        <LegalFooter terms={Terms.url} privacyPolicy={PrivacyPolicy.url} />
      }
    >
      <>
        {notification && (
          <Notification
            {...notification}
            closable
            onClose={onNotificationClose}
          >
            {notification.message}
          </Notification>
        )}
        {state.loadState.active && (
          <div>
            {!state.loadState.blocking && state.loadState.error && (
              <div className="account-form">
                <div className="instructions">
                  Your reset password link has expired. Please visit the sign in
                  page and press “Forgot Password” to try again.
                </div>
                <div>
                  <Button
                    className="primary block"
                    label="Return to Sign In"
                    onClick={goLoginPage}
                  />
                </div>
              </div>
            )}
            {state.loadState.success && (
              <form
                className="account-form"
                onSubmit={handleSubmit(actions.onSubmit)}
                noValidate={true}
                onInput={() => actions.resetSubmit()}
              >
                <div className="instructions">
                  Please enter a new password for your Transfr account.
                </div>
                <div className="inputs">
                  <Input
                    label="Password"
                    type="password"
                    required
                    errors={errors.password}
                    {...register("password", {
                      validate: {
                        count: (v) =>
                          v?.length >= 8 ||
                          "Password must be at least 8 characters",
                        hasLetter: (v) =>
                          /[A-Za-z]/.test(v) ||
                          "Password must include a letter",
                        hasNumber: (v) =>
                          /\d+/.test(v) || "Password must include a number",
                        hasSpecial: (v) =>
                          /[!,.?@_-]+/.test(v) ||
                          "Password must include a special character",
                        hasOnlyAllowedSpecials: (v) =>
                          !/[^\w!,.?@-]+/.test(v) ||
                          "Password cannot include special characters other than @ ! ? , . - _",
                      },
                    })}
                  />

                  <Input
                    label="Confirm Password"
                    type="password"
                    required
                    errors={errors.passwordMatch}
                    {...register("passwordMatch", {
                      validate: {
                        matches: (v) => {
                          const password = getValues("password");
                          return password === v || "match";
                        },
                      },
                    })}
                  />
                </div>
                <div className="requirements">
                  <ul>
                    <li>
                      <div className="title">Password Requirements</div>
                    </li>
                    <li>
                      <ValidationItem
                        label={"Minimum of 8 characters"}
                        valid={!errors.password?.types.count}
                        enabled={dirtyFields.password}
                      />
                    </li>
                    <li>
                      <ValidationItem
                        label={"At least one letter"}
                        valid={!errors.password?.types.hasLetter}
                        enabled={dirtyFields.password}
                      />
                    </li>
                    <li>
                      <ValidationItem
                        label={"At least one number"}
                        valid={!errors.password?.types.hasNumber}
                        enabled={dirtyFields.password}
                      />
                    </li>
                    <li>
                      <ValidationItem
                        label={"At least one special character"}
                        valid={!errors.password?.types.hasSpecial}
                        enabled={dirtyFields.password}
                      />
                    </li>
                    <li>
                      <ValidationItem
                        label={"No special characters other than @ ! ? , . - _"}
                        valid={!errors.password?.types.hasOnlyAllowedSpecials}
                        enabled={dirtyFields.password}
                      />
                    </li>
                    <li>
                      <ValidationItem
                        label={"Confirm your new password"}
                        valid={!errors.passwordMatch?.types?.matches}
                        enabled={
                          dirtyFields.password && dirtyFields.passwordMatch
                        }
                      />
                    </li>
                  </ul>
                </div>
                <div>
                  <Button
                    className="primary block"
                    type="submit"
                    label="Reset Password"
                    disabled={!isValid || state.saveState.blocking}
                    loader={state.saveState.blocking}
                  />
                </div>
              </form>
            )}
          </div>
        )}
      </>
    </SideImageLayout>
  );
}
