import React from "react";
import clsx from "clsx";

import { Select } from "@transfr-inc/dashboard-components/forms";
import { UserOptionItem } from "./user-option-item";

import "./user-select.scss";

export function UserSelect({
  selectedUsers,
  onChange,
  defaultText,
  data,
  name,
  className = "",
}) {
  const options = data.map((user) => new UserOptionItem(user));

  return (
    <Select
      selected={selectedUsers}
      placeholder={defaultText}
      icon={["fa-light", "fa-magnifying-glass"]}
      onOptionsChange={onChange}
      className={clsx("user-select", className)}
      options={options}
      searchEnabled
    ></Select>
  );
}
