import React, { useEffect, useState } from "react";

import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";

export const NoProductsNotification = () => {
  const [show, setShow] = useState();

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    show && (
      <div className="no-products-container">
        <Notification
          className="no-products-notification"
          closable
          type={NotificationType.error}
          onClose={() => setShow()}
        >
          We were unable to retrieve data for this organization. Try signing out
          and back in again to see if this issue is resolved.
        </Notification>
      </div>
    )
  );
};
