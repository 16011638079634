import React, { useEffect } from "react";

import { useStoreActions, useStoreState } from "easy-peasy";
import { useForm } from "react-hook-form";

import {
  SideImageLayout,
  LegalFooter,
} from "@transfr-inc/dashboard-components/layouts";
import { Input, Button } from "@transfr-inc/dashboard-components/forms";
import { PrivacyPolicy, Terms } from "../../models/links";

import forgotPasswordImage from "../../../public/images/forgot-password.svg";
import { useHistory } from "react-router-dom";

export default function ForgotPassword() {
  const history = useHistory();
  const { register, handleSubmit, formState } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    delayError: 1000,
    defaultValues: { email: "" },
  });

  const actions = useStoreActions((store) => store.account);
  const state = useStoreState((store) => store.account);

  useEffect(() => {
    if (formState.errors.email) {
      // TODO: Add notification component
      console.log(formState);
    }
  }, [formState]);

  return (
    <SideImageLayout
      title={["Forgot", "Password"]}
      image={forgotPasswordImage}
      footer={
        <LegalFooter terms={Terms.url} privacyPolicy={PrivacyPolicy.url} />
      }
    >
      {formState.isSubmitted && state.emailSent && (
        <form className="account-form">
          <div className="instructions">
            If an account exists you will receive an email allowing you to reset
            your password. If you can&rsquo;t find the email, check your spam
            folder or contact customer support for help.
          </div>
          <Button
            className="block"
            type="cancel"
            label="Return to Sign In"
            onClick={() => history.replace("/login")}
          />
        </form>
      )}

      {!(formState.isSubmitted && state.emailSent) && (
        <form
          className="account-form"
          onSubmit={handleSubmit(actions.sendResetPassword)}
          noValidate={true}
        >
          <div className="instructions">
            Please enter the email associated with your Transfr account.
          </div>
          <div className="inputs">
            <Input
              label="Email Address"
              type="email"
              errors={formState.errors?.email}
              required
              {...register("email", {
                required: true,
                pattern: {
                  value: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,}$/i,
                },
              })}
            />
          </div>
          <div>
            <Button
              label="Send Reset Email"
              className="block"
              primary
              type="submit"
              disabled={!formState.isValid || state.resolving}
              loader={state.resolving}
            />
            <br />
            <Button
              className="block"
              type="cancel"
              label="Return to Sign In"
              onClick={() => history.replace("/login")}
            />
          </div>
        </form>
      )}
    </SideImageLayout>
  );
}
