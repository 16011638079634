import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import {
  DashboardHeader,
  ClassroomsShortcuts,
  UploadFooter,
} from "./components";

import { ProductTabs } from "./views/single-org/product.tabs";
import { RosterModal } from "../users/roster/roster-modal";

import { useApiRequest } from "../../lib/http-client";
import { getClassroomsStats } from "../../services";

import "./dashboard.scss";

export default ({ className, responsiveStyles = {} }) => {
  const { state: locationState } = useLocation();
  const { currentUser, role, products, features } = useStoreState(
    (store) => store.account
  );
  const { classrooms: storeClassrooms } = useStoreState(
    (state) => state.organization
  );

  const { dashboardLoaded, rosteringEnabled } = useStoreState(
    (store) => store.app
  );
  const { organizationCode, organizationName, userId } = currentUser;
  const { organization: singleOrg, breadcrumbLabel } = locationState ?? {};

  const [organization, setOrganization] = useState();
  const [openRosterModal, setOpenRosterModal] = useState();
  const { canCreateUser } = features;

  const { response: classrooms = [], sendRequest: getClassrooms } =
    useApiRequest(
      () => getClassroomsStats(userId, role, organization?.code),
      false
    );

  const setOrganizationData = () => {
    setOrganization({
      name: organizationName,
      code: organizationCode,
      products,
    });
  };

  useEffect(() => {
    if (singleOrg) {
      setOrganization(singleOrg);
    } else {
      setOrganizationData();
    }
  }, [singleOrg]);

  useEffect(() => {
    if (organization?.code && singleOrg && dashboardLoaded) {
      getClassrooms();
    }
  }, [organization, dashboardLoaded]);

  return (
    <div className={clsx("dashboard", className)}>
      <div className="dashboard-container">
        <DashboardHeader
          title={organization?.name}
          breadcrumbLabel={breadcrumbLabel}
        ></DashboardHeader>
        <ProductTabs products={organization?.products}></ProductTabs>
        {canCreateUser && (
          <UploadFooter
            setOpenRosterModal={setOpenRosterModal}
            rosteringEnabled={rosteringEnabled}
          />
        )}
      </div>
      <div className="side-shortcuts">
        <ClassroomsShortcuts
          classrooms={singleOrg ? classrooms : storeClassrooms}
          orgCode={organization?.code}
        ></ClassroomsShortcuts>
      </div>
      {rosteringEnabled && (
        <RosterModal
          onClose={() => setOpenRosterModal(false)}
          show={openRosterModal}
          responsiveStyles={responsiveStyles}
        ></RosterModal>
      )}
    </div>
  );
};
