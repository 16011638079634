import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  DataTable,
  Column,
  ColumnOptions,
  ColumnType,
  BasicCell,
  SortDirection,
  PercentageDataCell,
  TimeDataCell,
} from "@transfr-inc/dashboard-components/data-table";
import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";

import {
  DataTableColumnDefinition,
  getUserDetailPathConfig,
} from "../../../users/list/data-table-column-definition";

import { MasteryStatus } from "../../../../models";

import "./classroom.mastery-table.scss";

export function ClassroomMasteryTable({ data, responsiveStyles }) {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const tableData = data.map((user) => {
      let completedCompletionDate = { completed: false, simCompletionDate: "" };
      if (user.completed) {
        completedCompletionDate.completed = true;
        if (user.simCompletionDate) {
          completedCompletionDate.simCompletionDate = user.simCompletionDate;
        }
      }
      return { ...user, completedCompletionDate };
    });

    setTableData(tableData);
  }, [data]);

  const { classroomId } = useLocation().state;
  const breadcrumbLabel = "Classroom";
  const columns = [
    DataTableColumnDefinition.AVATAR,
    DataTableColumnDefinition.LAST_NAME(
      getUserDetailPathConfig({ breadcrumbLabel, classroomId })
    ),
    DataTableColumnDefinition.FIRST_NAME(
      getUserDetailPathConfig({ breadcrumbLabel, classroomId })
    ),
    DataTableColumnDefinition.USERNAME,
    new Column(
      "timeSpent",
      "Time",
      ColumnType.CUSTOM,
      new ColumnOptions({ sortingEnabled: true, customFunction: TimeDataCell })
    ),
    new Column(
      "status",
      "Status",
      ColumnType.BASIC,
      new ColumnOptions({ sortingEnabled: true })
    ),
    new Column(
      "mastery",
      "Score",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value, row) => {
          const alwaysShowScore =
            row.status !== MasteryStatus.NotStarted && row.status;
          return PercentageDataCell(value, "truncate", alwaysShowScore);
        },
      })
    ),
    new Column(
      "attempts",
      "Attempts",
      ColumnType.COUNT,
      new ColumnOptions({ sortingEnabled: true })
    ),
    new Column(
      "latestTimeStamp",
      "Last Attempted",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => {
          const formattedDate = value ? formatDateDigits(value) : undefined;
          return <BasicCell value={formattedDate}></BasicCell>;
        },
      })
    ),
    new Column(
      "completedCompletionDate",
      "Complete",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => {
          const formattedDate = value.simCompletionDate
            ? formatDateDigits(value.simCompletionDate)
            : undefined;
          return (
            <div className="completed-cell">
              {value.completed ? (
                <>
                  <FontAwesomeIcon icon="fa-solid fa-circle-check"></FontAwesomeIcon>
                  {formattedDate}
                </>
              ) : (
                <BasicCell />
              )}
            </div>
          );
        },
      })
    ),
  ];

  return (
    <DataTable
      className={clsx("classroom-mastery-table", responsiveStyles)}
      columns={columns}
      data={tableData}
      keyFields={["userId"]}
      defaultSortPropName={"latestTimeStamp"}
      defaultSortDirection={SortDirection.DESCENDING}
      responsiveStyles={responsiveStyles}
      rowHeightConfig={{ large: 54, medium: 160, small: 280 }}
    ></DataTable>
  );
}
