import React, { useState } from "react";
import clsx from "clsx";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ProfileAvatar,
  //   Loader,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import container from "../../../container";

import { avatarMap } from "../../../lib/avatar-map";

import "./change-profile-avatar.modal.scss";

export default ({ show, onCancel, onEditAvatar, user }) => {
  const { userService } = container;
  const { userId, avatarId } = user;
  const [selectedAvatar, setSelectedAvatar] = useState(avatarId);

  async function onApply() {
    const avatarId = selectedAvatar;
    const updatedUser = user;
    updatedUser.avatarId = selectedAvatar;

    try {
      await userService.updateUserPreferences({
        userId,
        avatarId,
      });
      onEditAvatar(updatedUser);
    } catch (error) {
      console.log(error.data.detail);
    }
    onCancel();
  }

  const selectAvatar = (newAvatarId) => {
    setSelectedAvatar(newAvatarId);
  };

  return (
    <div className={clsx("edit-user-modal")}>
      <Modal
        open={show}
        onClose={onCancel}
        modalClassName={"change-password-modal"}
        backdropClassName={"change-password-modal-backdrop"}
      >
        <ModalHeader title="Change Photo"></ModalHeader>
        <ModalBody>
          <div className="photo-container">
            {Object.keys(avatarMap).map((k) => (
              <div
                onClick={() => selectAvatar(k)}
                className="photo-box"
                key={k}
              >
                <ProfileAvatar
                  className={`avatar ${
                    selectedAvatar == k ? "selected-avatar" : ""
                  }`}
                  fillColor={avatarMap[k]}
                />
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onApply}>Apply</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
