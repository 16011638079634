import React, { useState } from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";
import { Toolbar } from "@transfr-inc/dashboard-components";

import { DeleteTraineeModal } from "../modals/delete-trainee";
import {
  ChangePasswordModal,
  EditUserModal,
} from "../../../../components/modals";

export function TraineeToolbar({
  trainee,
  onEditTrainee,
  onPasswordUpdated,
  editEnabled,
}) {
  const [openEditModal, setOpenEditModal] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState();
  const [openPasswordModal, setOpenPasswordModal] = useState();
  const showEditTrainee = !trainee?.isAnonymous && editEnabled;

  const onEditModalClose = () => {
    setOpenEditModal(false);
  };

  const onDeleteModalClose = () => {
    setOpenDeleteModal(false);
  };

  const onEditTraineeHandler = (data) => {
    return onEditTrainee(data).then((response) => {
      !response.error && setOpenEditModal();
      return response;
    });
  };

  const onPasswordUpdatedHandler = () => {
    setOpenPasswordModal();
    onPasswordUpdated && onPasswordUpdated();
  };

  const toolbarActions = [
    {
      button: (
        <Button
          size="small"
          key="toolbar-password-trainee-button"
          icon={["fa-regular", "key"]}
          title={"Change Password"}
          tooltip={"Change Password"}
          onClick={() => setOpenPasswordModal(true)}
        ></Button>
      ),
      modal: (
        <ChangePasswordModal
          key="toolbar-password-trainee-modal"
          open={openPasswordModal}
          userIds={[trainee.userId]}
          onCancel={() => setOpenPasswordModal()}
          onPasswordChanged={onPasswordUpdatedHandler}
        ></ChangePasswordModal>
      ),
      enabled: showEditTrainee,
    },
    {
      button: (
        <Button
          size="small"
          key="toolbar-edit-trainee-button"
          icon={["fa-regular", "pen-to-square"]}
          onClick={() => setOpenEditModal(true)}
          title={"Edit Trainee"}
          tooltip={"Edit Trainee"}
        ></Button>
      ),
      modal: (
        <EditUserModal
          key="toolbar-edit-trainee-modal"
          open={openEditModal}
          user={trainee}
          onClose={onEditModalClose}
          onUpdateUser={onEditTraineeHandler}
        ></EditUserModal>
      ),
      enabled: showEditTrainee,
    },
    {
      button: (
        <Button
          size="small"
          key="toolbar-delete-trainee-button"
          icon={["fa-regular", "trash-can"]}
          onClick={() => setOpenDeleteModal(true)}
          title={"Delete Trainee"}
          tooltip={"Delete Trainee"}
        ></Button>
      ),
      modal: (
        <DeleteTraineeModal
          key="toolbar-delete-trainee-modal"
          trainee={trainee}
          open={openDeleteModal}
          onClose={onDeleteModalClose}
        ></DeleteTraineeModal>
      ),
      enabled: false,
    },
  ];

  return (
    <>
      <Toolbar>
        {toolbarActions.map(({ enabled, button }) => enabled && button)}
      </Toolbar>
      {toolbarActions.map(({ enabled, modal }) => enabled && modal)}
    </>
  );
}
