import { setDailyStats } from "../lib/chart-utils";
import HttpClient from "../lib/http-client";

const defaultExportOptions = {
  createFile: true,
};

/**
 * @memberof Services
 */
export class OrganizationService extends HttpClient {
  getOrganizationDetails(orgCode) {
    const apiUrl = `/organization/${orgCode}`;
    return this.http.get(apiUrl);
  }

  /**
   *
   * @param {string} orgCode
   * @returns {array}
   */
  getChildOrganizationStats(multiOrgCode) {
    const apiUrl = `/multi-organization/${multiOrgCode}/organizations/stats`;

    return this.http.get(apiUrl);
  }

  getMultiOrganizationStats(orgCode, filters) {
    const apiUrl = `/multi-organization/${orgCode}/stats`;
    return this.getGeneralStats(apiUrl, filters);
  }

  getMultiOrganizationCeStats(orgCode, filters) {
    const apiUrl = `/multi-organization/${orgCode}/ce/stats`;
    return this.getGeneralStats(apiUrl, filters);
  }

  exportMultiOrganizationCeStats(orgCode, filters, fileName, options) {
    const apiUrl = `/multi-organization/${orgCode}/ce/stats/export`;
    return this.exportStats(apiUrl, filters, fileName, options);
  }

  getMultiOrganizationDailyStats(orgCode, filters) {
    const apiUrl = `/multi-organization/${orgCode}/daily/stats/`;
    return this.getDailyStats(apiUrl, filters);
  }

  getMultiOrganizationDailyCeStats(orgCode, filters) {
    const apiUrl = `/multi-organization/${orgCode}/daily/ce/stats/`;
    return this.getDailyStats(apiUrl, filters);
  }

  exportMultiOrganizationStats(orgCode, filters, fileName) {
    const apiUrl = `/multi-organization/${orgCode}/stats/export`;
    return this.exportStats(apiUrl, filters, fileName);
  }

  getOrganizationStats(orgCode, filters) {
    const apiUrl = `/organization/${orgCode}/stats`;
    return this.getGeneralStats(apiUrl, filters);
  }

  getOrganizationCeStats(orgCode, filters, options) {
    const apiUrl = `/organization/${orgCode}/ce/stats`;
    return this.getGeneralStats(apiUrl, filters, options);
  }

  getOrganizationDailyStats(orgCode, filters) {
    const apiUrl = `/organization/${orgCode}/daily/stats/`;
    return this.getDailyStats(apiUrl, filters);
  }

  getOrganizationDailyCeStats(orgCode, filters) {
    const apiUrl = `/organization/${orgCode}/daily/ce/stats/`;
    return this.getDailyStats(apiUrl, filters);
  }

  exportOrganizationStats(orgCode, filters, fileName) {
    const apiUrl = `/organization/${orgCode}/stats/export`;
    return this.exportStats(apiUrl, filters, fileName);
  }

  exportOrganizationCeStats(orgCode, filters, fileName, options) {
    const apiUrl = `/organization/${orgCode}/ce/stats/export`;
    return this.exportStats(apiUrl, filters, fileName, options);
  }

  getInstructorStats(instructorId, filters) {
    const apiUrl = `/user/instructor/${instructorId}/stats/`;
    return this.getGeneralStats(apiUrl, filters).then((response) =>
      response?.pop()
    );
  }

  getInstructorCeStats(instructorId, filters) {
    const apiUrl = `/user/instructor/${instructorId}/ce/stats`;
    return this.getGeneralStats(apiUrl, filters).then((response) =>
      response?.pop()
    );
  }

  exportInstructorStats(instructorId, filters, fileName) {
    const apiUrl = `/user/instructor/${instructorId}/stats/export`;
    return this.exportStats(apiUrl, filters, fileName);
  }

  exportInstructorCeStats(instructorId, filters, fileName, options) {
    const apiUrl = `/user/instructor/${instructorId}/ce/stats/export`;
    return this.exportStats(apiUrl, filters, fileName, options);
  }

  getInstructorDailyStats(instructorId, filters) {
    const apiUrl = `/user/instructor/${instructorId}/daily/stats/`;
    return this.getDailyStats(apiUrl, filters);
  }

  getInstructorDailyCeStats(instructorId, filters) {
    const apiUrl = `/user/instructor/${instructorId}/daily/ce/stats/`;
    return this.getDailyStats(apiUrl, filters);
  }

  getGeneralStats(api, filters) {
    return this.http.post(api, { filters });
  }

  getDailyStats(api, filters) {
    return this.http
      .post(api, { filters })
      .then((response) => setDailyStats(filters, response));
  }

  getTopCareers(api, filters) {
    return this.http.post(api, { filters }).catch((error) => {
      console.error(`[Insights Service] - An error occured: ${error}`);
      return [];
    });
  }

  getInstructorTopCareers(instructorId, filters) {
    const apiUrl = `/user/instructor/${instructorId}/ce/top-careers`;
    return this.getTopCareers(apiUrl, filters);
  }

  getOrganizationTopCareers(orgCode, filters) {
    const apiUrl = `organization/${orgCode}/ce/top-careers`;
    return this.getTopCareers(apiUrl, filters);
  }

  getMultiOrganizationTopCareers(orgCode, filters) {
    const apiUrl = `multi-organization/${orgCode}/ce/top-careers`;
    return this.getTopCareers(apiUrl, filters);
  }

  getInstructorCareerRatings(instructorId, learningExperienceId, filters) {
    const apiUrl = `/user/instructor/${instructorId}/ce/career/${learningExperienceId}/ratings`;
    return this.getTopCareers(apiUrl, filters);
  }

  getOrganizationCareerRatings(orgCode, learningExperienceId, filters) {
    const apiUrl = `organization/${orgCode}/ce/career/${learningExperienceId}/ratings`;
    return this.getTopCareers(apiUrl, filters);
  }

  getMultiOrganizationCareerRatings(orgCode, learningExperienceId, filters) {
    const apiUrl = `multi-organization/${orgCode}/ce/career/${learningExperienceId}/ratings`;
    return this.getTopCareers(apiUrl, filters);
  }

  exportStats(api, filters, fileName, exportOptions) {
    const requestConfig = {
      method: "post",
      url: api,
      data: { filters },
    };
    const options = { ...defaultExportOptions, ...exportOptions };

    return options.createFile
      ? this.downloadBlob(requestConfig, fileName, "csv")
      : this.parseCsvResponse(requestConfig);
  }

  async getChildOrganizations(multiOrgCode) {
    const apiUrl = `/multi-organization/${multiOrgCode}/organizations`;

    const data = await this.http.get(apiUrl);
    return data;
  }

  async getOrganizationCustomCategories(orgCode) {
    const apiUrl = `/organization/${orgCode}/custom_categories`;

    const data = await this.http.get(apiUrl);
    return data;
  }

  async getMultiOrganizationCustomCategories(orgCode) {
    const apiUrl = `/multi-organization/${orgCode}/custom_categories`;

    const data = await this.http.get(apiUrl);
    return data;
  }
}
