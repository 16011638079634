import { Role } from "./role";

export const RoleTransformations = [
  [Role.MULTI_ORG_ADMIN.id, [Role.MULTI_ORG_VIEWER]],
  [Role.MULTI_ORG_VIEWER.id, [Role.MULTI_ORG_ADMIN]],
  [Role.INSTRUCTOR.id, [Role.ORG_ADMIN]],
  [Role.ORG_ADMIN.id, [Role.INSTRUCTOR]],
];
export const RoleTransformationsMap = new Map(RoleTransformations);

export const getRoleTransformations = (role) => {
  return RoleTransformationsMap.get(role.id);
};
