import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useStoreState } from "easy-peasy";
import clsx from "clsx";

import {
  FullScreenModal,
  FullScreenModalBody,
  FullScreenModalHeader,
  FullScreenModalFooter,
  NotificationType,
  Loader,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { ClassroomInfo } from "./classroom-info";

import { ConfirmDemoModal } from "./confirm-demo-modal";

import vrClassroomImage from "../../../../public/images/create-classroom-hero.jpg";
import container from "../../../container";

import { Product } from "../../../models";
import "./new-classroom-modal.scss";

export const NewClassroomModal = ({
  onClose,
  show,
  orgCode,
  general_orgSelectionEnabled = true,
  instructorCreationEnabled = true,
  className,
}) => {
  // If org is selectable, do not use passed in code - passed in code is the multiorg code, not an org code
  const [loading, setLoading] = useState();
  const [confirmDemoOpen, setConfirmDemoOpen] = useState();

  const currentOrg = general_orgSelectionEnabled
    ? undefined
    : { code: orgCode };

  // NOTE: specifying a default value for trainees and instructors results in state not being saved, perhaps
  // because they are arrays? Default values have been specified at the <Controller> node level
  const methods = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      name: "",
      organization: currentOrg,
      product: "",
      isDemo: false,
    },
  });

  methods.formState.isValid;
  methods.formState.errors;

  const { handleSubmit, reset, formState, watch } = methods;

  const { isValid } = formState;

  const { ceEnabled, trekEnabled } = useStoreState((state) => state.app);
  const { products } = useStoreState((state) => state.account);
  const { classroomService } = container;

  const submitForm = async (values) => {
    setLoading(true);
    const instructorIds = values.instructors.map((optionItem) => optionItem.id);

    const traineeIds = values.trainees?.map((optionItem) => optionItem.id);

    const productList =
      values.product.id == Product.TRK
        ? [Product.TRK, Product.CE]
        : [values.product.id];

    const classroom = {
      organizationCode: values.organization.code,
      productIds: productList,
      name: values.name,
      instructorIds: instructorIds,
      traineeIds: traineeIds,
      sessionTimeLimit: 0,
      isDemo: values.isDemo,
    };

    await classroomService.saveClassroom(classroom);

    // CA TODO: temp hack employed due to issues with data being preserved between launches of this modal
    const notification = {
      type: NotificationType.success,
      message: `${values.name} has been created.`,
    };
    setLoading();
    return closeDialog(/* confirm: */ true, notification);
  };

  const closeDialog = (confirm, notification) => {
    reset();
    onClose(notification);
    return confirm;
  };

  const cancelDialog = () => {
    return closeDialog(/* confirm: */ false);
  };

  const onCreateClick = (e) => {
    e.preventDefault();
    setConfirmDemoOpen();
    handleSubmit(submitForm)();
  };

  const onConfirmCreateClick = (e) => {
    const values = watch();
    if (values.isDemo) {
      setConfirmDemoOpen(true);
    } else {
      onCreateClick(e);
    }
  };

  return (
    <>
      <FullScreenModal
        className="create-classroom-modal"
        onClose={cancelDialog}
        open={show}
      >
        <FullScreenModalHeader
          title="Create a Classroom"
          titleIcon="fa-regular fa-screen-users"
        />
        <FullScreenModalBody>
          <div className="create-classroom">
            {loading && <Loader overlay></Loader>}
            <div className="image">
              <img src={vrClassroomImage} alt="VR Classroom" />
            </div>
            <div className="classroom-info">
              <FormProvider {...methods}>
                <form
                  className={clsx("account-form", className)}
                  noValidate={true}
                >
                  <ClassroomInfo
                    ceEnabled={ceEnabled}
                    trekEnabled={trekEnabled}
                    products={products}
                    orgSelectionEnabled={general_orgSelectionEnabled}
                    instructorCreationEnabled={instructorCreationEnabled}
                  />
                </form>
              </FormProvider>
            </div>
          </div>
        </FullScreenModalBody>
        <FullScreenModalFooter>
          <Button disabled={loading} onClick={cancelDialog}>
            Cancel
          </Button>
          <Button
            primary
            disabled={!isValid || loading}
            onClick={onConfirmCreateClick}
          >
            Create Classroom
          </Button>
        </FullScreenModalFooter>
      </FullScreenModal>
      <ConfirmDemoModal
        open={confirmDemoOpen}
        onBackToModal={setConfirmDemoOpen}
        onConfirmClose={onCreateClick}
      ></ConfirmDemoModal>
    </>
  );
};
