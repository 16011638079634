import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import clsx from "clsx";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Loader,
  NotificationType,
  RequiredFieldsText,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { UserInfo } from "../../custom/forms";
import { RoleSelector } from "../../custom/dropdowns";

import {
  areRolesEqual,
  getRole,
  Role,
  getRoleTransformations,
} from "../../../models";

import { catchEditUserError } from "../../../services";

import "./edit-user-modal.scss";

/**
 * Modal which allows editing of user's role, name, and email   address. Only specific role transformations are involved.
 */
export default ({ onClose, open, onUpdateUser, className, user }) => {
  const [defaultValues, setDefaultValues] = useState(user);
  const { register, handleSubmit, control, formState, reset, trigger } =
    useForm({
      criteriaMode: "all",
      mode: "onChange",
      defaultValues,
    });
  const { isValid } = formState;
  const initialRole = getRole(user.roleId);
  const [stateSelectedRole, setStateSelectedRole] = useState(initialRole);
  const [stateNotification, setStateNotification] = useState();
  const [loading, setLoading] = useState(false);
  const originalDataRef = useRef(user);
  const selectedRole = stateSelectedRole ?? initialRole;

  const submitForm = async (values) => {
    const user = {
      firstName: values.firstName,
      lastName: values.lastName,
      roleId: selectedRole.id,
      username: values.username,
      userId: originalDataRef.current.userId,
    };

    if (values.email) user["email"] = values.email;

    const updatedUser = await onUpdateUser(user);
    setLoading(false);

    if (updatedUser.error) {
      const errorMessage = catchEditUserError(updatedUser, selectedRole.id);
      setStateNotification({
        type: NotificationType.error,
        message: errorMessage,
      });
      return false;
    }
    setStateNotification();
    setDefaultValues(updatedUser);
    onClose();

    return true;
  };

  const editUser = () => {
    setLoading(true);
    handleSubmit(submitForm)();
  };

  const onRoleSelected = (role) => {
    setStateSelectedRole(role);
  };

  const roleTransformations = getRoleTransformations(selectedRole);
  const selectableRoles = [selectedRole, ...(roleTransformations ?? [])];

  useEffect(() => {
    open && trigger();
    !open && reset(defaultValues);
  }, [open]);

  return (
    <div className={clsx("edit-user-modal", className)}>
      <Modal
        modalClassName={"edit-user-modal"}
        open={open}
        onClose={onClose}
        uniqueName="edit-user-modal"
      >
        {loading && <Loader overlay />}
        <ModalHeader
          className="blue-icon small"
          icon={["fa-light", "edit"]}
          title="Edit User Details"
          uniqueName="edit-user-modal"
        ></ModalHeader>

        <ModalBody className={"create-trainee modal-user-details"}>
          <RequiredFieldsText />
          {initialRole.id !== Role.STUDENT.id && (
            <RoleSelector
              onRoleSelected={onRoleSelected}
              roles={selectableRoles}
              selectedRole={selectedRole}
            />
          )}

          <UserInfo
            register={register}
            control={control}
            defaultValues={defaultValues}
            formState={formState}
            role={initialRole}
            emailOptional={areRolesEqual(selectedRole, Role.STUDENT)}
            responseNotification={stateNotification}
            onNotificationChange={setStateNotification}
            isPasswordEnabled={false}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button primary onClick={editUser} disabled={!isValid}>
            Done
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
