import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import PropTypes from "prop-types";
import clsx from "clsx";

import {
  FullScreenModal,
  FullScreenModalBody,
  FullScreenModalHeader,
  FullScreenModalFooter,
  NotificationType,
  Loader,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { NewUserForm } from "./new-user-form";
import { RoleSelector } from "../../../components/custom/dropdowns/role-selector";

import { Role, getRoleFeatures, getRoleName } from "../../../models/role";
import container from "../../../container";

import createUserHero from "../../../../public/images/create-user-hero.jpg";
import "./new-user-modal.scss";
import { useStoreActions, useStoreState } from "easy-peasy";

export const NewUserModal = ({
  setShow,
  show,
  className,
  role,
  creatableUsers,
  onUserCreated,
}) => {
  const [loading, setLoading] = useState();
  const { userService, currentUserCache } = container;
  const organizationCode = currentUserCache.get().organizationCode;
  const { refreshOrganizationUsers } = useStoreActions(
    (store) => store.organization
  );
  const { role: currentUserRole, currentUser } = useStoreState(
    (store) => store.account
  );
  const { userId } = currentUser;

  //   get role permissions
  const permittedRoles = [];
  for (const role in Role) {
    const hasFeature = creatableUsers["canCreate" + role + "User"];
    if (hasFeature && role != "STUDENT") {
      permittedRoles.push(Role[role]);
    }
  }
  permittedRoles.sort((a, b) => a.displayOrder - b.displayOrder);

  // State variables & functions for dropdowns
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedClassroom, setSelectedClassroom] = useState("");
  const [selectedOrg, setSelectedOrg] = useState("");

  const onRoleSelected = (optionSelected) => {
    setSelectedRole(optionSelected);
    clearOrgSelection();
    setSelectedClassroom("");
    reset();
    setNotification();
  };

  const onOrgSelected = (selectedOrg) => {
    setSelectedOrg(selectedOrg);
    reset();
    setSelectedClassroom("");
    setNotification();
  };

  const onClassroomSelected = (selectedClassroom) => {
    setSelectedClassroom(selectedClassroom);
  };

  // Handle the success notification
  const [notification, setNotification] = useState();
  const [formInvalid, setFormInvalid] = useState(true);

  const formValidityChange = (validity) => {
    setFormInvalid(validity);
  };

  const orgSelectionEnabled =
    getRoleFeatures(selectedRole.id)?.orgSelectionEnabled &&
    role == Role.MULTI_ORG_ADMIN.id;

  const classroomSelectionEnabled = getRoleFeatures(
    selectedRole.id
  )?.classroomSelectionEnabled;

  const methods = useForm({
    criteriaMode: "all",
    mode: "onBlur",
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      username: "",
      password: "",
    },
  });

  // refs:
  // https://react-hook-form.com/api/useform/formstate
  // https://react-hook-form.com/advanced-usage#FormProviderPerformance
  //
  // Although formState is not used in this component, we still need to "subscribe" to it due to the following mention
  // in the first link above:
  // "formState is wrapped with a Proxy to improve render performance and skip extra logic if specific state is not
  // subscribed to. Therefore make sure you invoke or read it before a render in order to enable the state update."
  // The second link demonstrates sample usage with FormProvider that we are employing here.

  const { handleSubmit, reset, formState } = methods;

  formState.errors;
  formState.isValid;
  formState.dirtyFields;

  async function submitForm(values, e) {
    e.preventDefault();
    setLoading(true);

    const user = {
      firstName: values.firstName,
      lastName: values.lastName,
      roleId: selectedRole.id,
    };

    if (selectedClassroom) user["classrooms"] = [selectedClassroom.classroomId];
    if (values.email.length > 0) user["email"] = values.email;
    if (values.username.length > 0) user["username"] = values.username;
    if (values.password.length > 0) user["password"] = values.password;

    const orgCode = selectedOrg ? selectedOrg.code : organizationCode;

    let returnValue = false;
    try {
      await userService.createNewUser(user, orgCode);
      setSelectedRole("");
      clearOrgSelection();
      setSelectedClassroom("");
      reset();

      // CA TODO: temp hack employed due to issues with data being preserved between launches of this modal
      const notification = {
        type: NotificationType.success,
        message: `${values.firstName} ${
          values.lastName
        } has been created as a ${getRoleName(selectedRole.id)}`,
      };
      await refreshOrganizationUsers({
        role: currentUserRole,
        orgCode,
        userId,
      });
      onUserCreated(notification);
      setShow(false);
      returnValue = true;
    } catch (error) {
      console.log("failure");
      let errorMessage =
        error.data === undefined ? "Unable to create user." : error.data.detail;
      if (error.status == 409) {
        errorMessage =
          selectedRole.id == Role.STUDENT.id
            ? "Record already exists for this email or username"
            : "Email is not unique.";
      }
      setNotification({
        type: NotificationType.error,
        message: errorMessage,
      });
      returnValue = false;
    } finally {
      setLoading();
    }

    return returnValue;
  }

  const onClick = (e) => {
    (async () => {
      handleSubmit(submitForm)(e);
    })();
  };

  const clearOrgSelection = () => {
    if (orgSelectionEnabled) {
      setSelectedOrg();
    }
  };

  const closeModal = () => setShow();

  return (
    <FullScreenModal
      className={clsx("new-user-modal", className)}
      onClose={closeModal}
      open={show}
    >
      <FullScreenModalHeader
        title="Create a User"
        titleIcon="fa-regular fa-user-plus"
      />
      <FullScreenModalBody>
        <div className="multi-org-new-user">
          {loading && <Loader overlay></Loader>}
          <div className="image">
            <img src={createUserHero} alt="banner hero" />
          </div>
          <div className="text-with-info">
            <label>
              Required Fields<sup>*</sup>
            </label>
          </div>
          <RoleSelector
            roles={permittedRoles}
            onRoleSelected={onRoleSelected}
          />

          <div className="new-user-form">
            <FormProvider {...methods}>
              <form className="account-form" noValidate={true}>
                <NewUserForm
                  role={selectedRole}
                  setFormInvalid={(e) => formValidityChange(e)}
                  orgSelectionEnabled={orgSelectionEnabled}
                  classroomSelectionEnabled={classroomSelectionEnabled}
                  selectedOrg={selectedOrg}
                  selectedClassroom={selectedClassroom}
                  onOrgSelected={onOrgSelected}
                  onClassroomSelected={onClassroomSelected}
                  responseNotification={notification}
                  onNotificationChange={setNotification}
                />
              </form>
            </FormProvider>
          </div>
        </div>
      </FullScreenModalBody>
      <FullScreenModalFooter>
        <Button disabled={loading} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          primary
          disabled={formInvalid || loading}
          onClick={onClick}
          loader={loading}
        >
          Create User
        </Button>
      </FullScreenModalFooter>
    </FullScreenModal>
  );
};

NewUserModal.propTypes = {
  creatableUsers: PropTypes.shape({
    canCreateMULTI_ORG_ADMINUser: PropTypes.bool.isRequired,
    canCreateORG_ADMINUser: PropTypes.bool.isRequired,
    canCreateMULTI_ORG_VIEWERUser: PropTypes.bool.isRequired,
    canCreateINSTRUCTORUser: PropTypes.bool.isRequired,
    canCreateSTUDENTUser: PropTypes.bool.isRequired,
  }),
};

export default NewUserModal;
