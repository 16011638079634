import React, { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import clsx from "clsx";

import { Badge, HyphenIcon } from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import {
  AddCategoryModal,
  ActivityLogModal,
  CreateCategoryModal,
  EditCategoriesModal,
  ResetMenuModal,
} from "../modals/index.js";

import { CategoryFilter } from "../components/category.filter.jsx";

import "./menu-builder.header.scss";

export const MenuBuilderHeader = ({
  categories,
  onCategoryFilterSelected,
  onResetMenu,
  onCreateCategory,
  onAddCategories,
  onEditCategoriesClick,
  className,
  classroom,
  enableEdit,
  checkVersionNumber,
}) => {
  const [actionsDisabled, setActionsDisabled] = useState();
  const [openEditModal, setOpenEditModal] = useState();
  const [openResetMenuModal, setOpenResetMenuModal] = useState();
  const [openAddCategoryModal, setOpenAddCategoryModal] = useState();
  const [openCreateCategoryModal, setOpenCreateCategoryModal] = useState();
  const [openActivityLogModal, setOpenActivityLogModal] = useState();

  const categoryCount = useMemo(
    () => categories?.filter((c) => c.isVisible).length,
    [categories]
  );

  const onEditCategoriesHandler = (categories) => {
    return onEditCategoriesClick(categories).finally(() => setOpenEditModal());
  };

  const onResetMenuHandler = () => {
    return onResetMenu().then(() => setOpenResetMenuModal());
  };

  const onCreateCategoryHandler = (category) => {
    return onCreateCategory(category).finally(() =>
      setOpenCreateCategoryModal()
    );
  };

  useEffect(() => {
    setActionsDisabled(!categories);
  }, [categories]);

  const onOpenCreateCategoryModalHandler = () => {
    setOpenAddCategoryModal();
    setOpenCreateCategoryModal(true);
  };

  const onAddCategoriesHandler = (categories) => {
    onAddCategories(categories);
    setOpenAddCategoryModal();
  };

  return (
    <>
      <div className={clsx("menu-builder-header", className)}>
        <div className="left-section">
          <h2>Categories</h2>
          <Badge value={categoryCount ?? <HyphenIcon />} />
          {enableEdit && (
            <>
              <Button
                icon={["fa-regular", "pen-to-square"]}
                onClick={() => setOpenEditModal(true)}
                disabled={actionsDisabled}
                tooltip="Edit Categories"
              />
              <Button
                icon={["fa-regular", "plus"]}
                onClick={() => setOpenAddCategoryModal(true)}
                disabled={actionsDisabled}
                tooltip="Add Category"
              />
            </>
          )}
        </div>
        <div className="right-section">
          {enableEdit && (
            <Button
              icon={["fa-regular", "clock-rotate-left"]}
              onClick={() => setOpenResetMenuModal(true)}
              disabled={actionsDisabled}
            >
              Reset to Default
            </Button>
          )}
          <Button
            icon={["fa-regular", "list-timeline"]}
            onClick={() => setOpenActivityLogModal(true)}
            disabled={actionsDisabled}
          >
            Activity Log
          </Button>
          <CategoryFilter
            onFilterSelected={onCategoryFilterSelected}
            disabled={actionsDisabled}
          />
        </div>
      </div>
      {createPortal(
        <EditCategoriesModal
          open={openEditModal}
          onClose={() => setOpenEditModal()}
          categories={categories}
          onApplyChanges={onEditCategoriesHandler}
          checkVersionNumber={checkVersionNumber}
          async
        />,
        document.body
      )}
      {createPortal(
        <ResetMenuModal
          open={openResetMenuModal}
          onClose={() => setOpenResetMenuModal()}
          onConfirmReset={onResetMenuHandler}
          checkVersionNumber={checkVersionNumber}
          async
        />,
        document.body
      )}
      {createPortal(
        <AddCategoryModal
          open={openAddCategoryModal}
          onCancel={() => setOpenAddCategoryModal()}
          classroom={classroom}
          onCreateCategory={onOpenCreateCategoryModalHandler}
          onAddCategories={onAddCategoriesHandler}
          async
        ></AddCategoryModal>,
        document.body
      )}
      {createPortal(
        <CreateCategoryModal
          open={openCreateCategoryModal}
          onCancel={() => setOpenCreateCategoryModal()}
          classroom={classroom}
          onCreateCategorySuccessHandler={onCreateCategoryHandler}
          checkVersionNumber={checkVersionNumber}
          async
        ></CreateCategoryModal>,
        document.body
      )}
      {createPortal(
        <ActivityLogModal
          open={openActivityLogModal}
          onClose={() => setOpenActivityLogModal()}
          classroom={classroom}
          async
        ></ActivityLogModal>,
        document.body
      )}
    </>
  );
};
