import { useEffect, useState } from "react";

import {
  mergeCeMasteryInfoWithData,
  mergeTrekUserMasteryInfo,
} from "../views/classrooms/details/components/classroom.modules.utils";

import container from "../container";
import { useApiRequest } from "./http-client";

const sortByNameFn = (a, b) => {
  const aName = a.occupationName?.toLowerCase();
  const bName = b.occupationName?.toLowerCase();
  const ratingDiff = b.totalHighlyRated - a.totalHighlyRated;
  if (ratingDiff == 0) {
    return aName < bName ? -1 : 1;
  } else {
    return ratingDiff;
  }
};

const setCareerInfo = (careers, catalog) => {
  const simulations = catalog.map((s) => [s.learningExperienceId, s]);
  const simulationsMap = new Map(simulations);

  return careers
    .map((c) => {
      const career = simulationsMap.get(c.learningExperienceId);
      return (
        career && {
          ...c,
          ...career,
        }
      );
    })
    .filter((c) => c);
};

export const useClassroomCEProgress = (classroomId, explorers) => {
  const [topCareers, setTopCareers] = useState();
  const [careersExplored, setCareersExplored] = useState();
  const [careersUnexplored, setCareersUnexplored] = useState();

  const { insightsService, simulationsService } = container;

  const { response: usersMasteryResults } = useApiRequest(() =>
    insightsService.getClassroomCeResults(classroomId)
  );

  const { response: careersResponse } = useApiRequest(() =>
    simulationsService.getClassroomSimulations(classroomId)
  );

  const { response: topCareersResponse } = useApiRequest(async () =>
    insightsService.getCEClassroomTopCareers(classroomId)
  );

  const { response: insights } = useApiRequest(() =>
    insightsService.getClassroomCeSummaryStats(classroomId)
  );

  const cancelRequests = () => {
    insightsService.cancelRequest();
    simulationsService.cancelRequest();
  };

  useEffect(() => cancelRequests, []);

  useEffect(() => {
    if (usersMasteryResults && careersResponse) {
      const data = mergeCeMasteryInfoWithData(
        explorers,
        careersResponse,
        usersMasteryResults
      );

      setCareersExplored(data.exploredSims);
      setCareersUnexplored(data.unexploredSims);
    }
  }, [usersMasteryResults, careersResponse]);

  useEffect(() => {
    if (topCareersResponse && careersResponse) {
      setTopCareers(
        setCareerInfo(topCareersResponse, careersResponse).sort(sortByNameFn)
      );
    }
  }, [topCareersResponse, careersResponse]);

  return {
    careersExplored,
    careersUnexplored,
    topCareers,
    insights,
  };
};

export const useClassroomTrekProgress = (classroomId, users) => {
  const { trekService, insightsService } = container;
  const [careersExplored, setCareersExplored] = useState();
  const [loadingCareersExplored, setLoadingCareersExplored] = useState();

  const {
    response: careersExploredResponse,
    sendRequest: getCareersExploredRequest,
    cancelRequest: cancelCareersExploredRequest,
    loading: getCareersExploredLoading,
  } = useApiRequest(
    () =>
      trekService.getUsersExportData({
        classroomId,
        options: {
          include_lightcast_data: false,
        },
      }),
    false
  );

  const {
    response: ceResultsResponse,
    sendRequest: getCEResultsRequest,
    cancelRequest: cancelCEResultsRequest,
    loading: getCEResultsLoading,
  } = useApiRequest(
    () =>
      insightsService.getClassroomCeResults(classroomId, {
        includeOccupationCodes: true,
      }),
    false
  );

  const {
    response: trekInsights,
    sendRequest: getTrekInsightsRequest,
    cancelRequest: cancelTrekInsightsRequest,
  } = useApiRequest(() => trekService.getInsights({ classroomId }), false);

  useEffect(() => {
    setLoadingCareersExplored(true);
    Promise.allSettled([
      getCEResultsRequest(),
      getCareersExploredRequest(),
      getTrekInsightsRequest(),
    ]);

    return () => {
      cancelCEResultsRequest();
      cancelCareersExploredRequest();
      cancelTrekInsightsRequest();
    };
  }, [classroomId]);

  useEffect(() => {
    if (careersExploredResponse && ceResultsResponse) {
      setCareersExplored(
        mergeTrekUserMasteryInfo(
          users,
          careersExploredResponse,
          ceResultsResponse
        )
      );
    }
  }, [careersExploredResponse, ceResultsResponse]);

  useEffect(() => {
    setLoadingCareersExplored(
      !careersExplored && (getCEResultsLoading || getCareersExploredLoading)
    );
  }, [getCEResultsLoading, getCareersExploredLoading, careersExplored]);

  return {
    careersExplored,
    trekInsights,
    loadingCareersExplored,
  };
};
