import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { saveAs } from "file-saver";
import { getErrorMessage } from "../../../lib/browser-utils";
import clsx from "clsx";

import {
  Button,
  OrFormDivider,
  FileInput,
} from "@transfr-inc/dashboard-components/forms";

import {
  Notification,
  NotificationType,
  TextTooltip,
} from "@transfr-inc/dashboard-components";

import { UserSelect } from "./user-select";
import CreateTraineeModal from "./create-trainee-modal";
import { Product, getProductName } from "../../../models";

import container from "../../../container";

import "./trainees-editor.scss";

const downloadBulkUploadTemplate = (product) => {
  const productSuffix = getProductName(product)?.toLowerCase();
  saveAs(
    `/templates/bulk-user-upload-template.${productSuffix}.csv`,
    "bulk-user-upload-template.csv"
  );
};

export function TraineesEditor({
  traineeData,
  onCreateTrainee,
  className,
  userLabels,
  classroomProduct = Product.TS,
  classroomId,
  bulkUploadEnabled = false,
  rosteringEnabled = false,
  openRosterModal,
}) {
  const { singular, plural } = userLabels;

  const TEMPLATE_DOWNLOAD_INFO_TEXT =
    `Please download and fill out the ${singular} template. Once completed press Bulk Upload and locate your file. Any ` +
    `${plural} not currently in the system will be created. ` +
    `Larger uploads will continue to process in the background. We will send you an email when the file is finished.`;

  const { control } = useFormContext();

  const selectedOrg = useWatch({ name: "organization" });

  const [showCreateTraineeModal, setShowCreateTraineeModal] = useState(false);
  const [successfullyCreatedTrainee, setSuccessfullyCreatedTrainee] =
    useState();
  const [
    showFileUploadSuccessNotification,
    setShowFileUploadSuccessNotification,
  ] = useState(false);
  const [showFileUploadErrorNotification, setShowFileUploadErrorNotification] =
    useState(false);
  const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState();

  const [loading, setLoading] = useState(false);

  const onCreateTraineeModalClose = (trainee) => {
    if (!trainee || trainee.canceled) {
      setSuccessfullyCreatedTrainee();
    } else {
      setSuccessfullyCreatedTrainee(trainee);
    }

    setShowCreateTraineeModal(false);
  };

  const clearNotifications = () => {
    setShowFileUploadErrorNotification(false);
    setShowFileUploadSuccessNotification(false);
  };

  const { userService } = container;

  const onHandleFile = async (file) => {
    setLoading(true);
    clearNotifications();
    try {
      const isValidCsv = await userService.uploadUsersAsync(
        file,
        selectedOrg.code,
        classroomId
      );

      if (isValidCsv == true) {
        setShowFileUploadSuccessNotification(true);
      }
    } catch (error) {
      setFileUploadErrorMessage(getErrorMessage(error.data));
      setShowFileUploadErrorNotification(true);
    }
    setLoading(false);
  };

  return (
    <div className={clsx("trainees-editor", className)}>
      <div
        className={clsx(
          "notifications-area",
          `${
            (showFileUploadErrorNotification ||
              showFileUploadSuccessNotification) &&
            "show-notifications"
          }`
        )}
      >
        {successfullyCreatedTrainee && (
          <Notification
            type={NotificationType.success}
            closable
            onClose={() => {
              setSuccessfullyCreatedTrainee();
            }}
          >
            {successfullyCreatedTrainee.firstName}{" "}
            {successfullyCreatedTrainee.lastName} has been created as an
            trainee.
          </Notification>
        )}
        {showFileUploadSuccessNotification && (
          <Notification
            type={NotificationType.success}
            closable
            onClose={() => {
              setShowFileUploadSuccessNotification();
            }}
            icon="fa-regular fa-head-side-goggles"
          >
            {`Your file has been successfully uploaded. Due to its size this file will ` +
              `continue to process in the background. Feel free to continue and we will ` +
              `send you an email when it's finished. `}
          </Notification>
        )}
        {showFileUploadErrorNotification && (
          <Notification
            type={NotificationType.error}
            closable
            onClose={() => {
              setShowFileUploadErrorNotification();
            }}
          >
            {fileUploadErrorMessage}
          </Notification>
        )}

        {/*
            Post-MVP

        <Notification
          type={NotificationType.error}
          icon="fa-regular fa-head-side-goggles"
          closable={true}
          className="bulk-upload-notification"
        >
          Your organization has no remaining trainee seats.
        </Notification> */}
      </div>
      {bulkUploadEnabled && (
        <>
          {rosteringEnabled ? (
            <>
              <div className="text-with-info">
                <label>
                  Quickly create trainees for this classroom using Bulk Roster.
                </label>
              </div>
              <div className="create-trainee-button">
                <Button
                  icon={["fa-regular", "clipboard-list-check"]}
                  onClick={openRosterModal}
                  size="small"
                >
                  Bulk Roster
                </Button>
              </div>
              <OrFormDivider />
            </>
          ) : (
            <>
              <div className="text-with-info">
                <label>
                  Bulk upload is limited to a maximum of 1000 trainees.
                </label>
              </div>
              <div className="button-row">
                <FileInput
                  primary={true}
                  size="small"
                  icon={["fa-light", "upload"]}
                  onHandleFile={onHandleFile}
                  className={clsx("main-dialog", "csv")}
                  disabled={loading}
                  loader={loading}
                  label={"Bulk Upload (.csv)"}
                ></FileInput>
                <Button
                  primary={false}
                  size="small"
                  icon={["fa-light", "download"]}
                  onClick={() => downloadBulkUploadTemplate(classroomProduct)}
                  className={clsx("main-dialog", "csv")}
                >
                  Template (.csv)
                </Button>
                <div className="template-download-info">
                  <TextTooltip text={TEMPLATE_DOWNLOAD_INFO_TEXT}>
                    <FontAwesomeIcon icon="fa-regular fa-circle-info" />
                  </TextTooltip>
                </div>
              </div>
              <OrFormDivider />
            </>
          )}
        </>
      )}

      <div className="create-trainee-button">
        <Button
          primary={false}
          size="small"
          icon={["fa-light", "user-plus"]}
          onClick={() => setShowCreateTraineeModal(true)}
          className="main-dialog"
        >
          Create {singular}
        </Button>
      </div>
      <OrFormDivider />

      <Controller
        control={control}
        name="trainees"
        defaultValue={[]}
        render={({ field: { onChange, value } }) => {
          return (
            <UserSelect
              defaultText={`Add one or more existing ${plural}...`}
              onChange={onChange}
              data={traineeData}
              selectedUsers={value}
              name={"trainees"}
            ></UserSelect>
          );
        }}
      />

      <CreateTraineeModal
        open={showCreateTraineeModal}
        onCreateTrainee={onCreateTrainee}
        onClose={onCreateTraineeModalClose}
        userLabels={userLabels}
      />
    </div>
  );
}
