import React from "react";
import clsx from "clsx";

import { CategoryAccordion } from "../components/category.accordion";

import "./menu-builder.categories.scss";

export const MenuBuilderCategories = ({
  categories,
  onEditModules,
  onEditSimulations,
  onEditCategory,
  className,
  onDeleteCategory,
  allModules,
  onApplyModulesHandler,
  classroom,
  enableEdit,
  checkVersionNumber,
}) => {
  return (
    <div className={clsx("menu-builder-categories-container", className)}>
      {categories?.map(
        (category, index) =>
          category.isVisible && (
            <CategoryAccordion
              key={`${category?.categoryId}.${index}`}
              category={category}
              onEditModules={onEditModules}
              onEditSimulations={onEditSimulations}
              onEditCategory={onEditCategory}
              onDeleteCategory={onDeleteCategory}
              allModules={allModules}
              onApplyModulesHandler={onApplyModulesHandler}
              classroom={classroom}
              enableEdit={enableEdit}
              checkVersionNumber={checkVersionNumber}
            />
          )
      )}
    </div>
  );
};
