import React, { useEffect, useState } from "react";
import { Controller, useForm, FormProvider } from "react-hook-form";
import {
  Loader,
  Notification,
  NotificationType,
  NotificationStack,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  RequiredItem,
} from "@transfr-inc/dashboard-components";
import {
  Button,
  Input,
  Textarea,
} from "@transfr-inc/dashboard-components/forms";
import "./create-category.modal.scss";
import { CategoryImageDropdown } from "../../components/category-images.dropdown";
import container from "../../../../../../../container";

export function CreateCategoryModal({
  classroom,
  open,
  onCancel,
  onCreateCategorySuccessHandler,
  checkVersionNumber,
}) {
  const defaultValues = { name: "", image: "", description: "" };
  const [loading, setLoading] = useState();
  const [notifications, setNotifications] = useState();
  const [responseNotification, setResponseNotification] = useState();
  const { menuBuilderService } = container;

  const methods = useForm({
    criteriaMode: "all",
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, reset, formState, control, setValue } = methods;

  const { errors, isValid } = formState;

  const showError = (...errors) => {
    setNotifications(
      errors.map((e) => ({
        type: NotificationType.error,
        message: e,
      }))
    );
  };

  const checkErrors = () => {
    if (Object.keys(errors).length > 0) {
      const messages = new Set(Object.values(errors).map((e) => e.message));
      showError(...messages.values());
    } else {
      setNotifications();
    }
  };

  useEffect(() => {
    if (errors) {
      checkErrors();
    }
  }, [formState]);

  useEffect(() => {
    !open && reset();
  }, [open]);

  const onCloseHandler = () => {
    onCancel && onCancel();
    setNotifications();
    reset({ keepDefaultValues: true });
  };

  const submitForm = async (values) => {
    setLoading(true);
    const data = {
      categoryName: values.name,
      categoryImage: values.image,
      categoryDescription: values.description,
    };

    const versionNumberIsValid = await checkVersionNumber();
    if (!versionNumberIsValid) {
      setLoading();
      onCloseHandler();
      return;
    }

    const category = await createCategory(data);
    if (category.error) {
      setResponseNotification({
        type: NotificationType.error,
        message: category.error,
      });
      setLoading();
    }

    setNotifications();
    setLoading();
    onCloseHandler();
    onCreateCategorySuccessHandler(category);
    return true;
  };

  const createCategory = async (data) => {
    const category = await menuBuilderService.createCustomCategory(
      classroom?.classroomId,
      data
    );
    await menuBuilderService.getClassroomCategories(classroom?.classroomId);
    return category;
  };

  const onImageSelected = async (image) => {
    setValue("image", image);
  };

  const createCustomCategoryHandler = () => {
    handleSubmit(submitForm)();
  };

  return (
    <Modal
      modalClassName={"create-category-modal"}
      open={open}
      onClose={onCloseHandler}
      preventClose={loading}
    >
      {loading && <Loader overlay></Loader>}
      <ModalHeader
        className="blue-icon"
        icon={["fa-regular", "plus"]}
        title="Create New Category"
      ></ModalHeader>
      <ModalBody>
        <div className="validation-errors">
          {notifications?.length && (
            <NotificationStack
              notifications={notifications}
            ></NotificationStack>
          )}
          {responseNotification?.type == NotificationType.error && (
            <div className="error">
              <Notification
                {...responseNotification}
                closable
                onClose={() => setResponseNotification()}
              >
                {responseNotification.message}
              </Notification>
            </div>
          )}
        </div>
        <div className="text-with-info">
          <label>
            Required Fields<sup>*</sup>
          </label>
        </div>
        <FormProvider {...methods}>
          <form className="create-category-form">
            <Controller
              control={control}
              name="name"
              defaultValue={defaultValues?.name}
              rules={{
                required: {
                  value: true,
                  message: "Required fields are missing.",
                },
              }}
              render={({ field: { onBlur, onChange, value, ref } }) => {
                return (
                  <Input
                    label={<RequiredItem text="Name" />}
                    type="text"
                    name="name"
                    errors={errors?.name}
                    ref={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    required
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="image"
              defaultValue={defaultValues?.image}
              rules={{
                required: {
                  value: true,
                  message: "Required fields are missing.",
                },
              }}
              render={({ field: { value } }) => {
                return (
                  <CategoryImageDropdown
                    className="category-image-dropdown"
                    onCategoryImageSelected={(image) => onImageSelected(image)}
                    selectedImage={value}
                  />
                );
              }}
            />
            <Controller
              control={control}
              defaultValue={defaultValues?.description}
              name="description"
              rules={{
                required: {
                  value: true,
                  message: "Required fields are missing.",
                },
              }}
              render={({ field: { onBlur, onChange, value, ref } }) => {
                return (
                  <Textarea
                    label={<RequiredItem text="Description" />}
                    name="categoryDescription"
                    errors={errors?.name}
                    ref={ref}
                    onBlur={onBlur}
                    onInputChange={onChange}
                    value={value}
                    required
                  />
                );
              }}
            />
          </form>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCloseHandler}>Cancel</Button>
        <Button
          primary
          onClick={createCustomCategoryHandler}
          disabled={!isValid}
        >
          Create Category
        </Button>
      </ModalFooter>
    </Modal>
  );
}
