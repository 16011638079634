import React, { useState } from "react";

import { Toolbar } from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { EditClassroomModal } from "../modals/edit-classroom.modal";
import { DeleteClassroomModal } from "../modals/delete-classroom.modal";

export function ClassroomToolbar({
  classroom,
  onEditClassroom,
  onDeleteClassroom,
  hasEdit,
  hasDelete,
}) {
  const [openEditModal, setOpenEditModal] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState();

  const onCancelEditModal = () => {
    setOpenEditModal(false);
  };

  const onEditClassroomHandler = (updatedClassroom) => {
    return onEditClassroom(updatedClassroom).then(() => setOpenEditModal());
  };

  const onDeleteClassroomHandler = () => {
    return onDeleteClassroom().then(setOpenDeleteModal);
  };

  const editButton = hasEdit && (
    <Button
      icon={["fa-regular", "pen-to-square"]}
      onClick={() => setOpenEditModal(true)}
      title={"Edit Classroom"}
      tooltip={"Edit Classroom"}
      size="small"
    ></Button>
  );

  const deleteButton = false && (
    <Button
      icon={["fa-regular", "trash-can"]}
      onClick={() => setOpenDeleteModal(true)}
      title={"Delete Classroom"}
      tooltip={"Delete Classroom"}
    ></Button>
  );

  return (
    <>
      <Toolbar>
        {editButton}
        {deleteButton}
      </Toolbar>
      {hasDelete && (
        <DeleteClassroomModal
          open={openDeleteModal}
          classroom={classroom}
          onCancel={setOpenDeleteModal}
          onDeleteClassroom={onDeleteClassroomHandler}
        ></DeleteClassroomModal>
      )}
      {hasEdit && (
        <EditClassroomModal
          open={openEditModal}
          classroom={classroom}
          onCancel={onCancelEditModal}
          onEditClassroomAsync={onEditClassroomHandler}
        ></EditClassroomModal>
      )}
    </>
  );
}
