import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
  Loader,
  Notification,
  NotificationType,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";
import container from "../../../../container";
import { useApiRequest } from "../../../../lib/http-client";
import { Role } from "../../../../models/role";
import { catchUserCreationError } from "../../../../services/user.util";
import { InstructorsEditor } from "../../creation/instructors-editor";
import { UserOptionItem } from "../../creation/user-option-item";

import "./add-instructor.modal.scss";

const filterInstructors = (data, existingInstructors) => {
  const dataToRemove = new Set(existingInstructors.map((e) => e.userId));
  return data.filter((d) => !dataToRemove.has(d.userId));
};

export function AddInstructorModal({
  open,
  classroom,
  onAddInstructor,
  onCancel,
  existingInstructors = [],
  async = false,
}) {
  const [selectedInstructors, setSelectedInstructors] = useState();
  const [loading, setLoading] = useState();
  const [notification, setNotification] = useState();

  const { clientCode: orgCode } = classroom;
  const { userService } = container;

  const methods = useForm({
    criteriaMode: "all",
    mode: "onChange",
  });

  const { setValue, watch } = methods;

  const {
    loading: loadinInstructors,
    response: instructors = [],
    sendRequest: getInstructors,
  } = useApiRequest(
    () =>
      userService
        .getOrganizationUsersForRoles(orgCode, [
          Role.INSTRUCTOR.id,
          Role.ORG_ADMIN.id,
        ])
        .then((data) => filterInstructors(data, existingInstructors)),
    false
  );

  const instructorsWatch = watch("instructors");

  const addInstructorOption = (data) => {
    const updatedSelectedOptions = [...selectedInstructors, data];
    setValue("instructors", updatedSelectedOptions);
  };

  const onCreateInstructor = async (data) => {
    try {
      return await createUser(data);
    } catch (error) {
      return catchUserCreationError(error, "instructor");
    }
  };

  const createUser = async (data) => {
    const instructor = await userService.createNewUser(data, orgCode);
    await getInstructors();
    addInstructorOption(new UserOptionItem(instructor));
    return instructor;
  };

  const onCloseHandler = () => {
    onCancel && onCancel();
    setNotification();
  };

  const onAddInstructorHandler = () => {
    if (async) {
      asyncHandler(selectedInstructors);
    } else {
      onAddInstructor(selectedInstructors);
    }
  };

  const asyncHandler = (value) => {
    setNotification();
    setLoading(true);
    onAddInstructor(value)
      .catch(() =>
        setNotification({
          type: NotificationType.error,
          message: "Something went wrong. Please try again.",
        })
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    instructorsWatch && setSelectedInstructors([...instructorsWatch]);
  }, [instructorsWatch]);

  useEffect(() => {
    if (open) {
      getInstructors();
      setSelectedInstructors([]);
      setValue("instructors", []);
    }
  }, [open]);

  return (
    <Modal
      backdropClassName={"add-instructor-modal-backdrop"}
      modalClassName={"add-instructor-modal"}
      open={open}
      onClose={onCloseHandler}
      preventClose={loading}
    >
      {(loading || loadinInstructors) && <Loader overlay></Loader>}
      <ModalHeader
        className="blue-icon"
        icon={["fa-light", "chalkboard-user"]}
        title="Add Instructor"
      ></ModalHeader>
      <ModalBody>
        {notification && (
          <Notification {...notification}>{notification.message}</Notification>
        )}
        <FormProvider {...methods}>
          <form>
            <InstructorsEditor
              className="add-instructor-form"
              instructorData={instructors}
              onCreateInstructor={onCreateInstructor}
            ></InstructorsEditor>
          </form>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCloseHandler}>Cancel</Button>
        <Button
          primary
          onClick={onAddInstructorHandler}
          disabled={selectedInstructors?.length === 0}
        >
          Add Instructor
        </Button>
      </ModalFooter>
    </Modal>
  );
}
