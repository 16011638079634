import React, { useRef, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import clsx from "clsx";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import "./classroom-gradebook-filter.modal.scss";
import { ClassroomGradebookFiltersInfo } from "./classroom-gradebook-filters-info";

export default ({
  open,
  onCancel,
  onApplyFilters,
  backdropClassName,
  modalClassName,
  className,
  onUpdateFilterCount,
  gradebookData,
  defaultModule,
}) => {
  const moduleFilterRef = useRef();
  const simsFilterRef = useRef();
  const traineesFilterRef = useRef();

  const [modalFilterCount, setModalFilterCount] = useState();

  const methods = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: {
      module: defaultModule,
      sims: simsFilterRef.current ?? [],
      trainees: traineesFilterRef.current ?? [],
    },
  });

  const onApply = () => {
    onApplyFilters({
      module: moduleFilterRef.current,
      simulations: simsFilterRef.current,
      users: traineesFilterRef.current,
    });
    onUpdateFilterCount(modalFilterCount);
  };

  const onFiltersChangeHandler = (filters) => {
    let count = 0;
    if (filters.module) {
      count++;
      moduleFilterRef.current = filters.module;
    }
    if (filters.sims) {
      const sims = filters.sims.map((s) => s.data);
      simsFilterRef.current = sims;
      count += filters.sims.length;
    }
    if (filters.trainees) {
      const trainees = filters.trainees.map((t) => t.data);
      traineesFilterRef.current = trainees;
      count += filters.trainees.length;
    }
    setModalFilterCount(count);
  };

  return (
    <Modal
      open={open}
      onClose={onCancel}
      backdropClassName={backdropClassName}
      modalClassName={clsx("classroom-gradebook-filter-modal", modalClassName)}
    >
      <ModalHeader
        title={
          <div className="modal-title-badge">
            {"Filters"}
            {modalFilterCount > 0 && (
              <div className="badge">{modalFilterCount}</div>
            )}
          </div>
        }
        icon={["fa-regular", "sliders"]}
      ></ModalHeader>
      <ModalBody>
        <div className="classroom-gradebook-info">
          <FormProvider {...methods}>
            <form
              className={clsx("classroom-gradebook-filters-form", className)}
              noValidate={true}
            >
              <ClassroomGradebookFiltersInfo
                gradebookData={gradebookData}
                onFiltersChangeHandler={onFiltersChangeHandler}
                defaultModule={defaultModule}
              />
            </form>
          </FormProvider>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onApply} primary>
          Apply
        </Button>
      </ModalFooter>
    </Modal>
  );
};
