import React from "react";
import { Switch, useRouteMatch, Route, Redirect } from "react-router-dom";
import ContainerRoute from "../../components/common/routing/container-route";

import { default as IntegrationsList } from "./list";
import SyncHistory from "./sync-history/sync-history";

export default function Integrations({ responsiveStyles = {} }) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ContainerRoute
        exact={true}
        path={`${path}/:connectionId/sync-history`}
        component={SyncHistory}
        responsiveStyles={responsiveStyles}
      ></ContainerRoute>
      <ContainerRoute
        exact={true}
        path={path}
        component={IntegrationsList}
        responsiveStyles={responsiveStyles}
      ></ContainerRoute>
      <Route path={`${path}/*`}>
        <Redirect to={path} />
      </Route>
    </Switch>
  );
}
