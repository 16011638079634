import { createStore } from "easy-peasy";
import {
  app,
  account,
  resetPassword,
  createAccount,
  organization,
  date,
  providerIcon,
  connection,
} from "./stores";

import container from "./container";

const store = createStore({
  app: app(container),
  account: account(container),
  resetPassword: resetPassword(container),
  createAccount: createAccount(container),
  organization: organization(container),
  date: date(container),
  providerIcon: providerIcon(container),
  connection: connection(container),
});

export default store;
