import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQueryString } from "../../lib/hooks";

import { useStoreActions, useStoreState } from "easy-peasy";

export default function ProProfsAuth() {
  const history = useHistory();
  const queryParams = useQueryString();

  const accountStore = useStoreState((store) => store.account);
  const accountActions = useStoreActions((store) => store.account);

  const goLoginPageFromProProfsWithUrlParameters = (urlParams) => {
    history.push({
      pathname: "/login",
      state: {
        fromProProfsLoginUrl: true,
        urlParams: urlParams,
      },
    });
  };

  const goLoginPageFromProProfs = () => {
    history.push({
      pathname: "/login",
      state: {
        fromProProfsLogin: true,
      },
    });
  };

  const isValidSession = () => {
    return accountStore.isLoggedIn && accountStore.currentUser?.email;
  };

  const onPageLoad = async () => {
    accountActions.clearLogOutRequested();
    let hasRedirectUrl = accountStore?.proProfsSettings?.redirectUrl
      ? true
      : false;
    const action = queryParams["action"] ? queryParams["action"] : "login";
    const loggedOut = queryParams["logged_out"]
      ? queryParams["logged_out"]
      : false;

    if (action === "logout" || action === "custom_logout") {
      if (accountStore?.proProfsSettings?.appLogoutRequested) {
        accountActions.setRedirectUrl();
        history.replace("/login");
      } else {
        await accountActions.logout();
      }
    } else {
      const site = encodeURIComponent(queryParams["site"]);
      const returnPage = encodeURIComponent(queryParams["return_page"]);
      const urlParams = new URLSearchParams({ site, returnPage }).toString();

      let hostname = "";
      if (queryParams["site"]) {
        const url = new URL(queryParams["site"]);
        accountActions.setSsoSourceUrl({
          hostname: url.hostname,
          url: url.origin,
        });
        hostname = url.hostname;
      }

      if (!loggedOut && isValidSession()) {
        if (!hasRedirectUrl) {
          const sourceDomain =
            accountStore?.proProfsSettings?.ssoSourceUrl?.hostname ?? hostname;
          await accountActions.doProProfsAuthorization({
            email: accountStore?.currentUser?.email,
            sourceDomain: sourceDomain,
          });
        }
        window.location.href = accountStore?.proProfsSettings?.redirectUrl;
      } else {
        goLoginPageFromProProfsWithUrlParameters(urlParams);
      }
    }
    goLoginPageFromProProfs();
  };

  useEffect(() => {
    onPageLoad();
  }, []);

  return <div></div>;
}
