import React from "react";
import {
  Column,
  ColumnOptions,
  ColumnType,
  TableCellListContent,
} from "@transfr-inc/dashboard-components/data-table";
import { OverflowText } from "@transfr-inc/dashboard-components";
import { Product } from "../../../../models";
import { ColumnHeaderInformationTooltip } from "../../../../components/custom/content/column-header-information-tooltip";
import { CheckedCell } from "../../../../components/table-cells";

const tsColumnsDefinition = [
  new Column(
    "totalCompleted",
    "Sims Completed",
    ColumnType.BASIC,
    new ColumnOptions({ sortingEnabled: true })
  ),
  new Column(
    "totalMastered",
    "Sims Mastered",
    ColumnType.BASIC,
    new ColumnOptions({ sortingEnabled: true })
  ),
];

const ceColumnsDefinition = [
  new Column(
    "totalCompleted",
    (
      <ColumnHeaderInformationTooltip
        headerTitle={"Careers Explored"}
        tooltipText={"Careers completed in the headset"}
      ></ColumnHeaderInformationTooltip>
    ),
    ColumnType.BASIC,
    new ColumnOptions({ sortingEnabled: true })
  ),
  new Column(
    "topCareers",
    "Top Careers",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      customFunction: (value, row) => {
        return (
          <TableCellListContent
            collection={row.topCareers?.length > 0 ? row.topCareers : undefined}
            collectionCount={value}
          />
        );
      },
    })
  ),
  new Column(
    "careersViewed",
    (
      <ColumnHeaderInformationTooltip
        headerTitle={"Careers Viewed"}
        tooltipText={"Careers viewed in Trek"}
      ></ColumnHeaderInformationTooltip>
    ),
    ColumnType.BASIC,
    new ColumnOptions({ sortingEnabled: true })
  ),
  new Column(
    "isSelected",
    "Career Selected",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      customFunction: (value, _row) => {
        return <CheckedCell value={value} />;
      },
    })
  ),
];

const columnsDefinitionMap = new Map();
columnsDefinitionMap.set(Product.TS, tsColumnsDefinition);
columnsDefinitionMap.set(Product.CE, ceColumnsDefinition);

export const getColumnsDefinitionByProduct = (productId) =>
  columnsDefinitionMap.get(productId);
