import clsx from "clsx";
import React from "react";

import "./category.image.scss";

export const CategoryImage = ({ categoryImageConfig, className }) => {
  const { imagePath, label } = categoryImageConfig ?? {};

  return (
    <div className={clsx("category-image-container", className)}>
      {imagePath && <img src={imagePath} alt={label} />}
      <span>{label}</span>
    </div>
  );
};
