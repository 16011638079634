import React, { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import container from "../../../../container";
import { useApiRequest } from "../../../../lib/http-client";
import SyncIntegrationErrorsModal from "../../../../components/modals/sync-integration-errors/sync-integration-errors.modal";

import "./sync-badge.scss";

const SyncBadge = ({
  connectionId,
  jobId,
  runStatus = "Failed",
  setFailed = () => {},
}) => {
  const { integrationService } = container;

  const [showSyncErrorsModal, setShowSyncErrorsModal] = useState();
  const [stateNotification, setStateNotification] = useState();

  const {
    loading: syncErrorsLoading,
    response: syncErrors = [],
    sendRequest: requestSyncErrors,
  } = useApiRequest(async () => {
    try {
      return await integrationService.getSyncErrors(connectionId, jobId);
    } catch {
      launchNotification({
        type: NotificationType.error,
        message: `An error ocurred while fetching sync errors for your connection.`,
      });
    }
  });

  const launchNotification = async (notification) => {
    setStateNotification(notification);
    setTimeout(() => {
      setStateNotification();
    }, 10000);
  };

  const errorBadge = (
    <div onClick={() => setShowSyncErrorsModal(true)}>
      <Badge
        value={
          <>
            <FontAwesomeIcon icon={["fa-solid", "triangle-exclamation"]} />
            View Errors
          </>
        }
        className="badge error"
      />
    </div>
  );

  const successBadge = (
    <Badge
      value={
        <>
          <FontAwesomeIcon icon={["fa-solid", "circle-check"]} />
          Success
        </>
      }
      className="badge success"
    />
  );

  const syncInfo = useMemo(() => {
    const errorsExist = syncErrors && syncErrors.length > 0;
    const isInErrorState =
      runStatus === "Failed" || (runStatus === "Success" && errorsExist);
    if (isInErrorState) {
      setFailed(true);
    }
    const isInSuccessState = runStatus === "Success" && !errorsExist;

    if (isInErrorState) {
      return errorBadge;
    } else if (isInSuccessState) {
      return successBadge;
    } else return;
  }, [syncErrors, runStatus]);

  return (
    <div className="sync-badge">
      {syncInfo}
      <SyncIntegrationErrorsModal
        open={showSyncErrorsModal}
        onClose={() => setShowSyncErrorsModal(false)}
        errors={syncErrors}
      />
      {stateNotification && (
        <div className="notification-container">
          <Notification
            type={stateNotification.type}
            icon={stateNotification.icon}
            onClose={() => {
              setStateNotification();
            }}
            closable
          >
            {stateNotification.message}
          </Notification>
        </div>
      )}
    </div>
  );
};

export default SyncBadge;
