import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Column,
  ColumnType,
  ColumnOptions,
  TableCellListContent,
  getSortKeyFunctionForTableCellList,
  BasicCell,
} from "@transfr-inc/dashboard-components/data-table";

import {
  OverflowText,
  EmailLink,
  TextAvatar,
} from "@transfr-inc/dashboard-components";

import { RootPath, UsersRoute } from "../../../lib/routes";
import container from "../../../container";

export const getUserDetailPathConfig = ({ breadcrumbLabel, classroomId }) => {
  return (value, row) => ({
    pathname: `${RootPath}${UsersRoute.path}/${row.id}`,
    state: {
      breadcrumbLabel,
      classroomId,
      userId: row.userId,
    },
  });
};

const { ceEnabled } = container.toggles;

export const DataTableColumnDefinition = {
  ORGANIZATION: new Column(
    "organizationNames",
    "Organizations",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      customFunction: (value, row) => {
        return (
          <TableCellListContent
            collection={value}
            collectionCount={row.organizationCount}
          />
        );
      },
      sortKeyFunctions: [
        getSortKeyFunctionForTableCellList(
          "organizationNames",
          "organizationCount"
        ),
      ],
    })
  ),
  FIRST_NAME: (linkFunction) =>
    new Column(
      "firstName",
      "First Name",
      linkFunction ? ColumnType.LINK : ColumnType.BASIC,
      new ColumnOptions({
        sortingEnabled: true,
        overflowEnabled: true,
        customFunction: linkFunction,
      })
    ),
  LAST_NAME: (linkFunction) =>
    new Column(
      "lastName",
      "Last Name",
      linkFunction ? ColumnType.LINK : ColumnType.BASIC,
      new ColumnOptions({
        sortingEnabled: true,
        overflowEnabled: true,
        customFunction: linkFunction,
      })
    ),
  AVATAR: new Column(
    "avatar",
    "",
    ColumnType.CUSTOM,
    new ColumnOptions({
      customFunction: (value, row) =>
        row.isAnonymous && ceEnabled ? (
          <div className="anonymous-container-avatar">
            <FontAwesomeIcon icon={["fa-solid", "user-secret"]} />
          </div>
        ) : (
          <TextAvatar text={[row.lastName, row.firstName]} />
        ),
    })
  ),
  EMAIL: new Column(
    "email",
    "Email",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      customFunction: (value) => {
        return value ? (
          <OverflowText text={value}>
            <EmailLink>{value}</EmailLink>
          </OverflowText>
        ) : (
          <FontAwesomeIcon icon="fa-thin fa-hyphen" />
        );
      },
    })
  ),
  CLASSROOM: new Column(
    "classroomNames",
    "Classrooms",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      customFunction: (value, row) => {
        return (
          <TableCellListContent
            collection={value}
            collectionCount={row.classroomCount}
          />
        );
      },
      sortKeyFunctions: [
        getSortKeyFunctionForTableCellList("classroomNames", "classroomCount"),
      ],
    })
  ),
  USERNAME: new Column(
    "username",
    "Username",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      customFunction: (value) =>
        value ? (
          <OverflowText text={value}>
            <span>{value}</span>
          </OverflowText>
        ) : (
          <BasicCell />
        ),
    })
  ),
};
