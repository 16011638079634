import React, { useEffect, useState } from "react";

import { useStoreActions, useStoreState } from "easy-peasy";

import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { useUnloadWarning } from "@transfr-inc/dashboard-components/hooks";

import { Product } from "../../../../models";
import { CeInsights, DashboardBanner } from "../../components";

import {
  exportOrganizationCeStats,
  getCareerRatings,
  getCeOrganizationDailyStats,
  getCeOrganizationStats,
  getCeOrganizationTopCareers,
  getStatsFileName,
} from "../../../../services";

import { useApiRequest } from "../../../../lib/http-client";

import "./ce.tab.scss";

export const CeTab = () => {
  const [orgStats, setOrgStats] = useState({ isLoading: true });
  const [dailyOrgStats, setOrgDailyStats] = useState({ isLoading: true });
  const [topCareers, setTopCareers] = useState();
  const [dateRange, setDateRange] = useState({});
  const { currentUser, role } = useStoreState((store) => store.account);
  const { students } = useStoreState((store) => store.organization);
  const { setDashboardLoaded } = useStoreActions((store) => store.app);
  const { organizationName, organizationCode, userId } = currentUser;
  const [organizationSelected, setOrganizationSelected] = useState();
  const explorersMap = new Map(students?.map((s) => [s.userId, s]));

  const getCeOrganizationSelectedStats = (orgCode, filters, isMultiOrg) => {
    Promise.all([
      getCeOrganizationDailyStats(
        userId,
        role,
        orgCode,
        filters,
        isMultiOrg
      ).then((response) => setOrgDailyStats({ isLoading: false, response })),
      getCeOrganizationTopCareers(
        userId,
        role,
        orgCode,
        filters,
        isMultiOrg
      ).then(setTopCareers),
      getCeOrganizationStats(userId, role, orgCode, filters, isMultiOrg).then(
        (response) => setOrgStats({ isLoading: false, response })
      ),
    ]).catch(() => {
      setOrgDailyStats({ isLoading: false });
      setOrgStats({ isLoading: false });
    });
  };

  const onApplyFilters = ({ dateRange, organization }) => {
    const { startDate, endDate } = dateRange || {};
    clearOrgStats();
    setDateRange({ startDate, endDate });
    setOrganizationSelected(organization);
  };

  const clearOrgStats = () => {
    setOrgDailyStats({ isLoading: true });
    setOrgStats({ isLoading: true });
  };

  const { loading: downloadingFile, sendRequest: exportInsights } =
    useApiRequest(() => {
      const selectedName = organizationSelected?.code
        ? organizationSelected.name
        : organizationName;
      const orgCode = organizationSelected?.code ?? organizationCode;
      const fileName = getStatsFileName(selectedName, dateRange);
      const filters = { dateRange };

      return exportOrganizationCeStats(
        role,
        orgCode,
        filters,
        fileName,
        !organizationSelected?.code
      );
    }, false);

  useUnloadWarning({ trigger: downloadingFile });

  const onExportStatsData = () => {
    const { startDate, endDate } = dateRange;
    if (startDate && endDate) {
      exportInsights();
    }
  };

  const getExplorersRatings = async (career) => {
    const orgCode = organizationSelected?.code ?? organizationCode;
    const ratings = await getCareerRatings({
      orgCode,
      userId,
      role,
      isMultiOrg: !organizationSelected?.code,
      learningExperienceId: career.learningExperienceId,
      dateRange,
    });

    return ratings.map((r) => {
      const { userId, rating } = r;
      const user = explorersMap.get(userId);
      return { ...user, rating };
    });
  };

  useEffect(() => {
    const { startDate, endDate } = dateRange;
    const filters = { dateRange };
    const orgCode = organizationSelected?.code ?? organizationCode;

    if (startDate && endDate) {
      getCeOrganizationSelectedStats(
        orgCode,
        filters,
        !organizationSelected?.code
      );
    }
  }, [dateRange, organizationSelected]);

  useEffect(() => {
    if (!orgStats.isLoading && !dailyOrgStats.isLoading) {
      setDashboardLoaded();
    }
  }, [orgStats, dailyOrgStats]);

  return (
    <div className="ce-multi-tab">
      <DashboardBanner product={Product.CE} />
      <CeInsights
        orgCode={organizationCode}
        generalStats={orgStats?.response}
        dailyStats={dailyOrgStats}
        onApplyFilters={onApplyFilters}
        topCareers={topCareers}
        onExportData={onExportStatsData}
        exportDataDisabled={downloadingFile}
        organizationFilterEnabled
        customExplorersFn={getExplorersRatings}
      ></CeInsights>
      <div className="notification-loader-container">
        {downloadingFile && (
          <Notification
            type={NotificationType.info}
            closable
            icon={["fa-solid", "spinner"]}
            iconConfig={{ spin: true }}
          >
            We’re preparing a download of{" "}
            <strong>{`${
              organizationSelected?.code
                ? organizationSelected.name
                : organizationName
            }'s`}</strong>{" "}
            insights. Please wait...
          </Notification>
        )}
      </div>
    </div>
  );
};
