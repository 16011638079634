import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import clsx from "clsx";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Loader,
  NotificationType,
  RequiredFieldsText,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { UserInfo } from "../../../components/custom/forms";
import { Role } from "../../../models/role";

import "./create-instructor-modal.scss";
import { useStoreActions, useStoreState } from "easy-peasy";

export function CreateTraineeModal({
  onClose,
  open,
  onCreateTrainee,
  modalClassName,
  backdropClassName,
  userLabels = { singular: "trainee" },
}) {
  const { singular } = userLabels;
  const defaultValues = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    username: "",
  };

  const { register, handleSubmit, control, formState, reset } = useForm({
    criteriaMode: "all",
    mode: "onBlur",
    defaultValues,
  });

  const [stateNotification, setStateNotification] = useState();
  const [loading, setLoading] = useState();

  const { refreshOrganizationUsers } = useStoreActions(
    (store) => store.organization
  );
  const { role, currentUser } = useStoreState((store) => store.account);
  const { userId, organizationCode } = currentUser;

  const submitForm = async (values) => {
    setLoading(true);
    const user = {
      firstName: values.firstName,
      lastName: values.lastName,
      roleId: Role.STUDENT.id,
    };

    if (values.email) user["email"] = values.email;
    if (values.username) user["username"] = values.username;
    if (values.password) user["password"] = values.password;

    const trainee = await onCreateTrainee(user);

    if (trainee?.error) {
      setStateNotification({
        type: NotificationType.error,
        message: trainee.error,
      });

      setLoading();
      return false;
    }

    await refreshOrganizationUsers({ role, orgCode: organizationCode, userId });
    setLoading();
    setStateNotification();
    onClose(trainee);

    return true;
  };

  const createTrainee = () => {
    handleSubmit(submitForm)();
  };

  useEffect(() => {
    !open && reset();
  }, [open]);

  return (
    <Modal
      modalClassName={clsx("create-trainee-modal", modalClassName)}
      backdropClassName={backdropClassName}
      open={open}
      onClose={onClose}
      uniqueName="create-trainee-modal"
      preventClose={loading}
    >
      {loading && <Loader overlay></Loader>}
      <ModalHeader
        className="blue-icon small"
        icon={["fa-light", "user-plus"]}
        title={`Create ${singular}`}
        uniqueName="create-trainee-modal"
      ></ModalHeader>
      <ModalBody className={"create-trainee modal-user-details"}>
        <RequiredFieldsText />
        <div className="details-section-title"> User Details </div>
        <UserInfo
          register={register}
          control={control}
          formState={formState}
          defaultValues={defaultValues}
          role={Role.STUDENT}
          emailOptional={true}
          responseNotification={stateNotification}
          onNotificationChange={setStateNotification}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={createTrainee}
          primary={true}
          disabled={!formState.isValid}
        >
          Create {singular}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default CreateTraineeModal;
