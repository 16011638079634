import React from "react";
import clsx from "clsx";
import { Terms } from "../../../models/links";
import "./terms-and-conditions.scss";

export default function ({ checked, handleChecked, errors, ...rest }) {
  return (
    <div className={clsx("terms-conditions", errors && "errors")}>
      <input
        type="checkbox"
        onChange={handleChecked}
        checked={checked}
        {...rest}
      />
      <div>
        I agree to the{" "}
        <a href={Terms.url} target="_blank" rel="noreferrer">
          <u>Transfr Terms and Conditions</u>
        </a>
      </div>
    </div>
  );
}
