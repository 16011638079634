import React, { useEffect, useState } from "react";
import {
  handleSort,
  SortDirection,
} from "@transfr-inc/dashboard-components/data-table";

import container from "../../../../container";
import { useApiRequest } from "../../../../lib/http-client";
import { mergeMasteryInfoGradebook } from "../components/classroom.modules.utils";
import { ClassroomGradebookTable } from "../components/classroom.gradebook-table";

import "./classroom.gradebook.tab.scss";
import { ClassroomGradebookFilter } from "../../../../components/custom/forms/classroom-gradebook.filter";

export const ClassroomGradebookTab = ({ classroom, users }) => {
  const [gradebookData, setGradebookData] = useState();
  const [moduleData, setModuleData] = useState();
  const [filters, setFilters] = useState({});
  const [defaultModule, setDefaultModule] = useState();

  const { insightsService } = container;

  const { loading: loadingModules, response: modulesMap } = useApiRequest(() =>
    insightsService
      .getModulesForOrg(classroom.clientCode)
      .then((response) => new Map(response.map((m) => [m.moduleId, m])))
  );

  const { loading: loadingMasteryInfo, response: masteryInfo } = useApiRequest(
    () =>
      insightsService
        .getClassroomMasteryInfo(classroom.classroomId)
        .catch(() => new Map())
  );

  const onSortClick = ({ column, sortDirection }) => {
    const sortedData = handleSort(
      moduleData,
      column,
      "isAnonymous",
      sortDirection || SortDirection.ASCENDING
    );
    setModuleData(sortedData);
  };

  const onApplyFilters = (filtersData) => {
    setFilters(filtersData);
  };

  useEffect(() => {
    if (modulesMap && masteryInfo) {
      const modulesArray = [...modulesMap.values()];
      const result = mergeMasteryInfoGradebook(
        users,
        modulesArray,
        masteryInfo
      );

      setGradebookData(result);
      setDefaultModule(result.get(modulesArray[0].moduleId));
    }
  }, [modulesMap, masteryInfo]);

  useEffect(() => {
    if (gradebookData) {
      const { users } = filters;
      setModuleData(users);
    }
  }, [gradebookData, filters]);

  useEffect(() => () => insightsService.cancelRequest(), []);

  return (
    <div className="classroom-gradebook-tab">
      <div className="header-container">
        <div className="gradebook-filter-container">
          <ClassroomGradebookFilter
            onApplyFilters={onApplyFilters}
            gradebookData={gradebookData}
            defaultModule={defaultModule}
          ></ClassroomGradebookFilter>
          {filters.module && (
            <div className="filter-module">
              Module: <span>{filters?.module?.displayName}</span>
            </div>
          )}
        </div>
      </div>
      <div className="gradebook-table-container">
        <ClassroomGradebookTable
          data={moduleData}
          simulations={filters.simulations}
          classroom={classroom}
          isLoading={loadingMasteryInfo || loadingModules}
          onSortClick={onSortClick}
        ></ClassroomGradebookTable>
      </div>
    </div>
  );
};
