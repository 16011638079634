import React, { useEffect, useState } from "react";
import { useContainerQuery } from "@transfr-inc/dashboard-components/container-query";

import { SlashCircleIcon } from "@transfr-inc/dashboard-components";
import {
  SortDirection,
  getSortMethod,
} from "@transfr-inc/dashboard-components/data-table";

import {
  Button,
  SortHeader,
  useSortHeader,
} from "@transfr-inc/dashboard-components/forms";

import { ShortcutAction } from "../../../../components/shortcuts";

import { ClassroomSimulationAccordion } from "./classroom.simulation.accordion";

import "./classroom.simulations.scss";

const query = {
  "small-panel": {
    minWidth: 320,
    maxWidth: 850,
  },
  "medium-panel": {
    minWidth: 850,
    maxWidth: 1150,
  },
  "large-panel": {
    minWidth: 1150,
  },
};

export function ClassroomSimulations({
  data,
  openAccordion,
  onClearFilters,
  isAllExpanded,
}) {
  const [params, containerRef] = useContainerQuery(query);
  const [simulations, setSimulations] = useState();

  const sortHeader = useSortHeader({
    data: [
      {
        title: "Sim",
        propName: "displayName",
        disabled: true,
      },
      {
        title: "Module",
        propName: "moduleName",
      },
    ],
    onSortHandler: (header, sortDirection) => {
      const sortDirectionChar =
        sortDirection === SortDirection.DESCENDING ? "-" : "+";
      const sortParam = [`${sortDirectionChar}${header.propName}`];
      const sortFunc = getSortMethod(sortParam);
      simulations.sort(sortFunc);
    },
  });

  useEffect(() => {
    if (data) {
      setSimulations([]);
      const simulationsData = [];
      data.map((module) => {
        const moduleSims = module.simulations.map((sim) => ({
          ...sim,
          moduleName: module.displayName,
          key: `${module.moduleId}.${sim.learningExperienceId}`,
        }));
        simulationsData.push(...moduleSims);
      });
      setSimulations(simulationsData);
    }
  }, [data]);

  return (
    <div className="classroom-simulations-section">
      {simulations?.length === 0 && (
        <ShortcutAction className="no-data-message" tabIndex={-1}>
          <div>
            <SlashCircleIcon></SlashCircleIcon>
            No data to display. Please try adjusting your search or filters.
          </div>
          {openAccordion && (
            <Button onClick={onClearFilters} icon="fa-regular fa-circle-xmark">
              Clear All Filters
            </Button>
          )}
        </ShortcutAction>
      )}
      <div className="simulations-accordions" ref={containerRef}>
        {simulations?.length > 0 && (
          <SortHeader
            headers={sortHeader.headers}
            onSort={sortHeader.onSort}
          ></SortHeader>
        )}
        {simulations?.map((simulation, i) => (
          <ClassroomSimulationAccordion
            module={simulation}
            key={simulation.key}
            responsiveStyles={params}
            isOpen={(openAccordion && i === 0) || isAllExpanded}
          ></ClassroomSimulationAccordion>
        ))}
      </div>
    </div>
  );
}
