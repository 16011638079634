import React from "react";

import { Loader } from "@transfr-inc/dashboard-components";

import {
  useClassroomCEProgress,
  useClassroomTrekProgress,
} from "../../../../lib/classroom-progress.hook";
import { ClassroomTrekCareersExplored } from "../components/classroom.trek-careers-explored";
import { TrekClassroomSummaryStats } from "../components/classroom.trek.databoxes";
import {
  TopCareersSelected,
  useTopCareersSelected,
} from "../../../../components/top-careers-selected";

import "./classroom.progress.trek.tab.scss";

export const ClassroomProgressTrekTab = ({ classroom, users }) => {
  const { classroomId } = classroom;
  const { insights: masteryStats } = useClassroomCEProgress(classroomId, users);

  const { careersExplored, loadingCareersExplored, trekInsights } =
    useClassroomTrekProgress(classroomId, users);

  const { topCareers, loading } = useTopCareersSelected({
    classroomId,
  });

  return loadingCareersExplored ? (
    <Loader></Loader>
  ) : (
    <>
      <TopCareersSelected
        activeUsers={trekInsights?.totalActiveUsers}
        topCareers={topCareers}
        isLoading={loading}
        breadcrumbLabel={"classroom"}
        users={users}
      />
      <div className="classroom-trek-insights-section">
        <div className="trek-insights-header">
          <h2>Insights</h2>
        </div>
        <TrekClassroomSummaryStats
          masteryStats={masteryStats}
          trekInsights={trekInsights}
        />
      </div>
      <ClassroomTrekCareersExplored
        classroom={classroom}
        data={careersExplored}
      />
    </>
  );
};
