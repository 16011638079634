import React, { useState, useEffect } from "react";
import clsx from "clsx";

import { Filter } from "@transfr-inc/dashboard-components/forms";
import { useApiRequest } from "../../../lib/http-client";
import { getClassroomsStats } from "../../../services/classroom.utils";

export function ClassroomFilter({
  userId,
  role,
  orgCode,
  onClassroomSelected,
  placeholder = "Filter by Classroom",
  className,
  product,
}) {
  const defaultOption = { name: "All Classrooms" };
  const [classrooms, setClassrooms] = useState([defaultOption]);
  const { response } = useApiRequest(() =>
    getClassroomsStats(userId, role, orgCode)
  );

  useEffect(() => {
    if (product && response) {
      const filteredResponse = response
        ? response.filter((classrooom) => classrooom.products.includes(product))
        : [];
      setClassrooms([defaultOption, ...filteredResponse]);
    } else {
      response && setClassrooms([defaultOption, ...response]);
    }
  }, [response]);

  return (
    <Filter
      className={clsx("classroom-filter small", className)}
      placeholder={placeholder}
      data={classrooms}
      clearValue={classrooms[0]}
      onFilterSelected={onClassroomSelected}
      labelKey={"name"}
    ></Filter>
  );
}
