import React, { useState, useEffect } from "react";
import { ToggleButton, Button } from "@transfr-inc/dashboard-components/forms";

import { Terms } from "../../../models/links";

import "./student-terms-and-conditions.scss";

export const StudentTerms = ({ blocking, handleChecked }) => {
  const [overEighteen, setOverEighteen] = useState(true);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [parentalPermission, setParentalPermission] = useState(false);

  useEffect(() => {
    handleChecked(
      overEighteen ? termsAgreed : termsAgreed && parentalPermission
    );
  }, [overEighteen, termsAgreed, parentalPermission]);

  return (
    <div className="account-form">
      <div className="instructions">
        You&apos;re almost done! Please agree to the terms and conditions below.
      </div>
      <div className="toggle-with-description">
        <div className="description">Are you 18 or older?</div>
        <ToggleButton
          onChange={setOverEighteen}
          value={overEighteen}
          affirmText={"YES"}
          negateText={"NO"}
        />
      </div>
      {!overEighteen && (
        <div className="toggle-with-description">
          <div className="description">
            Do you have your parent’s or guardian’s permission to use this app?
          </div>
          <ToggleButton
            onChange={setParentalPermission}
            value={parentalPermission}
            affirmText={"YES"}
            negateText={"NO"}
          />
        </div>
      )}
      <div className="toggle-with-description">
        <div className="description">
          {overEighteen
            ? "I agree to the "
            : "Does your parent or guardian agree to Transfr’s "}
          <a href={Terms.url} target="_blank" rel="noreferrer">
            <u>Terms and Conditions</u>
          </a>
        </div>
        <ToggleButton
          onChange={setTermsAgreed}
          value={termsAgreed}
          affirmText={"YES"}
          negateText={"NO"}
        />
      </div>
      <Button
        className="primary block"
        type="submit"
        label="Sign Up"
        disabled={
          overEighteen ? !termsAgreed : !termsAgreed || !parentalPermission
        }
        loader={blocking}
      />
    </div>
  );
};
