import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Loader,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import "./delete-classroom.modal.scss";

export function DeleteClassroomModal({
  open,
  onCancel,
  onDeleteClassroom,
  classroom,
}) {
  const [loading, setLoading] = useState();

  const onCloseHandler = () => {
    onCancel && onCancel();
  };

  const onDeleteClassroomHandler = () => {
    setLoading(true);
    onDeleteClassroom().finally(() => setLoading(false));
  };

  return (
    <Modal
      modalClassName="delete-classroom-modal"
      open={open}
      onClose={onCloseHandler}
      preventClose={loading}
    >
      {loading && <Loader overlay></Loader>}
      <ModalHeader
        className="red-icon"
        icon={["fa-light", "trash-can"]}
        title="Delete Classroom"
      ></ModalHeader>
      <ModalBody>
        Are you sure you would like to delete the classroom{" "}
        <strong>{classroom.name}</strong> ? This action cannot be undone.
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCloseHandler} disabled={loading}>
          No, Cancel
        </Button>
        <Button
          destructive
          onClick={onDeleteClassroomHandler}
          disabled={loading}
        >
          Yes, Delete Classroom
        </Button>
      </ModalFooter>
    </Modal>
  );
}
