import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useStoreState } from "easy-peasy";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Loader,
  NotificationType,
  Notification,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { UserInfo } from "../../components/custom/forms";

import container from "../../container";

import "./profile-modal.scss";

export const EditProfileModal = ({ onCancel, show, user, onEditUser }) => {
  const defaultValues = { ...user };
  const { register, handleSubmit, control, formState, reset, trigger } =
    useForm({
      criteriaMode: "all",
      mode: "onChange",
      defaultValues,
    });
  const { isValid } = formState;
  const { userService } = container;
  const { currentUser } = useStoreState((store) => store.account);
  const { userId } = currentUser;

  const [loading, setLoading] = useState();
  const [notification, setNotification] = useState();

  const onNotificationClose = () => {
    setNotification();
  };

  const onCloseModal = () => {
    onCancel();
    reset();
    setNotification();
  };

  async function submitForm(values) {
    try {
      await userService.updateUserMetadata({
        userId,
        ...values,
      });
      onEditUser(values);
      reset();
    } catch (error) {
      console.log("failure");
      const errorMessage =
        error.data === undefined
          ? "Unable to update information"
          : error.data.detail;
      setNotification({
        type: NotificationType.error,
        message: errorMessage,
      });
    } finally {
      setLoading();
    }
  }

  const onClick = () => {
    setLoading(true);
    handleSubmit(submitForm)();
  };

  useEffect(() => {
    reset(user);
  }, [user]);

  useEffect(() => {
    show && trigger();
  }, [show]);

  return (
    <Modal modalClassName={"profile-modal"} open={show} onClose={onCloseModal}>
      {loading && <Loader overlay />}
      <ModalHeader
        className="blue-icon small"
        icon={["fa-regular", "edit"]}
        title="Edit Profile"
      ></ModalHeader>
      <ModalBody>
        {notification && (
          <Notification
            {...notification}
            closable
            onClose={onNotificationClose}
          >
            {notification.message}
          </Notification>
        )}
        <UserInfo
          role={0}
          register={register}
          control={control}
          formState={formState}
          emailOptional
          firstNameOptional
          lastNameOptional
          defaultValues={defaultValues}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCloseModal} disabled={loading}>
          Cancel
        </Button>
        <Button primary onClick={onClick} disabled={!isValid || loading}>
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};
