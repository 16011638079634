import container from "../container";
import { Product, Role } from "../models";

export const getClassroomUsersMastery = (classroom) => {
  const { classroomId, products } = classroom;
  const { insightsService, simulationsService } = container;

  const setTopCareers = async (masteryInfo) => {
    const simulations = (await simulationsService.getAllSimulations()).map(
      (s) => [s.learningExperienceId, s]
    );
    const simulationsMap = new Map(simulations);

    for (const m of masteryInfo) {
      const topCareers = m.topExperiences.map((e) => {
        const sim = simulationsMap.get(e);
        return sim?.occupationName;
      });

      const careersSet = new Set(topCareers);
      m.topCareers = [...careersSet.values()].filter((c) => c);
      m.totalTopCareers = careersSet.size;
    }

    return masteryInfo;
  };

  const getCeClassroomUsersMastery = async (classroomId) => {
    const masteryInfo = await insightsService.getCeClassroomExplorersMastery(
      classroomId
    );
    return setTopCareers(masteryInfo);
  };

  return products[0] === Product.TS
    ? insightsService.getClassroomTraineesMastery(classroomId)
    : getCeClassroomUsersMastery(classroomId);
};

export const getCareerRatings = ({
  orgCode,
  userId,
  classroomId,
  role,
  dateRange,
  learningExperienceId,
  isMultiOrg,
}) => {
  const { classroomService, organizationService } = container;
  const filters = { dateRange };

  if (classroomId) {
    return classroomService.getClassroomCareerRatings(
      classroomId,
      learningExperienceId,
      filters
    );
  } else if (userId && role === Role.INSTRUCTOR.id) {
    return organizationService.getInstructorCareerRatings(
      userId,
      learningExperienceId,
      filters
    );
  } else if (orgCode && !isMultiOrg) {
    return organizationService.getOrganizationCareerRatings(
      orgCode,
      learningExperienceId,
      filters
    );
  } else if (orgCode) {
    return organizationService.getMultiOrganizationCareerRatings(
      orgCode,
      learningExperienceId,
      filters
    );
  } else {
    return Promise.resolve([]);
  }
};

export const setCareerInfo = async (sims = []) => {
  const { simulationsService } = container;

  const simulations = (await simulationsService.getAllSimulations()).map(
    (s) => [s.learningExperienceId, s]
  );
  const simulationsMap = new Map(simulations);

  return sims
    .map((s) => {
      const career = simulationsMap.get(s.learningExperienceId);
      return (
        career && {
          ...s,
          ...career,
        }
      );
    })
    .filter((c) => c);
};

export const sortByNameFn = (a, b) => {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();
  const ratingDiff = b.rating - a.rating;
  if (ratingDiff == 0) {
    return aName < bName ? -1 : 1;
  } else {
    return ratingDiff;
  }
};
